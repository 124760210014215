import {
  Theme,
  createStyles,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import {
  FavoriteBorder,
  LockOpen,
  FlipToFront,
  FlipToBack,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
} from '@material-ui/icons';

interface Props {}

const IconBar = (props: Props) => {
    const classes = styles(props);
    return (
      <div className={classes.itemIconBar}>
        <IconButton aria-label="Favorite" disabled className={classes.iconButton}>
          <FavoriteBorder fontSize="small" />
        </IconButton>
        <IconButton aria-label="Lock" disabled className={classes.iconButton}>
          <LockOpen fontSize="small" />
        </IconButton>
        <IconButton aria-label="Flip to Front" disabled className={classes.iconButton}>
          <FlipToFront fontSize="small" />
        </IconButton>
        <IconButton aria-label="Flip to Back" disabled className={classes.iconButton}>
          <FlipToBack fontSize="small" />
        </IconButton>
        <IconButton aria-label="Align to Bottom" disabled className={classes.iconButton}>
          <VerticalAlignBottom fontSize="small" />
        </IconButton>
        <IconButton aria-label="Align to Center" disabled className={classes.iconButton}>
          <VerticalAlignCenter fontSize="small" />
        </IconButton>
        <IconButton aria-label="Align to Top" disabled className={classes.iconButton}>
          <VerticalAlignTop fontSize="small" />
        </IconButton>
      </div>
    );
}

const fieldBackground = '#35373B';
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    itemIconBar: {
      backgroundColor: fieldBackground,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(),
    },
    iconButton: {
      margin: 0,
      marginRight: theme.spacing() / 2,
      padding: theme.spacing() / 4,
    },
  })
)

export default IconBar;
