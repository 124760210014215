import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { UpdateUserSetting } from '../../reducers/settings';
import {
  Theme,
} from '@material-ui/core/styles';

import {
  makeStyles,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';

import Jumbotron from '../../components/Jumbotron';
import settingStyles from './SettingStyles.css';
import { LocalStorageKey, useLocalStorageState } from '../../components/Hooks/useLocalStorageState'

interface Props {};

const CollisionSettings = (props: Props) => {
  const styles = makeStyles<Theme>(settingStyles);
  const classes = styles(props);

  const [collisionPrevention, setCollisionPrevention] = useLocalStorageState(LocalStorageKey.CollisionPrevention)
  const [collisionDetection, setCollisionDetection] = useLocalStorageState(LocalStorageKey.CollisionDetection)
  const [keepInRoom, setKeepInRoom] = useLocalStorageState(LocalStorageKey.KeepInRoom)
  const [snapPosition, setSnapPosition] = useLocalStorageState(LocalStorageKey.SnapPosition)

  return (
    <div className={classes.root}>
      <Jumbotron
        title="Collision Settings"/>
      <div className={classes.form}>
        <Paper
          className={classes.formControl}>
          <Typography variant="body1">Collision Prevention</Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant="body1">{`${collisionPrevention ? 'On' : 'Off'}`}</Typography>
          <Switch
            color="secondary"
            checked={collisionPrevention}
            onChange={e => setCollisionPrevention(e.target.checked)}
          />
          </div>
        </Paper>
        <Paper
          className={classes.formControl}>
          <Typography variant="body1">Collision Detection</Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant="body1">{`${collisionDetection ? 'On' : 'Off'}`}</Typography>
          <Switch
            color="secondary"
            checked={collisionDetection}
            onChange={e => setCollisionDetection(e.target.checked)}
          />
          </div>
        </Paper>
        <Paper
          className={classes.formControl}>
          <Typography variant="body1">Keep In Room</Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant="body1">{`${keepInRoom ? 'On' : 'Off'}`}</Typography>
          <Switch
            color="secondary"
            checked={keepInRoom}
            onChange={e => setKeepInRoom(e.target.checked)}
          />
          </div>
        </Paper>
        <Paper
          className={classes.formControl}>
          <Typography variant="body1">Snap Position</Typography>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
          <Typography variant="body1">{`${snapPosition ? 'On' : 'Off'}`}</Typography>
          <Switch
            color="secondary"
            checked={snapPosition}
            onChange={e => setSnapPosition(e.target.checked)}
          />
          </div>
        </Paper>
      </div>
    </div>
  );
}

export default CollisionSettings;
