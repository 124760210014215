import { all, takeLatest, put, call } from 'redux-saga/effects';
import {
  types,
  GetSceneLayoutsAction,
  CreateLayoutAction,
  UpdateLayoutAction,
  DeleteLayoutAction,
  UpdateLayoutsAction,
} from '../reducers/layouts';
import {
  types as designerTypes,
} from '../reducers/designer';
import { SagaReady } from '../reducers/lifecycle';
import { NeedSaveAction, SavingAction, types as blueTypes } from '../reducers/blue';

// Api
import { placezApi } from '../api';

export default function* layoutSaga() {
  yield all([
    takeLatest(types.GET_SCENE_LAYOUTS, getSceneLayouts),
    takeLatest(types.CREATE_LAYOUT, createLayout),
    takeLatest(types.UPDATE_LAYOUT, updateLayout),
    takeLatest(types.UPDATE_LAYOUTS, updateLayouts),
    takeLatest(types.DELETE_LAYOUT, deleteLayout),
    takeLatest(types.UPDATE_LAYOUT_SUCCESS, updateLayoutSuccess),
  ]);
  yield put(SagaReady('layout'));
}

function* getSceneLayouts(action: GetSceneLayoutsAction) {
  try {
    const { sceneId } = action;
    const response = yield call(placezApi.getSceneLayouts, sceneId);
    yield put({ type: types.GET_SCENE_LAYOUTS_SUCCESS, layouts: response.parsedBody });
  } catch (error) {
    yield put({ type: types.GET_SCENE_LAYOUTS_FAILURE, error });
  }
}

function* createLayout(action: CreateLayoutAction) {
  try {
    const { layout } = action;
    const response = yield call(placezApi.postLayout, layout);
    yield put({ type: types.CREATE_LAYOUT_SUCCESS, layout: response.parsedBody });
    yield put({ type: designerTypes.GET_DESIGNER_LAYOUT_SUCCESS, layout: response.parsedBody });
  } catch (error) {
    yield put({ type: types.CREATE_LAYOUT_FAILURE, error });
  }
}

function* updateLayout(action: UpdateLayoutAction) {
  try {
    const { layout } = action;
    const response = yield call(placezApi.putLayout, layout);
    yield put({ type: types.UPDATE_LAYOUT_SUCCESS, layout: response.parsedBody });
  } catch (error) {
    yield put({ type: types.UPDATE_LAYOUT_FAILURE, error });
    yield put({ type: blueTypes.TOAST_MESSAGE, message: 'Failed To Save' });
  }
}

function* updateLayouts(action: UpdateLayoutsAction) {
  try {
    const { layouts } = action;
    const response = yield call(placezApi.putLayouts, layouts);
    yield put({ type: types.UPDATE_LAYOUTS_SUCCESS, layouts: response.parsedBody });
  } catch (error) {
    yield put({ type: types.UPDATE_LAYOUT_FAILURE, error });
    yield put({ type: blueTypes.TOAST_MESSAGE, message: 'Failed To Save' });
  }
}

function* deleteLayout(action: DeleteLayoutAction) {
  try {
    const { layoutId } = action;
    const response = yield call(placezApi.deleteLayout, layoutId);
    yield put({ type: types.DELETE_LAYOUT_SUCCESS, layoutId });
  } catch (error) {
    yield put({ type: types.DELETE_LAYOUT_FAILURE, error });
  }
}

function* updateLayoutSuccess() {
  yield put(NeedSaveAction(false));
  yield put(SavingAction(false));
}
