
import { useSelector } from 'react-redux';
import DesignerView from '../../DesignerView';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import classnames from 'classnames';
import {
  HandlesFromBlue,
} from '../../../models';
import Header from '../Header';
import { FixtureViewState, PlannerTool } from '../../../../../models/GlobalState';
import { ReduxState } from '../../../../../reducers';
import FloorplanUI from './FloorPlanUI';
import FixturePlanUI from './FixturePlanUI';
interface Props {
  placeId: number;
  placePlannerMode: Function;
  designerCallbacks: HandlesFromBlue;
  floorPlanId?: number;
}

const PlaceDesignerView = (props: Props) => {
  const classes = styles(props);

  const plannerToolState = useSelector((state: ReduxState) => state.globalstate.plannerTool);
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);

  const isFloorPlan = (): boolean => {
    return fixtureViewState === FixtureViewState.Floorplan ?? false;
  }

  const {
    designerCallbacks
  } = props;

  const fullScreen = (plannerToolState !== PlannerTool.Designer && plannerToolState !== PlannerTool.Photosphere) || isFloorPlan();

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Header mode={'Floor Plan Mode'} />
      </div>
      <div className={classes.designerContainer}>
        <div className={fullScreen
          ? classnames(classes.designerViewContainer, classes.designerViewContainerFullscreen)
          : classes.designerViewContainer}>
          <DesignerView
              showFloorplan={isFloorPlan()}
              designerCallbacks={designerCallbacks} />
        </div>
        { isFloorPlan() &&
          <FloorplanUI
            designerCallbacks={designerCallbacks}
          />
        }
        { !isFloorPlan() &&
          <FixturePlanUI
            handlesFromBlue={designerCallbacks}
          />
        }
      </div>
    </div>
  );
}

const headerHeight = '29px';
const itempanelWidth = '258px';
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    headerContainer: {
      width: '100%',
      height: headerHeight,
    },
    designerContainer: {
      display: 'flex',
      width: '100%',
      height: `calc(100% - ${headerHeight})`,
    },
    designerViewContainer: {
      width: `calc(100% - ${itempanelWidth})`,
      height: '100%',
    },
    designerViewContainerFullscreen: {
      width: '100%',
    },
    itempanels: {
      background: '#1F2126',
      width: itempanelWidth,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
    },
    itempanelsHidden: {
      display: 'hidden',
    },
    bottomButtons: {
      float: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '10px',
    },
  })
);

export default PlaceDesignerView;
