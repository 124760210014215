import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  BlueLayoutDesigner,
} from '../../components/Blue';
import { InitializeLayoutDesign } from '../../reducers/designer';
import { GetScene } from '../../reducers/scenes';
import { ChangeLayoutViewState } from '../../reducers/globalState';
import { LayoutViewState } from '../../models/GlobalState';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
  })
);

interface Props {}

const LayoutPlanner = (props: Props) => {
  const classes = styles(props);
  const dispatch = useDispatch();
  const { id, planId } = useParams();

  useEffect(() => {
    dispatch(ChangeLayoutViewState(LayoutViewState.TwoDView));
  }, [])

  useEffect(() => {
    dispatch(GetScene(parseInt(id)));
    dispatch(InitializeLayoutDesign(planId));
  }, [id]);

  return (
    <div className={classes.root}>
      {id && planId &&
        <BlueLayoutDesigner/>
      }
    </div>
  );
}

export default LayoutPlanner;
