import { WeeklyOption, DateRange, getWeekRange } from '../../sharing/utils/DateHelper';

interface RangeFilterOption {
  value: WeeklyOption;
  label: string;
  range: DateRange;
}

export const dashboardWeeklyOptions: RangeFilterOption[] = [
  {
    value: WeeklyOption.LastWeek,
    label: 'Last Week',
    range: getWeekRange(WeeklyOption.LastWeek),
  },
  {
    value: WeeklyOption.ThisWeek,
    label: 'This Week',
    range: getWeekRange(WeeklyOption.ThisWeek),
  },
  {
    value: WeeklyOption.NextWeek,
    label: 'Next Week',
    range: getWeekRange(WeeklyOption.NextWeek),
  },
  {
    value: WeeklyOption.LastMonth,
    label: 'Last Month',
    range: getWeekRange(WeeklyOption.LastMonth),
  },
  {
    value: WeeklyOption.ThisMonth,
    label: 'This Month',
    range: getWeekRange(WeeklyOption.ThisMonth),
  },
  {
    value: WeeklyOption.NextMonth,
    label: 'Next Month',
    range: getWeekRange(WeeklyOption.NextMonth),
  },
  {
    value: WeeklyOption.AllTime,
    label: 'All Time',
    range: getWeekRange(WeeklyOption.AllTime),
  },
];
