import { createRef, useEffect, useRef } from 'react';
import { BlueJS } from '../../../blue/blueEntryPoint';
import { HandlesFromBlue } from '../models';
import { Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

export interface Props {
  designerCallbacks: HandlesFromBlue;
  showFloorplan: boolean;
}

const fullScreenStyle = {
  width: '100%',
  height: '100%',
};

const DesignerView = (props: Props) => {
  // FIXME CSS Id's should be passed by Blue Core
  const designerId = 'contentViewer';
  const canvasId = 'floorplan';
  const viewerId = 'viewer';

  const theme: Theme = useTheme();

  const designerElementRef = createRef<HTMLDivElement>();
  const designer = useRef(undefined);

  const setDesinger = () => {
    designer.current = new BlueJS(designerElementRef.current, props.designerCallbacks);
  }

  useEffect(() => {
    setDesinger();
    return () => {
      if (designer) {
        designer.current.dispose();
      }
    }
  }, [])

  useEffect(() => {
    designer.current?.setTheme(theme);
  }, [theme])

  // FIXME 2D and 3D View should have isolated views
  const { showFloorplan } = props;
  return (
    <div style={fullScreenStyle} id={designerId} ref={designerElementRef}>
        <div
          id={canvasId}
          style={fullScreenStyle}
          hidden={ !showFloorplan }>
          {/* <canvas id={canvasId} /> */}
        </div>
        <div
          id={viewerId}
          style={fullScreenStyle}
          hidden={ showFloorplan }>
        </div>
    </div>
  );
}

export default DesignerView;
