import { useState } from 'react';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// Model
import { Asset } from '../../blue/items';
import { DeleteCustomAsset, SaveCustomAsset } from '../../reducers/asset';
import { useDispatch } from 'react-redux';
import CustomAssetDialog from '../Blue/components/CustomAssetDialog';
import { Delete, MoreVert, Refresh, Visibility, VisibilityOff } from '@material-ui/icons';
import { Utils } from '../../blue/core/utils';

interface Props {
  asset: Asset;
  onClick: any;
  timeout?: number;
  disableSave?: boolean;
  showHidden?: boolean;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(),
      cursor: 'pointer',
    },
    paper: {
      borderRadius: '8px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    image: {
      flex: 1,
      height: '100%',
    },
    label: {
      padding: '5px !important',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-around',
    },
    name: {
      ...theme.typography.body2,
      padding: '0px',
      fontWeight: 500,
      color: theme.palette.text.primary,
    },
    overlayContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px',
      height: '100%',
    },
    options: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
    },
    button: {
      height: '30px',
      width: '30px',
      padding: '5px',
      margin: '5px',
      borderRadius: '4px',
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    customGradient: {
      flex: 1,
      background: `radial-gradient(${theme.palette.background.default}, ${theme.palette.primary.main} 160%)`,
    },
    normalGradient: {
      flex: 1,
      background: `radial-gradient(${theme.palette.background.default}, ${theme.palette.grey[500]} 160%)`,
    },
  })
);


const AssetCard = (props: Props) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const handleVisibilityToggle = e => {
    e.stopPropagation();
    const extensionProperties = props.asset.extensionProperties;
    if (extensionProperties) {
      const hidden = !props.asset.extensionProperties.hidden;
      const customAsset = {
        ...props.asset,
        extensionProperties: {
          ...props.asset.extensionProperties,
          hidden,
        },
      };

      dispatch(SaveCustomAsset(customAsset));
    } else {
      const customAsset = {
        ...props.asset,
        extensionProperties: {
          hidden: true,
        },
      };

      dispatch(SaveCustomAsset(customAsset));
    }

  }

  const handleEditMode = e => {
    e.stopPropagation();
    setEditMode(!editMode);
  }

  const classes = styles(props);

  const { asset, onClick, disableSave, showHidden } = props;
  const visible = !asset?.extensionProperties?.hidden
  return (
    <div className={classes.root}>
      {
        asset && (visible || showHidden) ?
          <Card
            className={classes.paper}
            >
            <div className={asset.custom ? classes.customGradient : classes.normalGradient}>
              <CardMedia
                className={classes.image}
                image={Utils.buildPath(asset ? asset.previewPath : '')}
                title="image"
                onClick={onClick}
              >
              <div
                className={classes.overlayContainer}
                >
                <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
                  <Tooltip title='Settings'>
                    <MoreVert
                      style={{
                        color: editMode ? '#AFA0B3' : 'black',
                        marginTop: '10px',
                        marginRight: '5px',
                      }}
                      onClick={ handleEditMode }
                    />
                  </Tooltip>
                </div>
                <div
                  className={classes.options}>
                  { editMode &&
                    <div
                      className={classes.buttonDiv}>
                      <div
                        className={classes.button}>
                        <CustomAssetDialog
                          asset={asset}
                          disableSave={disableSave}
                        />
                      </div>
                      { !visible &&
                        <Tooltip title="Show">
                          <VisibilityOff
                            className={classes.button}
                            onClick={handleVisibilityToggle}
                          />
                        </Tooltip>
                      }
                      { visible &&
                        <Tooltip title="Hide">
                          <Visibility
                            className={classes.button}
                            onClick={handleVisibilityToggle}
                          />
                        </Tooltip>
                      }
                      {!disableSave && asset.custom && asset.extensionProperties.progenitorId &&
                        <Tooltip title="Delete">
                          <Delete
                            className={classes.button}
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(DeleteCustomAsset(asset.id));
                            }}
                          />
                        </Tooltip>
                      }
                      {!disableSave && asset.custom && !asset.extensionProperties.progenitorId &&
                        <Tooltip title="Reset">
                          <Refresh
                            className={classes.button}
                            onClick={e => {
                              e.stopPropagation();
                              dispatch(DeleteCustomAsset(asset.id));
                            }}
                          />
                        </Tooltip>
                      }
                    </div>
                  }
                </div>
              </div>
              </CardMedia>
            </div>
            <CardContent className={classes.label}>
              <Typography className={classes.name} noWrap>
                {asset.name}
              </Typography>

            </CardContent>
          </Card>
          :
          <></>
      }

    </div>
  );
}

export default AssetCard;
