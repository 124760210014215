import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReduxState } from '../../reducers';
import {
  AppBar,
  Tooltip,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  makeStyles
} from '@material-ui/core';
import classNames from 'classnames';
import {
  Chat as ChatIcon,
  ExitToApp as LogoutIcon,
  MoreVert as MoreIcon,
  Help as HelpIcon,
  Notifications as NotificationsIcon,
  AccountCircle as ProfileIcon,
  Stars,
  Restore,
} from '@material-ui/icons';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import SearchBar from './SearchBar';
import userManager from '../../auth/userManager';
import { SelectScene } from '../../reducers/scenes';
import { placezApi } from '../../api';

import { sceneRoutes } from '../../routes';
import { userIsInRole, guestRole, ableToResetDatabase } from '../../sharing/utils/userHelpers';
import { ModalConsumer } from '../Modals/ModalContext';
import UniversalPromiseModal from '../Modals/UniversalPromiseModal';

interface Props {
  drawerOpen: boolean;
  drawerMinimized: boolean;
};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    appBar: {
      minHeight: 68,
      justifyContent: 'center',
      background: theme.palette.background.paper,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${theme.overrides.MuiDrawer.root['width']}px)`,
      marginLeft: theme.overrides.MuiDrawer.root['width'],
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarMaximized: {
      width: `calc(100% - ${theme.overrides.MuiDrawer.root['minimizedWidth']}px)`,
      marginLeft: theme.overrides.MuiDrawer.root['minimizedWidth'],
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    outlinedButton: {
      alignSelf: 'center',
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: '0px',
      padding: `${theme.spacing() / 2}px ${theme.spacing(3)}px`,
    },
    inactiveIcon: {
      color: '#AAAAAA',
    },
    helpIcon: {
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
    },
    lastItem: {
      borderLeft: '1px solid rgba(40, 40, 40, 0.10)',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
        alignItems: 'center',
      },
    },
    grow: {
      flexGrow: 1,
    },
    profileInitials: {
      width: 32,
      height: 32,
      borderRadius: 32 / 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 14,
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
    toolbar: {
      padding: 0,
    },
    toolbarContent: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    salesForce: {
      minWidth: '150px',
      paddingRight: '130px',
    },
  })
);

const betaFeature = 'New Features';
const resetDemoDatabaseLabel = 'Reset Demo Database';


const TopBar = (props: React.PropsWithChildren<Props>) => {
  const user = useSelector((state: ReduxState) => state.oidc.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const classes = styles(props);

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const handleProfileMenuOpen = (scene: any) => {
    setAnchorEl(scene.currentTarget);
  }

  const handleProfileRedirect = () => {
    window.location.href = `${window.env['REACT_APP_PORTAL']}/Account/Manage/ProfileInformation`;
    return null;
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  const handleMobileMenuOpen = (scene: any) => {
    setMobileMoreAnchorEl(scene.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  }

  const newScene = () => {
    (window as any).gtag('event', 'new_scene');
    dispatch(SelectScene(null));
    navigate(sceneRoutes.new.path);
  }

  const logout = (scene: any) => {
    scene.preventDefault();

    (window as any).gtag('event', 'logout');

    localStorage.removeItem('placez-layoutId');
    userManager.signoutRedirect({ id_token_hint: user.id_token });
    userManager.removeUser();
  }

  const renderProfileIcon = () => {
    const username = user.profile.name;
    if (username === null || username === undefined) {
      return (<ProfileIcon color="primary" />);
    }
    const nameArray = username.split(/ +/);
    if (nameArray.length < 2) {
      return (<ProfileIcon color="primary" />);
    }
    const initials = String(nameArray[0][0]) + String(nameArray[1][0]);
    return (
      <div className={classes.profileInitials}>
        {initials}
      </div>
    );
  }

  const resetDatabase = () => {
    return placezApi.resetDemoDatabase();
  }

  const { drawerOpen, drawerMinimized } = props;
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const searchableLocations = ['/scenes', '/clients', '/places', '/assets'];
  const nonSearchableLocations = ['/planner', '/floorplan'];

  const isSearchableLocation = location &&
    searchableLocations.some(searchLocation => location.pathname.includes(searchLocation)) &&
    !nonSearchableLocations.some(searchLocation => location.pathname.includes(searchLocation));

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {!userIsInRole(user, guestRole) &&
        <MenuItem onClick={handleProfileRedirect}>
          <IconButton >
            <ProfileIcon color="primary" />
          </IconButton>
          Profile
        </MenuItem>
      }
      <MenuItem onClick={scene => logout(scene)}>
        <IconButton >
          <LogoutIcon color="primary" />
        </IconButton>
        Logout
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      <Tooltip title={betaFeature}>
        <span>
          <MenuItem disabled onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <ChatIcon className={classes.inactiveIcon} />
            </IconButton>
            <p>Messages</p>
          </MenuItem>
        </span>
      </Tooltip>
      <Tooltip title="Help">
        <span>
          <MenuItem onClick={() => {
            (window as any).gtag('event', 'topBar', 'clicked_support');
            handleMobileMenuClose()
            }}>
            <a href="http://support.getplacez.com/helpmenu/content/home.htm" target="_blank" rel="noopener">
              <IconButton color="inherit">
                <HelpIcon className={classes.helpIcon} />
              </IconButton>
              Support
            </a>
          </MenuItem>
        </span>
      </Tooltip>
      <Tooltip title={betaFeature}>
        <span>
          <MenuItem disabled onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <NotificationsIcon className={classes.inactiveIcon} />
            </IconButton>
            <p>Notifications</p>
          </MenuItem>
        </span>
      </Tooltip>
      <Tooltip title="">
        <span>
          <MenuItem onClick={handleMobileMenuClose}>
            <IconButton color="inherit">
              <ProfileIcon color="primary" />
            </IconButton>
            <p>Profile</p>
          </MenuItem>
        </span>
      </Tooltip>
      <MenuItem onClick={scene => logout(scene)}>
        <IconButton color="inherit">
          <LogoutIcon color="primary" />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={
          classNames(
            classes.appBar,
            {
              [classes.appBarShift]: drawerOpen,
              [classes.appBarMaximized]: !drawerMinimized,
            })}>
        <Toolbar disableGutters={true}>
          <div className={classes.toolbarContent}>
            {
              props.children
                ? props.children
                : (
                  <>
                    {isSearchableLocation && <SearchBar />}
                    <div className={classes.grow} />
                    <Button
                      variant="outlined"
                      className={classes.outlinedButton}
                      onClick={newScene}
                    >
                      New Scene
                    </Button>
                  </>
                )
            }
          </div>
          <div className={classes.sectionDesktop}>
            <Tooltip title="Support">
              <span>
                <a href="http://support.getplacez.com/helpmenu/content/home.htm" target="_blank" rel="noopener">
                  <IconButton color="inherit">
                    <HelpIcon className={classes.helpIcon} />
                  </IconButton>
                </a>
              </span>
            </Tooltip>
            <Tooltip title={betaFeature}>
              <span>
                <a href="https://www.getplacez.com/new-in-version/#1583940439542-78932663-f848" target="_blank" rel="noopener">
                  <IconButton color="inherit" onClick={
                    () => (window as any).gtag('event', 'topBar', 'new_features_clicked')
                  }>
                    <Stars className={classes.helpIcon} />
                  </IconButton>
                </a>
              </span>
            </Tooltip>
            {
              ableToResetDatabase(user)
                ? <Tooltip title={resetDemoDatabaseLabel}>
                  <span>
                    <ModalConsumer>
                      {({ showModal, props }) =>
                        <Tooltip title="Reset Demo Database">
                          <IconButton

                            onClick={() => showModal(
                              UniversalPromiseModal, {
                              ...props,
                              onContinue: () => resetDatabase(),
                              okButtonLabel: 'Reset',
                              modalHeader: 'Are you sure you want to reset current database?',
                            })}>
                            <Restore className={classes.helpIcon} />
                          </IconButton>
                        </Tooltip>
                      }
                    </ModalConsumer>
                  </span>
                </Tooltip>
                : <></>
            }
            {!userIsInRole(user, guestRole) &&
              <div className={classes.lastItem}>
                <IconButton
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  {renderProfileIcon()}
                </IconButton>
              </div>
            }
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon color="primary" />
            </IconButton>
          </div>
          {userIsInRole(user, guestRole) &&
            <Button
              color="primary"
              variant="contained"
              onClick={scene => logout(scene)}>
              Logout
            </Button>
          }
          {/* {!userIsInRole(user, guestRole) &&
            <div id="sfChat" className={classes.salesForce} />
          } */}
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </div>
  );
}

export default TopBar;
