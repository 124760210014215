import React, { useState } from 'react';
import {
  Theme,
  createStyles,
  Tabs,
  Tab,
  FormLabel,
  FormControl,
  Switch,
  Slider,
  Paper,
  makeStyles,
  IconButton,
  Tooltip,
} from '@material-ui/core';

import TabPanel from './TabPanel';
import { TableTypes } from '../../../blue/itemModifiers/ChairMod';
import { Utils } from '../../../blue/core/utils';
import { AssetModifierKeys, AssetModifiers } from '../../../blue/items/asset';
import { KeyboardArrowUp, Settings } from '@material-ui/icons';
import { Matrix4 } from 'three'
import { LocalStorageKey, useLocalStorageSelector } from '../../Hooks/useLocalStorageState'
import { ValidUnits } from '../../../api/placez/models/UserSetting'

enum TabIndex {
  Chair = 0,
  CenterPiece = 1,
  PlaceSetting = 2,
  Linen = 3,
}

interface Props {
  modifiers: AssetModifiers;

  tableConfigTabIndex: number;
  handleTabChange: (event: any, value: any) => void;
  handleSwitchChange: <modifierKey extends keyof AssetModifiers, modifierParamKey extends keyof AssetModifiers[modifierKey]>(assetModifierKey: modifierKey, keyOfAssetModifierKey: modifierParamKey) => (event: React.ChangeEvent<HTMLInputElement>) => void;

  handleAssetModOnChange: <modifierKey extends keyof AssetModifiers, modifierParamKey extends keyof AssetModifiers[modifierKey]>(assetModifierKey: modifierKey, keyOfAssetModifierKey: modifierParamKey) => (event, value: AssetModifiers[modifierKey][modifierParamKey]) => void;
}

function renderSeatCount(props: Props) {
  const {
    modifiers,
    handleAssetModOnChange
  } = props;
  const classes = styles(props);

  return (
   <FormControl className={classes.tabFormControl}>
      <FormLabel className={classes.tabSettingsLabel}>
        SEAT COUNT {modifiers?.chairMod?.seats}
      </FormLabel>
      <Slider
        className={classes.settingSlider}
        value={modifiers?.chairMod?.seats}
        step={1}
        min={0}
        max={modifiers.chairMod.maxSeats}
        valueLabelDisplay="auto"
        onChange={handleAssetModOnChange(AssetModifierKeys.chairMod, 'seats')}
        aria-labelledby="input-slider"
        marks={true}
      />
    </FormControl>
  );
}

function RenderTabSettins(props: Props, showSettings, setShowSettings) {
  const {
    tableConfigTabIndex,
    handleSwitchChange,
    handleAssetModOnChange,
    modifiers,
  } = props;
  const classes = styles(props);
  const units = useLocalStorageSelector<ValidUnits>(LocalStorageKey.Units);
  const smallUnits = units === ValidUnits.ftIn ? ValidUnits.in : units;

  const currentUnits = Utils.getUnit()

  return (
    <Paper className={classes.root}>
      <TabPanel
        className={classes.tabPanelSettings}
        value={tableConfigTabIndex}
        index={TabIndex.Chair}
        >

        <div className={classes.settings}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{width: '20px'}}></div>
            {renderSeatCount(props)}
            { !showSettings &&
              <Tooltip title="More Settings">
                <IconButton className={classes.iconButton} aria-label="toggleSettings" onClick={() => setShowSettings(!showSettings)}>
                  <Settings fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            { showSettings &&
              <Tooltip title="Hide Settings">
                <IconButton className={classes.iconButton} aria-label="toggleSettings" onClick={() => setShowSettings(!showSettings)}>
                  <KeyboardArrowUp fontSize="small" />
                </IconButton>
              </Tooltip>
            }
          </div>
          <FormControl className={classes.tabFormControl} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
            <FormLabel className={classes.tabSettingsLabel}>
              DISTANCE ({smallUnits})
            </FormLabel>
            <Slider
              className={classes.settingSlider}
              value={Utils.unitsOutNumber(modifiers.chairMod.distance ?? 0, smallUnits, [ValidUnits.ft, ValidUnits.ftIn].includes(smallUnits) ? 1 : 0)}
              min={
                -Utils.convertUnitsFromTo(
                  50,
                  'cm',
                  smallUnits
                )
              }
              max={Utils.convertUnitsFromTo(
                50,
                'cm',
                smallUnits
              )}
              onChange={(e, v) => {
                const distance = Utils.convertUnitsFromTo(v, smallUnits, 'cm', 3);
                handleAssetModOnChange(AssetModifierKeys.chairMod, 'distance')(e, distance);
              }}
              aria-labelledby="input-slider"
              valueLabelDisplay="auto"
              step={0.1}
            />
          </FormControl>
          {(modifiers.chairMod.tableType === TableTypes.Square || modifiers.chairMod.tableType === TableTypes.Banquet) &&
            <FormControl className={classes.tabFormControl} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
              <FormLabel className={classes.tabSettingsLabel}>
                Seat Width ({smallUnits})
              </FormLabel>
              <Slider
                className={classes.settingSlider}
                value={Utils.unitsOutNumber(modifiers.chairMod.seatWidth ?? 0, smallUnits, [ValidUnits.ft, ValidUnits.ftIn].includes(smallUnits) ? 1 : 0)}
                min={Utils.convertUnitsFromTo(
                  20,
                  'in',
                  smallUnits
                )}
                max={Utils.convertUnitsFromTo(
                  36,
                  'in',
                  smallUnits
                )}
                onChange={(e, v) => {
                  handleAssetModOnChange(AssetModifierKeys.chairMod, 'seatWidth')(e,Utils.convertUnitsFromTo(v, smallUnits, 'cm'));
                }}
                aria-labelledby="input-slider"
                valueLabelDisplay="auto"
              />
            </FormControl>
          }
        </div>

        {(modifiers.chairMod.tableType === TableTypes.Round || modifiers.chairMod.tableType === TableTypes.Oval) &&
          <div className={classes.settings}>
            <FormControl className={classes.gridToggle} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
              <FormLabel className={classes.tabSettingsLabel}>
                Equal Spacing
              </FormLabel>
              <Switch
                size="small"
                checked={modifiers?.chairMod?.equalSpacing}
                onChange={handleSwitchChange('chairMod', 'equalSpacing')}
                value={modifiers?.chairMod?.equalSpacing}
              />
            </FormControl>
          </div>
        }
        {(modifiers.chairMod.tableType === TableTypes.Square || modifiers.chairMod.tableType === TableTypes.Banquet) && (
          <div className={classes.settings} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
            <div className={classes.chairPlacementContainer}>
              <div className={classes.topSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Top
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.top}
                    onChange={handleSwitchChange('chairMod', 'top')}
                    value={modifiers.chairMod.top}
                  />
                </FormControl>
              </div>
              <div className={classes.bottomSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Bottom
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.bottom}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'bottom')}
                    value={modifiers.chairMod.bottom}
                  />
                </FormControl>
              </div>
              <div className={classes.center}>CHAIR PLACEMENT</div>
              <div className={classes.leftSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Left
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.left}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'left')}
                    value={modifiers.chairMod.left}
                  />
                </FormControl>
              </div>
              <div className={classes.rightSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Right
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.right}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'right')}
                    value={modifiers.chairMod.right}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}
        {modifiers.chairMod.tableType === TableTypes.Sweetheart && (
          <div className={classes.settings} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
            <div className={classes.chairPlacementContainer}>
              <div className={classes.topSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Top
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.top}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'top')}
                    value={modifiers.chairMod.top}
                  />
                </FormControl>
              </div>
              <div className={classes.sweetheart}>CHAIR PLACEMENT</div>
              <div className={classes.bottomSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Bottom
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.bottom}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'bottom')}
                    value={modifiers.chairMod.bottom}
                  />
                </FormControl>
              </div>
            </div>
          </div>
        )}
        {modifiers.chairMod.tableType === TableTypes.Serpentine && (
          <div className={classes.settings} style={{display: `${showSettings ? 'flex' : 'none'}`}}>
            <div className={classes.chairPlacementContainer}>
              <div className={classes.leftSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Top
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.top}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'top')}
                    value={modifiers.chairMod.top}
                  />
                </FormControl>
              </div>
              <div className={classes.bottomSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Bottom
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.bottom}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'bottom')}
                    value={modifiers.chairMod.bottom}
                  />
                </FormControl>
              </div>
              <div className={classes.insideRadiusSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Inside
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.left}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'left')}
                    value={modifiers.chairMod.left}
                  />
                </FormControl>
              </div>
              <div className={classes.outsideRadiusSwitch}>
                <FormControl className={classes.gridToggle}>
                  <FormLabel className={classes.tabSettingsLabel}>
                    Outside
                  </FormLabel>
                  <Switch
                    size="small"
                    checked={modifiers.chairMod.right}
                    onChange={handleSwitchChange(AssetModifierKeys.chairMod, 'right')}
                    value={modifiers.chairMod.right}
                  />
                </FormControl>
              </div>
              <div className={classes.serpentine}>CHAIR PLACEMENT</div>
            </div>
          </div>
        )}

      </TabPanel>
      {modifiers.centerpieceMod.centerpieceAsset &&
        <TabPanel
          className={classes.tabPanelSettings}
          value={tableConfigTabIndex}
          index={TabIndex.CenterPiece}
        >
          <div className={classes.settings}>
            <FormControl className={classes.tabFormControl}>
              <FormLabel className={classes.tabSettingsLabel}>
                Size
              </FormLabel>
              <Slider
                className={classes.settingSlider}
                value={Utils.scaleFactorFromScale(modifiers.centerpieceMod?.transformation?.[0] ?? 1)}
                min={-3.0}
                step={0.1}
                max={3.0}
                onChange={(e, v) => {
                  const scalar = Utils.scaleFactor(v as number);
                  const transformation = new Matrix4().makeScale(scalar, scalar, scalar).toArray()
                  handleAssetModOnChange(AssetModifierKeys.centerpieceMod, 'transformation')(e, transformation);
                }}
                marks={[{value: 0}]}
                aria-labelledby="input-slider"
                track={false}
              />
            </FormControl>
          </div>
        </TabPanel>
      }
      <TabPanel
        className={classes.tabPanelSettings}
        value={tableConfigTabIndex}
        index={TabIndex.PlaceSetting}
      >
        <div className={classes.settings}>
          <FormControl className={classes.tabFormControl}>
            <FormLabel className={classes.tabSettingsLabel}>
              DISTANCE (units)
            </FormLabel>
            <Slider
              className={classes.settingSlider}
              value={Utils.unitsOutNumber(modifiers.placeSettingMod.distance ?? 0, smallUnits, [ValidUnits.ft, ValidUnits.ftIn].includes(smallUnits) ? 1 : 0)}
              min={0}
              max={Utils.convertUnitsFromTo(
                15,
                'in',
                smallUnits
              )}
              onChange={(e, v) => {
                handleAssetModOnChange(AssetModifierKeys.placeSettingMod, 'distance')(e, Utils.convertUnitsFromTo(v, smallUnits, 'cm'));
              }}
              aria-labelledby="input-slider"
              valueLabelDisplay="auto"
              step={0.1}
            />
          </FormControl>
        </div>
      </TabPanel>
      {modifiers.linenMod.linenAsset &&
        <TabPanel
          className={classes.tabPanelSettings}
          value={tableConfigTabIndex}
          index={TabIndex.Linen}
        >
          <div className={classes.settings}>
            <FormControl className={classes.tabFormControl}>
              <FormLabel className={classes.tabSettingsLabel}>
                Length
              </FormLabel>
              <Slider
                className={classes.settingSlider}
                value={modifiers.linenMod?.transformation?.[5] ?? 1}
                min={0.2}
                max={2}
                step={0.1}
                onChange={(e, v) => {
                  const transformation = new Matrix4().makeScale(1, v as number, 1).toArray()
                  handleAssetModOnChange(AssetModifierKeys.linenMod, 'transformation')(e, transformation);
                }}
                aria-labelledby="input-slider"
                track={false}
              />
            </FormControl>
          </div>
        </TabPanel>
      }
    </Paper>
  );
}

function tabProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TabsPanel = (props: Props) => {
  const {
    tableConfigTabIndex,
    handleTabChange,
    modifiers,
  } = props;
  const classes = styles(props);

  const [showSettings, setShowSettings] = useState(true);

  return (
    <>
      <Tabs
        value={tableConfigTabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Table Configuration"
      >
        <Tab
          label="Chair"
          {...tabProps(0)}
          icon={
            <img
              className={classes.tabPanelIcon}
              style={{ marginTop: -8, marginBottom: -5 }}
              src={Utils.getAssetUrl(window, modifiers?.chairMod?.chairAsset)}
              alt={""}
            />
          }
          classes={{
            labelIcon: classes.tabLabelIcon,
            selected: classes.tabsIndicator,
          }}
        />
        <Tab
          label="Center Piece"
          {...tabProps(1)}
          icon={
            <img
              className={classes.tabPanelIcon}
              style={{ marginTop: -8, marginBottom: -5 }}
              src={Utils.getAssetUrl(window, modifiers?.centerpieceMod?.centerpieceAsset)}
            />
          }
          classes={{
            labelIcon: classes.tabLabelIcon,
            selected: classes.tabsIndicator,
          }}
        />
        <Tab
          label="Place Setting"
          {...tabProps(2)}
          icon={
            <img
              className={classes.tabPanelIcon}
              style={{ marginTop: -8, marginBottom: -5 }}
              src={Utils.getAssetUrl(window, modifiers?.placeSettingMod?.placeSettingAsset)}
            />
          }
          classes={{
            labelIcon: classes.tabLabelIcon,
            selected: classes.tabsIndicator,
          }}
        />
        <Tab
          label="Linen"
          {...tabProps(3)}
          icon={
            <img
              className={classes.tabPanelIcon}
              style={{ marginTop: -8, marginBottom: -5 }}
              src={Utils.getAssetUrl(window, modifiers?.linenMod?.linenAsset)}
            />
          }
          classes={{
            labelIcon: classes.tabLabelIcon,
            selected: classes.tabsIndicator,
          }}
        />
      </Tabs>
      {RenderTabSettins(props, showSettings, setShowSettings)}
    </>
  );
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      margin: 5,
      marginTop: '10px',
      borderRadius: '4px',
    },
    tabPanelIcon: {
      height: 90,
    },
    tabLabelIcon: {
      padding: 0,
      margin: 0,
    },
    tabPanelLinen: {
      height: 51,
      width: 51,
      marginTop: 12,
      borderRadius: '50%',
    },
    tabPanelSettings: {
      ...theme.typography.body1,
      padding: '2px',
      background: theme.palette.background.paper,
      width: '100%',
      flexDirection: 'column',
    },
    tabFormControl: {
      flex: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    gridToggle: {
      flex: '1',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    hiddenToggle: {
      flex: '1',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    tabSettingsLabel: {
      display: 'flex',
      flexDirection: 'row',
      ...theme.typography.body1,
      marginLeft: 15,
      lineHeight: '2',
      fontSize: 11,
    },
    settingSlider: {
      width: '157px',
      marginLeft: 10,
    },
    settings: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    chairPlacementContainer: {
      display: 'grid',
      gridTemplateColumns: '90px 90px 90px',
      gridTemplateRows: '60px 60px 60px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    topSwitch: {
      gridRowStart: '1',
      gridColumnStart: '2',
    },
    bottomSwitch: {
      gridRowStart: '3',
      gridColumnStart: '2',
    },
    outsideRadiusSwitch: {
      gridRow: '1',
      gridColumn: '3',
    },
    insideRadiusSwitch: {
      gridRow: '3',
      gridColumn: '1',
    },
    center: {
      gridRowStart: '2',
      gridColumnStart: '2',
      border: `solid 2px ${theme.palette.secondary.main}`,
      lineHeight: '2',
      fontSize: 11,
      textAlign: 'center',
      padding: 6,
      color: theme.palette.text.secondary,
      height: '100%',
    },
    serpentine: {
      gridRow: '2',
      gridColumn: '2',
      border: `solid 2px ${theme.palette.secondary.main}`,
      borderRadius: '0 50% 0 0',
      lineHeight: '2',
      fontSize: 11,
      textAlign: 'center',
      padding: 6,
      color: theme.palette.text.secondary,
      height: '100%',
    },
    leftSwitch: {
      gridRow: '2',
      gridColumn: '1',
    },
    rightSwitch: {
      gridRow: '2',
      gridColumn: '3',
    },
    tabsIndicator: {
      color: `${theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white} !important`,
    },
    sweetheart: {
      gridRowStart: '2',
      gridColumnStart: '2',
      border: `solid 2px ${theme.palette.secondary.main}`,
      borderRadius: '0 0 50% 50%',
      lineHeight: '2',
      fontSize: 11,
      textAlign: 'center',
      padding: 6,
      color: theme.palette.text.secondary,
      height: '100%',
    },
    iconButton: {
      padding: '0px',
    }
  })
);

export default TabsPanel;
