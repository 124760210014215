import { HTMLAttributes } from 'react';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import classnames from 'classnames';

import {
  Card,
  CardMedia,
  CardContent,
  Grow,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// Model
import animations from '../../../assets/themes/animations';
import { Asset } from '../../../blue/items';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import CustomAssetDialog from '../../Blue/components/CustomAssetDialog';
import { Delete, Refresh } from '@material-ui/icons';
import { DeleteCustomAsset } from '../../../reducers/asset';

interface Props {
  catalogItem: Asset;
  onClick: any;
  timeout?: number;
  selected?: boolean;
  allowEdit?: boolean;
  deleteCustomAsset?: (id: string) => {};
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      margin: `0px ${theme.spacing()}px
              ${theme.spacing(2)}px ${theme.spacing()}px`,
      height: 180,
      cursor: 'pointer',
      borderRadius: theme.shape.borderRadius,
    },
    image: {
      width: 150,
      height: 150,
    },
    label: {
      width: 150,
      padding: `${theme.spacing() / 2}px ${theme.spacing()}px`,
      display: 'flex',
      justifyContent: 'center',
    },
    name: {
      ...theme.typography.body2,
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
      fontSize: 14,
      fontWeight: 500,
    },
    cardSelected: {
      border: `solid 2px ${theme.palette.primary.main}`,
      margin: '0px 6px 14px 6px',
    },
    editControls: {
      padding: '5px',
    },
  })
);

const AssetTile = (props: Props & HTMLAttributes<HTMLDivElement>) => {
  const classes = styles(props);
  const dispatch = useDispatch();

  const deleteCatalogItem = assetId => e => {
    e.stopPropagation();
    dispatch(DeleteCustomAsset(assetId));
  }

  const userProfile = useSelector((state: ReduxState) => state.oidc.user.profile);

  const { catalogItem, onClick, timeout, selected, allowEdit } = props;
  const color = '#9e9e9e'; // Future capability to override color
  const url = catalogItem && catalogItem.id ? `${window.env['REACT_APP_DAM']}/Organization/${userProfile.organization_id}/MediaAssetFile/${catalogItem.id}/preview` : '';
  return (
    <Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      timeout={timeout ? timeout : animations.growDuration}>
      <Card
        className={selected ? classnames(classes.root, classes.cardSelected) : classes.root}
        onClick={() => { onClick(url); }}>
        <div style={{
          background: `radial-gradient(#ffffff, ${color})`,
        }}>
          <CardMedia
            className={classes.image}
            image={`${window.env['REACT_APP_DAM']}/${catalogItem.previewPath}`}
            title="image"
          >
            {allowEdit &&
              <div
                className={classes.editControls}
              >
                <CustomAssetDialog
                  asset={catalogItem}
                />
                {catalogItem.custom && catalogItem.extensionProperties.progenitorId &&
                  <Tooltip title="Delete">
                    <Delete
                      onClick={deleteCatalogItem(catalogItem.id)}
                    />
                  </Tooltip>
                }
                {catalogItem.custom && !catalogItem.extensionProperties.progenitorId &&
                  <Tooltip title="Reset">
                    <Refresh
                      onClick={deleteCatalogItem(catalogItem.id)}
                    />
                  </Tooltip>
                }
              </div>
            }
          </CardMedia>
        </div>
        <CardContent className={classes.label}>
          <Typography className={classes.name} noWrap>
            {catalogItem.name}
          </Typography>
        </CardContent>
      </Card>
    </Grow>
  );
}

export default AssetTile;
