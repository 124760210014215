
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import findInSearchableFeilds from '../../../sharing/utils/findInSearchableFeilds';
import {
  Clear,
  Search,
} from '@material-ui/icons';

import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import { Asset } from '../../../blue/items';
import { Button, makeStyles, Input, IconButton } from '@material-ui/core';
import AssetTile from './AssetTile';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
      fontSize: '40px',
      textAlign: 'center',
    },
    heading: {
      ...theme.typography.h2,
      marginTop: theme.spacing(2),
      fontSize: '20px',
      height: '40px',
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0px ${theme.spacing(3)}px`,
    },
    content: {
      paddingRight: 16,
      paddingLeft: 16,
    },
    field: {
      minWidth: '40%',
      margin: '10px 24px',
    },
    button: {
      padding: '4px 30px',
      borderRadius: '8px',
      width: '150px',
      height: '180px',
      margin: '8px',
      marginTop: '0px',
      marginBottom: '16px',
    },
    actions: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
    },
    container: {
      padding: theme.spacing(),
      textAlign: 'center',
    },
    imageDetails: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    logo: {
    },
    leftColumn: {
      flexDirection: 'column',
      width: '50%',
      padding: theme.spacing(),
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputField: {
      marginBottom: '0px',
    },
    inputCheckField: {
      marginBottom: '-15px',
    },
    cardContainer: {
      display: 'flex',
      alignContent: 'start',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
    },
    cardContainerAfter: {
      flex: 'auto',
    },
  })
);
interface Props {
  handleSelectedCatalog: Function;
  catalog: any[];
  selectedSku: string;
  clearSelected: boolean;
  allowEdit?: boolean;
}

const ChairSelectForm = (props: Props) => {

  const [assetFilter, setAssetFilter] = useState('');

  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);

  const {
    catalog,
    selectedSku,
    handleSelectedCatalog,
    clearSelected,
    allowEdit,
  } = props;

  const getAsset = (catalogItem): Asset => {
    const asset = assetsBySku[catalogItem.sku];
    if (!asset) {
      console.warn('SKU from catalog not found in Asset Collection');
    }
    return asset;
  }

  const formatCatalog = (catalog: {sku: string}[]): Asset[] => {
    return catalog
      .map(catalogItem => getAsset(catalogItem))
      .filter((asset: Asset) => asset && asset.extensionProperties && !asset.extensionProperties.hidden)
      .filter((asset: Asset) => findInSearchableFeilds(asset, assetFilter));
  }

  const assets = useRef<Asset[]>(formatCatalog(props.catalog));


  useEffect(
    () => {
      const catalogItem = catalog.find(catalogItem => {
        return catalogItem.sku === selectedSku;
      });
      if (catalogItem !== undefined) {
        const asset = getAsset(catalogItem);
        if (asset) {
          handleSelectedCatalog(getAsset(catalogItem));
        } else {
          handleSelectedCatalog(undefined);
        }
      }
      assets.current = formatCatalog(props.catalog);
    }, [assetsBySku, catalog]);


  const classes = styles(props);

  return(
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Input
        placeholder="Search Library"
        id="adornment-password"
        value={assetFilter}
        onChange={(e) => {
          setAssetFilter(e.target.value)
        }}
        style={{margin: '10px 20px', alignSelf: 'stretch'}}
        endAdornment={
          <>
            { assetFilter !== '' &&
              <IconButton
                onClick={event => { setAssetFilter('') }}
              >
                <Clear/>
              </IconButton>
            }
            { assetFilter === '' &&
              <IconButton>
                <Search />
              </IconButton>
            }
          </>
        }
      />
      <div className={classes.cardContainer}>
        { clearSelected &&
          <Button
            key=""
            color="primary"
            className={classes.button}
            variant="contained"
            disabled={selectedSku === ''}
            onClick={() => {
              props.handleSelectedCatalog(undefined);
            }}>
            Clear Selection
          </Button>
        }
        { assets.current.map(asset =>
            <AssetTile
              selected={selectedSku === asset.id}
              key={asset.id}
              catalogItem={asset}
              timeout={(1 + 1) * 0.5 }
              onClick={() => handleSelectedCatalog(asset)}
              allowEdit={allowEdit}
            />
        )}
      </div>
    </div>
  );
}


export default ChairSelectForm;
