import { RouteSection } from './Route';

// Icons
import {
  ClientsIcon,
} from '../assets/icons';

// Views
import ClientList from '../views/Clients/ClientList';
import EditClient from '../views/Clients/EditClient';
import NewClient from '../views/Clients/NewClient';

const baseUrl = '/clients';
export const clients: RouteSection = {
  main: {
    path: `${baseUrl}`,
    name: 'Client List',
    icon: ClientsIcon,
    component: ClientList,
  },
  new: {
    path: `${baseUrl}/new`,
    name: 'New Client',
    component: NewClient,
  },
  edit: {
    path: `${baseUrl}/:id/edit`,
    name: 'Edit Client',
    component: EditClient,
  },
  view: {
    path: `${baseUrl}/:id`,
    name: 'Edit Client',
    component: EditClient,
  },
};

export default clients;
