import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const MaskItemIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg version="1.1" id="Capa_1" x="0px" y="0px"
      viewBox="0 0 512 512">
    <path d="M425,0H87C39,0,0,39,0,87s39,87,87,87c10.2,0,20-1.8,29.1-5v343h43.4l63.6-45.9l84.7-26.9L395.9,329V169
      c9.1,3.2,18.9,5,29.1,5c48,0,87-39,87-87S473,0,425,0L425,0z M156.1,465.1V139.8c3.1-4,5.8-8.3,8.2-12.8H196v309.3L156.1,465.1z
      M276,407.4L236,420V127h40V407.4z M316,364.9V127h31.8c2.3,4.5,5.1,8.8,8.2,12.8V315L316,364.9z M425,134c-25.9,0-47-21.1-47-47
      H134c0,25.9-21.1,47-47,47s-47-21.1-47-47s21.1-47,47-47h338c25.9,0,47,21.1,47,47S450.9,134,425,134z"/>
    </svg>
  </SvgIcon>
);

export default MaskItemIcon;
