import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Theme,
  createStyles,
  makeStyles}
from '@material-ui/core';
import classNames from 'classnames';

// Routing
import { Routes, Route } from 'react-router-dom';

// Components
import {
  Menu as HamburgerIcon,
 } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import SideBar from '../components/SideBar/SideBar';
import TopBar from '../components/TopBar/TopBar';
import DesignPreview from '../views/Guest/DesignPreview';
import ModalInjector from '../components/Modals/ModalInjector';
import { ModalProvider } from '../components/Modals/ModalContext';

import { ReduxState } from '../reducers';
import { getOrgTheme } from '../api/placez/models/UserSetting';

const headerSizeWithMargin = '68px';
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        display: 'block',
        width: '8px !important',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderRadius: '5px !important',
        backgroundClip: 'content-box !important',
        border: '2px solid transparent !important',
      },
    },
    root: {
      display: 'flex',
      maxHeight: '100vh',
      overflow: 'hidden',
      transform: 'translate(0,0)',
    },
    hide: {
      display: 'none',
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: '29px',
      margin: '0 20px',
      fontFamily: 'Montserrat',
    },
    drawer: {
      height: '100%',
      width: theme.overrides.MuiDrawer.root['width'],
      flexShrink: 0,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.paper,
    },
    drawerPaper: {
      width: theme.overrides.MuiDrawer.root['width'],
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.background.paper,
    },
    drawerMinimized: {
      flexShrink: 0,
      width: theme.overrides.MuiDrawer.root['minimizedWidth'],
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeIn,
        duration: theme.transitions.duration.enteringScreen,
      }),
      backgroundColor: theme.palette.background.paper,
    },
    listContainer: {
      height: '100vh',
      backgroundColor: '#1F2126',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      color: theme.palette.grey[600],
      alignSelf:'flex-start',
      margin: '0px 16px',
      marginBottom: 4,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      marginBottom: 4,
    },
    routeMain: {
      width: '100%',
      height: `calc(100% - ${headerSizeWithMargin})`,
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `calc(0 - ${theme.overrides.MuiDrawer.root['width']}px)`,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      whiteSpace: 'nowrap',
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100vw - ${theme.overrides.MuiDrawer.root['width']}px)`,
      height: '100vh',
    },
  })
);

interface Props {};

const GuestLayout = (props: Props) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [drawerMinimized, setDrawerMinimized] = useState<boolean>(false);

  const profile = useSelector((state: ReduxState) => state.oidc.user.profile);
  const sagasLoaded = useSelector((state: ReduxState) => state.lifecycle.sagasReady);

  const handleDrawerSizeToggle = () => {
    setDrawerMinimized(!drawerMinimized);
  }

  const sharedLayoutId = localStorage.getItem('placez-layoutId');

  const classes = styles(props);

  return (
    <ModalProvider>
      <div className={classes.root}>
        <ModalInjector/>
        <CssBaseline />
        <TopBar
          drawerOpen={ drawerOpen }
          drawerMinimized = { drawerMinimized }>
          <div className={classes.title}>{`${getOrgTheme(profile.organization_id).name} Viewer`}</div>
        </TopBar>
        <Drawer className={classNames(classes.drawer, { [classes.drawerMinimized]: !drawerMinimized })}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{ paper: drawerMinimized ? classes.drawerPaper : classes.drawerMinimized }}
        >
          <div className={classes.listContainer}>
            <SideBar guest={true}/>
            <IconButton
              color="inherit"
              aria-label="Toggle Drawer"
              onClick={handleDrawerSizeToggle}
              className={classes.button}>
              <HamburgerIcon fontSize="small" />
            </IconButton>
          </div>
        </Drawer>
        <main className={classNames(classes.content, { [classes.contentShift]: drawerOpen })}>
          <div className={classes.drawerHeader} />
          <div className={classes.routeMain}>
            { sagasLoaded && sharedLayoutId ?
              <Routes>
                <Route path='/*' element={<DesignPreview/>} />
              </Routes> :
              <></>
            }
          </div>
        </main>
      </div>
    </ModalProvider>
  );
}

export default GuestLayout;
