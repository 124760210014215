import { useState } from 'react';
import { useSelector } from 'react-redux';
import findInSearchableFeilds from '../../../../sharing/utils/findInSearchableFeilds';

import {
  Typography,
  Button,
  Slider,
  Switch,
  Tooltip,
  Theme,
  makeStyles,
  IconButton,
  Input,
} from '@material-ui/core';

import {
  Clear,
  Search,
} from '@material-ui/icons';

import { useTheme } from "@material-ui/styles";

import { ChromePicker } from 'react-color';

import panelStyles from './panels.css';
import { PlacezMaterial, Colors, MinifiedColors, MaterialCategories, GetImgUrlForMap } from '../../../../api/placez/models/PlacezMaterial';
import { Utils } from '../../../../blue/core/utils';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { ReduxState } from '../../../../reducers';
import AdvancedColorPicker from '../utility/AdvancedColorPicker'

const checkColor = (color, theme): string => {
  const luminance = Utils.calculateLuminance(color);

  if (luminance < 0.3) {
    return 'white';
  } else if (luminance > 0.7) {
    return 'black';
  }
  return theme.palette.text.primary;
}

interface Props {
  setMaterial: (material) => void;
  material: PlacezMaterial;
  defaultMaterial?: PlacezMaterial;
  noTransparent?: boolean;
}

const EditMaterial = (props: Props) => {
  const theme: Theme = useTheme();

  const styles = makeStyles<Theme>(panelStyles);

  const host = window.env['REACT_APP_DAM'];
  const [toggles, setToggles] = useState({
    advancedSettings: false,
    showAllColors: false,
    showAllMaterials: false,
  })
  const [assetFilter, setAssetFilter] = useState('');

  const {advancedSettings, showAllColors, showAllMaterials} = toggles;

  const materials = useSelector((state: ReduxState) => state.material.materials);
  const materialsById = useSelector((state: ReduxState) => state.material.byId);

  const handleTextureChange = (mat: PlacezMaterial) => {
    props.setMaterial({
      ...props.material,
      id: mat ? mat.id : null,
      scale: mat ? mat.scale : null,
      threeJSMaterial: {
        ...props.material.threeJSMaterial,
        color: Utils.hexColorToDec('#ffffff'),
      },
      preview: mat.preview,
    });
  }

  const handleMaterialColorChange = e => {
    props.setMaterial({
      ...props.material,
      threeJSMaterial: {
        ...props.material.threeJSMaterial,
        color: Utils.hexColorToDec(e.hex),
      },
    });
  }

  const handleMaterialParamChange = (param: string) => (e, v) => {
    let value = v;
    if (param === 'opacity') {
      value = parseFloat((1 - value).toFixed(2));
    }
    props.setMaterial({
      ...props.material,
      threeJSMaterial: {
        ...props.material.threeJSMaterial,
        [param]: value,
      },
    });
  }

  const handleMaterialSizeChange = (e, v) => {
    props.setMaterial({
      ...props.material,
      threeJSMaterial: {
        ...props.material.threeJSMaterial,
        textures: props.material.threeJSMaterial.textures && props.material.threeJSMaterial.textures.length > 0 ? props.material.threeJSMaterial.textures.map(texture => {
          const newTexture = { ...texture };
          newTexture.repeat = [v, v];
          return newTexture;
        }) : [{ repeat: [v, v] }],
      },
    });
  }

  const clearMaterial = () => {
    props.setMaterial({
      id: 'DefaultWhite',
    });
  }

  const defaultMaterial = () => {
    props.setMaterial(null);
  }

  const clearTexture = () => {
    props.setMaterial({
      ...props.material,
      id: null,
    });
  }

  const onSwitch = prop => e => {
    setToggles({
      ...toggles,
      [prop]: e.target.checked,
    })
  }

  const classes = styles(props);

  const materialTile = (material, category) => {
    return (
      <div
        onClick={() => handleTextureChange(material)}
        key={`${material.id}${category}`}
        className={material.id === props.material.id ? classes.selected : classes.unSelected}
        style={{
          backgroundImage: `url(${material.preview ? `${host}${material.preview}` : ''})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className={classes.gridTitle} >
          <span>{material.name}</span>
        </div>
      </div>
    );
  }

  const { material } = props;
  const filteredMaterials = materials
    .filter((material: PlacezMaterial) => {
      const found = findInSearchableFeilds(material, assetFilter);
      return found;
    })

  let materialTextureUrl = '';
  if (material?.id) {
    const foundMaterial = materialsById[material.id];
    const textureImage = GetImgUrlForMap(foundMaterial, 'map');
    materialTextureUrl = `url(${textureImage})`;
  }

  const backgroundColor = (material?.threeJSMaterial?.color) ? `${Utils.decColorToHex(material.threeJSMaterial.color)}` : ''

  return (
    <div className={classes.tabPanel}>
      {material &&
        <div className={classes.panelBody}>
          <div className={classes.buttonDiv}>
            <Button
              onClick={clearMaterial}
              className={classes.button}
              variant="outlined"
              style={{
                backgroundImage: materialTextureUrl,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: (material?.threeJSMaterial?.color) ? `${Utils.decColorToHex(material.threeJSMaterial.color)}` : '',
                opacity: (material?.threeJSMaterial?.opacity) ? `${material.threeJSMaterial.opacity}` : '',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
                flex: 1,
                alignSelf: 'stretch',
                color: checkColor(backgroundColor, theme),
              }}
            >Remove Material
            </Button>
          </div>
          <div className={classes.buttonDiv}>
            <Button
              onClick={defaultMaterial}
              className={classes.button}
              variant="outlined"
            >Reset
            </Button>
          </div>
          <div className={classes.headingContainerLight}>
            <Typography className={classes.heading} align="center">
              Advanced Settings
            </Typography>
            <Switch
              size="small"
              checked={advancedSettings}
              onChange={onSwitch('advancedSettings')}
            />
          </div>

          <AdvancedColorPicker
            advanced={advancedSettings}
            width={'240px'}
            color={material && material.threeJSMaterial ? Utils.decColorToHex(material.threeJSMaterial.color) : '#ffffff'}
            onChange={handleMaterialColorChange}
          />

          {advancedSettings &&
            <>
              {!props.noTransparent &&
                <>
                  <div className={classes.headingContainerLight}>
                    <Typography className={classes.heading} align="center">
                      Transparent
                    </Typography>
                  </div>
                  <div className={classes.pickerContainer}>
                    <Slider
                      min={0}
                      max={1}
                      step={0.05}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      value={parseFloat((1.0 - (material && material.threeJSMaterial && material.threeJSMaterial.opacity !== undefined && material.threeJSMaterial.opacity !== null ? material.threeJSMaterial.opacity : 1.0)).toFixed(2))}
                      onChange={handleMaterialParamChange('opacity')}
                    />
                  </div>
                </>
              }
              <div className={classes.headingContainerLight}>
                <Typography className={classes.heading} align="center">
                  Metalness
                </Typography>
              </div>
              <div className={classes.pickerContainer}>
                <Slider
                  min={0}
                  max={1}
                  step={0.05}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={parseFloat((material && material.threeJSMaterial && material.threeJSMaterial.metalness !== undefined && material.threeJSMaterial.metalness !== null ? material.threeJSMaterial.metalness : 0.0).toFixed(2))}
                  onChange={handleMaterialParamChange('metalness')}
                />
              </div>
              <div className={classes.headingContainerLight}>
                <Typography className={classes.heading} align="center">
                  Roughness
                </Typography>
              </div>
              <div className={classes.pickerContainer}>
                <Slider
                  min={0}
                  max={1}
                  step={0.05}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={parseFloat((material && material.threeJSMaterial && material.threeJSMaterial.roughness !== undefined && material.threeJSMaterial.roughness !== null ? material.threeJSMaterial.roughness : 1.0).toFixed(2))}
                  onChange={handleMaterialParamChange('roughness')}
                />
              </div>
              <div className={classes.headingContainerLight}>
                <Typography className={classes.heading} align="center">
                  Scale
                </Typography>
              </div>
              <div className={classes.pickerContainer}>
                <Slider
                  min={0}
                  max={10}
                  step={0.05}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  value={parseFloat((material && material.threeJSMaterial && material.threeJSMaterial.textures && material.threeJSMaterial.textures[0] ? material.threeJSMaterial.textures[0].repeat[0] : 1.0).toFixed(2))}
                  onChange={handleMaterialSizeChange}
                />
              </div>
            </>
          }


          <div className={classes.headingContainerLight}>
            <Typography className={classes.heading} align="center">
              Materials
            </Typography>
            <Tooltip
              title="Show All">
              <Switch
                size="small"
                checked={showAllMaterials}
                onChange={onSwitch('showAllMaterials')}
              />
            </Tooltip>
          </div>
          <Input
            placeholder="Search Materials"
            id="adornment-password"
            value={assetFilter}
            onChange={(e) => {
              setAssetFilter(e.target.value)
            }}
            style={{margin: '10px'}}
            endAdornment={
              <>
                { assetFilter !== '' &&
                  <IconButton
                    onClick={event => { setAssetFilter('') }}
                  >
                    <Clear/>
                  </IconButton>
                }
                { assetFilter === '' &&
                  <IconButton>
                    <Search />
                  </IconButton>
                }
              </>
            }
          />
          {!showAllMaterials &&
            <Accordion
              allowZeroExpanded={true}>
              {filteredMaterials
                .map((material: PlacezMaterial) => {
                  return material.tags ?? [];
                })
                .filter((tags: string[]) => {
                  return tags.some((tag: string) => {
                    return MaterialCategories.includes(tag);
                  })
                })
                .reduce((acc, tags: string[]) => {
                  acc.push(...tags);
                  return [...new Set(acc)];
                }, [])
                .map(
                  category => {
                    return (
                      <AccordionItem
                        key={category}
                        style={{
                          margin: 'flex',
                          alignItems: 'center',
                        }}>
                        <AccordionItemHeading>
                          <AccordionItemButton
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: theme.palette.text.primary,
                            }}>
                            <Typography>{category}</Typography>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel
                          style={{
                            padding: 0,
                          }}
                        >
                          <div
                            className={classes.gridList}
                          >
                            {filteredMaterials
                              .filter((material: PlacezMaterial) => {
                                return material.tags ? material.tags.includes(category) : false;
                              })
                              .map(material => {
                                return materialTile(material, category);
                              })
                            }
                          </div>
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  }
                )
              }
            </Accordion>
          }
          {showAllMaterials &&
            <div>
              <div
                className={classes.gridList}
              >
                {filteredMaterials
                  .map(material => {
                    return materialTile(material, 'showAll');
                  })
                }
              </div>
            </div>
          }
        </div>}
    </div>
  );
}


export default EditMaterial;
