import { RouteSection } from './Route';

// Icons
import {
  PlacezLibraryIcon
} from '../assets/icons';

// Views
import PlaceLibrary from '../views/PlaceLibrary/PlaceLibrary';
import NewPlace from '../views/PlaceLibrary/NewPlace';
import EditPlace from '../views/PlaceLibrary/EditPlace';
import PlacePlanner from '../views/PlaceLibrary/PlacePlanner';

const baseUrl = '/places';
export const places: RouteSection = {
  main: {
    path: `${baseUrl}`,
    name: 'Place Library',
    icon: PlacezLibraryIcon,
    component: PlaceLibrary,
  },
  new: {
    path: `${baseUrl}/new`,
    name: 'New Place',
    component: NewPlace,
  },
  edit: {
    path: `${baseUrl}/:id/edit`,
    name: 'Place Edit',
    component: EditPlace,
  },
  editFloorPlan: {
    path: `${baseUrl}/:id/floorplan/:floorPlanId`,
    name: 'Place Planner',
    component: PlacePlanner,
  },
  newFloorPlan: {
    path: `${baseUrl}/:id/floorplan`,
    name: 'Place Planner',
    component: PlacePlanner,
  },
};

export default places;
