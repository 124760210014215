import React, { Component } from 'react';
import {
  WithStyles,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import PlaceForm from '../../components/Place/PlaceForm';
import { makeStyles } from '@material-ui/styles'

interface Props {}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      height: '100%',
      width: '100%',
      overflow: 'none',
      overflowY: 'auto',
    },
    title: {
      ...theme.typography.h5,
      fontSize: 18,
      fontWeight: 'bold',
    },
  })
);

const NewPlace = (props: Props) => {
  const classes = styles(props);
  return (
    <div className={classes.root}>
      <PlaceForm />
    </div>
  );
}

export default NewPlace;
