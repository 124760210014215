import {
  Theme, createStyles,
} from '@material-ui/core';

const modalStyles = (theme: Theme) => createStyles({
  dialog: {

  },
  dialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    fontSize: '26px',
    // textAlign: 'center',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.contrastText,
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: '4px 30px',
    borderRadius: theme.shape.borderRadius,
    width: '120px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  setting: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 25px',
  },
  rowContainer: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  fieldHeadingLight: {
    margin: theme.spacing(),
    fontWeight: 'bold',
    fontSize: 14,
    flex: '1',
  },
});

export default modalStyles;
