import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import {
  TextField,
  Paper,
  MenuItem,
  InputLabel,
  makeStyles,
  Theme,
} from '@material-ui/core';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { stateAbbreviations } from '../sharing/utils/StateDropDown/stateAbbreviations';

interface Props {
  value: any;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

// Note: Most of this is adapted from the Demo:
// https://material-ui.com/demos/autocomplete/#react-autosuggest
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: 250,
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 30,
      '& div': {
        color: 'inherit',
      },
    },
    suggestionContainer: {
      position: 'relative',
    },
    suggestion: {
      display: 'block',
      textColor: '#f5f5f5',
    },
    suggestionsList: {
      background: 'white',
      position: 'absolute',
      margin: 0,
      padding: 0,
      listStyleType: 'none',
      zIndex: 100,
    },
  })
)

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      label="St/Prov"
      inputRef={ref}
      InputProps={{
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
       <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 300 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : stateAbbreviations.filter(suggestion =>
    suggestion.toLowerCase().slice(0, inputLength) === inputValue
  );
}

const AutoSuggest = (props: Props) => {
  const [suggestions, setSuggestions] = React.useState([]);

  const getSuggestionValue = (suggestion: string) => {
    props.onChange(suggestion);
    return suggestion;
  }

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  }

  const handleSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const handleChange = (value: string) => {
    const { onChange } = props;
    if (onChange && value !== undefined) {
      onChange(value);
    }
  }

  const { value, disabled } = props;
  const classes = styles(props);

  return (
    <Autosuggest
      theme={{
        container: classes.suggestionContainer,
        suggestionsList: classes.suggestionsList,
        suggestion: classes.suggestion,
      }}
      renderInputComponent={renderInput}
      suggestions={suggestions}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      renderSuggestionsContainer={renderSuggestionsContainer}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      value={value}
      isDisabled={disabled}
      inputProps={{
        classes,
        value,
        onChange: event => { handleChange(event.target.value); },
        disabled,
      }}
    />
  );
}

export default AutoSuggest;
