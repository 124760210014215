export enum PhotosphereSetup {
  View,
  Home,
  HomeModified,
  MoveFloor,
  MoveFloorModified,
  RotateSphere,
  RotateSphereModified,
  ChangeHeight,
  ChangeHeightModified,
  SaveSetup,
  Cancel,
}

export enum GlobalViewState {
  Layout,
  Fixtures,
  Guest,
}

//TODO cleanup Inactive
export enum FixtureViewState {
  Floorplan,
  TwoDView,
  Inactive0,
  ThreeDView,
  Inactive1,
  PhotosphereView,
  LabelView,
  ShapeView,
}

//TODO cleanup Inactive
export enum LayoutViewState {
  TwoDView,
  Inactive2,
  ThreeDView,
  Inactive3,
  PhotosphereView,
  StreetView,
  AttendeeView,
  Inactive4,
  LabelView,
  Inactive5,
  NumberView,
  Inactive6,
  ShapeView,
}

export enum MainNavState {
  Home,
  Scenes,
  Clients,
  Places,
  AssetLibrary,
  Settings,
  SettingsQuickPicks,
  SettingsCollisionDetection,
  SettingsMeasurment,
}

export enum PlannerTool {
  None = 0,
  Designer = 4,
  Photosphere = 14,
  Attendee = 15,
  Label = 16,
  Number = 17,
  Shape = 18,
}

export enum ToolState {
  EditItem = 1,
  AddItem = 2,
  AddBatch = 3,
  NewBatch = 4,
  EditSurface = 5,
}

export enum SaveBarState {
  None = 0,
  Read = 1,
  Edit = 2,
  Save = 3,
  Cancel = 4,
}
