import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Theme,
} from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  Button,
  TextField,
  makeStyles,
  DialogTitle,
} from '@material-ui/core';
import Place from '../../../api/placez/models/Place';
import { CreateFloorPlan } from '../../../reducers/floorPlans';
import { defaultFloorplan } from '../../../blue/DefaultFloorplan';
import { SimpleModalProps } from '../SimpleModal';
import modalStyles from '../../Blue/models/modalStyles.css';

interface Props extends SimpleModalProps {
  place?: Place;
};

const NewFloorPlanForm = (props: Props) => {

  const styles = makeStyles<Theme>(modalStyles);
  const classes = styles(props);


  const dispatch = useDispatch();

  const [newFloorplanName, setNewFloorplanName] = useState<string>(undefined);


  const createNewFloorplan = () => {
    (window as any).gtag('event', 'create_floorplan');
    const { place } = props;
    const nameByPlace = `${place ? place.name : 'New'} Floorplan`;

    const newFloorPlan = {
      ...defaultFloorplan,
      name: newFloorplanName ?? nameByPlace,
      fixtures: [],
      placeId: place.id,
    };
    dispatch(CreateFloorPlan(newFloorPlan));
    props.setOpen(false);
  }

  const { place } = props;

  return (
    <Fragment>
      <div>
        <DialogTitle
          id="place-modal-dialog">
          Create Floorplan
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            style={{
              margin: '10px',
              color: 'white',
              alignItems: 'stretch',
              display: 'flex',
              width: '300px',
            }}
            id="New Floorplan Name"
            label={'New Floorplan Name (Optional)'}
            value={newFloorplanName}
            onChange={(e) => {setNewFloorplanName(e.target.value)}}
            inputProps={{
              maxLength: 200,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} variant="contained" onClick={() => props.setOpen(false)}>Cancel</Button>
          <Button className={classes.button} disabled={!place.id} variant="contained" onClick={createNewFloorplan} color='primary'>Create</Button>
        </DialogActions>
      </div>
    </Fragment>
  );
}

export default NewFloorPlanForm;
