import { useState, useEffect } from 'react';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  makeStyles,
} from '@material-ui/core';

import FileSelector from '../../Utils/FileSelector';
import { damApi } from '../../../api';
import { Image } from '@material-ui/icons';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: '8px',
      fontSize: '24px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    error: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    heading: {
      ...theme.typography.h2,
      fontSize: '20px',
      height: '40px',
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0px ${theme.spacing(3)}px`,
    },
    content: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    field: {
      minWidth: '40%',
      margin: '10px 24px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionButton: {
      margin: '10px'
    },
    container: {
      padding: theme.spacing(),
      textAlign: 'center',
    },
    image: {
      width: '500px',
    },
    imageDetails: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    logo: {
    },
    leftColumn: {
      flexDirection: 'column',
      width: '50%',
      padding: theme.spacing(),
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputField: {
      marginBottom: '0px',
    },
    inputCheckField: {
      marginBottom: '-15px',
    },
  })
);
interface Props {
  imageLabel?: string;
  setAName?: boolean;
  handleSetImage: (imageUrl: string, name?: string) => void;
  onClose?: (e?) => void;
  currentImage?: string;
  autoSetImage?: boolean;
  imageTypes?: string;
  defaultImage?: string;
}

const ImageForm = (props: Props) => {

  const { imageLabel, onClose, currentImage, autoSetImage, handleSetImage } = props;

  const [imageUrl, setImageUrl] = useState<string>(currentImage);

  const [saving, setSaving] = useState<boolean>(false);
  const [formData, setFormData] = useState(undefined);
  const [name, setName] = useState(undefined);


  const handleImport = (imageUrl?: string) => () => {
    if (props.handleSetImage !== undefined) props.handleSetImage(imageUrl, name);
    if (props.onClose !== undefined) {
      props.onClose();
    }
  }

  useEffect(() => {
    if (autoSetImage) {
      if (props.handleSetImage !== undefined) props.handleSetImage(imageUrl);
    }
  }, [
    autoSetImage, imageUrl,
  ]);

  useEffect(() => {
    if (saving) {
      damApi.postBlob(formData).then(data => {
        setImageUrl(data.parsedBody.url);
        setSaving(false);
      });
    }
  }, [
    saving, formData
  ]);

  const handleFileSubmit = (files: FileList) => {
    const formData = new FormData();
    formData.append('file', files[0], files[0].name);
    setFormData(formData);
    setSaving(true);
  }

  const previewImage = imageUrl ?? currentImage;


  const classes = styles(props);

  return(
    <>
      { imageLabel &&
        <DialogTitle className={classes.title}>{imageLabel} Preview</DialogTitle>
      }
      <DialogContent style={{padding: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        {!saving && ((previewImage && previewImage !== '') || props.defaultImage) &&
          <img width='100%' src={previewImage ? previewImage : props.defaultImage} alt={'Missing'}/>
        }
        {saving &&
          <div style={{width: '300px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CircularProgress/>
          </div>
        }
        {!saving && !currentImage && !imageUrl && !props.defaultImage &&
          <div style={{width: '300px', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Image color="secondary" style={{ fontSize: 50 }}/>
          </div>
        }
        {props.setAName && imageUrl &&
            <Input
              placeholder="Label Photo"
              id="adornment-password"
              value={name}
              onChange={event => { setName(event.target.value); }}
              style={{
                margin: '10px',
              }}
            />

        }
      </DialogContent>

      {handleSetImage !== undefined &&
        <DialogActions className={classes.actions}>
          {onClose !== undefined &&
            <Button
              className={classes.actionButton}
              variant="contained"
              onClick={onClose}>
              Cancel
            </Button>
          }
          <FileSelector
            customID={`load${imageLabel}Image`}
            handleFileSubmit={handleFileSubmit}
            buttonText={currentImage ? 'Replace Image' : 'Load Image'}
            accept={props.imageTypes ?? "image/*"} />
          {currentImage && currentImage !== props.defaultImage &&
            <Button
              className={classes.actionButton}
              variant="contained"
              classes={{
                root: classes.error,
              }}
              onClick={() => {
                handleImport('')
                setImageUrl('');
              }}
              >
              Remove Image
            </Button>
          }
          {!autoSetImage &&
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              disabled={imageUrl === undefined}
              onClick={handleImport(imageUrl)}>
                Save
            </Button>
          }
        </DialogActions>
      }
    </>
  );
}

export default ImageForm;
