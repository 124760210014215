import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { MainRoute, dashboardRoutes } from '../../routes';
import { SetGlobalFilter } from '../../reducers/settings';
import { MainNavState } from '../../models/GlobalState';
import { ChangeMainNavState } from '../../reducers/globalState';
import sideBarStyles from './sideBarStyles.css';

interface Props {
  children?: ReactElement<any>;
  route: MainRoute;
  mainNavStateType?: MainNavState;
  timeout?: number;
}

const SideBarNavLink = (props: Props) => {
  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(sideBarStyles);
  const classes = styles(props);

  const handleClick = e => {
    const {
      route,
      mainNavStateType,
    } = props;

    if (mainNavStateType !== undefined) {
      dispatch(ChangeMainNavState(mainNavStateType));
    }

    if (route.disabled) {
      e.preventDefault();
    }

    dispatch(SetGlobalFilter(''));
  }

  const { route, timeout } = props;
  return (
    <Grow in={true} timeout={timeout}>
      <div className={classes.root}>
        <NavLink
          onClick={handleClick}
          to={route.path}
          end={route.path === dashboardRoutes.main.path} // Only exact for root
          className={(navData) => navData.isActive ? classes.activeLink : classes.link }>
          <ListItem disabled={route.disabled}
            button className={classes.listItem}>
            <Tooltip title={route.name}>
              <ListItemIcon
                classes={{ root: classes.icon }}>
                <route.icon fontSize="large"/>
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              classes={{
                root: classes.label,
                primary: classes.label,
              }}
              primary={route.name}/>
          </ListItem>
        </NavLink>
      </div>
    </Grow>
  );
}

export default SideBarNavLink;
