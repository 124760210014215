import { AuthenticatedBase, IHttpResponse } from '../AuthenticatedBase';
import MediaAssetsApi from './contracts/MediaAssetsApi';
import { AssetCatalog } from './models';
import { Asset, AssetGroup } from '../../blue/items';
import { he } from 'date-fns/locale';
import { PlacezMaterial } from '../placez/models/PlacezMaterial';

export default class DAMAPI extends AuthenticatedBase implements MediaAssetsApi {
  private url: string = 'tempUrl';

  constructor(url: string) {
    super();
    this.url = url;

    this.postBlob = this.postBlob.bind(this);
    this.postMediaAsset = this.postMediaAsset.bind(this);
    this.getMediaAssets = this.getMediaAssets.bind(this);
    this.getMediaAssetsByCollection = this.getMediaAssetsByCollection.bind(this);
    this.deleteMediaAsset = this.deleteMediaAsset.bind(this);

    this.getAssetCatalog = this.getAssetCatalog.bind(this);
    this.postAssetCatalog = this.postAssetCatalog.bind(this);
    this.postCustomAsset = this.postCustomAsset.bind(this);
    this.deleteCustomAsset = this.deleteCustomAsset.bind(this);
    this.getCustomAssets = this.getCustomAssets.bind(this);
    this.getMaterials = this.getMaterials.bind(this);
    this.getMaterial = this.getMaterial.bind(this);
    this.postMaterial = this.postMaterial.bind(this);
    this.deleteMaterial = this.deleteMaterial.bind(this);
    this.postGroup = this.postGroup.bind(this);
    this.getAssetGroups = this.getAssetGroups.bind(this);
    this.deleteAssetGroup = this.deleteAssetGroup.bind(this);
  }
  // media Library
  // FIXME This doesn't actually post a blob but files
  public async postBlob(formData: FormData, id?: string) {
    return await super.postFile<any>(`${this.url}/MediaAssetFile${id ? '/' + id : ''}`, formData);
  }

  public async postMediaAsset(payload: any) {
    return await super.postMediaAsset<any>(`${this.url}/MediaAsset`, payload);
  }

  public async getMediaAssets(ignoreCustomization: boolean = false) {
    const headers = super.getHeader();
    if (ignoreCustomization) {
      headers['placez_ignore_customizations'] = 'true';
    }

    return await super.get<any>(`${this.url}/MediaAsset`, { method: 'get', headers });
  }

  public async getMediaAssetsByCollection(collectionName: string) {
    return await super.get<any>(`${this.url}/MediaAsset/${collectionName}`);
  }

  public async getMediaAsset(id, ignoreCustomization: boolean = false) {
    const headers = super.getHeader();
    if (ignoreCustomization) {
      headers['placez_ignore_customizations'] = 'true';
    }

    return await super.get<any>(`${this.url}/MediaAsset/${id}`, { method: 'get', headers });
  }

  public async deleteMediaAsset(id) {
    return await super.delete<any>(`${this.url}/MediaAsset/${id}`);
  }

  public async getAssetCatalog() {
    return await super.get<any>(`${this.url}/MediaAssetCatalog`);
  }

  public async postAssetCatalog(catalog: AssetCatalog) {
    return await super.post<any>(`${this.url}/MediaAssetCatalog`, catalog);
  }

  public async postCustomAsset(customAsset: Asset) {
    return await super.post<Asset>(`${this.url}/MediaAsset/Custom`, customAsset);
  }

  public async deleteCustomAsset(assetId: string) {
    return await super.delete(`${this.url}/MediaAsset/Custom/${assetId}`);
  }

  public async getCustomAssets() {
    return await super.get<any>(`${this.url}/MediaAsset/Custom`);
  }

  public async getMaterials() {
    return await super.get<any>(`${this.url}/Material?includeDeleted=false`);
  }

  public async getMaterial(id: string) {
    return await super.get<any>(`${this.url}/Material/${id}`);
  }

  public async postMaterial(material: PlacezMaterial) {
    return await super.post<PlacezMaterial>(`${this.url}/Material`, material);
  }

  public async deleteMaterial(id: string) {
    return await super.delete<PlacezMaterial>(`${this.url}/Material/${id}`);
  }

  public async postGroup(assetGroup: AssetGroup) {
    return await super.post<Asset>(`${this.url}/MediaAssetGroup/`, assetGroup);
  }

  public async getAssetGroups() {
    return await super.get<Asset>(`${this.url}/MediaAssetGroup/`);
  }

  public async deleteAssetGroup(id: string) {
    return await super.delete<Asset>(`${this.url}/MediaAssetGroup/${id}`);
  }
}
