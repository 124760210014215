import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  TextField,
  Tooltip,
  makeStyles,
  Theme,
} from '@material-ui/core';

import panelStyles from './panels.css';
import { HandlesFromBlue } from '../../models';
import { ReduxState } from '../../../../reducers';
import { SetNextTableNumber, ToggleDeleteTableNumberModeAction, } from '../../../../reducers/blue';
import { ToggleButton } from '@material-ui/lab';
import { Delete } from '@material-ui/icons';


type Props = {
  handlesFromBlue: HandlesFromBlue;
  onBack: Function;
  onClosePanel: Function;
};

const  NumberPanel = (props: Props) => {

  const nextTableNumber: number = useSelector((state: ReduxState) => state.blue.nextTableNumber);
  const deleteTableNumber: boolean = useSelector((state: ReduxState) => state.blue.deleteTableNumber);

  const styles = makeStyles<Theme>(panelStyles);

  const dispatch = useDispatch();

  const nextTableNumberChange = (e: any) => {
    const tableNumber = isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10);
    dispatch(SetNextTableNumber(tableNumber))
  }

  const classes = styles(props);
  return (
      <div className={classes.root}>
        <div className={classes.tablePanel}>
          <div className={classes.titleContainer}>
            <Typography className={classes.title} align="center">
              Number Tables
            </Typography>
          </div>
          <div className={classes.fieldHeading}>
            Click or Drag Over Cursor
          </div>
          <div className={classes.tableNumberLabel}>
            <TextField
              margin="dense"
              className={classes.tableNumber}
              id="scene-total"
              value={nextTableNumber}
              onChange={nextTableNumberChange}
              inputProps={{style: {
                fontSize: 80,
                textAlign: 'center',
              }}}
            />
          </div>
          <ToggleButton
            value={deleteTableNumber}
            name="sectionView"
            aria-label="label"
            selected={deleteTableNumber}
            onClick={() => dispatch(ToggleDeleteTableNumberModeAction(!deleteTableNumber))}
            classes={{
              root: classes.bigButton,
              selected: classes.bigButtonSelected,
            }}
            >
            <Tooltip title="Delete Tables">
              <Delete fontSize="large"/>
            </Tooltip>
          </ToggleButton>
        </div>
      </div>
  );
}

export default NumberPanel;
