import { ReactElement } from 'react';
import classnames from 'classnames';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import {
  CircularProgress,
  Button,
  Grow,
  makeStyles,
} from '@material-ui/core';
import { Color } from 'csstype';
import { useTheme } from '@material-ui/styles'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      fontWeight: 300,
    },
    button: {
      color: theme.palette.common.white,
      borderRadius: 16,
      background: theme.palette.grey[800],
      padding: 16,
      margin: 16,
    },
    progressWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    progress: {
      position: 'absolute',
      height: 64,
      width: 64,
    },
    disabledButton: {
      color: `${theme.palette.grey[500]} !important`,
    },
    disabledText: {
      color: theme.palette.grey[500],
    },
  })
);

type Props = {
  loading?: boolean;
  color?: Color;
  label?: string;
  children?: ReactElement<any>;
  disabled?: boolean;
  onClick?: (event) => void;
};

const LargeIconButton = (props: Props) => {
  const classes = styles(props);
  const theme = useTheme<Theme>();
  const {
    color,
    loading,
    label,
    children,
    onClick,
    disabled,
  } = props;
  return(
    <Grow in={true} timeout={500}>
      <div  className={disabled ? classnames(classes.root, classes.disabledText) : classes.root}>
        <Button
          disabled={disabled}
          onClick={onClick}
          aria-label={label}
          style={{
            backgroundColor: color ? color : theme.palette.grey[800],
          }}
          classes={{
            root: classes.button,
            disabled: classes.disabledButton,
          }}
          variant="contained">
          <div className={classes.progressWrapper}>
            {
              loading ?
              <CircularProgress className={classes.progress}/> :
              <></>
            }
            { children }
          </div>
        </Button>
        { label ? label : '' }
      </div>
    </Grow>
  );
}

export default LargeIconButton;
