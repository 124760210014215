import React, { Component, useEffect, useState } from 'react';
import {
  WithStyles,
  withStyles,
  Theme,
  createStyles,
 }
  from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Client } from '../../api';
import { clientRoutes } from '../../routes';

// Components
import { Grid, GridColumn, GridCell, GridCellProps } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { tableStyles } from './tableSyles.css';
import ArrowNavigateCell from './ArrowNavigateCell';
interface Props {
  clients: Client[];
  selectedId?: number | null;
  onRowClick?: (event: any) => void;
  samePage: boolean;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...tableStyles(theme),
  })
);

type State = {
  sort: SortDescriptor[],
  visibleColumns: any[],
};

const ViewContactByArrowCell = (props: GridCellProps) => {
  const { id } = props.dataItem;
  return(
    <ArrowNavigateCell {...props}
      path={clientRoutes.edit.path.replace(':id', id)}
    />
  )
};

const ClientTable = (props: Props) => {
  let grid;
  const classes = styles(props);

  const columns = [
    { field: 'name', title: 'Client Name', className: classes.overflowEllipsis, },
    { field: 'type', title: 'Client Type', className: classes.overflowEllipsis, minShow: 770 },
    { field: 'phone', title: 'Phone Number', className: classes.overflowEllipsis, minShow: 570 },
    { field: 'email', title: 'Email', className: classes.overflowEllipsis, minShow: 440 },
    { cell: ViewContactByArrowCell, className: classes.arrow, width: 60 },
  ];

  const [sort, setSort] = useState<SortDescriptor[]>([{field: 'lastModifiedDate', dir: 'desc'}]);
  const [visibleColumns, setVisibleColumns] = useState<any[]>(columns);


  useEffect(() => {
    grid = document.querySelector(`.${CSS.escape(classes.gridRoot)}`);
    window.addEventListener('resize', checkColumnMaxWidth);
    checkColumnMaxWidth();
   return () => {
    window.removeEventListener('resize', checkColumnMaxWidth);
   }
  },[])

  const checkColumnMaxWidth = () => {
    const currentVisibleColumns = columns.filter(item => !item.minShow || item.minShow <= grid.offsetWidth);
    if (currentVisibleColumns.length !== visibleColumns.length) {
      setVisibleColumns(currentVisibleColumns);
    }
  }

  const {
    onRowClick,
    clients,
    selectedId,
  } = props;

  return (
    <Paper>
      <Grid
        sortable
        sort={sort}
        onSortChange={e => {
          setSort(e.sort);
        }}
        className={classes.gridRoot}
        selectedField="selected"
        data={orderBy(
          clients.map(
            client => ({
              ...client,
              selected: client.id === selectedId,
              lastModifiedDate: new Date(client.lastModifiedUtcDateTime).getTime(),
            })),
          sort)
        }
        onRowClick={onRowClick}>
          {
            visibleColumns.map((column, index) => {
              return (
                <GridColumn
                  field={column.field}
                  title={column.title}
                  cell={column.cell}
                  key={index}
                  className={column.className}
                  width={column.width}
                />
              );
            })
          }
      </Grid>
    </Paper>
  );
}

class ViewContactByNameCell extends GridCell {
  render() {
    const { name, id } = this.props.dataItem;
    const { className } = this.props;
    return (
      <td>
        <Link to={clientRoutes.edit.path.replace(':id', id)} >
          <Typography className={className} >{name}</Typography>
        </Link>
      </td>
    );
  }
}


export default ClientTable;
