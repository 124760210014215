import { Paper, Theme, Tooltip, makeStyles } from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "../../../../../reducers"
import { SetSectionView } from "../../../../../reducers/blue"
import viewOptionsStyles from "./ViewOptions.css"
import classNames from "classnames"
import { GlobalViewState, FixtureViewState, PlannerTool } from '../../../../../models/GlobalState';

import {
  BrokenImage
} from '@material-ui/icons';


interface Props {};

const SectionViewToggle = (props: Props) => {
  const sectionView = useSelector((state: ReduxState) => state.blue.sectionView)
  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState)
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState)
  const plannerTool = useSelector((state: ReduxState) => state.globalstate.plannerTool)

  const styles = makeStyles<Theme>(viewOptionsStyles);
  const classes = styles(props);



  const dispatch = useDispatch();

  const floorPlanMode = globalViewState === GlobalViewState.Fixtures &&
    fixtureViewState === FixtureViewState.Floorplan

  return (<>
    { plannerTool !== PlannerTool.Photosphere &&
      !floorPlanMode &&
      <Paper
        className={classNames(classes.border, classes.viewToggles)}
        style={{ marginBottom: '5px' }}
        >
        <Tooltip title="Section View">
          <ToggleButton
            value={sectionView}
            name="sectionView"
            aria-label="label"
            selected={sectionView}
            onClick={() => dispatch(SetSectionView(!sectionView))}
            classes={{
              root: classes.button,
              selected: classes.selected,
            }}
            >
              <BrokenImage/>
          </ToggleButton>
        </Tooltip>
      </Paper>
    }
  </>)
}

export default SectionViewToggle;
