import React, { Fragment } from 'react';
import { WithModalContext } from '../withModalContext';

import {
  WithStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
} from '@material-ui/core';
import { Utils } from '../../../blue/core/utils';
import modalStyles from '../../Blue/models/modalStyles.css';
import NumberEditor from '../../NumberEditor'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...modalStyles,
    details: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    field: {
      minWidth: '40%',
      margin: '10px 24px',
    },
    rowLabel: {
      minWidth: '100%',
      margin: '0px 24px',
    },
  })
);
interface Props extends WithModalContext {
  handleWallHeight: Function;
  wallHeight: number;
  hideWalls: boolean;
}

const WallScaleForm = (props: Props) => {

  const [includeWall, setIncludeWall] = React.useState(!props.hideWalls);
  const [wallHeight, setWallHeight] = React.useState(props.wallHeight);

  const handleWallHeightChange = v => {
    setWallHeight(v !== undefined ? v : 0);
  }

  const handleCheck = () => {
    setIncludeWall(!includeWall);
  }

  const onSetWall = () => {
    props.handleWallHeight(includeWall ? wallHeight : 0);
    props.modalContext.hideModal();

  }

  const { hideModal } = props.modalContext;

  const classes = styles(props);

  return(
    <Fragment>
      <DialogTitle className={classes.dialogTitle}>
        Wall Height ({Utils.getUnit()})
      </DialogTitle>
      <DialogContent className={classes.content}>
        <FormControlLabel
          labelPlacement="start"
          onChange={handleCheck}
          control={
            <Checkbox
              checked={includeWall}
              onChange={() => handleCheck()}
            />
          }
          label={'Include Walls'}/>
        <NumberEditor
          value={wallHeight}
          onChange={handleWallHeightChange}
          step={1}
          round={0}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button className={classes.button} variant="contained" onClick={hideModal}>Cancel</Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onSetWall}>
            OK
          </Button>
      </DialogActions>
    </Fragment>
  );
}

export default WallScaleForm;
