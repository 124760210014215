import React, { Component, ComponentClass, createContext } from 'react';

export default interface ModalContext {
  component: ComponentClass | null;
  props: any | {};
  showModal: (component: (props:any) => JSX.Element, props: any) => void;
  hideModal: () => void;
}

const defaultValue = {
  component: null,
  props: {},
  showModal: (component: (props:any) => JSX.Element, props: any) => {},
  hideModal: () => {},
};

const { Provider, Consumer } = createContext<ModalContext>(defaultValue);

export class ModalProvider extends Component {
  private showModal = (component: (props:any) => JSX.Element, props: any = {}) => {
    this.setState({
      props,
      component,
    });
  }

  private hideModal = () =>
    this.setState({
      props: {},
      component: null,
    })

  state: ModalContext = {
    props: {},
    component: null,
    showModal: this.showModal,
    hideModal: this.hideModal,
  };

  render() {
    return (
      <Provider value={this.state}>
        {this.props.children}
      </Provider>
    );
  }
}

export const ModalConsumer = Consumer;
