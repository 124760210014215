import { useState, useEffect } from 'react';

import {
  Typography,
  Button,
  TextareaAutosize,
  FormLabel,
  Slider,
  Popover,
  Select,
  MenuItem,
  Paper,
  Theme,
  Tooltip,
  IconButton,
  TextField,
  Fab,
  makeStyles,
  Tabs,
  Tab,
  Input,
} from '@material-ui/core';

import panelStyles from './panels.css';
import { RotateLeft, RotateRight, OpenWith, DragIndicator, Edit, Favorite, Delete, Search, Clear } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../reducers';
import LayoutLabel from '../../../../api/placez/models/LayoutLabel';
import { SetSelectedLabelId, NeedSaveAction } from '../../../../reducers/blue';
import { CreateLabel, DeleteLabel, GetLabels } from '../../../../reducers/label';
import { PlacezLayoutPlan, PlacezFixturePlan } from '../../../../api';
import ColorPicker from '../utility/ColorPicker';
import { useTheme } from '@material-ui/styles';
import { GlobalViewState } from '../../../../models/GlobalState';
import TabPanel from '../../../Modals/TableConfigModal/TabPanel';

import { LabelMaker } from '../../../../blue/three/labelMaker';
import { SetFloorPlan, SetLayout } from '../../../../reducers/designer';

type Props = {
};

const LabelPanel = (props: Props) => {

  const styles = makeStyles<Theme>(panelStyles);

  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const [labelFilter, setLabelFilter] = useState(undefined);

  const labelText = globalViewState === GlobalViewState.Fixtures ? `Enter Note Text
Drag to Floorplan` : `Enter Note Text
Drag to Layout`;

  const backgroundColor = globalViewState === GlobalViewState.Fixtures ? '#cccccc' : '#ffffff';

  const defaultLabel: LayoutLabel = {
    id: 0,
    labelText,
    fontSize: 24,
    fontface: 'Arial',
    textColor: '#000000',
    position: undefined,
    borderColor: '#000000',
    backgroundColor,
    borderThickness: 2,
    borderRadius: 6,
    margin: 2,
    lineSpacing: 0,
    rotation: 0,
    justify: 'center',
  };

  interface Anchors {
    backgroundColorAnchor: HTMLElement,
    textColorAnchor: HTMLElement,
    borderColorAnchor: HTMLElement,
  }

  const [moved, setMoved] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<LayoutLabel>({...defaultLabel});
  const [anchors, setAnchors] = useState<Anchors>({backgroundColorAnchor: undefined, textColorAnchor: undefined, borderColorAnchor: undefined});
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [labels, setLabels] = useState<LayoutLabel[]>([]);
  const [newLabelText, setNewLabelText] = useState<string>(labelText);

  const {backgroundColorAnchor, textColorAnchor, borderColorAnchor} = anchors;

  const floorplanLabels = useSelector((state: ReduxState) => state.designer.floorPlan?.floorplanLabels);
  const layoutLabels = useSelector((state: ReduxState) => state.designer.layout?.layoutLabels);
  const favoriteLabels = useSelector((state: ReduxState) => state.label.labels);

  // const favoriteNotes = useSelector((state: ReduxState) => state.asset.customNotes);

  useEffect(() => {
    if (globalViewState === GlobalViewState.Fixtures) {
      setLabels(floorplanLabels ?? [])
      dispatch(GetLabels());
    } else {
      setLabels(layoutLabels ?? [])
      dispatch(GetLabels());
    }
  },
    [floorplanLabels, layoutLabels, globalViewState]
  )


  const selectedLabelId: number = useSelector((state: ReduxState) => state.blue.selectedLabelId);
  const layout: PlacezLayoutPlan = useSelector((state: ReduxState) => state.designer.layout);
  const fixturePlan: PlacezFixturePlan = useSelector((state: ReduxState) => state.designer.floorPlan);

  const dispatch = useDispatch();

  const save =  (newLabels: LayoutLabel[]) => {
    if (globalViewState === GlobalViewState.Fixtures) {
      dispatch(SetFloorPlan({
        floorplanLabels: newLabels,
      }));
    } else {
      dispatch(SetLayout({
        ...layout,
        layoutLabels: newLabels,
      }));
    }
    dispatch(NeedSaveAction(true));
  }

  const createLabel = (label: LayoutLabel) => (e?: TouchEvent & MouseEvent) => {
    const newLabels = labels ? [...labels] : [];
    const newLabelId = newLabels.length;
    const newLabel = {
      ...label,
      id: newLabelId,
    }
    newLabel.position = undefined;
    newLabels.push({ ...newLabel });
    save(newLabels);
    dispatch(SetSelectedLabelId(newLabelId));
  }

  const updateSelectedLabel = (key: string, value: any) => {
    if (selectedLabel[key] === value) return;
    const newLabels = labels ? [...labels] : [];
    const selectedLabelIndex = newLabels.findIndex((label: LayoutLabel) => {
      return label.id === selectedLabelId;
    });

    const updatedLabel = {
      ...selectedLabel,
      [key]: value,
    }
    setNewLabelText(updatedLabel.labelText);

    if (selectedLabelIndex >= 0) {
      newLabels[selectedLabelIndex] = { ...updatedLabel };
      save(newLabels);
      dispatch(SetSelectedLabelId(updatedLabel.id));
    } else {
      // just update local selected
      setSelectedLabel(updatedLabel);
    }
  }

  const deleteLabel = () => {
    const newLabels = labels.filter((label: LayoutLabel) => {
      return label.id !== selectedLabelId;
    });
    newLabels.forEach((label, index) => {
      label.id = index;
    });
    save(newLabels)
    dispatch(SetSelectedLabelId(undefined));
  }

  useEffect(
    // only select label when selected label Id changes
    () => {
      if (selectedLabelId !== undefined) {
        let labels;
        if (globalViewState === GlobalViewState.Fixtures) {
          if (floorplanLabels) {
            labels = [...floorplanLabels] ?? [];
          } else {
            labels = [];
          }
        } else {
          if (layoutLabels) {
            labels = [...layoutLabels] ?? [];
          } else {
            labels = []
          }
        }

        const selectLabel = labels
          .find((layoutLabel: LayoutLabel) => {
            return layoutLabel.id === selectedLabelId;
          })
        if (selectLabel) {
          // something to do with this
          setSelectedLabel(selectLabel);
          setNewLabelText(selectLabel.labelText);
        } else {
          setSelectedLabel(defaultLabel);
          dispatch(SetSelectedLabelId(undefined));
        }
      } else {
        setSelectedLabel(defaultLabel);
        setNewLabelText(labelText);
      }
    },
    [selectedLabelId, layoutLabels, floorplanLabels]);

  const theme: Theme = useTheme();
  const classes = styles(props);

  const handleOpenColorPicker = (anchor: keyof Anchors):React.MouseEventHandler => (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchors({
      ...anchors,
      [anchor]: event.currentTarget,
    })
  };
  const handleCloseColorPicker = (anchor: keyof Anchors) => () => {
    setAnchors({
      ...anchors,
      [anchor]: null,
    })
  };
  const backgroundColorOpen = Boolean(backgroundColorAnchor);
  const textColorOpen = Boolean(textColorAnchor);
  const borderColorOpen = Boolean(borderColorAnchor);


  const [favoriteLabelsWithThumb, setFavoriteLabelsWithThumb] = useState([]);
  useEffect(() => {
    if (favoriteLabels) {
      const labelsWithThumb = favoriteLabels.map((note: LayoutLabel) => {
        const labelMaker = new LabelMaker();
        const noteCanvas = document.createElement('canvas');
        labelMaker.drawText(noteCanvas, labelMaker.fixParameters(note));
        return {
          ...note,
          thumbNail: noteCanvas.toDataURL(),
        }
      });
      setFavoriteLabelsWithThumb(labelsWithThumb);
    }
  },
    [favoriteLabels]
  )

  useEffect(() => {
    updateSelectedLabel('labelText', newLabelText);
  },
    [newLabelText]
  )


  return (
    <Paper className={classes.root}>
      <div className={classes.panelUpper}>
        <div className={classes.mainHeadingContainer}>
          <Typography className={classes.title}>
            { 'Notes'}
          </Typography>
        </div>
        <Tabs
          className={classes.tabs}
          value={tabIndex}
          onChange={(e, v) => setTabIndex(v)}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="Table Configuration"
        >
          <Tooltip title="Items">
            <Tab
              className={classes.tabIcon}
              color="secondary"
              icon={<Edit/>} />
          </Tooltip>
          <Tooltip title="Favorites">
          <Tab
            className={classes.tabIcon}
            icon={<Favorite/>} />
          </Tooltip>
        </Tabs>
        {tabIndex === 1 &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            margin: '5px',
          }}>
            <Input
              placeholder="Search Labels"
              className='labelSearch'
              id="adornment-password"
              value={labelFilter}
              onChange={event => { setLabelFilter(event.target.value) }}
              endAdornment={
                <>
                  { labelFilter !== '' &&
                    <IconButton
                      onClick={event => { setLabelFilter('') }}
                    >
                      <Clear/>
                    </IconButton>
                  }
                  { labelFilter === '' &&
                    <IconButton>
                      <Search />
                    </IconButton>
                  }
                </>
              }
            />
          </div>
        }
      </div>
      <div className={classes.panelLower}>
        <TabPanel
          value={tabIndex}
          index={0}
          className={classes.tabPanel}
          >
      { selectedLabel &&
        <div
          className={classes.itemImage}
          style={{
            backgroundColor: theme.palette.background.default,
          }}
          >
          <TextareaAutosize
            className={classes.textArea}
            aria-label="empty textarea"
            placeholder="Empty"
            value={newLabelText}
            onChange={e => updateSelectedLabel('labelText', e.target.value) }
            style={{
              fontFamily: selectedLabel.fontface,
              resize: "none",
              textAlign: selectedLabel.justify,
            }}
            onKeyDown={e => e.stopPropagation()}
          />
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            position: 'relative',
            marginBottom: '-52px',
            paddingLeft:'12px',
            paddingRight:'12px',
            paddingBottom: '10px',
            bottom: '52px',
          }}>
            <Tooltip title="Drag to Create Label">
              <Fab
                style={{
                  cursor: 'grab',
                }}
                color="secondary"
                size="small" aria-label="Drag to Create"
                draggable={true}
                label-data={JSON.stringify(selectedLabel)}
                onDragEnd={(e: any) =>  createLabel(selectedLabel)(e)}
                onTouchEnd= {(e: any) => createLabel(selectedLabel)(e)}
                >
                <OpenWith/>
                {/* <DragIndicator/> */}
              </Fab>
            </Tooltip>

            <Tooltip title="Favorite">
              <Fab
                style={{
                  cursor: 'grab',
                }}
                color="secondary"
                size="small" aria-label="Favorite"
                draggable={true}
                onClick={(e: any) => {
                  const newLabel = {...selectedLabel};
                  newLabel.position = undefined;
                  dispatch(CreateLabel(newLabel))
                }}>
                <Favorite/>
              </Fab>
            </Tooltip>
          </div>

        </div>
      }
      { selectedLabel &&
      <div className={classes.panelLower}>
        <div className={classes.fieldContainer}>
          <Tooltip title="Rotate Clockwise">
            <IconButton
              onClick={e => updateSelectedLabel('rotation', (360 + (selectedLabel.rotation ?? 0) - 22.5) % 360)}>
              <RotateRight/>
            </IconButton>
          </Tooltip>
          <form
            className={classes.rotationInput}
            noValidate
            autoComplete="off">
            <TextField
              onKeyPress={ e => { if (e.key === 'Enter') { e.stopPropagation(); e.preventDefault(); } } }
              onChange={e => updateSelectedLabel('rotation', (360 - parseInt(e.target.value) % 360) ?? 0)}
              helperText="Label Rotation"
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 3,
              }}
              FormHelperTextProps={{
                style: {
                  textAlign: 'center',
                },
              }}
              value={selectedLabel.rotation === 0 ? selectedLabel.rotation : (360 - selectedLabel.rotation)}/>
          </form>
          <Tooltip title="Rotate Counterclockwise">
            <IconButton
              onClick={e => updateSelectedLabel('rotation', (360 + (selectedLabel.rotation ?? 0) + 22.5) % 360)}>
              <RotateLeft/>
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Select Font
          </FormLabel>
          <Select
            className={classes.select}
            value={selectedLabel.fontface}
            onChange={e => updateSelectedLabel('fontface', e.target.value)}
          >
            <MenuItem value={'Times New Roman'} style={{ fontFamily: 'Times New Roman' }}>Times New Roman</MenuItem>
            <MenuItem value={'Arial'} style={{ fontFamily: 'Arial' }}>Arial</MenuItem>
            <MenuItem value={'Verdana'} style={{ fontFamily: 'Verdana' }}>Verdana</MenuItem>
            <MenuItem value={'Calibri'} style={{ fontFamily: 'Calibri' }}>Calibri</MenuItem>
            <MenuItem value={'Vivaldi'} style={{ fontFamily: 'Vivaldi' }}>Vivaldi</MenuItem>
            <MenuItem value={'Montserrat'} style={{ fontFamily: 'Montserrat' }}>Avenir</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldRow}>
          <FormLabel className={classes.fieldHeading}>
            Font Color
          </FormLabel>
          <div
            className={classes.swatch}
            onClick={handleOpenColorPicker('textColorAnchor')}
            style={{ backgroundColor: selectedLabel.textColor }}>
          </div>
          <Popover
            open={textColorOpen}
            anchorEl={textColorAnchor}
            onClose={handleCloseColorPicker('textColorAnchor')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ColorPicker
              color={selectedLabel.textColor}
              colors={['#FF6900', '#FCB900', '#00D084', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#ffffff', '#000000']}
              allowInput={true}
              // className={classes.picker}
              onChange={e => updateSelectedLabel('textColor', e.hex)}
            />
          </Popover>
        </div>
        <div className={classes.fieldRow}>
          <FormLabel className={classes.fieldHeading}>
            Background Color
          </FormLabel>
          <div
            className={classes.swatch}
            onClick={handleOpenColorPicker('backgroundColorAnchor')}
            style={{ backgroundColor: selectedLabel.backgroundColor }}>
          </div>
          <Popover
            open={backgroundColorOpen}
            anchorEl={backgroundColorAnchor}
            onClose={handleCloseColorPicker('backgroundColorAnchor')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ColorPicker
              color={selectedLabel.backgroundColor}
              colors={['#FF6900', '#FCB900', '#00D084', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#ffffff', '#000000']}
              allowInput={true}
              allowClear={true}
              onChange={e => updateSelectedLabel('backgroundColor', e.hex)}
            />
          </Popover>
        </div>
        <div className={classes.fieldRow}>
          <FormLabel className={classes.fieldHeading}>
            Border Color
          </FormLabel>
          <div
            className={classes.swatch}
            onClick={handleOpenColorPicker('borderColorAnchor')}
            style={{ backgroundColor: selectedLabel.borderColor }}>
          </div>
          <Popover
            open={borderColorOpen}
            anchorEl={borderColorAnchor}
            onClose={handleCloseColorPicker('borderColorAnchor')}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <ColorPicker
              color={selectedLabel.borderColor}
              colors={['#FF6900', '#FCB900', '#00D084', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#ffffff', '#000000']}
              allowInput={true}
              onChange={e => updateSelectedLabel('borderColor', e.hex)}
            />
          </Popover>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Font Size
          </FormLabel>
          <div className={classes.sliderDiv}>
            <Slider
              className={classes.spacingSlider}
              value={selectedLabel.fontSize}
              step={1}
              min={12}
              max={120}
              valueLabelDisplay="auto"
              onChange={(e, v) => updateSelectedLabel('fontSize', v)}
              color="secondary"
            />
          </div>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Justify
          </FormLabel>
          <Select
            className={classes.select}
            value={selectedLabel.justify}
            onChange={e => updateSelectedLabel('justify', e.target.value)}
          >
            <MenuItem value={'center'}>Center</MenuItem>
            <MenuItem value={'left'}>Left</MenuItem>
            <MenuItem value={'right'}>Right</MenuItem>
          </Select>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Line Spacing
          </FormLabel>
          <div className={classes.sliderDiv}>
            <Slider
              className={classes.spacingSlider}
              value={selectedLabel.lineSpacing}
              step={1}
              min={0}
              max={20}
              valueLabelDisplay="auto"
              onChange={(e, v) => updateSelectedLabel('lineSpacing', v)}
              color="secondary"
            />
          </div>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Margin
          </FormLabel>
          <div className={classes.sliderDiv}>
            <Slider
              className={classes.spacingSlider}
              value={selectedLabel.margin}
              step={1}
              min={1}
              max={60}
              valueLabelDisplay="auto"
              onChange={(e, v) => updateSelectedLabel('margin', v)}
              color="secondary"
            />
          </div>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Border Thickness
          </FormLabel>
          <div className={classes.sliderDiv}>
            <Slider
              className={classes.spacingSlider}
              value={selectedLabel.borderThickness}
              step={1}
              min={1}
              max={24}
              valueLabelDisplay="auto"
              onChange={(e, v) => updateSelectedLabel('borderThickness', v)}
              color="secondary"
            />
          </div>
        </div>
        <div className={classes.fieldColumns}>
          <FormLabel className={classes.fieldHeading}>
            Border Radius
          </FormLabel>
          <div className={classes.sliderDiv}>
            <Slider
              className={classes.spacingSlider}
              value={selectedLabel.borderRadius}
              step={1}
              min={1}
              max={selectedLabel.fontSize}
              valueLabelDisplay="auto"
              onChange={(e, v) => updateSelectedLabel('borderRadius', v)}
              color="secondary"
            />
          </div>
        </div>
        <div className={classes.buttonDiv}>
          <Button
              disabled={selectedLabel.id === undefined}
              className={classes.button}
              onClick={deleteLabel}
              variant="outlined"
              classes={{
                root: classes.deleteButton,
              }}>
              Delete Label
          </Button>
        </div>
      </div>
    }
    </TabPanel>
    <TabPanel
      value={tabIndex}
      index={1}
      className={classes.tabPanel}
      >
      <div style={{display: 'grid', gridTemplateColumns: 'repeat(1) 270px', gridGap: '0px' }}>
        {favoriteLabelsWithThumb
        .filter((value: LayoutLabel) => labelFilter ? value.labelText.toLowerCase().includes(labelFilter.toLowerCase()) : true)
        .sort((a: LayoutLabel, b: LayoutLabel) => a.labelText.localeCompare(b.labelText))
        .map((value: LayoutLabel) => {
          return(
            <div key={value.id} style={{
              height: '200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '250px',
              backgroundImage: `url(${value.thumbNail})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              justifyContent: 'space-between',
              }}>
              <Tooltip title="Delete" style={{alignSelf: 'end', margin: '10px'}}>
                <Fab
                  color="secondary"
                  size="small" aria-label="Favorite"
                  draggable={true}
                  onClick={(e: any) => dispatch(DeleteLabel(value))}>
                  <Delete/>
                </Fab>
              </Tooltip>
              <Tooltip title="Drag to Create Label" style={{alignSelf: 'start', margin: '10px'}}>
                <Fab
                  color="secondary"
                  size="small" aria-label="Drag to Create"
                  draggable={true}
                  onDragEnd={(e: any) =>  createLabel(value)(e)}
                  onTouchEnd= {(e: any) => createLabel(value)(e)}>
                  <OpenWith/>
                </Fab>
              </Tooltip>
            </div>
          )
        })}
      </div>

    </TabPanel>
    </div>
    </Paper>
  );
}

export default LabelPanel;
