import { Filter, FilterItem } from './Filter';
import { Scene } from '../../../api';

const filterItems : FilterItem<Scene>[] = [
  {
    id: 1,
    name: 'Today',
    select: scene => isToday(scene.startUtcDateTime),
  },
  {
    id: 2,
    name: 'This Week',
    select: scene => thisWeek(scene.startUtcDateTime),
  },
  {
    id: 3,
    name: 'This Month',
    select: scene => thisMonth(scene.startUtcDateTime),
  },
  {
    id: 4,
    name: 'This Year',
    select: scene => thisYear(scene.startUtcDateTime),
  },
  {
    id: 5,
    name: 'Select Month',
    select: (scene, customMonth: Date) => monthRange(scene.startUtcDateTime, customMonth),
  },
  {
    id: 6,
    name: 'Select Year',
    select: (scene, customYear: Date) => yearRange(scene.startUtcDateTime, customYear),
  },
];

const lastHour = (date: Date): boolean => {
  const today = new Date();
  const lastHour = new Date();
  const ensuredDate = ensureDate(date);
  lastHour.setHours(today.getHours() - 1);
  return (lastHour <= ensuredDate && ensuredDate <= today);
};

const isToday = (date: Date) => {
  const today = new Date();
  const ensuredDate = ensureDate(date);
  return ensuredDate.getDate() === today.getDate() &&
  ensuredDate.getMonth() === today.getMonth() &&
  ensuredDate.getFullYear() === today.getFullYear();
};

const thisWeek = (date: Date): boolean => {
  const today = new Date();
  const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
  const ensuredDate = ensureDate(date);
  return (today <= ensuredDate && ensuredDate <= endOfWeek);
};

const thisMonth = (date: Date): boolean => {
  const today = new Date();
  return ensureDate(date).getMonth() === today.getMonth() &&
  ensureDate(date).getFullYear() === today.getFullYear();
};

const thisYear = (date: Date): boolean => {
  const today = new Date();
  return ensureDate(date).getFullYear() === today.getFullYear();
};

const monthRange = (date: Date, customMonth: Date): boolean => {
  return ensureDate(date).getMonth() === ensureDate(customMonth).getMonth() &&
  ensureDate(date).getFullYear() === ensureDate(customMonth).getFullYear();
};

const yearRange = (date: Date, customYear: Date): boolean => {
  return ensureDate(date).getFullYear() === ensureDate(customYear).getFullYear();
};

const ensureDate = (date: any): Date => {
  return new Date(date);
};

export class DateFilter implements Filter<Scene>{
  name = 'Date';
  items = filterItems;
}

export default DateFilter;
