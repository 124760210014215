import withModalContext, { WithModalContext } from '../withModalContext';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Dialog, makeStyles,
} from '@material-ui/core';

import WallScaleForm from './WallScaleForm';

interface Props extends WithModalContext {
  handleWallHeight: Function;
  wallHeight: number;
  hideWalls: boolean;
}


const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: theme.shape.borderRadius,
      border: `solid 2px ${theme.palette.primary.main}`,
    },
  })
);

const FloorPlanWallScaleModal = (modalProps: Props) => {
  const classes = styles(modalProps);
  return(
    <Dialog
      classes={{
        paper: classes.modal,
      }}
      open={true}
      aria-labelledby="place-modal"
      fullWidth={true}
      scroll="paper">
      <WallScaleForm
        handleWallHeight={modalProps.handleWallHeight}
        hideWalls={modalProps.hideWalls}
        wallHeight={modalProps.wallHeight}
        modalContext={modalProps.modalContext} />
    </Dialog>
  );
};

export default withModalContext(FloorPlanWallScaleModal);
