import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const AssetGroupIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg
      width="18pt" height="18pt" viewBox="0 0 92.000000 92.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,92.000000) scale(0.100000,-0.100000)"
       stroke="none">
      <path d="M63 900 c-43 -26 -63 -68 -63 -135 l0 -55 45 0 44 0 3 58 3 57 58 3
      57 3 0 44 0 45 -57 0 c-40 0 -68 -6 -90 -20z"/>
      <path d="M710 875 l0 -44 58 -3 57 -3 3 -57 3 -58 44 0 45 0 0 55 c0 104 -50
      155 -152 155 l-58 0 0 -45z"/>
      <path d="M210 605 l0 -105 105 0 106 0 -3 103 -3 102 -102 3 -103 3 0 -106z"/>
      <path d="M529 693 c-17 -19 -26 -389 -11 -444 l8 -34 97 -3 c97 -3 97 -3 107
      23 14 38 13 418 -2 450 -11 24 -15 25 -98 25 -69 0 -90 -3 -101 -17z"/>
      <path d="M274 426 c-75 -33 -98 -119 -46 -176 76 -86 219 -18 198 93 -7 36
      -54 87 -81 87 -7 0 -19 2 -27 5 -7 2 -27 -2 -44 -9z"/>
      <path d="M0 153 c0 -103 51 -153 155 -153 l55 0 0 45 0 44 -57 3 -58 3 -3 58
      -3 57 -44 0 -45 0 0 -57z"/>
      <path d="M828 153 l-3 -58 -57 -3 -58 -3 0 -44 0 -45 58 0 c102 0 152 51 152
      155 l0 55 -45 0 -44 0 -3 -57z"/>
      </g>
    </svg>
  </SvgIcon>
);

export default AssetGroupIcon;
