import { useDispatch } from 'react-redux';
import { canEditLayout } from '../../../../reducers/globalState';

import {
  Theme,
  createStyles,
  IconButton,
  Tooltip,
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import {
  Cancel,
  Redo,
  Save as SaveIcon,
  Undo,
} from '@material-ui/icons';

import { Save, Reset } from '../../../../reducers/blue';
import { UndoHistory, RedoHistory } from '../../../../reducers/undoRedo';
import { LayoutViewState, FixtureViewState, GlobalViewState } from '../../../../models/GlobalState';
import { ReduxState } from '../../../../reducers';

import { useSelector } from 'react-redux';
import { UniversalModalWrapper } from '../../../Modals/UniversalModalWrapper';

interface Props {
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      margin: theme.spacing(),
      display: 'flex',
    },
    iconButton: {
      padding: theme.spacing(),
      '&:hover': {
        color: `${theme.palette.common.white} !important`,
      },
    },
    buttonContainer: {
      margin: `0px ${theme.spacing() / 2}px`,
      border: `2px solid ${theme.palette.common.black}`,
      borderRadius: 4,
      '&:hover': {
        background: theme.palette.secondary.main,
      },
    },
    progress: {
      margin: '8px',
    }
  })
);

const UndoRedo = (props: Props) => {

  const layoutViewState: LayoutViewState = useSelector((state: ReduxState) => state.globalstate.layoutViewState);
  const fixtureViewState: FixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);
  const globalViewState: GlobalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const floorPlanMode = useSelector((state: ReduxState) => state.floorPlan.mode);
  const future: any[] = useSelector((state: ReduxState) => state.undoRedo.future);
  const past: any[] = useSelector((state: ReduxState) => state.undoRedo.past);
  const blueReady: boolean = useSelector((state: ReduxState) => state.blue.blueInitialized);

  const allowUpdateLayout: boolean = useSelector((state: ReduxState) => canEditLayout(state));
  const needSave: boolean = useSelector((state: ReduxState) => state.blue.needSave);
  const saving: boolean = useSelector((state: ReduxState) => state.blue.saving);

  const dispatch = useDispatch();

  const classes = styles(props);

  const validLayoutViewState = [
    LayoutViewState.TwoDView,
    LayoutViewState.ThreeDView,
    LayoutViewState.LabelView,
    LayoutViewState.NumberView,
    LayoutViewState.ShapeView,
  ].includes(layoutViewState) &&
    globalViewState === GlobalViewState.Layout

  const validFixtureViewState = [
    FixtureViewState.Floorplan,
    FixtureViewState.TwoDView,
    FixtureViewState.ThreeDView,
    FixtureViewState.ShapeView,
  ].includes(fixtureViewState) &&
  globalViewState === GlobalViewState.Fixtures

  const undoRedoValidLayoutState = [
    LayoutViewState.TwoDView,
    LayoutViewState.ThreeDView,
  ].includes(layoutViewState) &&
  globalViewState === GlobalViewState.Layout

  const save = (e) => {
    dispatch(Save());
  }

  return (
    <span className={classes.root}>
      {(validLayoutViewState || validFixtureViewState) && allowUpdateLayout && blueReady &&
        <>
          { (undoRedoValidLayoutState || validFixtureViewState) &&
            <>
          { (past.length !== 0 || future.length !== 0) &&
          <Tooltip title="Undo">
            <Paper className={classes.buttonContainer}>
              <IconButton
                onClick={() => {
                  dispatch(UndoHistory());
                }}
                disabled={past.length === 0}
                aria-label="Undo"
                className={classes.iconButton}>
                <Undo fontSize="medium" />
              </IconButton>
            </Paper>
          </Tooltip>
          }
          { (past.length !== 0 || future.length !== 0) &&
          <Tooltip title="Redo">
            <Paper className={classes.buttonContainer}>
              <IconButton
                onClick={() => {
                  dispatch(RedoHistory());
                }}
                disabled={future.length === 0}
                aria-label="Redo"
                className={classes.iconButton}>
                <Redo fontSize="medium" />
              </IconButton>
            </Paper>
          </Tooltip>
          }
          </>
          }
          <Tooltip title={saving ? 'Saving' : needSave ? 'Save' : 'Saved'}>
            <Paper className={classes.buttonContainer}>
              { saving &&
                <CircularProgress variant="indeterminate" color="primary" size={24} className={classes.progress}/>
              }
              { !saving &&
                <IconButton
                disabled={!needSave}
                onClick={save}
                aria-label="Save"
                onKeyDown={e => { e.preventDefault(); }}
                className={classes.iconButton}>
                <SaveIcon fontSize="medium" />
              </IconButton>
              }
            </Paper>
          </Tooltip>
          <Tooltip title="Reset">
            <Paper className={classes.buttonContainer}>
              <UniversalModalWrapper
                onDelete= {() => {
                  (window as any).gtag('event', 'cancel-reset', {
                    'globalState': globalViewState,
                    'viewState': globalViewState === GlobalViewState.Layout ? layoutViewState : fixtureViewState,
                    'floorplanMode': globalViewState === GlobalViewState.Fixtures ? floorPlanMode : undefined,
                  });
                  dispatch(Reset());
                }}
                modalHeader='Are you sure?'
              >
                <IconButton
                  aria-label="Cancel"
                  onKeyDown={e => { e.preventDefault(); }}
                  className={classes.iconButton}
                >
                  <Cancel fontSize="medium"/>
                </IconButton>
                {'This will clear all changes since the beginning of your session. \n Are you Sure you want to Delete Changes?'}
              </UniversalModalWrapper>
            </Paper>
          </Tooltip>
        </>
      }
    </span>
  );
}

export default UndoRedo;
