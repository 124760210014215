import { ReactElement } from 'react';
import {
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
} from '@material-ui/core';
import {
  WithStyles,
} from '@material-ui/core/styles';
import ButtonController from './ButtonController';
import sideBarStyles from '../sideBarStyles.css';
import { makeStyles } from '@material-ui/styles'

interface Props {
  children?: ReactElement<any>;
  buttonController: ButtonController;
  disabled?: boolean;
}

const SideBarNavLink = (props: Props) => {
  const handleClick = e => {
    const { buttonController } = props;
    if (buttonController.hidden) {
      e.preventDefault();
    } else {
      buttonController.onClick();
    }
  }
  const styles = makeStyles<Theme>(sideBarStyles);
  const classes = styles(props);

  const { buttonController, disabled } = props;

  return (
    !buttonController.hidden &&
      <Grow in={true}>
        <div className={classes.root}>
          <ListItem
            disabled={buttonController.disabled || disabled || false}
            button
            selected={buttonController.selected}
            className={classes.listItem}
            onClick={handleClick}
            classes={{ selected: classes.activeLink }}
            >
            <Tooltip title={buttonController.label}>
              <ListItemIcon
                style={{ background: 'transparent', fontSize: '35px' }}
                classes={{ root: classes.icon }}>
                {props.children}
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              style={{ background: 'transparent' }}
              classes={{ primary: classes.label }}
              primary={buttonController.label} />
          </ListItem>
        </div>
      </Grow>
  );
}

export default SideBarNavLink;
