import { RouteSection } from './Route';

// Icons
import {
  AssetLibraryIcon
} from '../assets/icons';

// Views
import AssetsPreview from '../views/Assets/AssetsPreview';

const baseUrl = '/assets';
export const assets: RouteSection = {
  main: {
    path: `${baseUrl}`,
    name: 'Model Libraries',
    icon: AssetLibraryIcon,
    component: AssetsPreview,
  },
};

export default assets;
