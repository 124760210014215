enum SceneStatus {
  Proposed = 0,
  Pending = 1,
  Complete = 2,
}

export const sceneStatuses: { value: SceneStatus, label: string }[] = [
  { value: SceneStatus.Proposed, label: 'Proposed' },
  { value: SceneStatus.Pending, label: 'Pending' },
  { value: SceneStatus.Complete, label: 'Complete' },
];

export default SceneStatus;
