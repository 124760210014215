import { createUserManager } from 'redux-oidc';

const userManagerConfig = {
  authority: window.env['REACT_APP_PORTAL'],
  client_id: window.env['REACT_APP_CLIENT_ID'],
  redirect_uri: window.env['REACT_APP_LOGIN_REDIRECT'],
  response_type: 'id_token token',
  scope: window.env['REACT_APP_SCOPE'],
  post_logout_redirect_uri: window.env['REACT_APP_LOGOUT_REDIRECT'],
  automaticSilentRenew: true,
  silent_redirect_uri: `${window.location.origin}/silent-refresh.html`,
  silentRequestTimeout: 50000,
  clockSkew: 900, // 15 min
};

export const createUserManagerWithGuestToken = (guestTokenId?: string) => {
  return createUserManager({
    ...userManagerConfig,
    extraQueryParams: { guest_token: guestTokenId ? guestTokenId : '' },
  });
};

export default createUserManagerWithGuestToken();
