import { GlobalViewState, FixtureViewState, LayoutViewState } from './GlobalState';

export enum CameraLayers {
  Default = 0,
  TitleLabel = 1,
  NumberLabel = 2,
  Fixtures = 3,
  Walls = 4,
  BasePlanes = 5,
  Floorplanes = 6,
  Items = 7,
  FloorplaneImage = 8,
  Photospheres = 9,
  Mask = 10,
  PhotosphereCameras = 11,
  WallFixtures = 12,
  WallItems = 13,
  LayoutLabels = 14,
  Measurments = 15,
  AttendeeLabel = 16,
  Linen = 17,
  TableNumberLabel = 18,
  Grid = 19,
  ChairNumberLabel = 20,
  ArchitectureElement = 21,
}

export const labelLayers = [
  CameraLayers.TitleLabel,
  CameraLayers.NumberLabel,
  CameraLayers.LayoutLabels,
  CameraLayers.Measurments,
  CameraLayers.TableNumberLabel,
  CameraLayers.ChairNumberLabel,
];

export enum ControllerType {
  None = 0,
  Main = 1,
  Label = 2,
  Number = 3,
  Attendee = 4,
  Batch = 5,
  Floorplan = 6,
  Drawing = 7,
}

export enum ControllerMode {
  MOVE,
  CREATE,
  DELETE,
}

export enum DrawingMode {
  NONE,
  DIMENSION,
}


export const InitialUserState = {
  [GlobalViewState.Layout] : {
    [LayoutViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
      ],
    },
    [LayoutViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.WallItems,
        CameraLayers.Fixtures,
        CameraLayers.WallFixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Photospheres,
      ],
    },
    [LayoutViewState.AttendeeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Floorplanes,
        CameraLayers.TableNumberLabel,
        CameraLayers.BasePlanes,
        CameraLayers.AttendeeLabel,
        CameraLayers.Fixtures,
      ],
    },
    [LayoutViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.LayoutLabels,
      ],
    },
    [LayoutViewState.NumberView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.TableNumberLabel,
      ],
    },
    [LayoutViewState.StreetView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.WallItems,
        CameraLayers.Fixtures,
        CameraLayers.WallFixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
      ],
    },
  },
  [GlobalViewState.Fixtures] : {
    [FixtureViewState.Floorplan] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.FloorplaneImage,
        CameraLayers.Grid,
      ],
    },
    [FixtureViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Mask,
      ],
    },
    [FixtureViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.WallItems,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Walls,
        CameraLayers.Mask,
      ],
    },
    [FixtureViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.BasePlanes,
        CameraLayers.Mask,
        CameraLayers.Photospheres,
      ],
    },
    [FixtureViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.LayoutLabels,
      ],
    },
    [FixtureViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Measurments,
      ],
    },
  },
};

export const RequiredLayers = {
  [GlobalViewState.Layout] : {
    [LayoutViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
      ],
    },
    [LayoutViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
      ],
    },
    [LayoutViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Photospheres,
      ],
    },
    [LayoutViewState.AttendeeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.AttendeeLabel,
        CameraLayers.Fixtures,
      ],
    },
    [LayoutViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.LayoutLabels,
      ],
    },
    [LayoutViewState.NumberView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.TableNumberLabel,
      ],
    },
    [LayoutViewState.StreetView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
      ],
    },
    [LayoutViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Fixtures,
        CameraLayers.Measurments,
      ],
    },
  },
  [GlobalViewState.Fixtures] : {
    [FixtureViewState.Floorplan] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Floorplanes,
      ],
    },
    [FixtureViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
      ],
    },
    [FixtureViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
      ],
    },
    [FixtureViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.BasePlanes,
        CameraLayers.Photospheres,
      ],
    },
    [FixtureViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.LayoutLabels,
      ],
    },
    [FixtureViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Default,
        CameraLayers.Items,
        CameraLayers.Linen,
        CameraLayers.Measurments,
      ],
    },
  },
};
export const ProhibitedLayers = {
  [GlobalViewState.Layout] : {
    [LayoutViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
      ],
    },
    [LayoutViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Floorplanes,
        CameraLayers.BasePlanes,
        CameraLayers.Grid,
        CameraLayers.FloorplaneImage,
        CameraLayers.Walls,
        CameraLayers.WallItems,
        CameraLayers.WallFixtures,
      ],
    },
    [LayoutViewState.AttendeeView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.NumberView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [LayoutViewState.StreetView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
      ],
    },
    [LayoutViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
  },
  [GlobalViewState.Fixtures] : {
    [FixtureViewState.Floorplan] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Mask,
        CameraLayers.Items,
        CameraLayers.Walls,
        CameraLayers.WallItems,
        CameraLayers.WallFixtures,
      ],
    },
    [FixtureViewState.TwoDView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [FixtureViewState.ThreeDView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
      ],
    },
    [FixtureViewState.PhotosphereView] : {
      cameraLayers : [
        CameraLayers.Walls,
        CameraLayers.WallFixtures,
        CameraLayers.WallItems,
      ],
    },
    [FixtureViewState.LabelView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
    [FixtureViewState.ShapeView] : {
      cameraLayers : [
        CameraLayers.Photospheres,
        CameraLayers.Walls,
      ],
    },
  },
};

