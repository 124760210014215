import { PlacezMaterial } from '../../api/placez/models/PlacezMaterial';
import { Edit as EditIcon } from '@material-ui/icons';
import { useSelector } from 'react-redux';

import { GridListTile, GridList, GridListTileBar } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReduxState } from '../../reducers';

type Props = {};

const ShowMaterials = (props: Props) => {
  const materials = useSelector((state: ReduxState) => state.material.materials);

  const host = window.env['REACT_APP_DAM'];

  const listMaterials = (materials: PlacezMaterial[]) => {
    return materials.map((material: PlacezMaterial) => {
      return (
        <GridListTile
          key={material.id}
        >
          <img src={`${host}${material.preview}`} alt='' />
          <GridListTileBar
            title={material.name}
            // subtitle={<span>by: {tile.author}</span>}
            actionIcon={
              <Link to={`/media/material/${material.id}`}>
                <EditIcon style={{ color: 'purple', marginRight: '15px' }} />
              </Link>
            }
          />
        </GridListTile>
      );
    });
  }

  return (
    <div style={{margin: '100px'}}>
      <GridList
        cols={5}
      >
        {listMaterials(materials)}
      </GridList>
    </div>
  );
}

export default ShowMaterials;
