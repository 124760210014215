import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

import ClientForm from '../../components/ClientForm';

interface Props {}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      height: '100%',
      width: '100%',
      overflow: 'none',
      overflowY: 'auto',
    },
    title: {
      ...theme.typography.h5,
      fontSize: 18,
      fontWeight: 'bold',
    },
  })
);

const NewClient = (props: Props) => {
  const classes = styles(props);
  return (
    <div className={classes.root}>
      <ClientForm />
    </div>
  );
}

export default NewClient;
