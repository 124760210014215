import { SortableContainer, arrayMove } from 'react-sortable-hoc';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

import SortableItem from './SortableItem';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    test: {
      border: '1px solid #AFA0B3',
      borderRadius: 4,
    },
  })
);

const SortableList = SortableContainer(({ items, edited, deleted, classes, disabled }) => {
  return (
    <div className={classes.test}>
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value}
          edited={edited}
          deleted={deleted}
          disabled={disabled} />
      ))}
    </div>
  );
});

interface Props {
  updateOptions: Function;
  updateOptionName: Function;
  items: any[];
  disabled?: boolean;
}

const SortableComponent = (props: Props) => {
  const classes = styles(props);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    props.updateOptions(arrayMove(props.items, oldIndex, newIndex));
  }

  const edited = newItem => {
    props.updateOptionName(newItem);
  }

  const deleted = id => {
    const newItems = props.items.filter(item => item.id !== id);
    props.updateOptions(newItems);
  }

  return (
    <SortableList
      classes={classes}
      items={props.items}
      edited={edited}
      onSortEnd={onSortEnd}
      lockAxis="y"
      useDragHandle={true}
      deleted={deleted}
      disabled={props.disabled}
    />);
}

export default SortableComponent;
