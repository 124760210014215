import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Edit as EditIcon, FilterList as FilterIcon } from '@material-ui/icons';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { IconButton, makeStyles } from '@material-ui/core';
import Jumbotron from '../../components/Jumbotron';
import { tableStyles } from '../../components/Tables/tableSyles.css';

interface Props {}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...tableStyles(theme),
    root: {

    },
    form: {
      padding: theme.spacing(4),
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      margin: 0,
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    filterButtonContainer: {
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      background: theme.palette.grey[300],
      justifyContent: 'space-between',
    },
  })
);

interface SubCatItem {
  catalogId: string;
  catalogName: string;
  PrimaryName: string;
  SubCategoryName: string;
}

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading...</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const Catalog = (props: Props) => {
  const classes = styles(props);

  const [event, setEvent] = useState<any>(null);
  const [subcatagoryList, setSubcatagoryList] = useState<SubCatItem[]>([]);
  const [sort, setSort] = useState<any>([]);
  const [filter, setFilter] = useState<any>(undefined);
  const [isFilterable, setIsFilterable] = useState<boolean>(true);

  const catalogs = useSelector((state: ReduxState) => state.asset.allCatalogs);

  const handleSortChange = event => {
    setSort(event.sort);
  }

  const handleFilterChange = event => {
    setFilter(event.filter)
  }

  const onFilterClick = () => {
    setIsFilterable(!isFilterable);
  }

  const onDelete = value => {
    console.log('not implement');
  }

  const setupSku = value => {
    return `{ "sku": "${value}","sortOrder": 99},`;
  }

  useEffect(() => {
    init();
  }, [catalogs])

  useEffect(() => {
    init();
  }, [])


  const init = () => {
    const subcatagoryList = [] as SubCatItem[];
    catalogs.forEach(catalog => {
      catalog.categories.forEach(primaryCat => {
        primaryCat.subCategories.forEach(subCat => {
          subcatagoryList.push({
            catalogId: catalog.id,
            catalogName: catalog.name,
            PrimaryName: primaryCat.name,
            SubCategoryName: subCat.name });
        });
      });
    });
    setSubcatagoryList(subcatagoryList);
  }

  if (subcatagoryList.length === 0) {
    return loadingPanel;
  }

  return (
    <div className={classes.root}>
      <Jumbotron
        title="Catalog">
        <IconButton
          color="primary"
          aria-label="Toggle Filter"
          onClick={onFilterClick}>
          <FilterIcon fontSize="large"/>
        </IconButton>
      </Jumbotron>
      <div className={classes.form}>
        <Grid
          className={classes.gridRoot}
          data={process(subcatagoryList, {})}
          filterable={true && isFilterable}
          filter={filter}
          onFilterChange={handleFilterChange}
          sortable={true}
          sort={sort}
          onSortChange={handleSortChange}>
          <Column
            field="catalogName"
            title="Catalog Name" />
          <Column
            field="PrimaryName"
            title="Primary Name" />
          <Column
            field="SubCategoryName"
            title="SubCategory Name" />
          <Column
            field="edit"
            title="Edit"
            className={classes.LinkStyle}
            width="100px"
            cell={EditDetailsCell}
            sortable={false}
            filterable={false} />
          {/*
        skus.filter(asset =>
          asset.vendor === "Ashley Furniture")
        .map((asset: any) =>
        <div>
          {this.setupSku(asset.id)}
        </div>)
        */}
        </Grid>
      </div>
    </div>
  );
}

class EditDetailsCell extends GridCell {
  render() {
    const { catalogId, PrimaryName, SubCategoryName } = this.props.dataItem;
    return (
      <td>
        <Link to={`/catalog/edit/${catalogId}/${PrimaryName}/${SubCategoryName}/`}>
          <EditIcon color="primary"/>
        </Link>
      </td>
    );
  }
}

export default Catalog;
