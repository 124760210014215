import { createRef, useEffect, useState } from 'react';
import { PhotospherePreview } from '../../../../blue/PhotospherePreview';

import {
  Theme,
  createStyles,
  }
from '@material-ui/core/styles';
import { Photosphere } from '../../models/Photosphere';
import { damApi } from '../../../../api';
import { CircularProgress, makeStyles } from '@material-ui/core';

interface Props {
  photosphere: Photosphere;
  updatePhotosphere: (sphere: Photosphere) => void;
};

let photosphereRef

const PhotosphereViewer = (props: Props) => {
  const myRef = createRef<HTMLDivElement>();

  const [direction, setDirection] = useState<string>(JSON.stringify(props.photosphere.direction));
  const [spinnerHidden, setSpinnerHidden] = useState<boolean>(false);

  useEffect(() => {
    photosphereRef = new PhotospherePreview(
      myRef.current,
      props.photosphere);
    setDirection(JSON.stringify(props.photosphere.direction));
    setTimeout(() => {
      setSpinnerHidden(true)
    // tslint:disable-next-line:align
    }, 5000);
    return () => photosphereRef.dispose()
  }, [])


  useEffect(() => {
    if (props.photosphere.direction !== undefined) {
      setDirection(JSON.stringify(props.photosphere.direction));
      photosphereRef.pointCamera(props.photosphere.direction, props.photosphere.transformation);
      savePhoto(props.photosphere);
    }
  }, [props.photosphere.direction])

  const savePhoto = (sphere: Photosphere) => {
    photosphereRef.screenCapture().then(blob => {
      handleFileSubmit(blob, sphere);
    });
  }

  const handleFileSubmit = (blob: Blob, newPhotosphere: Photosphere) => {
    const formData = new FormData();
    formData.append('file', blob, `${newPhotosphere.name}.png`);

    damApi.postBlob(formData).then(data => {
      console.warn('Save Photosphere Thumb');
      newPhotosphere.thumbnailPath = `/${data.parsedBody.path}`;
      props.updatePhotosphere(newPhotosphere);
    });
  }

  const classes = styles(props);
  return (
    <div className={classes.root} ref={myRef} >
      { !spinnerHidden &&
        <CircularProgress className={classes.spinner} />
      }
    </div>
  );
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    spinner: {
      position: 'absolute',
      top: '50px',
      left: '45%',
    },
  })
)

export default PhotosphereViewer;
