import {
  Theme,
  createStyles,
  Typography,
  makeStyles,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
} from "@material-ui/core";
import { Settings, Delete, ExpandMore } from "@material-ui/icons";
import { Asset, SkuType } from "../../../../../blue/items/asset";
import { Sku } from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCustomAsset } from "../../../../../reducers/asset";
import { ReduxState } from "../../../../../reducers";
import TableConfigModal from "../../../../Modals/TableConfigModal";
import { useEffect, useMemo, useState } from "react";
import {
  PlacezAccordion,
  PlacezAccordionDetails,
  PlacezAccordionSummary,
} from "../../../../PlacezUIComponents/PlacezAccordion";
import { format } from "mathjs";
import SkuItem from "./SkuItem"

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: "100%",
      minWidth: "0px",
      gridTemplateColumns: "1fr",
      rowGap: "2px",
    },
    settings: {
      position: "absolute",
      cursor: "pointer",
      right: 0,
      top: 0,
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      margin: "3px",
    },
    delete: {
      position: "absolute",
      cursor: "pointer",
      right: 0,
      bottom: 0,
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      margin: "3px",
    },
    itemList: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: "100%",
      gap: "2px",
      padding: "2px",
    },
    item: {
      cursor: "grab",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      overflow: "hidden",
      minHeight: "116px",
      width: "100%",
    },
    selectedImageContainer: {
      border: `solid 3px ${theme.palette.primary.main}`,
      display: "flex",
      position: "relative",
      width: "112px",
      height: "112px",
      maxWidth: "112px",
      maxHeight: "112px",
      borderRadius: "8px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",
    },
    imageContainer: {
      // border: 'solid 2px transparent',
      display: "flex",
      position: "relative",
      minWidth: "100%",
      widht: "100%",
      minHeight: "112px",
      maxHeight: "112px",
      borderRadius: "8px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      "&:hover": {
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
      },
    },
    itemText: {
      maxWidth: "112px",
      marginTop: 4,
      fontWeight: "bold",
      fontSize: 12,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      top: "35px",
      left: "35px",
    },
  }),
);

interface Props {
  skus: Sku[];
  includeCustomSkus?: boolean;
  onSelect?: (asset) => void;
  onDragAsset?: (event: any, dragType: string, asset: Asset) => void;
  canDelete?: boolean;
  deleteCustomAsset?: (id: string) => {};
  cols?: number;
  categories?: boolean;
}

const ItemList = (props: Props) => {
  const classes = styles(props);
  const theme: Theme = useTheme();

  const dispatch = useDispatch();

  const [selectedAsset, setSelectedAsset] = useState<Asset>(null);
  const [tableConfigModalOpen, setTableConfigModalOpen] =
    useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<string>("");

  const configuredAssets = useSelector(
    (state: ReduxState) => state.blue.configuredAssets,
  );
  const assetBySku = useSelector((state: ReduxState) => state.asset.bySku);
  const customSkus = useSelector((state: ReduxState) => state.asset.customSkus);

  useEffect(() => {
    if (selectedAsset) {
      setShowSpinner("");
      props?.onSelect(selectedAsset);
    }
  }, [configuredAssets, selectedAsset]);

  const { onDragAsset, skus, canDelete } = props;

  const onSelect = (asset: Asset) => {
    setSelectedAsset(
      configuredAssets[asset.id]
        ? { ...configuredAssets[asset.id] }
        : { ...asset },
    );
  };

  const onTableConfigured = (configuredItem: Asset) => {
    setSelectedAsset(configuredItem);
    setTableConfigModalOpen(false);
    setShowSpinner(configuredItem.id);
  };

  type Sku = {
    asset: Asset;
    sku: string;
    sortOrder: number;
  };

  const [formattedListItems, setFormattedListItems] = useState({});

  const listItems = () => {
    const tagItems: { [key: string]: any[] } = {};

    if (!props.categories) {
      tagItems["noCategories"] = [];
    }

    // First map: Assign asset and filter custom SKUs
    const assignedAndFiltered = skus
      .sort((a, b) => a.sortOrder - b.sortOrder)
      .map((catalogSku: Sku) => {
        if (!("asset" in catalogSku)) {
          (catalogSku as Sku).asset = assetBySku[(catalogSku as Sku).sku];
        }

        let filteredCustomSkus = [];
        if (props.includeCustomSkus) {
          filteredCustomSkus = customSkus.filter((customSku) => {
            return (
              customSku.asset.extensionProperties?.progenitorId ===
              catalogSku.sku
            );
          });
        }

        return {
          catalogSku,
          filteredCustomSkus,
        };
      });
    assignedAndFiltered.forEach(({ catalogSku, filteredCustomSkus }) => {
      if (props.categories && catalogSku?.asset?.skuType) {
        const species = catalogSku.asset.skuType;
        if (!tagItems[species]) {
          tagItems[species] = [];
        }
        tagItems[species].push({ catalogSku, filteredCustomSkus });
      } else {
        if (!tagItems["noCategories"]) {
          tagItems["noCategories"] = [];
        }
        tagItems["noCategories"].push({ catalogSku, filteredCustomSkus });
      }
    });

    // Second map: Return the appropriate items
    Object.keys(tagItems).forEach((tag) => {
      tagItems[tag] = tagItems[tag].flatMap(
        ({ catalogSku, filteredCustomSkus }) => {
          if (filteredCustomSkus.length > 0) {
            return [
              ...filteredCustomSkus.map((customSku) =>
              <SkuItem
                catalogSku={customSku}
                onDragAsset={onDragAsset}
                selectedAsset={selectedAsset}
                onAssetSelect={onSelect}
                configuredAssets={configuredAssets}
                setTableConfigModalOpen={setTableConfigModalOpen}
                canDelete
              />
              ),
              <SkuItem
                catalogSku={catalogSku}
                onDragAsset={onDragAsset}
                selectedAsset={selectedAsset}
                onAssetSelect={onSelect}
                configuredAssets={configuredAssets}
                setTableConfigModalOpen={setTableConfigModalOpen}
                canDelete
              />
            ];
          } else {
            return [
              <SkuItem
                catalogSku={catalogSku}
                onDragAsset={onDragAsset}
                selectedAsset={selectedAsset}
                onAssetSelect={onSelect}
                configuredAssets={configuredAssets}
                setTableConfigModalOpen={setTableConfigModalOpen}
                canDelete
              />
            ];
          }
        },
      );
    });

    return tagItems;
  };

  useEffect(() => {
    setFormattedListItems(listItems());
  }, [skus, assetBySku, customSkus, selectedAsset]);


//   const formattedListItems = useMemo(() => {
//   return listItems();
// }, [skus, assetBySku, customSkus]);

  return (
    <>
      <TableConfigModal
        asset={selectedAsset}
        tableConfigModalOpen={tableConfigModalOpen}
        onTableConfigured={onTableConfigured}
        onConfigureCancel={() => setTableConfigModalOpen(false)}
        configuredItems={configuredAssets}
      />
      {props.categories &&
        <div className={classes.root}>
          {Object.keys(formattedListItems)?.map((key, index) => (
              <PlacezAccordion>
                <PlacezAccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    {SkuType[key]}
                  </Typography>
                </PlacezAccordionSummary>
                <PlacezAccordionDetails className={classes.itemList}>
                  {formattedListItems[key]}
                </PlacezAccordionDetails>
              </PlacezAccordion>
            ))}
        </div>
      }
      <div className={classes.itemList}>
        {!props.categories && formattedListItems["noCategories"]}
      </div>
    </>
  );
};

export default ItemList;
