import { useState } from 'react';

import {
  Theme,
  Tooltip,
  Paper,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
  Label,
  Info,
  ThreeDRotation,
  DoneAll,
  Image,
  Visibility,
  Camera,
  FormatAlignJustify,
  SquareFoot,
  LooksOne,
  GridOn,
  EventSeat,
  AccountBalance,
} from '@material-ui/icons';

import classnames from 'classnames';
import { ControlsType, CameraType } from '../../../models';
import { useDispatch, useSelector } from 'react-redux';
import { SetMultiSelect, SetAutoRotate, AutoRotatePhotosphereCamera, SetControllerMode, ChangeCopiedAssetsState, SetActiveController, SetDrawingMode } from '../../../../../reducers/blue';
import { ReduxState } from '../../../../../reducers';
import { CameraLayers, ControllerMode, ControllerType, DrawingMode, ProhibitedLayers } from '../../../../../models/BlueState';
import { PlannerTool, GlobalViewState, LayoutViewState, FixtureViewState } from '../../../../../models/GlobalState';
import { AddMeasurmentIcon, RemoveMeasurmentIcon, MaskItemIcon, NotesIcon, ClearClipboardIcon } from '../../../../../assets/icons';
import { UpdateUserSetting } from '../../../../../reducers/settings';
import { UserSetting } from '../../../../../api';
import viewOptionsStyles from './ViewOptions.css'
import LockToggle from './LockToggle'
import SectionViewToggle from './SectionViewToggle'
import CameraLayerToggle from './CameraLayerToggle'
import DimensionCutoffToggle from './DimensionCutoffToggle'
import { LocalStorageKey } from '../../../../Hooks/useLocalStorageState'
import { LicenseType, canEditLayout } from '../../../../../reducers/globalState'

type Props = {};

const ViewOptions = (props: Props) => {
  const styles = makeStyles<Theme>(viewOptionsStyles);
  const classes = styles(props);

  const [layer, setLayer] = useState<CameraLayers>(undefined);
  const [showViewsToolbar, setShowViewsToolbar] = useState<boolean>(false);

  const autoRotate = useSelector((state: ReduxState) => state.blue.autoRotate);
  const controlsType = useSelector((state: ReduxState) => state.blue.controlsType);
  const cameraType = useSelector((state: ReduxState) => state.blue.cameraType);
  const cameraLayers = useSelector((state: ReduxState) => state.blue.cameraLayers);
  const multiSelect = useSelector((state: ReduxState) => state.blue.multiSelect);
  const plannerTool = useSelector((state: ReduxState) => state.globalstate.plannerTool);
  const autoRotatePhotosphereCamera = useSelector((state: ReduxState) => state.blue.autoRotatePhotosphereCamera);
  const layoutViewState = useSelector((state: ReduxState) => state.globalstate.layoutViewState);
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);
  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const currentFloorPlan = useSelector((state: ReduxState) => state.designer.floorPlan);
  const license = useSelector((state: ReduxState) => state.globalstate.licenseState);
  const controllerMode = useSelector((state: ReduxState) => state.blue.controllerMode);
  const activeController = useSelector((state: ReduxState) => state.blue.activeController);
  const copiedAssets = useSelector((state: ReduxState) => state.blue.copiedAssets);

  const layoutEditable = useSelector((state: ReduxState) => canEditLayout(state));


  const dispatch = useDispatch();

  const rotateCamera = () => {
    dispatch(AutoRotatePhotosphereCamera(!autoRotatePhotosphereCamera));
  }

  const toggleMultiSelect = () => {
    dispatch(SetMultiSelect(!multiSelect));
  }

  const toggleViewsToolbar = () => {
    setShowViewsToolbar(!showViewsToolbar);
  }


  const floorPlanMode = globalViewState === GlobalViewState.Fixtures &&
    fixtureViewState === FixtureViewState.Floorplan

  const prohibitedLayers = ProhibitedLayers[globalViewState][globalViewState === GlobalViewState.Fixtures ? fixtureViewState : layoutViewState].cameraLayers;

  return (
    <>
    { showViewsToolbar &&
    <div className={classnames(classes.popup)}>
      <LockToggle/>
      <SectionViewToggle/>
      <Paper className={classnames(classes.border)}>
            <ToggleButtonGroup
              className={classnames(classes.viewToggles)}
              value={layer}
              exclusive
              aria-label="Toggle Labels"
              size="small"
            >
          { fixtureViewState !== FixtureViewState.PhotosphereView && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.TitleLabel]}
              excludeLayers={[CameraLayers.NumberLabel]}
              label={LocalStorageKey.ItemLabel}
              scale
            >
              <Label/>
            </CameraLayerToggle>
          }

          { fixtureViewState !== FixtureViewState.PhotosphereView && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.NumberLabel]}
              excludeLayers={[CameraLayers.TitleLabel]}
              label={LocalStorageKey.NumberLabel}
              scale
            >
              <Info/>
            </CameraLayerToggle>
          }
          { fixtureViewState === FixtureViewState.Floorplan && globalViewState === GlobalViewState.Fixtures &&
            <DimensionCutoffToggle
              min={0}
              step={5}
              max={1525}
            />
          }
          { globalViewState !== GlobalViewState.Fixtures && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.TableNumberLabel]}
              label={LocalStorageKey.TableNumber}
              scale
            >
              <LooksOne/>
            </CameraLayerToggle>
          }
          { fixtureViewState !== FixtureViewState.PhotosphereView && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.ChairNumberLabel]}
              label={LocalStorageKey.ChairNumber}
              scale
            >
              <EventSeat/>
            </CameraLayerToggle>
          }
          {!floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.LayoutLabels]}
              label={LocalStorageKey.Notes}
              scale
              disabled={(fixtureViewState === FixtureViewState.LabelView) || (layoutViewState === LayoutViewState.LabelView)}
            >
              <NotesIcon/>
            </CameraLayerToggle>
          }
          { !floorPlanMode && layoutEditable &&
            <CameraLayerToggle
              layers={[CameraLayers.Measurments]}
              label={LocalStorageKey.Dimensions}
              scale
              disabled={(fixtureViewState === FixtureViewState.ShapeView) || (layoutViewState === LayoutViewState.ShapeView)}
            >
              <SquareFoot/>
            </CameraLayerToggle>
          }
          { !prohibitedLayers.includes(CameraLayers.Grid) &&
            <CameraLayerToggle
              layers={[CameraLayers.Grid]}
              label={LocalStorageKey.Grid}
            >
              <GridOn/>
            </CameraLayerToggle>
          }
        </ToggleButtonGroup>

        <ToggleButtonGroup
          className={classnames(classes.viewToggles)}
          aria-label="Toggle Labels"
          size="small"
        >
          { !prohibitedLayers.includes(CameraLayers.Mask) && license === LicenseType.PlacezPro && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.Mask]}
              label={LocalStorageKey.MaskObjects}
            >
              <MaskItemIcon/>
            </CameraLayerToggle>
          }
          {/* { !prohibitedLayers.includes(CameraLayers.ArchitectureElement) && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.ArchitectureElement]}
              label={LocalStorageKey.ArchitectureObjects}
            >
              <AccountBalance/>
            </CameraLayerToggle>
          } */}
          { !prohibitedLayers.includes(CameraLayers.Walls) && (license === LicenseType.PlacezPlus || license === LicenseType.PlacezPro) && !floorPlanMode &&
            <CameraLayerToggle
              layers={[CameraLayers.Walls]}
              label={LocalStorageKey.WallObjects}
            >
              <FormatAlignJustify />
            </CameraLayerToggle>
          }
          { (cameraType === CameraType.FPVCamera || plannerTool === PlannerTool.Photosphere) &&
            <CameraLayerToggle
              layers={[CameraLayers.PhotosphereCameras]}
              label={LocalStorageKey.PhotosphereCameras}
            >
              <Camera/>
            </CameraLayerToggle>
          }
          { !prohibitedLayers.includes(CameraLayers.FloorplaneImage) && currentFloorPlan?.floorplanImageUrl &&
            <CameraLayerToggle
              layers={[CameraLayers.FloorplaneImage]}
              label={LocalStorageKey.FloorplanImage}
              excludeLayers={[CameraLayers.Floorplanes, CameraLayers.BasePlanes]}
            >
              <Image/>
            </CameraLayerToggle>
          }
        </ToggleButtonGroup>
      </Paper>
    </div>
    }

  {/* Horizontal */}
    <Paper className={classnames(classes.root, classes.border, classes.horizontal)}>
      <ToggleButtonGroup
        aria-label="Toggle Labels"
        size="small"
      >
        <Tooltip title="View Options">
          <ToggleButton
            value="hideCamera"
            name="hide"
            aria-label="label"
            selected={showViewsToolbar}
            onClick={toggleViewsToolbar}
            classes={{
              root: classes.button,
              selected: classes.selected,
            }}
            ><Visibility/>
          </ToggleButton>
        </Tooltip>
        { (layoutViewState === LayoutViewState.ThreeDView || fixtureViewState === FixtureViewState.ThreeDView) &&
          <Tooltip title="3D Rotate">
          <ToggleButton
            value={'3d-rotate'}
            selected={autoRotate}
            onClick={(e) => dispatch(SetAutoRotate(!autoRotate))}
            classes={{
              root: classes.button,
              selected: classes.selected,
            }}>
            <ThreeDRotation />
          </ToggleButton>
          </Tooltip>
        };
        { plannerTool === PlannerTool.Photosphere &&
          <Tooltip title="Auto-Rotate Camera">
            <ToggleButton
              value="autoRotateCamera"
              name="autoRotate"
              aria-label="label"
              selected={autoRotatePhotosphereCamera}
              onClick={rotateCamera}
              classes={{
                root: classes.button,
                selected: classes.selected,
              }}
              ><ThreeDRotation/>
            </ToggleButton>
          </Tooltip>
        };
        {controlsType !== ControlsType.PointerLock &&
          <Tooltip title="Multi-Select">
          <ToggleButton
            value={'multiSelect'}
            selected={multiSelect}
            onClick={toggleMultiSelect}
            classes={{
              root: classes.button,
              selected: classes.selected,
            }}
          ><DoneAll/>
          </ToggleButton>
          </Tooltip>
        };
      </ToggleButtonGroup>
      { ((
        layoutViewState === LayoutViewState.ThreeDView ||
        layoutViewState === LayoutViewState.TwoDView ||
        fixtureViewState === FixtureViewState.ThreeDView ||
        fixtureViewState === FixtureViewState.TwoDView) &&
        fixtureViewState !== FixtureViewState.Floorplan &&
        showViewsToolbar &&
        cameraLayers.includes(CameraLayers.Measurments)
        ) &&
        <Divider orientation="vertical" className={classes.divider} />
      }
    </Paper>
    { copiedAssets !== undefined &&
      <Paper className={classnames(classes.clipboard, classes.border)}>
        <Tooltip title="Clear Clipboard">
          <IconButton
            onClick={() => { dispatch(ChangeCopiedAssetsState(undefined)); }}
            aria-label="Clear Clipboard"
            classes={{
              root: classes.button,
            }}>
            <ClearClipboardIcon/>
          </IconButton>
        </Tooltip>
      </Paper>
    }
  </>
  );
}

export default ViewOptions
