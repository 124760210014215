import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, Input, InputLabel, MenuItem, Select, TextField, Tooltip } from '@material-ui/core';
import { PlacezFixturePlan, PlacezLayoutPlan, Scene } from '../../api';
import { InvoiceLineItem } from './InvoiceModels';
import { AddCircleOutline, SaveAltOutlined } from '@material-ui/icons';
import { UpdateLayout } from '../../reducers/layouts';
import { Utils, BillingRate } from '../../blue/core/utils';

interface Props {
  layouts: PlacezLayoutPlan[];
  hiddenLayouts?: {[layoutId: string]: boolean};
  addInvoiceLineItem: (layoutId: string, invoiceLineItem: InvoiceLineItem) => void;
};

const DefaultLineItem: InvoiceLineItem = {
  id: undefined,
  description: 'New Line Item',
  quantity: 1,
  price: 0,
  priceRateInHours: BillingRate.FlatRate,
  notes: '',
}

const NewLineItemInput = (props: Props) => {
  const { layouts } = props;
  const [selectedLayoutName, setSelectedLayoutName] = useState<string>('');
  const [selectedLineItem, setSelectedLineItem] = useState<InvoiceLineItem>(DefaultLineItem);

  const handleDetailChange = (value) => e => {
    setSelectedLineItem({
      ...selectedLineItem,
      [value]: e.target.value,
    })
  }

  const onSetRate = (event: React.ChangeEvent<{ value: number}>) => {
    setSelectedLineItem({
      ...selectedLineItem,
      priceRateInHours: event.target.value,
    });
  }

  const onSetLayout = (event: React.ChangeEvent<{ value: string}>) => {
    setSelectedLayoutName(event.target.value);
  }

  const handleAddLineItem = () => {
    const layout = props.layouts.find((layout: PlacezLayoutPlan) => layout.name === selectedLayoutName);
    const newLineItem: InvoiceLineItem = {
      ...selectedLineItem,
      id: Utils.guid(),
    }
    // const newLineItems: InvoiceLineItem[] = layout.invoiceLineItems ? layout.invoiceLineItems.filter((invoiceLineItem: InvoiceLineItem) => invoiceLineItem.id !== selectedLineItem.id) : [];
    // newLineItems.push(newLineItem);

    // const layoutCopy: PlacezLayoutPlan = {
    //   ...layout,
    //   invoiceLineItems: newLineItems,
    // };
    props.addInvoiceLineItem(layout.id, newLineItem);
    setSelectedLineItem(DefaultLineItem);
    setSelectedLayoutName('');
  }

return (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',

    }}>
    <FormControl>
      <InputLabel>
        Layout
      </InputLabel>
      <Select
        id="placeSelect"
        value={selectedLayoutName}
        required
        style={{
          width: '140px',
        }}
        onChange={onSetLayout}>
          {layouts
            ?.filter((layout: PlacezLayoutPlan) => {
              if (props.hiddenLayouts) {
                return !props.hiddenLayouts[layout.id];
              }
              return  !layout.hideInInvoice;
            })
            ?.map((layout: PlacezLayoutPlan) => {
              return <MenuItem value={layout.name}>{layout.name}</MenuItem>
            })}
      </Select>
    </FormControl>
    <TextField
      required
      id="outlined-required"
      label="Description"
      defaultValue="New Line Item"
      value={selectedLineItem?.description}
      onChange={handleDetailChange('description')}
    />
    <TextField
      required
      id="outlined-required"
      label="Price"
      value={selectedLineItem?.price}
      onChange={handleDetailChange('price')}
      style={{
        width: '60px',
      }}
    />
    <FormControl>
      <InputLabel>
        Rate
      </InputLabel>
      <Select
        id="placeSelect"
        value={selectedLineItem?.priceRateInHours}
        style={{
          width: '100px',
        }}
        onChange={onSetRate}>
        {Object.values(BillingRate)
          .filter((v) => isNaN(Number(v)))
          .map((rate) =>
            <MenuItem value={BillingRate[rate]}>{rate}</MenuItem>
          )}
      </Select>
    </FormControl>
    <TextField
      required
      id="outlined-required"
      label="Quantity"
      value={selectedLineItem?.quantity}
      onChange={handleDetailChange('quantity')}
      style={{
        width: '60px',
      }}
    />
    <TextField
      required
      id="outlined-required"
      label="Notes"
      value={selectedLineItem?.notes}
      onChange={handleDetailChange('notes')}
    />
    <Tooltip title="Add Line Item">
      <IconButton aria-label="Add Line Item"
        color="primary"
        onClick={handleAddLineItem}
        disabled={!selectedLayoutName}
        >
        { selectedLineItem.id ?
          <SaveAltOutlined fontSize="large"/> :
          <AddCircleOutline fontSize="large"/>
        }
      </IconButton>
    </Tooltip>
  </div>
)
            }

export default NewLineItemInput;
