import { RouteSection } from './Route';

// Icons
import {
  ScenesIcon as SceneIcon
} from '../assets/icons';

// Views
import Scenes from '../views/Scene/Scenes';
import NewScene from '../views/Scene/NewScene';
import EditScene from '../views/Scene/EditScene';
import LayoutPlanner from '../views/Scene/LayoutPlanner';

const baseUrl = '/scenes';

export const scenes: RouteSection = {
  main: {
    path: `${baseUrl}`,
    name: 'Scenes',
    component: Scenes,
    icon: SceneIcon,
  },
  new: {
    path: `${baseUrl}/new`,
    name: 'New Scene',
    component: NewScene,
  },
  edit: {
    path: `${baseUrl}/:id/edit`,
    name: 'Edit Scene',
    component: EditScene,
  },
  planner: {
    path: `${baseUrl}/:id/planner/:planId`,
    name: 'Scene Planner',
    component: LayoutPlanner,
  },
};

export default scenes;
