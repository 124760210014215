
import { useEffect, useState } from 'react';

import {
  Checkbox,
  FormControlLabel,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  CircularProgress,
  IconButton,
  makeStyles,
  useTheme,
  ModalProps,
} from '@material-ui/core';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import LargeIconButton from '../ShareModal/LargeIconButton';
import { Camera, FileCopy } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { GlobalViewState, LayoutViewState } from '../../../models/GlobalState';
import PhotosphereIcon from '../../../assets/icons/Photosphere';
import { Screenshot, TakeEquirectangularPhotoAction } from '../../../reducers/blue'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    dialogActions: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    customChecked: {
      '& svg': {
        height: 35,
        width: 35,
      },
    },
    cancel: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.common.white,
    },
    choices: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      minHeight: '0px',
      alignItems: 'stretch',
      flex: '1',
    },
    icon: {
      height: 128,
      width: 128,
    },
    photosphereLinkContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '150px',
    },
    photosphereLinkState: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
  })
);

enum VirtualCameraModes {
  None = 'none',
  Screenshot = 'screenshot',
  Equirectangular = 'equirectangular',
}

interface Props {
  hideModal: () => void;
}

const ScreenshotOptions = (props: Props) => {

  const layoutViewState = useSelector((state: ReduxState) => state.globalstate.layoutViewState);
  const photosphereLink = useSelector((state: ReduxState) => state.blue.selectedEquirectangularPhotoLink);
  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);

  const [download, setDownload] = useState<boolean>(true);
  const [sceneImage, setSetSceneImage] = useState<boolean>(false);
  const [floorplanImage, setFloorplanImage] = useState<boolean>(false);
  const [virtualCameraMode, setVirtualCameraMode] = useState<VirtualCameraModes>(VirtualCameraModes.None);
  const [generatingPhotosphereLink, setGeneratingPhotosphereLink] = useState<boolean>(false);

  const dispatch = useDispatch();


  useEffect(() => {
    if (virtualCameraMode === VirtualCameraModes.Equirectangular && !photosphereLink) {
      getPhotosphere();
    }
  }, [virtualCameraMode])

  useEffect(() => {
    dispatch(TakeEquirectangularPhotoAction())
  }, [generatingPhotosphereLink]);

  useEffect(() => {
    setGeneratingPhotosphereLink(false);
  }, [photosphereLink]);

  const handleScreenshot = (event: any) => {
    setVirtualCameraMode(VirtualCameraModes.None)
    event.preventDefault();
    const { hideModal } = props;

    dispatch(Screenshot(download, sceneImage, floorplanImage));
    hideModal();
  }

  const getPhotosphere = () => {
    setGeneratingPhotosphereLink(true);
  }

  const handleCancelScreenshot = (event: any) => {
    setVirtualCameraMode(VirtualCameraModes.None)
    event.preventDefault();
    const { hideModal } = props;
    hideModal();
  }

  const classes = styles(props);
  const theme = useTheme<Theme>();

  const renderContent = () => {
    switch (virtualCameraMode) {
      case VirtualCameraModes.Screenshot:
        return (<>
          <FormControlLabel
              key="checkbox-download"
              labelPlacement="end"
              control={
                <Checkbox
                  color="secondary"
                  checked={download}
                  onChange={() => { setDownload(!download) }}
                  classes={{
                    root: classes.customChecked,
                  }}
                />
              }
              label="Download image"
          />
          <br/>
          <FormControlLabel
            key="checkbox-set-scene-image"
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                checked={sceneImage}
                onChange={() => { setSetSceneImage(!sceneImage) }}
                classes={{
                  root: classes.customChecked,
                }}
              />
            }
            label="Set as the cover image for the Scene"
          />
          <br/>
          <FormControlLabel
            key="checkbox-set-floorplan-image"
            labelPlacement="end"
            control={
              <Checkbox
                color="secondary"
                checked={floorplanImage}
                onChange={() => { setFloorplanImage(!floorplanImage) }}
                classes={{
                  root: classes.customChecked,
                }}
              />
            }
            label="Set as the cover image for the Floorplan"
          />
        </>);
      case VirtualCameraModes.Equirectangular:
        return (
          <>
          { generatingPhotosphereLink &&
            <div className={classes.photosphereLinkContainer}>
              <div>
                Generating Photosphere
              </div>
              <div className={classes.photosphereLinkState}>
                <CircularProgress variant="indeterminate" color="primary"/>
              </div>
            </div>
          }
          { photosphereLink !== '' && !generatingPhotosphereLink &&
            <div className={classes.photosphereLinkContainer}>
              Copy Share Link
              <div className={classes.photosphereLinkState}>
                <IconButton
                  onClick={() => { navigator.clipboard.writeText(`${window.env['REACT_APP_LOGOUT_REDIRECT']}/360Viewer.html#${photosphereLink}`); }}>
                  <FileCopy />
                </IconButton>
              </div>
            </div>
          }
          </>
        );
      case VirtualCameraModes.None:
      default:
        return (
          <div className={classes.choices}>
            <LargeIconButton
              label="Screenshot"
              color={theme.palette.secondary.main}
              onClick={() => setVirtualCameraMode(VirtualCameraModes.Screenshot)}>
              <Camera className={classes.icon} />
            </LargeIconButton>
            {globalViewState === GlobalViewState.Layout &&
              (layoutViewState === LayoutViewState.PhotosphereView ||
               layoutViewState === LayoutViewState.StreetView) &&
              <LargeIconButton
                label="Photosphere"
                color={theme.palette.secondary.main}
                onClick={() => setVirtualCameraMode(VirtualCameraModes.Equirectangular)}>
                <PhotosphereIcon className={classes.icon} />
              </LargeIconButton>
            }
          </div>
        );
    }
  }

  return(
    <>
      <DialogTitle className={classes.dialogTitle}
        id="alert-dialog-slide-title">Screenshot options:</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
        </DialogContentText>
        {renderContent()}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          onClick={e => handleCancelScreenshot(e)}>
          Cancel
        </Button>
        {virtualCameraMode === VirtualCameraModes.Screenshot &&
          <Button
            variant="contained"
            onClick={e => handleScreenshot(e)}
            color="primary">
            Confirm
          </Button>
        }
        {virtualCameraMode === VirtualCameraModes.Equirectangular && photosphereLink &&
          <Button
            variant="contained"
            onClick={e => getPhotosphere()}
            color="primary">
            New Photosphere
          </Button>
        }
      </DialogActions>
    </>
  );
}

export default ScreenshotOptions;
