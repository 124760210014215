import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HandlesFromBlue } from '../../models';
import {
  Typography,
  Button,
  Switch,
  Tooltip,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { Room } from '../../../../blue/model/room';
import { HalfEdge } from '../../../../blue/model/half_edge';

import panelStyles from './panels.css';
import { Cancel, FileCopy } from '@material-ui/icons';
import { ReduxState } from '../../../../reducers';
import { SetSelectedSurfaces, NeedSaveAction } from '../../../../reducers/blue';
import EditMaterial from './EditMaterial';
import { PlacezMaterial, DefaultWallMaterial, DefaultFloorMaterial } from '../../../../api/placez/models/PlacezMaterial';
import { Utils } from '../../../../blue/core/utils';

interface Props {
  handlesFromBlue: HandlesFromBlue;
  selectedSurfaces?: any[];
  setSelectedSurfaces?: (surfaces) => void;
  save?: () => void,
}

const EditSurfacePanel = (props: Props) => {
  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const selectedSurfaces = useSelector((state: ReduxState) => state.blue.selectedSurfaces);

  const [selectedWallMaterial, setSelectedWallMaterial] = useState(DefaultWallMaterial);
  const [selectedFloor, setSelectedFloor] = useState(undefined);
  const [selectedFloorMaterial, setSelectedFloorMaterial] = useState(DefaultWallMaterial);
  const [selectedWall, setSelectedWall] = useState(undefined);
  const [selectedWallVisible, setSelectedWallVisible] = useState(true);
  const [copiedMaterial, setCopiedMaterial] = useState(undefined);
  const canSave = useRef(false);

  useEffect(() => {
    return () => {
      dispatch(SetSelectedSurfaces([]));
    }
  }, [])

  useEffect(() => {
    if (selectedSurfaces?.length > 0) {
      canSave.current = false;
      selectedWallFloor(selectedSurfaces[0]);
    } else {
      setSelectedWall(undefined);
      setSelectedFloor(undefined);
    }
  }, [selectedSurfaces])

  const selectedWallFloor = obj => {
    // Working with floor
    if (obj instanceof Room) {
      const texture = obj.getTexture();
      setSelectedFloor(obj);
      setSelectedWall(undefined);
      setSelectedFloorMaterial(texture);
    }

    // Working with Wall
    if (obj instanceof HalfEdge) {
      const texture = obj.getMaterial();
      setSelectedWall(obj);
      setSelectedFloor(undefined);
      setSelectedWallMaterial(texture);
      setSelectedWallVisible(obj.getVisibility());
    }

  }

  const nothingSelected = () => {
    dispatch(SetSelectedSurfaces([]));
  }

  useEffect(() => {
    setWallMaterial();
  }, [selectedWallMaterial]);

  useEffect(() => {
    setFloorMaterial();
  }, [selectedFloorMaterial]);

  const handleWallMaterialChange = (material: PlacezMaterial) => {
    canSave.current = true;
    setSelectedWallMaterial(material ? { ...material } : DefaultWallMaterial);
  }

  const handleFloorMaterialChange = (material: PlacezMaterial) => {
    canSave.current = true;
    setSelectedFloorMaterial(material ? { ...material } : DefaultFloorMaterial);
  }

  const copyWallMaterial = () => {
    setCopiedMaterial(selectedWallMaterial);
  }

  const pasteWallMaterial = () => {
    setSelectedWallMaterial(copiedMaterial);
  }

  const copyFloorMaterial = () => {
    setCopiedMaterial(selectedFloorMaterial);
  }

  const pasteFloorMaterial = () => {
    setSelectedFloorMaterial(copiedMaterial);
  }

  const setWallMaterial = () => {
    if (!selectedWall) return;
    selectedWall.setMaterial(selectedWallMaterial);
    if (canSave.current) dispatch(NeedSaveAction(true));
  }

  const setAllWalls = material => {
    props.handlesFromBlue.setAllWalls(material);
    dispatch(NeedSaveAction(true));
  }

  const setAllFloors = material => {
    props.handlesFromBlue.setAllFloors(material);
    dispatch(NeedSaveAction(true));
  }

  const setRoom = material => {
    props.handlesFromBlue.setRoom(selectedWall.room, material);
    dispatch(NeedSaveAction(true));
  }

  const setFloorMaterial = () => {
    if (!selectedFloor) return;
    selectedFloor.setMaterial(selectedFloorMaterial); // this is wrong!!!!!!!!!!!!
    if (canSave.current) dispatch(NeedSaveAction(true));
  }

  useEffect(() => {
    if (!selectedWall) return;
    selectedWall.setVisibility(selectedWallVisible);
    dispatch(NeedSaveAction(true));
  }, [ selectedWallVisible ])

  const changeWallVisibility = event => {
    setSelectedWallVisible(event.target.checked);
  }

  return (
    <div className={classes.root} style={{ display: 'flex' }}>
      {selectedFloor &&
        <div className={classes.panelUpper}>
          <div className={classes.titleContainer}>
            <div className={classes.cancelButton} />
            <Typography className={classes.title} align="center">
              Floor
            </Typography>
            <Tooltip title="Cancel Texture">
              <Cancel onClick={nothingSelected} className={classes.cancelButton} />
            </Tooltip>
          </div>
          <div style={{display: 'flex'}}>
            <Tooltip title="Copy">
              <Button
                onClick={copyFloorMaterial}
                style={{
                  backgroundImage: copiedMaterial?.id ? `url(${window.env['REACT_APP_DAM']}/Assets/${copiedMaterial.id}.jpg)` : '',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundColor: (copiedMaterial?.threeJSMaterial?.color) ? `${Utils.decColorToHex(copiedMaterial.threeJSMaterial.color)}` : '',
                  opacity: (copiedMaterial?.threeJSMaterial?.opacity) ? `${copiedMaterial.threeJSMaterial.opacity}` : '',
                  backgroundBlendMode: 'multiply',
                  backgroundSize: 'cover',
                  flex: 1,
                  alignSelf: 'stretch',
                }}
                className={classes.button}
                variant="outlined"
                classes={{
                  root: classes.button,
                }}>
                <FileCopy />
              </Button>
            </Tooltip>
            <Tooltip title="Paste">
              <div className={classes.modGroup}>
                <Button
                  disabled={copiedMaterial === undefined}
                  onClick={pasteFloorMaterial}
                  className={classes.button}
                  variant="outlined">
                  Floor
                </Button>
                <Button
                  disabled={copiedMaterial === undefined}
                  onClick={() => { setAllFloors(copiedMaterial); }}
                  className={classes.button}
                  variant="outlined">
                  All
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      }

      {selectedWall &&
      <div className={classes.panelUpper}>
        <div className={classes.titleContainer}>
          <div className={classes.cancelButton} />
          <Typography className={classes.title} align="center">
            Wall
          </Typography>
          <Tooltip title="Cancel Texture">
            <Cancel onClick={nothingSelected} className={classes.cancelButton} />
          </Tooltip>
        </div>
        <div style={{display: 'flex'}}>
          <Tooltip title="Copy">
            <Button
              onClick={copyWallMaterial}
              style={{
                backgroundImage: copiedMaterial?.id ? `url(${window.env['REACT_APP_DAM']}/Assets/${copiedMaterial.id}.jpg)` : '',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundColor: (copiedMaterial?.threeJSMaterial?.color) ? `${Utils.decColorToHex(copiedMaterial.threeJSMaterial.color)}` : '',
                opacity: (copiedMaterial?.threeJSMaterial?.opacity) ? `${copiedMaterial.threeJSMaterial.opacity}` : '',
                backgroundBlendMode: 'multiply',
                backgroundSize: 'cover',
                flex: 1,
                alignSelf: 'stretch',
              }}
              className={classes.button}
              variant="outlined"
              classes={{
                root: classes.button,
              }}>
              <FileCopy />
            </Button>
          </Tooltip>
          <Tooltip title="Paste">
            <div className={classes.modGroup}>
              <Button
                disabled={copiedMaterial === undefined}
                onClick={pasteWallMaterial}
                className={classes.button}
                variant="outlined">
                Wall
              </Button>
              <Button
                disabled={copiedMaterial === undefined}
                onClick={() => { setRoom(copiedMaterial); }}
                className={classes.button}
                variant="outlined">
                Room
              </Button>
              <Button
                disabled={copiedMaterial === undefined}
                onClick={() => { setAllWalls(copiedMaterial); }}
                className={classes.button}
                variant="outlined">
                All
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      }

      <div className={classes.panelLower}>
        { selectedFloor &&
          <EditMaterial
            material={selectedFloorMaterial}
            setMaterial={handleFloorMaterialChange}
            defaultMaterial={DefaultFloorMaterial}
            noTransparent
          ></EditMaterial>
        }
        { selectedWall &&
          <EditMaterial
            material={selectedWallMaterial}
            setMaterial={handleWallMaterialChange}
            defaultMaterial={DefaultWallMaterial}
          ></EditMaterial>
        }
      </div>
      { selectedWall &&
        <div className={classes.panelFooter}>
          <div className={classes.fieldContainer}>
            <div className={classes.fieldHeading}>Visible</div>
            <Switch checked={selectedWallVisible} onChange={changeWallVisibility} />
          </div>
        </div>
      }
    </div>
  );
}

export default EditSurfacePanel;
