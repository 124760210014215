import  { useEffect, useState } from 'react';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import {
  TextField,
  Typography,
  Paper,
  makeStyles,
} from '@material-ui/core';
import {
  Menu,
  Delete,
} from '@material-ui/icons';

import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import classnames from 'classnames';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '100px 1fr 100px',
      justifyItems: 'center',
      alignItems: 'center',
      borderRadius: 'unset',
      height: 48,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      boxShadow: 'none',
      '&:first-child': {
        borderRadius: '4px 4px 0 0',
        borderTop: 'none',
      },
      '&:last-child': {
        borderRadius: '0 0 4px 4px',
        borderBottom: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
      },
    },
    noHover: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    },
    inputFocused: {
      fontSize: 18,
      // color: theme.palette.grey[700],
      color: theme.palette.text.primary,
    },
    textHolder: {
      height: 48,
      width: '100%',
      justifySelf: 'start',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      borderLeft: `2px solid ${theme.palette.grey[300]}`,
      borderRight: `2px solid ${theme.palette.grey[300]}`,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      verticalAlign: 'middle',
      fontSize: 18,
      color: theme.palette.grey[700],
    },
    dragHandle: {
      color: theme.palette.grey[700],
      cursor: 'grab',
    },
    disabledHandle: {
      color: theme.palette.grey[500],
    },
    textField: {},
  })
);

interface Props {
  value: { id: number, name: string };
  edited: (item: { id: number, name: string }) => void;
  deleted: (id: number) => void;
  disabled?: boolean;
}

const Item = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(props.value.name);

  useEffect(() => {
    setName(props.value.name);
  },[props.value]);

  const startEditing = () => {
    setIsEditing(true);
  }

  const onChange = event => {
    setName(event.target.value.slice(0, 25));
  }

  const onBlur = () => {
    setIsEditing(false);
    const { name: originalName, id } = props.value;

    if (name !== originalName) {
      // flush the new name back up
      props.edited({ id, name });
    }
  }

  const onDelete = () => {
    const { id } = props.value;

    props.deleted(id);
  }

  const classes = styles(props);
  const { disabled } = props;
  const DragHandle = SortableHandle(() =>
  <Menu className={classes.dragHandle} />);

  return (
    <Paper className={disabled ?
      classnames(classes.root, classes.noHover) :
      classes.root}>
      { disabled ?
      <Menu className={classes.disabledHandle} /> :
      (<DragHandle />)
      }
      {
        isEditing ?
        (<div className={classes.textHolder}>
          <TextField
            classes={{ root: classes.textField }}
            autoFocus
            fullWidth
            onChange={onChange}
            name="name"
            value={name}
            onBlur={onBlur}
            margin="none"
            InputProps={{
              classes: {
                focused: classes.inputFocused,
              },
            }}
          />
        </div>) :
        <Typography
          component="span"
          className={classes.textHolder}
          onClick={disabled ? () => {} : startEditing}>
          {name}
        </Typography>
      }
      { !disabled &&
      <Delete

        onClick={onDelete}

      />
      }
    </Paper>
  );
}

export default SortableElement(Item);
