import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

// Components
import SceneForm from '../../components/Scenes/SceneForm';

interface Props {}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      height: '100%',
      width: '100%',
    },
  })
);

const NewScene = (props: Props) => {
  const classes = styles(props);

  return (
    <div className={classes.root}>
      <SceneForm />
    </div>
  );
}

export default NewScene;
