import { useEffect, useRef, useState } from 'react';
import { Paper, Theme, makeStyles } from '@material-ui/core';
// Components
import { Grid, GridColumn, GridCellProps } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import ActiveCell from './ActiveCell';
import { Place } from '../../api';
import { placeRoutes } from '../../routes';
import { tableStyles } from './tableSyles.css';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { SelectPlace } from '../../reducers/place';
import ArrowNavigateCell from './ArrowNavigateCell';

interface Props{
  places: Place[];
}

const PlaceTable = (props: Props) => {
  const styles = makeStyles<Theme>(tableStyles);
  const dispatch = useDispatch();
  const classes = styles(props);

  const selectedId = useSelector((state: ReduxState) => state.place.selectedId);
  const grid = useRef();

  const ViewPlaceByArrowCell = (props: GridCellProps) => {
    const { id } = props.dataItem;
    return(
      <ArrowNavigateCell {...props}
        path={placeRoutes.edit.path.replace(':id', id)}
        onClick={() =>
          dispatch(SelectPlace(id))
      }/>
    )
  };

  const columns = [
    { field: 'name', title: 'Place', className: classes.overflowEllipsis },
    { field: 'location', title: 'Location', className: classes.overflowEllipsis, minShow: 500 },
    { field: 'type', title: 'Type', className: classes.overflowEllipsis, minShow: 900 },
    { field: 'notes', title: 'Notes', className: classes.overflowEllipsis, minShow: 700 },
    { field: 'isActive', title: 'Active', cell: ActiveCell, width: 90, minShow: 400 },
    { cell: ViewPlaceByArrowCell, width: 60 },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns);
  const [sort, setSort] = useState([]);

  useEffect(() => {
    setSort([
      {field: 'lastModifiedDate', dir: 'desc'}
    ])
    grid.current = document.querySelector(`.${CSS.escape(classes.gridRoot)}`);
    // window.addEventListener('resize', checkColumnMaxWidth);
    checkColumnMaxWidth();
    return (() => {
      window.removeEventListener('resize', checkColumnMaxWidth);
    })
  }, [])

  const checkColumnMaxWidth = () => {
    if (grid.current) {
      const currentVisibleColumns = columns?.filter(item => !item.minShow || item.minShow <= (grid?.current as any)?.offsetWidth) ?? columns;
      if (currentVisibleColumns.length !== visibleColumns.length) {
        setVisibleColumns(currentVisibleColumns);
      }
    }
  }

  const {
    places,
    } = props;

  return (
    <Paper>
      <Grid
        sortable
        sort={sort}
        onSortChange={e => {
          setSort(e.sort);
        }}
        className={classes.gridRoot}
        selectedField="selected"
        data={orderBy(
          places.map(
            place => ({
              ...place,
              selected: place.id === selectedId,
              lastModifiedDate: new Date(place.lastModifiedUtcDateTime).getTime(),
            })
          ),
          sort)
        }
        onRowClick={(e) => {
          dispatch(SelectPlace(e.dataItem.id))
        }}>
          {
            visibleColumns.map((column, index) => {
              return (
                <GridColumn
                  field={column.field}
                  title={column.title}
                  cell={column.cell}
                  key={index}
                  className={column.className}
                  width={column.width}
                />
              );
            })
          }
      </Grid>
    </Paper>
  );
}

export default PlaceTable;
