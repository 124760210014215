import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Models
import {
  LayoutDesignerCallbacks,
} from '../../models';
import LayoutDesigner from './LayoutDesigner';
import { GlobalViewState, PlannerTool } from '../../../../models/GlobalState';
import { ChangeGlobalViewState, GlobalStateInitializing } from '../../../../reducers/globalState';
import { ReduxState } from '../../../../reducers';

interface Props {
}

const designerCallbacks = new LayoutDesignerCallbacks();

const LayoutDesignerWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const [sceneID, setSceneID] = useState<number>(undefined);


  const scene = useSelector((state: ReduxState) => state.scenes.currentScene);
  const globalStateInitialized = useSelector((state: ReduxState) => state.globalstate.globalStateInitialized);

  useEffect(() => {
    return () => setSceneID(undefined);
  }, [])

  useEffect(() => {
    if (scene !== null && sceneID !== scene.id) {
      setSceneID(scene.id);
      dispatch(GlobalStateInitializing());
      dispatch(ChangeGlobalViewState(GlobalViewState.Layout));
    }
  }, [scene])

  return (
    <>
      { globalStateInitialized &&
        <LayoutDesigner
        designerCallbacks={designerCallbacks}
        />
      }
    </>
  )
}

export default LayoutDesignerWrapper
