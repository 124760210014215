import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AutoComplete from '../AutoComplete';
import { toast } from 'react-toastify';

import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  FileCopy as CopyIcon,
  Add,
  Edit,
} from '@material-ui/icons';
import classnames from 'classnames';
// Components
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormGroup,
  Tooltip,
  Paper,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import { MuiPickersUtilsProvider, KeyboardTimePicker, TimePicker, DatePicker } from '@material-ui/pickers';

// Models
import {
  Place,
  Scene,
  SceneDetail,
  Client,
  placezApi,
} from '../../api';

import { sceneRoutes } from '../../routes';
import { CreateScene, CopySceneWithLayouts, UpdateScene, DeleteScene } from '../../reducers/scenes';

import { ModalConsumer } from '../Modals/ModalContext';
import AddClientModal from '../Modals/AddClientModal';
import { getFormatedNiceDate } from '../../sharing/utils/DateHelper';
import { AreYouSureDelete } from '../Modals/UniversalModal';
import { ReduxState } from '../../reducers';
import PlacezLayoutPlan from '../../api/placez/models/PlacezLayoutPlan';
import { createSelector } from 'reselect';
import { getOrgTheme, TimeFormats } from '../../api/placez/models/UserSetting';
import { GetFloorPlans } from '../../reducers/floorPlans';
import InvoiceModal, { buildSceneLineItems, rollUpPrice, updateComputedInvoiceLineItems, updateInvoiceLineItems } from '../Invoicing/InvoiceModal';
import { matchPath } from 'react-router';
import { userIsInRole } from '../../sharing/utils/userHelpers';
import { LayoutsAndFloorplans } from './LayoutsAndFloorplans';
import LoadImageModal from '../Modals/LoadImageModal';
import { UniversalModalWrapper } from '../Modals/UniversalModalWrapper';
import { SimpleModal } from '../Modals/SimpleModal';
import { LocalStorageKey, useLocalStorageSelector } from '../Hooks/useLocalStorageState'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      backgroundPosition: 'center',
    },
    sceneImageBackground: {
      background: `${theme.palette.grey[900]} no-repeat 50% 50%`,
    },
    formContainer: {
      width: '550px',
      minWidth: 500,
      height: 'calc(100vh - 66px)',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[2],
      paddingTop: theme.spacing(3),
      overflowY: 'auto',
    },
    imageContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      flex: '1',
      height: '100%',
      position: 'relative',
      overflow: 'hidden',
    },
    imageOverlay: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'start',
      margin: 2 * theme.spacing(),
    },
    imageButtonOverlay: {
      display: 'flex',
      bottom: '10%',
      margin: 2 * theme.spacing(),
      alignSelf: 'start',
    },
    imageButton:{
      margin: theme.spacing(3),
      fontSize: 14,
      textTransform: 'none',
      alignSelf: 'flex-end',
    },
    sceneName: {
      ...theme.typography.h6,
      color: theme.palette.common.white,
      fontSize: '35px',
      fontWeight: 700,
      marginBottom: theme.spacing(),
    },
    sceneDate: {
      ...theme.typography.body2,
      color: theme.palette.common.white,
      fontSize: '20px',
      fontWeight: 700,
    },
    image: {
      height: '100%',
      width: '100%',
    },
    heading: theme.typography.h1,
    editButtonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      gridColumn: 'span 2',
      color: 'white',
      margin: '0px',
    },
    fullWidthField: {
      gridColumn: 'span 4',
      margin: '4px 0px',
      color: 'white',
    },
    miniField: {
      flex: 1,
      margin: '0px',
      color: 'white',
    },
    fieldRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    fieldContainer: {
      display: 'grid',
      gap: '8px',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      padding: '0px 14px',
    },
    inputContainer: {
      gridColumn: 'span 2',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    actions: {
      padding: theme.spacing(4),
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      margin: 2 * theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
    },
    invoiceButton: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      margin: 2 * theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      gridColumn: 'span 4',
    },
    sceneTypeContainer: {
      gridColumn: 'span 4',
      borderRadius: theme.shape.borderRadius,
      // backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(2),
    },
    checkboxes: {
      display: 'flex',
      flexDirection: 'row',
    },
    sceneTypeLabels: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    customLabel: {
      ...theme.typography.body2,
      color: theme.palette.text.primary,
    },
    customChecked: {
      '& svg': {
        height: 50,
        width: 50,
      },
    },
    checkboxLabel: {
      color: theme.palette.text.primary,
      margin: 0,
      width: '16.6%',
      overflow: 'hidden',
    },
    floorplanButton: {
      height: 30,
      width: 150,
      textTransform: 'none',
      verticalAlign: 'top',
      marginTop: '2px',
    },
    controlButtons: {
      color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
    },
    addButton: {
      borderRadius: 0,
      '&:hover': {
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
      },
    },
    notes: {
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    hr: {
      width: '2px',
      alignSelf: 'stretch',
      margin: '0px 5px',
      border: `1px solid ${theme.palette.divider}`,
    }
  })
)

type Props = {
  scene?: Scene;
  layouts?: PlacezLayoutPlan[];
};

const dollarFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      prefix="$"
    />
  );
};

const numberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
};

const roundTime = (date: Date) => {
  date.setHours(date.getHours() + Math.round(date.getMinutes() / 60));
  date.setMinutes(0);
  return date;
};

const add2hours = date => {
  date.setHours(date.getHours() + 2);
  return date;
};


const LayoutsToast = () => (
  <div style={{ fontSize: '14px', color: '#000', textAlign: 'center' }}>You cannot change Place with existing Scene Layouts.</div>
);

const SceneForm = (props: Props) => {
  let toastId = null;

  const theme: Theme = useTheme();
  const dispatch = useDispatch();
  const classes = styles(props);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const places = useSelector((state: ReduxState) => state.place.places);
  const clients = useSelector((state: ReduxState) => state.client.clients);
  const selectedId = useSelector((state: ReduxState) => state.scenes.selectedId);
  const picklists = useSelector((state: ReduxState) => state.settings.pickLists);
  const floorplans = useSelector((state: ReduxState) => state.floorPlans.unsorted);
  const user = useSelector((state: ReduxState) => getUser(state));
  const twentyFourHourTime = useLocalStorageSelector<boolean>(LocalStorageKey.TwentyFourHourTime);

  const [inEdit, setInEdit] = useState<boolean>(!props.scene);
  const [detail, setDetail] = useState<SceneDetail>(
    props.scene ? props.scene as SceneDetail : {
      name: '',
      categories: [] as number[],
      startUtcDateTime: roundTime(new Date()),
      endUtcDateTime: add2hours(roundTime(new Date())),
      thumbnailUrl: getOrgTheme(user.profile.organization_id).defaultSceneImage,
    } as SceneDetail
  );
  const [modifiedClients, setModifiedClients] = useState<Client[]>(clients);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState<boolean>(false);
  const [guaranteedSpend, setGuaranteedSpend] = useState<number>(0);

  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);

  const getClient = clientId => {
    const filteredClients = modifiedClients.filter(client => client.id === clientId);
    if (filteredClients.length > 0) {
      const client = filteredClients[0];
      if (client.name) {
        return client.name;
      }
    }
    return '';
  }

  const getPlace = placeId => {
    const filteredPlaces = places.filter(place => place.id === placeId);
    if (filteredPlaces.length > 0) {
      const place = filteredPlaces[0];
      if (place.name) {
        return place.name;
      }
    }
    return '';
  }

  const getAbbreviateName = (name: string) => {
    const maxLengthIndex = 11;
    if (name.length < maxLengthIndex) {
      return name;
    }

    const postfix = '...';
    const abbreviatedName = `${name.substring(0, maxLengthIndex - postfix.length)}${postfix}`;

    return abbreviatedName;
  }





  const onCancel = () => {
    setInEdit(false);
    setDetail(props.scene ? props.scene as SceneDetail : {
      categories: [] as number[],
      startUtcDateTime: new Date(),
      endUtcDateTime: new Date(),
    } as SceneDetail);
    if (selectedId === null) {
      goBack();
    }
  }

  const onSubmit = () => {
    setInEdit(false);
    const { scene } = props;
    const updatedScene = {
      ...scene,
      ...detail,
    };
    if (detail === undefined) {
      return;
    }
    if (!detail.name) {
      detail.name = 'Untitled Scene';
    }
    if (scene !== undefined) {
      dispatch(UpdateScene(updatedScene))
    } else {
      dispatch(CreateScene({
        ...detail,
      }));
    }
  }

  const onCopySceneWithLayouts = () => {
    (window as any).gtag('event', 'scene_form', 'copy_scene_with_layouts');
    const { scene } = props;
    dispatch(CopySceneWithLayouts(scene));
  }


  const onDeleteScene = (sceneId: number) => {
    if (sceneId) {
      dispatch(DeleteScene(sceneId));
      navigate(sceneRoutes.main.path);
    }
  }

  const handleDetailChange = (prop: any) => (event: any) => {
    setDetail({
      ...detail,
      [prop]: event.target.value,
    });
  }

  const handlePlaceChange = (prop: any) => (event: any) => {
    (window as any).gtag('event', 'scene_form', 'changed_place');
    const { layouts } = props;
    if (! toast.isActive(toastId)) {
      toastId = toast(<LayoutsToast />);
    }
    setDetail({
      ...detail,
      [prop]: event.target.value,
    })
    dispatch(GetFloorPlans(event.target.value));
  }

  const handleImageChange = (imageUrl: string) => {
    (window as any).gtag('event', 'scene_form', 'changed_image');
    setDetail({
      ...detail,
      thumbnailUrl: imageUrl,
    });
  }

  const handleAutoComplete = (prop: any) => client => {
    setDetail({
      ...detail,
      [prop]: client ? client.value : undefined,
    })
  }

  const handleNewClient =  (value: any) => {
    (window as any).gtag('event', 'scene_form', 'create_new_client');
    const newClient = value;
    placezApi.postClient(newClient).then(response => {
      const client = response.parsedBody as Client;
      setModifiedClients(modifiedClients.concat([client]));
      setDetail({
        ...detail,
        clientId: client.id,
      })
    });
  }

  const handleStartDateChange = (startUtcDateTime: Date) => {
    const endUtcDateTime = new Date(detail.endUtcDateTime);
    if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
      endUtcDateTime.setUTCFullYear(startUtcDateTime.getUTCFullYear());
      endUtcDateTime.setUTCMonth(startUtcDateTime.getUTCMonth());
      endUtcDateTime.setUTCDate(startUtcDateTime.getUTCDate());
      if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
        endUtcDateTime.setHours(startUtcDateTime.getHours() + 1);
      }
    }
    setDetail({
      ...detail,
      startUtcDateTime,
      endUtcDateTime,
    })
  }

  const handleEndDateChange = (endUtcDateTime: Date) => {
    const startUtcDateTime = new Date(detail.startUtcDateTime);
    if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
      startUtcDateTime.setUTCFullYear(endUtcDateTime.getUTCFullYear());
      startUtcDateTime.setUTCMonth(endUtcDateTime.getUTCMonth());
      startUtcDateTime.setUTCDate(endUtcDateTime.getUTCDate());
      if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
        startUtcDateTime.setHours(endUtcDateTime.getHours() - 1);
      }
    }
    setDetail({
      ...detail,
      startUtcDateTime,
      endUtcDateTime,
    });
  }

  const handleStartTimeChange = (startUtcDateTime: Date) => {
    const endUtcDateTime = new Date(detail.endUtcDateTime);

    if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
      endUtcDateTime.setHours(startUtcDateTime.getHours() + 1);
    }
    setDetail({
      ...detail,
      startUtcDateTime,
      endUtcDateTime,
    });
  }

  const handleEndTimeChange = (endUtcDateTime: Date) => {
    const startUtcDateTime = new Date(detail.startUtcDateTime);

    if (startUtcDateTime.getTime() > endUtcDateTime.getTime()) {
      startUtcDateTime.setHours(endUtcDateTime.getHours() - 1);
    }
    setDetail({
      ...detail,
      startUtcDateTime,
      endUtcDateTime,
    });
  }

  const handleCategoryCheck = (category: any) => {
    const found = detail.categories.includes(category);
    if (found) {
      setDetail({
        ...detail,
          categories: detail.categories.filter(x => x !== category),
      });
    } else {
      setDetail({
        ...detail,
          categories: [...detail.categories, category],
      });
    }
  }

  const goBack = () => navigate(-1);

  const CopySceneModalBody = () => {
    return (
      <span>
        Press continue to copy Scene with all Layouts.
      </span>
    );
  }


  const onShowInvoiceModal = () => {
    setInvoiceModalOpen(true);
  }

  const onSetGuaranteedSpend = (guaranteedSpend: number = 0) => {
    setInvoiceModalOpen(false);
    // TODO not linnked to guaranteed Spend
    setGuaranteedSpend(guaranteedSpend);
    // setDetail({
    //   ...detail,
    //   guaranteedSpend,
    // })
  }

  const computeGuaranteedSpend = () => {

    const computedInvoiceLineItems = updateComputedInvoiceLineItems(props.layouts, floorplans, props.scene, assetsBySku);
    const invoiceLineItems = updateInvoiceLineItems(props.layouts);
    setGuaranteedSpend(rollUpPrice(buildSceneLineItems(props.layouts, computedInvoiceLineItems, invoiceLineItems)));
  }

  useEffect(() => {
    // this path might not work
    if (matchPath('scenes/new', pathname)) {
      setInEdit(true);
    }
  }, [])

  useEffect(() => {
    if (selectedId) {
      navigate(`/scenes/${selectedId}/edit`, { replace: true });
    }
  }, [selectedId])


  useEffect(() => {
    if (props.scene) {
      setDetail(props.scene);
      dispatch(GetFloorPlans(props.scene.placeId));
    }
  }, [props.scene])

  useEffect(() => {
    if (props.layouts && props.layouts.length !== 0 && floorplans && floorplans.length !== 0) {
      computeGuaranteedSpend();
    }
  }, [props.layouts, floorplans, assetsBySku])

  const { scene } = props;


  const orgTheme = getOrgTheme(user.profile.organization_id);


  const EditButtons = (
    <div className={classes.editButtonContainer}>
      { !inEdit && (<>
          <Tooltip title="Edit Scene">
            <IconButton
              className={classes.controlButtons}
              aria-label="Switch to Edit"
              onClick={() => {
                setInEdit(true);
              }}>
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
            <UniversalModalWrapper
              onContinue= {onCopySceneWithLayouts}
              modalHeader='Clone Scene?'
            >
              <Tooltip title="Copy Scene">
                <IconButton
                  className={classes.controlButtons}
                  aria-label="Clone Scene"
                  >
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
              <CopySceneModalBody/>
            </UniversalModalWrapper>


        </>)
      }
      {
        !inEdit && selectedId && (
          <UniversalModalWrapper
            onDelete= {() => onDeleteScene(selectedId)}
            modalHeader='Are you sure?'
          >
            <Tooltip title="Delete Scene">
              <IconButton
                className={classes.controlButtons}
                >
                <DeleteIcon fontSize="small"/>
              </IconButton>
            </Tooltip>
            {AreYouSureDelete('Scene')}
          </UniversalModalWrapper>
        )
      }
    </div>
  )

  return (
    <div
      className={classnames(classes.root)}>
      < div className={classnames(classes.imageContainer, classes.sceneImageBackground)}
        style={{
          backgroundImage: `url(${detail.thumbnailUrl}), linear-gradient(to top right, ${theme.palette.primary.main}, ${theme.palette.background.paper})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}>
        <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          { detail?.thumbnailUrl ? <></> :
            <div style={{
              backgroundImage: `url(${orgTheme.icon})`,
              backgroundRepeat: 'no-repeat',
              width: '250px',
              height: '250px',
            }}
            />
          }
        </div>
        <div className={classes.imageOverlay}>
          <span className={classes.sceneName}>
            {detail?.name}
          </span>
          <span  className={classes.sceneDate}>
            { getFormatedNiceDate(detail.startUtcDateTime, twentyFourHourTime) }
          </span>
        </div>
        {/* <div className={classes.imageButtonOverlay}> */}
        {inEdit  ? (
          <ModalConsumer>
            { ({ showModal, props }) =>
              <Button
                variant="contained"
                color="primary"
                className={classes.imageButton}
                onClick={() => showModal(
                  LoadImageModal,
                  { ...props,
                    imageLabel: 'Scene Image',
                    handleSetImage: handleImageChange,
                    currentImage: detail.thumbnailUrl
                   })}
              >
                Modify Image
              </Button>
            }
              </ModalConsumer>
              ) : <></> }
        {/* </div> */}
      </div>
      <div className={classes.formContainer}>
        <div className={classes.fieldContainer}>
          <TextField
            disabled={!inEdit}
            className={classes.fullWidthField}
            style={{gridColumn: 'span 3'}}
            id="title"
            autoFocus
            label={inEdit ? 'Scene Title *' : 'Scene Title'}
            value={detail.name ? detail.name : ''}
            onChange={handleDetailChange('name')}
            inputProps={{
              maxLength: 200,
            }}
          />
          {EditButtons}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                disabled={!inEdit}
                className={classes.miniField}
                margin="normal"
                label="Start Date"
                value={detail.startUtcDateTime}
                onChange={handleStartDateChange}
                autoOk
              />
              <KeyboardTimePicker
              variant="inline"
                disabled={!inEdit}
                className={classes.miniField}
                margin="normal"
                label="Start Time"
                value={detail.startUtcDateTime}
                onChange={handleStartTimeChange}
                ampm={!twentyFourHourTime}
                autoOk
              />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                variant="inline"
                disabled={!inEdit}
                className={classes.miniField}
                margin="normal"
                label="End Date"
                value={detail.endUtcDateTime}
                onChange={handleEndDateChange}
                autoOk
              />
              <KeyboardTimePicker
                variant="inline"
                disabled={!inEdit}
                className={classes.miniField}
                margin="normal"
                label="End Time"
                value={detail.endUtcDateTime}
                onChange={handleEndTimeChange}
                ampm={!twentyFourHourTime}
                autoOk
              />
          </MuiPickersUtilsProvider>
          {inEdit ? (
                <div className={classes.inputContainer}>
                  <FormControl style={{ flex: 1 }}>
                    <AutoComplete
                      disabled={!inEdit}
                      label="Client"
                      value={detail.clientId}
                      suggestions={
                        modifiedClients?.map((client, index) => {
                          return {
                            label: client.name,
                            value: client.id,
                          };
                        })
                      }
                      handleChange={handleAutoComplete('clientId')}
                    />
                  </FormControl>
                  <SimpleModal>
                    <IconButton
                      color="primary"
                      className={classes.addButton}
                    >
                      <Add fontSize="small" />
                    </IconButton>
                    <AddClientModal
                      handleSetNewClient={handleNewClient}
                      picklists={picklists}
                    />
                  </SimpleModal>
                </div>
          ) : <TextField
          disabled={!inEdit}
          className={classes.field}
          label="Client"
          value={getClient(detail.clientId)}
            />}
          <TextField
            disabled={!inEdit}
            className={classes.field}
            id="scene-manager"
            label="Scene Manager"
            value={detail.manager ? detail.manager : ''}
            onChange={handleDetailChange('manager')}
            inputProps={{
              maxLength: 100,
            }}
          />
          {inEdit ? (
            <FormControl className={classes.field}>
              <InputLabel
                htmlFor="placeId"
                shrink={!(detail.placeId === undefined)}>
                Place *
                </InputLabel>
              <Select
                value={detail.placeId ?? 0}
                onChange={handlePlaceChange('placeId')}
                disabled={detail.placeId !== undefined && detail.id !== undefined}
                inputProps={{
                  name: 'placeId',
                  id: 'place-id',
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                { places?.map((place: Place, index) =>
                    <MenuItem
                      key={`${place.name}-${index}`}
                      value={place.id}>{place.name}
                    </MenuItem>)
                }
              </Select>
            </FormControl>) : <TextField
            disabled={true}
            className={classes.field}
            label="Place"
            value={getPlace(detail.placeId)}
            />}

            {inEdit ? (
            <FormControl className={classes.field}>
              <InputLabel
                htmlFor="setupStyle"
                shrink={!(detail.setupStyle === undefined)}>
                Setup Style
                </InputLabel>
              <Select
                disabled={!inEdit}
                value={detail.setupStyle }
                onChange={handleDetailChange('setupStyle')}
                inputProps={{
                  name: 'setupStyle',
                  id: 'setup-style',
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                { picklists?.length > 0 &&
                  picklists.find(list => {
                    return list.name === 'SetupStyle';
                  }).picklistOptions
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((setupStyle, index) =>
                    <MenuItem
                      key={`${setupStyle.name}-${index}`}
                      value={setupStyle.name}>{setupStyle.name}
                    </MenuItem>)
                }
              </Select>
            </FormControl>) : <TextField
            disabled={!inEdit}
            className={classes.field}
            label="Setup Style"
            value={ detail.setupStyle || ''}
            />}
          {inEdit ? (
            <FormControl className={classes.field}>
              <InputLabel
                htmlFor="status"
                shrink={!(detail.status === undefined)}>
                Status
                </InputLabel>
              <Select
                disabled={!inEdit}
                value={detail.status }
                onChange={handleDetailChange('status')}
                inputProps={{
                  name: 'status',
                  id: 'status-id',
                }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                { picklists &&
                  picklists.length > 0 &&
                  picklists.find(list => {
                    return list.name === 'Status';
                  }).picklistOptions
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((status, index) =>
                    <MenuItem
                      key={`${status.name}-${index}`}
                      value={status.name}>{status.name}</MenuItem>)
                }
              </Select>
            </FormControl>) : <TextField
            disabled={!inEdit}
            className={classes.field}
            label="Status"
            value={ detail.status || ''}
            />}
            <TextField
              disabled={!inEdit}
              className={classes.miniField}
              id="scene-total"
              label="Scene Total"
              value={detail.guaranteedSpend}
              onChange={handleDetailChange('guaranteedSpend')}
              InputProps={{
                inputComponent: dollarFormatCustom,
              }}
            />
            <TextField
              disabled={!inEdit}
              className={classes.minifield}
              id="guest-count"
              label="Guest Count"
              value={detail.estimatedGuestCount ?? ""}
              onChange={handleDetailChange('estimatedGuestCount')}
              InputProps={{
                inputComponent: numberFormatCustom,
              }}
            />
            <TextField
              disabled={!inEdit}
              className={classes.fullWidthField}
              value={detail.notes || ''}
              onChange={handleDetailChange('notes')}
              id="notes"
              label="Notes"
              multiline
              rowsMax="5"
            />
          { inEdit &&
            <div className={classes.notes} >
              * Indicates Required Field
            </div>
          }
          <Paper className={classes.sceneTypeContainer}>
            <div className={classes.sceneTypeLabels}>
              <span className={classes.customLabel}>Scene Type</span>
              <span className={classes.customLabel}>
                { detail.categories ? detail.categories.length : 0 } Selected
              </span>
            </div>
            {picklists &&
            <FormGroup className={classes.checkboxes}>
              {picklists.length > 0 &&
                picklists.find(list => {
                  return list.name === 'SceneType';
                }).picklistOptions
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map(sceneType =>
                  <Tooltip
                    key={`tooltip-${sceneType.name}-${sceneType.id}`}
                    title={getAbbreviateName(sceneType.name) !== sceneType.name ? sceneType.name : ''}>
                    <FormControlLabel
                      disabled={!inEdit}
                      className={classes.checkboxLabel}
                      key={`checkbox-${sceneType.name}-${sceneType.id}`}
                      labelPlacement="bottom"
                      control={
                        <Checkbox
                          color="default"
                          checked={detail.categories.includes(sceneType.id)}
                          onChange={() => handleCategoryCheck(sceneType.id)}
                          classes={{
                            root: classes.customChecked,
                          }}
                        />
                      }
                      label={getAbbreviateName(sceneType.name)}/>
                    </Tooltip>
                )
              }
            </FormGroup>
            }
          </Paper>

          { userIsInRole(user, 'admin') && Object.keys(assetsBySku).length > 0 && <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gridColumn: 'span 3',
                justifyContent: 'end',
              }}>
                <h2 className={classes.tableHeader}>
                  Invoice Total:
                </h2>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
            >
              ${guaranteedSpend}
              <Tooltip title="Edit Invoice">
                <IconButton
                  className={classes.controlButtons}
                  aria-label="Clone Scene"
                  onClick={onShowInvoiceModal}
                >
                  <Edit fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <InvoiceModal
              floorPlans={floorplans}
              scene={props.scene}
              open={invoiceModalOpen}
              onInvoiceComplete={onSetGuaranteedSpend}
            />
          </> }
        </div>
      <div className={classes.fieldRow}>
        { inEdit ?
          <div className={classes.actions}>
            <Button
              onClick={onCancel}
              className={classes.button}
              variant="contained">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              className={classes.button}
              disabled={detail.name === undefined || detail.placeId === undefined}
              variant="contained"
              color="primary">
              Save
            </Button>
          </div> : <></>
        }
      </div>

      { !inEdit &&
        <LayoutsAndFloorplans
          scene={props.scene}
          layouts={props.layouts}
        />
      }
      </div>
    </div>
  );
}

const getUserState = state => {
  return state.oidc.user;
};

const getUser = createSelector(
  [getUserState],
  user => user
);

export default SceneForm;
