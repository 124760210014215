import { AmbientLight, CameraHelper, DirectionalLight, HemisphereLight, Mesh, PlaneGeometry, ShadowMaterial, Vector3 } from 'three';
import { CameraLayers } from '../../models/BlueState';
import { Floorplan } from '../model/floorplan';
import { store } from '../..';
import { ReduxState } from '../../reducers';
import { CameraType } from '../../components/Blue';
import { LayoutLabel } from '../../api'
import { LabelMaker } from './labelMaker'
import { GlobalViewState } from '../../models/GlobalState'
import { LocalStorageKey, localStorageObservable$ } from '../../components/Hooks/useLocalStorageState'
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators'
import { Subject } from 'rxjs'
import { Utils } from '../core/utils'

export const Notes = function (scene) {


  const scope = this; // tslint:disable-line
  this.labels = [];
  this.staticLabels = [];
  this.labelSprites = [];
  this.destroy$ = new Subject();
  this.noteScale = 1;

  const init = () => {
    this.listener = () => {
      const blueReady = (store.getState() as ReduxState).blue.blueInitialized;
      const globalViewState = (store.getState() as ReduxState).globalstate.globalViewState;
      let storeLabels: LayoutLabel[] = [];
      let backgroundLabels: LayoutLabel[] = [];
      if (globalViewState === GlobalViewState.Fixtures) {
        storeLabels = (store.getState() as ReduxState).designer?.floorPlan?.floorplanLabels;
      } else {
        storeLabels = (store.getState() as ReduxState).designer?.layout?.layoutLabels;
        backgroundLabels = (store.getState() as ReduxState).designer?.floorPlan?.floorplanLabels;
      }

      if (storeLabels && storeLabels !== this.labels) {
        this.labels = blueReady ? storeLabels : [];
        this.drawLabels(scene, this.labels, this.labelSprites);
        scene?.update();
      }

      if (backgroundLabels && backgroundLabels !== this.staticLabels) {
        this.staticLabels = backgroundLabels;
        this.drawLabels(scene, this.staticLabels);
        scene?.update();
      }

    }

    this.NotesSizeSubscription = localStorageObservable$.pipe(
      takeUntil(this.destroy$),
      map((localStorageState) => localStorageState[LocalStorageKey.Notes]),
      distinctUntilChanged(),
    ).subscribe((e: any) => {
      scope.noteScale = e;
      this.drawLabels(scene, this.labels, this.labelSprites);
      scene?.update();
    })

    scope.unsubscribeStore = store.subscribe(scope.listener);

  };

  const addSprite = (obj: LayoutLabel, addToLabelSprites?: boolean) => {
    if (obj.position && obj.labelText) {
      const scaleAdjusted = {
        ...obj,
        fontSize: obj.fontSize * Utils.scaleFactor(scope.noteScale),
      }
      const label = new LabelMaker(scaleAdjusted, CameraLayers.LayoutLabels);
      const sprite = label.getSprite();
      sprite.userData = {
        id: obj.id,
      };
      sprite.position.setX(obj.position[0]);
      sprite.position.setZ(obj.position[2]);
      if (addToLabelSprites) this.labelSprites.push(sprite);
      scene.add(sprite);
    }
  }

  this.drawLabels = (scene, labels: LayoutLabel[], labelSprites: THREE.Sprite[]) => {
    if (labelSprites) {
      labelSprites.forEach((obj: THREE.Sprite) => {
        scene.remove(obj);
      });

      labelSprites.length = 0;
      labels.forEach((obj: LayoutLabel) => {
        addSprite(obj, true);
      });
    } else {
      labels.forEach((obj: LayoutLabel) => {
        addSprite(obj);
      });
    }
  };

  init();

  this.dispose = () => {
    scope.unsubscribeStore();
    this.destroy$.next();
    this.destroy$.complete();
  }
};
