import React, { Component, useState } from 'react';
import {
  Theme,
  createStyles,
 }
  from '@material-ui/core/styles';
import {
   Edit as EditIcon,
   FileCopy as CopyIcon,
   Delete as DeleteIcon,
   Send,
   Save,
   Cancel,
   AttachMoney,
  } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Typography, IconButton, Tooltip, TextField, makeStyles } from '@material-ui/core';
import { placeRoutes } from '../../routes';

// Components
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import PlacezFixturePlan from '../../api/placez/models/PlacezFixturePlan';
import { tableStyles } from './tableSyles.css';
import { BillingRate } from '../../blue/core/utils';
import { userIsInRole } from '../../sharing/utils/userHelpers';
import { AreYouSureDelete } from '../Modals/UniversalModal';
import { UniversalModalWrapper } from '../Modals/UniversalModalWrapper';
import PhotosphereIcon from '../../assets/icons/Photosphere'

interface Props {
  fixturePlans: PlacezFixturePlan[];
  onDeleteFloorPlan: (floorPlanId: string) => void;
  onCopyFloorPlan: (floorPlan: PlacezFixturePlan) => void;
  isFloorPlanInUse: (floorPlanId: string) => boolean;
  onMoveFloorPlan: (floorPlan: PlacezFixturePlan) => void;
  onEditFloorPlan: (floorPlan: PlacezFixturePlan) => void;
  onEditPrice: (floorPlan: PlacezFixturePlan) => void;
  user: any;
  globalFilter: string;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ...tableStyles(theme),
    iconButton: {
      height: 20,
      width: 20,
      padding: 2,
      fontSize: 16,
      margin: 0,
    },
    floorPlanInUseWarning: {
      color: theme.palette.primary.main,
    },
    planItemEditContainer: {
    },
  })
);

const FloorplansTable = (props: Props) => {

  const [sort, setSort] = useState([]);
  const [planItemNameInEdit, setPlanItemNameInEdit] = useState(undefined);
  const [newPlanItemName, setNewPlanItemName] = useState(undefined);

  const handlePlanItemNameChange = e => {
    setNewPlanItemName(e.target.value);
  }

  const editName = (id: string, name: string) => {
    setNewPlanItemName(name);
    setPlanItemNameInEdit(id);
  }

  const savePlanItemName = floorplan => {
    props.onEditFloorPlan({
      ...floorplan,
      name: newPlanItemName,
    });
    setNewPlanItemName(undefined);
    setPlanItemNameInEdit(undefined);
  }

  const classes = styles(props);

  const floorplanButtonsCell = gridCellProps => (
    <FloorplanButtonsCell
      {...gridCellProps}
      onCopyFloorPlan={props.onCopyFloorPlan}
      onDeleteFloorPlan={props.onDeleteFloorPlan}
      onMoveFloorPlan={props.onMoveFloorPlan}
      planItemNameInEdit={planItemNameInEdit}
      savePlanItemName={savePlanItemName}
      onEditPrice={props.onEditPrice}
      editName={editName}
      newPlanItemName={newPlanItemName}
      classes={classes}
      user={props.user}
    />
  )

  const viewFloorplanByNameCell = props => (
    <ViewFloorplanByNameCell
      {...props}
      planItemNameInEdit={planItemNameInEdit}
      newPlanItemName={newPlanItemName}
      handlePlanItemNameChange={handlePlanItemNameChange}
    />
  )

  const {
    fixturePlans,
  } = props;


  return (
    <Grid
      sortable
      sort={sort}
      onSortChange={e => {
        setSort(e.sort);
      }}
      className={classes.gridRoot}
      selectedField="selected"
      data={orderBy(
        fixturePlans
        .filter(fixturePlan => fixturePlan.name.toLowerCase().includes(props.globalFilter?.toLowerCase()))
        .map(
          floorplan => ({ ...floorplan })), sort)
      }>
      <GridColumn
        field="name"
        title="Name"
        cell={viewFloorplanByNameCell}
        className={`${classes.LinkStyle} ${classes.overflowEllipsis}`}
      />
      <GridColumn
        cell={floorplanButtonsCell}
        className={classes.iconButton}
        width="160"/>
    </Grid>
  );
}

interface viewFloorplanByNameCellProps extends Props, GridCellProps {
  planItemNameInEdit;
  newPlanItemName;
  handlePlanItemNameChange;
}

const ViewFloorplanByNameCell = (props: viewFloorplanByNameCellProps) =>{
  const { name, id, placeId, price, priceRateInHours, photoSpheres } = props.dataItem;
  const { planItemNameInEdit, newPlanItemName, handlePlanItemNameChange, user } = props;
  const classes = styles(props);
  return (
    <td style={{paddingLeft: '12px'}}>
      {id === planItemNameInEdit ?
          <TextField
            className={classes.fullWidthField}
            id="title"
            autoFocus
            value={newPlanItemName}
            onChange={handlePlanItemNameChange}
            inputProps={{
              maxLength: 200,
            }}
          />
        :
        <div style={{display: 'flex', alignItems: 'center'}}>
        <Tooltip title={name}>
          <Link to={placeRoutes.editFloorPlan.path.replace(':id', placeId).replace(':floorPlanId', id)} >
            {price !== 0 &&
              <Typography>{name ? name : 'Default'}{` ($${price} ${BillingRate[priceRateInHours]})` ?? ''}</Typography>
            }
            { price === 0 &&
              <Typography>{name ? name : 'Default'}</Typography>
            }
          </Link>
        </Tooltip>
          {photoSpheres && photoSpheres.length > 0 && <PhotosphereIcon fontSize="inherit" style={{margin: '5px'}}/>}
        </div>
      }
    </td>
  );
}

interface FloorplansButtonCellProps extends Props, GridCellProps {
  planItemNameInEdit;
  savePlanItemName;
  editName;
}
const FloorplanButtonsCell = (props: FloorplansButtonCellProps) => {
  const {
    onCopyFloorPlan,
    onDeleteFloorPlan,
    dataItem,
    onMoveFloorPlan,
    planItemNameInEdit,
    savePlanItemName,
    editName,
    onEditPrice,
  } = props;
  const classes = styles(props);
  const { id } = dataItem;
  return (
    <td>
      {id === planItemNameInEdit ?
        <>
            <Tooltip title="Save Name">
              <IconButton
                key={`save-${id}`}
                className={classes.iconButton}
                onClick={() => savePlanItemName(dataItem)}>
                <Save fontSize="inherit"/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancle Name">
              <IconButton
                key={`cancle-${id}`}
                className={classes.iconButton}
                onClick={() => editName(undefined, undefined)}>
                <Cancel fontSize="inherit"/>
              </IconButton>
            </Tooltip>
        </>
        :
        <div
          style={{
            display: 'flex',
            alignItems: 'stretch',
            minWidth: '200px !important',
            textAlign: 'end',
            padding: '0px 6px 0px 0px',
          }}
        >
          <Tooltip title="Edit Name">
            <IconButton
              key={`edit-${id}`}
              className={classes.iconButton}
              onClick={() => editName(id, dataItem.name)}
              >
              <EditIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          { props.user && userIsInRole(props.user, 'admin') &&
            <Tooltip title="Set Price">
              <IconButton
                className={classes.iconButton}
                onClick={() => { onEditPrice(dataItem); }}>
                <AttachMoney fontSize="inherit"/>
              </IconButton>
            </Tooltip>
          }
          <Tooltip title="Move Floorplan">
            <IconButton
              className={classes.iconButton}
              onClick={() => { onMoveFloorPlan(dataItem); }}>
              <Send fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Clone Floorplan">
            <IconButton
              className={classes.iconButton}
              onClick={() => { onCopyFloorPlan(dataItem); }}>
              <CopyIcon fontSize="inherit"/>
            </IconButton>
          </Tooltip>
          <UniversalModalWrapper
            onDelete= {() => onDeleteFloorPlan(id)}
            modalHeader='Are you sure?'
          >
            <IconButton
              className={classes.iconButton}
              >
              <DeleteIcon fontSize="small"/>
            </IconButton>
            {AreYouSureDelete('Floorplan')}
          </UniversalModalWrapper>
        </div>
      }
    </td>
  );
}

export default FloorplansTable;
