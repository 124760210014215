import React, { Component, useEffect, useState } from 'react';
import {
  Theme,
} from '@material-ui/core/styles';

import {
  Button, TextField,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';

import classnames from 'classnames';
import { Utils } from '../../blue/core/utils';
import * as math from 'mathjs';
import { makeStyles, createStyles } from '@material-ui/styles'
import { LocalStorageKey, useLocalStorageSelector } from '../Hooks/useLocalStorageState'
import { ValidUnits } from '../../api/placez/models/UserSetting'
import NumberFormat from 'react-number-format'

const borderColor = '#FFFFFF';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      minWidth: '0px',
      justifyContent: 'space-between',
    },
    buttonGroup: {
      display: 'flex',
      flex: 1,
      minWidth: '0px',
      justifyContent: 'center',
    },
    buttonGroupStart: {
      display: 'flex',
      flex: 1,
      minWidth: '0px',
      justifyContent: 'start',
    },
    buttonDark: {
      backgroundColor: theme.palette.background.paper,
      '&:hover': {
        background: 'rgba(42, 44, 50, 0.4)',
      },
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.contrastText,
      minWidth: 'unset',
      padding: 0,
    },
    buttonIcon: {
      color: theme.palette.text.primary,
    },
    button: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.secondary.main,
      },
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.primary,
      minWidth: 'unset',
      padding: 0,
    },
    buttonLeft: {
      borderRadius: '4px 0 0 4px',
    },
    buttonRight: {
      borderRadius: '0 4px 4px 0',
    },
    inputDark: {
      textAlign: 'center',
      background: 'transparent',
      color: theme.palette.text.primary,
      outline: 'none',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      '&:focus': {
        borderColor,
        outline: 'none',
      },
      minWidth: '44px',
      maxWidth: '44px',
      boxShadow: '0px 0px 0px 0.2px rgba(0, 0, 0, 0.87)',
      marginTop: '1px',
      marginBottom: '1px',
    },
    input: {
      textAlign: 'center',
      width: '50px',
      background: 'transparent',
      color: theme.palette.text.primary,
      outline: 'none',
      position: 'relative',
      backgroundColor: theme.palette.secondary.main,
      border: 'none',
      '&:focus': {
        borderColor,
        outline: 'none',
      },
      minWidth: '44px',
      maxWidth: '44px',
      boxShadow: '0px 0px 0px 0.2px rgba(0, 0, 0, 0.87)',
      marginTop: '1px',
      marginBottom: '1px',
    },
  })
);

type Props = {
  value: number; // Assumes this number is in cm
  round?: number;
  step?: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  dark?: boolean;
  allowZero?: boolean;
  unitless?: boolean;
};

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.stopPropagation();
    setTimeout(() => {
      e.target.select();
    }, 0);
  };

const NumberEditor = (props: Props) => {
  const units = useLocalStorageSelector<ValidUnits>(LocalStorageKey.Units);

  const valueToString = (value: number): string => {
    if (props.unitless) {
      return `${Utils.roundDigits(value, props.round ? props.round : 0)}`;
    } else {
      return Utils.unitsOutString(value)
    }
  }

  const valueToFeet = (value: number): number => {
    const dimension = math.unit(value, 'cm');
    const split = dimension.splitUnit(['ft', 'in']);
    return split[0].toNumber('ft');
  }

  const valueToInch = (value: number): number => {
    const dimension = math.unit(value, 'cm');
    const split = dimension.splitUnit(['ft', 'in']);
    return split[1].toNumber('in');
  }


  const [stringValue, setStringValue] = useState(valueToString(props.value));
  const [feet, setFeet] = useState<number>(valueToFeet(props.value));
  const [inch, setInch] = useState<number>(valueToInch(props.value));

  useEffect(() => {
    setStringValue(valueToString(props.value))
    setFeet(valueToFeet(props.value))
    setInch(valueToInch(props.value))
  }, [props.value])

  const parseInputValue = (value: string, unit?: string) => {
    setStringValue(value);
    let out;
    try {
      out = math.evaluate(value)
    } catch (error) {
    }
    if (out) {
      if (props.unitless) {
        props.onChange(out.toNumber());
      } else if (!props.unitless && unit) {
        props.onChange(out.convert(unit).toNumber('cm'));
      } else {
        props.onChange(out.toNumber('cm'));
      }
    }
  }

  const unitChange = (change: string) => () => {
    if (props.unitless) {
      props.onChange(props.value + 1)
    } else {
      const out = math.evaluate(`${stringValue} ${change}`).toNumber('cm');
      props.onChange(out);
    }
  }

  const feetChange = (change: string) => () => {
      const out = math.evaluate(`${feet}ft ${change} + ${inch}in`).toNumber('cm');
      props.onChange(out);
  }

  const inchChange = (change: string) => () => {
    const out = math.evaluate(`${feet}ft  + ${inch}in ${change}`).toNumber('cm');
    props.onChange(out);
  }

  const unitlessChange = (change: number) => () => {
    props.onChange(props.value + change)
  }

  const classes = styles(props);

  return (
    <div className={classes.root}>
      {props.unitless &&
        <>
          <div className={classes.buttonGroup}>
          <Button
              variant="outlined"
            className={props.dark ? classnames(classes.buttonDark, classes.buttonLeft) : classnames(classes.button, classes.buttonLeft)}
            onClick={props.disabled ? () => {} : unitlessChange(-1)}>
            <RemoveIcon className={classes.buttonIcon}/>
          </Button>
            <NumberFormat
              disabled={props.disabled}
              className={props.dark ? classnames(classes.inputDark) : classnames(classes.input)}
              value={stringValue}
              defaultValue={0}
              decimalScale={0}
              displayType={props.disabled ? 'text' : 'input'}
              decimalSeparator={'.'}
              onChange={e => { const out = math.evaluate(e.target.value).toNumber(); props.onChange(out)}}
              onFocus={handleFocus}
            />
          <Button
              variant="outlined"
            className={props.dark ? classnames(classes.buttonDark, classes.buttonRight) : classnames(classes.button, classes.buttonRight)}
            onClick={props.disabled ? () => {} : unitlessChange(+1)}>
            <AddIcon className={classes.buttonIcon}/>
          </Button>
          </div>
          <div className={classes.buttonGroup}></div>
        </>
      }
      {units !== ValidUnits.ftIn && !props.unitless &&
        <>
          <div className={classes.buttonGroup}>
          <Button
              variant="outlined"
            className={props.dark ? classnames(classes.buttonDark, classes.buttonLeft) : classnames(classes.button, classes.buttonLeft)}
            onClick={props.disabled ? () => {} : unitChange(`- 1${units}`)}>
            <RemoveIcon className={classes.buttonIcon}/>
          </Button>
            <NumberFormat
              disabled={props.disabled}
              className={props.dark ? classnames(classes.inputDark) : classnames(classes.input)}
              value={stringValue}
              suffix={units}
              defaultValue={0}
              decimalScale={units === ValidUnits.ft ? 2 : 0}
              displayType={props.disabled ? 'text' : 'input'}
              decimalSeparator={'.'}
              onChange={e => { const out = math.evaluate(e.target.value).toNumber('cm'); props.onChange(out)}}
              onFocus={handleFocus}
            />
          <Button
              variant="outlined"
            className={props.dark ? classnames(classes.buttonDark, classes.buttonRight) : classnames(classes.button, classes.buttonRight)}
            onClick={props.disabled ? () => {} : unitChange(`+ 1${units}`)}>
            <AddIcon className={classes.buttonIcon}/>
          </Button>
          </div>
          <div className={classes.buttonGroup}></div>
        </>
      }
      {units === ValidUnits.ftIn && !props.unitless &&
        <>
          <div className={classes.buttonGroup}>
            <Button
              variant="outlined"
              className={props.dark ? classnames(classes.buttonDark, classes.buttonLeft) : classnames(classes.button, classes.buttonLeft)}
              onClick={props.disabled ? () => {} : feetChange(`- 1ft`)}>
              <RemoveIcon className={classes.buttonIcon}/>
            </Button>
              <NumberFormat
                disabled={props.disabled}
                className={props.dark ? classnames(classes.inputDark) : classnames(classes.input)}
                suffix={'ft'}
                value={feet}
                defaultValue={0}
                decimalScale={0}
                displayType={props.disabled ? 'text' : 'input'}
                decimalSeparator={'.'}
                onChange={e => { const out = math.evaluate(`${e.target.value ?? '0ft'} + ${inch}in`).toNumber('cm'); props.onChange(out)}}
                onFocus={handleFocus}
              />
            <Button
              variant="outlined"
              className={props.dark ? classnames(classes.buttonDark, classes.buttonRight) : classnames(classes.button, classes.buttonRight)}
              onClick={props.disabled ? () => {} : feetChange(`+ 1ft`)}>
              <AddIcon className={classes.buttonIcon}/>
            </Button>
          </div>

          <div className={classes.buttonGroup}>
            <Button
              size="small"
              variant="outlined"
              className={props.dark ? classnames(classes.buttonDark, classes.buttonLeft) : classnames(classes.button, classes.buttonLeft)}
              onClick={props.disabled ? () => {} : inchChange(`- 1in`)}>
              <RemoveIcon className={classes.buttonIcon}/>
            </Button>
              <NumberFormat
                disabled={props.disabled}
                className={props.dark ? classnames(classes.inputDark) : classnames(classes.input)}
                value={inch}
                suffix={'in'}
                defaultValue={0}
                decimalScale={0}
                displayType={props.disabled ? 'text' : 'input'}
                decimalSeparator={'.'}
                onChange={e => { const out = math.evaluate(`${feet}ft  + ${e.target.value ?? '0in'}`).toNumber('cm'); props.onChange(out)}}
                onFocus={handleFocus}
              />
            <Button
              size="small"
              variant="outlined"
              className={props.dark ? classnames(classes.buttonDark, classes.buttonRight) : classnames(classes.button, classes.buttonRight)}
              onClick={props.disabled ? () => {} : inchChange(`+ 1in`)}>
              <AddIcon className={classes.buttonIcon}/>
            </Button>
          </div>
        </>
      }
    </div>
  );
}

export default NumberEditor;
