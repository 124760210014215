import React from 'react';
import withModalContext, { WithModalContext } from '../withModalContext';

import {
  WithStyles,
  withStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Dialog,
} from '@material-ui/core';
import AttendeeForm from './AttendeeForm';
import { Attendee } from '../../../api';
import modalStyles from '../../Blue/models/modalStyles.css';

interface Props extends WithModalContext, WithStyles<typeof modalStyles> {
}

const AttendeeModal = (modalProps: Props) => {
  const { classes } = modalProps;
  return(
    <Dialog
      className={classes.dialog}
      open={true}
      aria-labelledby="place-modal"
      maxWidth="lg"
      fullWidth={true}
      scroll="paper"
    >
      <AttendeeForm
        modalContext={modalProps.modalContext} />
    </Dialog>
  );
};

export default withModalContext(withStyles(modalStyles)(AttendeeModal));
