import { Button, createStyles, FormControl, IconButton, makeStyles, MenuItem, Select, TextField, Theme, Tooltip, Typography, useTheme } from "@material-ui/core"
import { AddCircleOutline, Cancel, Delete, Edit, FileCopy, Print, Save } from "@material-ui/icons";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import PlacezFixturePlan from "../../api/placez/models/PlacezFixturePlan";
import PlacezLayoutPlan from "../../api/placez/models/PlacezLayoutPlan";
import { ReduxState } from "../../reducers";
import { CreateLayout, DeleteLayout, UpdateLayout } from "../../reducers/layouts";
import { sceneRoutes, placeRoutes } from "../../routes";
import { ModalConsumer } from "../Modals/ModalContext";
import PlaceLayoutModal from "../Modals/PlaceLayoutModal";
import { AreYouSureDelete } from "../Modals/UniversalModal";
import { toast } from 'react-toastify';
import SceneDetail from "../../api/placez/models/SceneDetail";
import { UniversalModalWrapper } from "../Modals/UniversalModalWrapper";
import { SimpleModal } from "../Modals/SimpleModal";
import PrintLayoutsModal from "../Modals/PrintLayoutModal";

interface Props{
  scene: SceneDetail;
  layouts: PlacezLayoutPlan[];
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    fieldRowForLayout: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    iconButton: {
      height: 20,
      width: 20,
      padding: 2,
      fontSize: 16,
    },
    leftContent: {
      justifyContent: 'flex-start',
      textAlign: 'start',
    },
    rightContent: {
      justifyContent: 'flex-end',
      textAlign: 'end',
    },
    planContainerLeft: {
      width: '68%',
    },
    planContainerRight: {
      width: '28%',
    },
    planItemEditContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      alignItems: 'center',
    },
    floorplanEditContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 1,
      alignItems: 'center',
    },
    planItemButton: {
      ...theme.typography.body1,
      textTransform: 'none',
      width: '100%',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '2px 0px',
    },
    overflowEllipsis: {
      whiteSpace: 'normal',
      overflowWrap: 'break-word',
      wordBreak: 'break-word',
      display: 'block',
      width: '98%',
    },
    planHeader: {
      ...theme.typography.body1,
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      borderBottom: `2px solid ${theme.palette.divider}`,
      fontSize: 14,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tableHeader: {
      ...theme.typography.h2,
      fontSize: '20px',
      margin: 0,
      height: 55,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flex: 1,
    },
    planItem: {
      borderBottom: `2px solid ${theme.palette.divider}`,
      display: 'flex',
      alignItems: 'center',
      margin: '2px 0px',
      padding: '2px 0px',
    },
    layoutEditContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 2,
      alignItems: 'center',
    },
    fullwidthfield: {
      gridColumn: 'span 4',
      margin: '4px 0px',
      color: 'white',
    },
  })
);

const CopyLayoutModalBody = (props) => {
  const {newLayoutName, setNewLayoutName, layoutName} = props;
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '500px',
    }}>
      <div style={{alignSelf: 'stretch', display: 'flex'}}>
        <TextField
          id="New Layout Name"
          autoFocus
          label={'New Layout Name (Optional)'}
          value={newLayoutName}
          onChange={(e) => {setNewLayoutName(e.target.value)}}
          style={{flex: 1}}
          inputProps={{
            maxLength: 200,
          }}
        />
      </div>
      Press continue to copy {layoutName}.
    </div>
  );
}

export const LayoutsAndFloorplans = (props: Props) => {
  let toastId = null;

  const dispatch = useDispatch();
  const classes = styles(props);
  const navigate = useNavigate();

  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);

  const [newPlanItemName, setNewPlanItemName] = useState<string>(undefined);
  const [planItemNameInEdit, setPlanItemNameInEdit] = useState<string>(undefined);
  const [floorplanInEdit, setFloorplanInEdit] = useState<string>(undefined);
  const [newLayoutName, setNewLayoutName] = useState<string>(undefined);

  const floorplans = useSelector((state: ReduxState) => state.floorPlans.unsorted);
  const floorplansById = useSelector((state: ReduxState) => state.floorPlans.byId);

  const floorplanNames = floorplans
    .filter((floorplan: PlacezFixturePlan) =>
      floorplan && floorplan?.name?.toLowerCase().includes(globalFilter?.toLowerCase())
    )

  const editName = (id: string, name: string) => {
    setNewPlanItemName(name);
    setPlanItemNameInEdit(id);
  }

  const savePlanItemName = layout => {
    const updatedLayout = {
      ...layout,
      name: newPlanItemName,
    };
    dispatch(UpdateLayout(updatedLayout));
    setNewPlanItemName(undefined);
    setPlanItemNameInEdit(undefined);
  }

  const handlePlanItemNameChange = e => {
    setNewPlanItemName(e.target.value);
  }

  const changeFloorplan = (layout: PlacezLayoutPlan, floorPlanId: string) => {
    const updatedLayout = {
      ...layout,
      floorPlanId,
    };
    dispatch(UpdateLayout(updatedLayout));
    setFloorplanInEdit(undefined);
  }

  const goToSpecificPlan = planId => navigate(
    sceneRoutes.planner.path
      .replace(':id', props.scene.id.toString())
      .replace(':planId', planId.toString())
  )

  const ScenesToast = () => (
    <div style={{ fontSize: '14px', color: '#000', textAlign: 'center' }}>You must select a Place before designing new Layout.</div>
  );

  const checkPlace = (): boolean => {
    if (!props.scene.placeId) {
      if (!toast.isActive(toastId)) {
        toastId = toast(<ScenesToast />);
      }
      return false;
    }

    return true;
  }

  const goToPlanner = () => navigate(
    sceneRoutes.planner.path
      .replace(':id', props.scene.id.toString())
      .replace(':planId', '')
  )

  const onEditLayout = () => {
    if (checkPlace()) {
      goToPlanner();
    }
  }

  const onCopyLayout = (layout: PlacezLayoutPlan, newLayoutName?: string) => {
    if (checkPlace()) {
      const layoutCopy = {
        ...layout,
        name: newLayoutName ? newLayoutName : `${layout.name} - Copy`,
      };
      layoutCopy.id = null;

      dispatch(CreateLayout(layoutCopy));
      setNewLayoutName(undefined);
    }
  }

  const onDeleteLayout = (sceneLayoutId: string) => {
    if (sceneLayoutId) {
      dispatch(DeleteLayout(sceneLayoutId));
    }
  }

  const goToSpecificFloor = (placeId: number, floorPlanId: string) => navigate(
    placeRoutes.editFloorPlan.path
      .replace(':id', placeId.toString())
      .replace(':floorPlanId', floorPlanId.toString())
  )


  return (
    <div className={classes.fieldRowForLayout}>
          <div style={{margin: '10px', width: '100%'}}>
            <div className={classes.planHeader}>
              <div style={{flex: 2, display: 'flex'}}>
                <h2 className={classes.tableHeader}>
                  Layouts
                  <SimpleModal>
                    <IconButton
                      disabled={!props?.layouts || props.layouts?.length < 1}
                      className={classes.iconButton}>
                      <Tooltip title="Share Layout">
                        <Print />
                      </Tooltip>
                    </IconButton>
                    <PrintLayoutsModal
                      layouts={props.layouts}
                      scene={props.scene}
                    />
                  </SimpleModal>
                </h2>
                <ModalConsumer>
                  { ({ showModal, props }) =>
                    <Tooltip title="Add New Layout">
                      <IconButton aria-label="New floorplan"
                        disabled={floorplans.length === 0}
                        onClick={() => showModal(
                          PlaceLayoutModal,
                          {
                            ...props,
                          })}
                        >
                        <AddCircleOutline fontSize="large"/>
                      </IconButton>
                    </Tooltip>
                  }
                </ModalConsumer>
              </div>
              <h2 className={classes.tableHeader} style={{justifyContent:"flex-end"}}>
                  Floorplans
              </h2>
            </div>
              { props?.layouts?.length > 0 && floorplans?.length > 0 &&
              props.layouts
                .filter(layout => layout.name.toLowerCase().includes(globalFilter?.toLowerCase()))
                .map((layout, index) => {
                const floorPlan = floorplansById[layout.floorPlanId];
                return (
                <div
                  key={`layout-${layout.id}-${index}`}
                  className={classes.planItem}>
                  {layout.id === planItemNameInEdit ?
                    <div className={classes.layoutEditContainer}>
                      <TextField
                        className={classes.fullWidthField}
                        id="title"
                        autoFocus
                        value={newPlanItemName}
                        onChange={handlePlanItemNameChange}
                        inputProps={{
                          maxLength: 200,
                        }}
                      />
                      <>
                        <Tooltip title="Save Name">
                          <IconButton
                            key={`copy-${index}`}
                            className={classes.iconButton}
                            onClick={() => savePlanItemName(layout)}>
                            <Save fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancle Name">
                          <IconButton
                            key={`copy-${index}`}
                            className={classes.iconButton}
                            onClick={() => editName(undefined, undefined)}>
                            <Cancel fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                      </>
                    </div>
                    :
                    <div className={classes.layoutEditContainer}>
                      <Button
                        key={`goto-${index}`}
                        onClick={() => goToSpecificPlan(layout.id)}
                        className={classes.planItemButton}
                        classes={{
                          label: `${classes.overflowEllipsis} ${classes.leftContent}`,
                        }}>
                        {layout.name}
                      </Button>
                      {layout.hideInInvoice ? '' :
                        <Tooltip
                          title="Inclued In Invoice">
                          <div>$</div>
                        </Tooltip>
                      }
                      <Tooltip title="Edit Layout Name">
                        <IconButton
                          key={`edit-${index}`}
                          onClick={() => editName(layout.id, layout.name)}
                          className={classes.iconButton}>
                          <Edit fontSize="inherit"/>
                        </IconButton>
                      </Tooltip>
                      <UniversalModalWrapper
                        onContinue= {() => onCopyLayout(layout, newLayoutName)}
                        modalHeader='Clone Layout'
                      >
                        <Tooltip title="Copy Layout">
                          <FileCopy fontSize="inherit"/>
                        </Tooltip>
                        <CopyLayoutModalBody
                          layoutName={layout.name}
                          newLayoutName={newLayoutName}
                          setNewLayoutName={setNewLayoutName}
                        />
                      </UniversalModalWrapper>
                      <UniversalModalWrapper
                        onDelete= {() => onDeleteLayout(layout.id)}
                        modalHeader='Are you sure?'
                      >
                        <Tooltip title="Delete Layout">
                          <Delete fontSize="inherit"/>
                        </Tooltip>
                        {AreYouSureDelete('SceneLayout')}
                      </UniversalModalWrapper>
                    </div>
                  }
                  <hr className={classes.hr}></hr>
                  <div
                    key={`floorplan-${layout.floorPlanId}-${index}`}
                    className={classes.floorplanEditContainer}>
                    {floorplanInEdit !== layout.id &&
                      <div className={classes.planItemEditContainer}>
                        <Typography variant="body2">
                          {floorPlan && floorPlan.name ? floorPlan.name : 'Default'}
                        </Typography>
                        {/* <Button
                          key={`goto-floorplan-${index}`}
                          className={classes.planItemButton}
                          classes={{
                            label: `${classes.overflowEllipsis} ${classes.leftContent}`,
                          }}
                          onClick={() => goToSpecificFloor(floorPlan.placeId, floorPlan.id)}
                        >
                          {floorPlan && floorPlan.name ? floorPlan.name : 'Default'}
                        </Button> */}
                        <Tooltip title="Edit Name">
                          <IconButton
                            key={`edit-${index}`}
                            onClick={() => {
                              setFloorplanInEdit(layout.id);
                            }}
                            className={classes.iconButton}>
                            <Edit fontSize="inherit"/>
                          </IconButton>
                        </Tooltip>
                      </div>
                    }
                    {floorplanInEdit === layout.id &&
                      <div className={classes.planItemEditContainer}>
                        <FormControl style={{flex: 1}}>
                          <Select
                            value={layout.floorPlanId}
                            onChange={(e)=>{
                              changeFloorplan(layout, (e.target.value as string));
                            }}
                          >
                            { floorplanNames?.map((floorplan: PlacezFixturePlan) => {
                                return (<MenuItem key={floorplan.id} value={floorplan.id}>{floorplan.name}</MenuItem>);
                              })
                            }
                          </Select>
                        </FormControl>
                      </div>
                    }
                  </div>
                </div>)
              })
            }
          </div>
    </div>
  )

}
