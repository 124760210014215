import { Paper, Theme, Tooltip, makeStyles } from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "../../../../../reducers"
import { SetIgnoreFixed } from "../../../../../reducers/blue"
import viewOptionsStyles from "./ViewOptions.css"
import classNames from "classnames"
import { GlobalViewState, FixtureViewState } from '../../../../../models/GlobalState';

import {
  Lock,
  LockOpen,
} from '@material-ui/icons';
import { canEditLayout } from "../../../../../reducers/globalState"


interface Props {};

const LockToggle = (props: Props) => {
  const ignoreFixed = useSelector((state: ReduxState) => state.blue.ignoreFixed)
  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState)
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState)
  const canEdit = useSelector((state: ReduxState) => canEditLayout(state))

  const styles = makeStyles<Theme>(viewOptionsStyles);
  const classes = styles(props);



  const dispatch = useDispatch();

  const floorPlanMode = globalViewState === GlobalViewState.Fixtures &&
    fixtureViewState === FixtureViewState.Floorplan

  return (<>
    { (globalViewState === GlobalViewState.Fixtures || canEdit) &&
      !floorPlanMode &&
      <Paper
        className={classNames(classes.border, classes.viewToggles)}
        style={{ marginBottom: '5px' }}
        >
        <ToggleButton
          value={ignoreFixed}
          name="labelSelected"
          aria-label="label"
          selected={ignoreFixed}
          onClick={() => dispatch(SetIgnoreFixed(!ignoreFixed))}
          classes={{
            root: classes.button,
            selected: classes.selected,
          }}
          >
          {ignoreFixed &&
            <Tooltip title="Locks Enabled">
              <Lock/>
            </Tooltip>
          }
          {!ignoreFixed &&
            <Tooltip title="Locks Disabled">
              <LockOpen/>
            </Tooltip>
          }
        </ToggleButton>
      </Paper>
    }
  </>)
}

export default LockToggle;
