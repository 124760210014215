import { all, takeLatest, put, call, select, take, race, delay } from 'redux-saga/effects';
import { getFromLocalStorage, saveToLocalStorage } from '../sharing/utils/localStorageHelper';
import { SagaReady } from '../reducers/lifecycle';
import { damApi, Scene, Attendee } from '../api';
import { SeatInstance } from '../blue/itemModifiers/ChairMod';

import blue, {
  types,
  InitDesignerAction,
  SetCameraTypeAction,
  SetShaderViewAction,
  SetShaderViewSuccess,
  SetShaderViewFailure,
  SetControlsTypeAction,
  SetAutoRotateAction,
  ScreenshotAction,
  ScreenshotSuccess,
  ScreenshotFailure,
  SetPhotosphereAction,
  getBlueInitialized,
  ChangeCameraLayersAction,
  ToastMessageAction,
  ToastMessage,
  InitBatchItemAction,
  UpdateItemAction,
  HideChairAction,
  BlueInitAction,
  Save,
  NeedSaveAction,
  SavingAction,
  getSaveDebounceTimeSeconds,
  needSave,
  SetSelectedItems,
  sceneScanLoadedAction,
  CancelBatchAction,
  ApplyBatchAction,
  SetBatchSettingsAction,
  SetSelectedItemsAction,
  getSelectedItems,
  CancelBatch,
  SetSelectedSurfaces,
  ZoomInAction,
  RotateCameraAction,
  FitToViewAction,
  SetControlsTypeSuccess,
  ChangeCopiedAssetsState,
  } from '../reducers/blue';
import {
  types as attendeeTypes, GetAttendeesByLayoutId,
} from '../reducers/attendee';
import {
  types as labelTypes, GetLabels
} from '../reducers/label';
import {
  types as globalStateTypes, ChangeFixtureViewState, getGlobalViewState, getFixtureViewState, getLayoutViewState,
} from '../reducers/globalState';
import { FixtureViewState, PlannerTool, SaveBarState, ToolState, } from '../models/GlobalState';
import { UpdateScene } from '../reducers/scenes';
import { CameraType, ShaderView, ControlsType } from '../components/Blue/models';
import { types as TypesOfLayoutsReducer, UpdateLayoutFailure, UpdateLayoutSuccess, CreateLayoutSuccess, CreateLayoutFailure } from '../reducers/layouts';
import { types as TypesOfFloorPlanReducer, UpdateFloorPlanFailure, UpdateFloorPlanSuccess, CreateFloorPlanSuccess, CreateFloorPlanFailure, UpdateFloorPlan, UpdateFloorPlanAction } from '../reducers/floorPlans';
import { types as TypesOfDesignerReducer, getLayout, getFloorPlan as getDesignerFloorPlan, RequireNewDesignExport, ResetLayout, ResetFixturePlan, GetDesignerFloorPlanSuccess, getFloorPlan, SetFloorPlan, SetLayout } from '../reducers/designer';
import { escapeJSON } from '../components/Utils';
import { ReduxState } from '../reducers';
import { GlobalViewState } from '../models/GlobalState';
import PlacezFixturePlan from '../api/placez/models/PlacezFixturePlan';
import PlacezLayoutPlan from '../api/placez/models/PlacezLayoutPlan';
import { Asset } from '../blue/items';
import PlacezCameraState from '../api/placez/models/PlacezCameraState';
import { DimensionMaker } from '../blue/three/dimensionMaker';
import { ToastStateService } from '../components/Blue/components/toast';
import { store } from '..';
import { DefaultFloorMaterialPromise, DefaultWallMaterialPromise } from '../api/placez/models/PlacezMaterial';
import { Item } from '../blue/items/item';
import { Matrix4, Vector2 } from 'three';
import asset from '../reducers/asset';
import { CameraLayers, ControllerType, ProhibitedLayers, RequiredLayers } from '../models/BlueState';
import { SceneScan } from '../blue/items/sceneScan';
import { Camera } from '@material-ui/icons';
import { Utils } from '../blue/core/utils';
import { SkuType } from '../blue/items/asset'
import { PlacezShape } from '../blue/shapes/placezShapes'
import { AttendeeSkuTypes } from '../blue/three/attendeeController'

const layoutScene = (state: ReduxState) => {
  const sceneId = state.designer && state.designer.layout && state.designer.layout.sceneId;
  if (sceneId) {
    return state.scenes.byId[sceneId] as Scene;
  }

  return undefined;
};

let designer: any;

export default function* blueSaga() {
  yield all([
    takeLatest(types.LOAD_LAYOUT, loadLayout),
    takeLatest(types.LOAD_FIXTURE_PLAN, loadFixturePlan),
    takeLatest(types.INIT_DESIGNER, initDesigner),
    takeLatest(types.SET_CAMERA_TYPE, setCameraType),
    takeLatest(types.SET_SHADER_VIEW, setShaderView),
    takeLatest(types.SET_CONTROLS_TYPE, setControlsType),
    takeLatest(types.SET_AUTO_ROTATE, setAutoRotate),
    takeLatest(types.SCREENSHOT, screenshot),
    takeLatest(types.SET_PHOTOSPHERE, setPhotosphere),
    takeLatest(types.SAVE, save),
    takeLatest(types.NEED_SAVE, debounceSave),
    takeLatest(types.SAVE_LAYOUT, saveLayout),
    takeLatest(types.SAVE_FIXTURE, saveFixture),
    takeLatest(types.SAVE_FLOORPLAN, saveFloorplan),
    takeLatest(types.RESET, reset),
    takeLatest(types.CAMERA_LAYERS_STATE, changeCameraLayersState),
    takeLatest(attendeeTypes.UPDATE_ATTENDEE_SUCCESS, refreshAttendeeList),
    takeLatest(attendeeTypes.UPDATE_ATTENDEES_SUCCESS, refreshAttendeeList),
    takeLatest(attendeeTypes.DELETE_ATTENDEE_SUCCESS, refreshAttendeeList),
    takeLatest(attendeeTypes.SELECT_ATTENDEE, refreshAttendeeList),
    takeLatest(types.TOAST_MESSAGE, toastMessage),
    takeLatest(types.SET_TABLE_NUMBER, tableNumber),
    takeLatest(types.INCREMENT_NEXT_TABLE_NUMBER, tableNumber),
    takeLatest(types.INIT_BATCH_ITEM, initBatchItem),
    takeLatest(types.TAKE_EQUIRECTANGULAR_PHOTO, takePhotosphere),
    takeLatest(types.UPDATE_ITEM, updateItem),
    takeLatest(types.HIDE_CHAIR, hideChair),
    takeLatest(types.DISPOSE_DESIGNER, disposeDesigner),
    takeLatest(types.CANCEL_BATCH, cancelBatch),
    takeLatest(types.APPLY_BATCH, applyBatch),
    takeLatest(types.SET_BATCH_SETTINGS, setBatchSettings),
    takeLatest(types.SELECTED_ITEMS, selectedItems),
    takeLatest(types.SELECTED_SURFACES, selectedSurface),
    takeLatest(types.ZOOM_IN, zoomIn),
    takeLatest(types.ZOOM_OUT, zoomOut),
    takeLatest(types.FIT_TO_VIEW, fitToView),
    takeLatest(types.ROTATE_CAMERA, rotateCamera),
    takeLatest(TypesOfDesignerReducer.REFRESH_FIXTURE_PLAN, refreshFloorPlan),

  ]);
  yield put(SagaReady('blue'));
}

function initDesigner(action: InitDesignerAction) {
  designer = action.designer;
}

function setCameraType(action: SetCameraTypeAction) {
  if (designer) designer.getMain().setCamera(action.cameraType);
}

function* setShaderView(action: SetShaderViewAction) {
  try {
    switch (action.shaderView) {
      case ShaderView.None:
        designer.getMain().setView(action.shaderView);
        break;
      case ShaderView.BlackAndWhite:
        designer.getMain().setView(action.shaderView);
        break;
      default:
        yield put(SetShaderViewFailure('Unknown Shader'));
    }
    yield put(SetShaderViewSuccess(action.shaderView));
  } catch (error) {
    yield put(SetShaderViewFailure(error));
  }
}

function* setControlsType(action: SetControlsTypeAction) {
  if (!designer) return;
  switch (action.controlsType) {
    case ControlsType.OrthographicControls:
      designer.getMain().setOrthographicControls();
      yield put(SetControlsTypeSuccess(action.controlsType));
      break;
    case ControlsType.PerspectiveControls:
      designer.getMain().setPerspectiveControls();
      yield put(SetControlsTypeSuccess(action.controlsType));
      break;
    case ControlsType.PointerLock:
      designer.getMain().streetView(action.position, action.direction);
      yield put(SetControlsTypeSuccess(action.controlsType));
      break;
    case ControlsType.Photosphere:
      designer.getMain().PhotosphereView(action.position, action.direction);
      yield put(SetControlsTypeSuccess(action.controlsType));
      break;
    default:
      console.log('set controls type fail', action)
      break;
  }
}

function setPhotosphere(action: SetPhotosphereAction) {
  if (action.editPhotosphere) {
    designer.getMain().editPhotosphere(action.photosphere);
  } else {
    designer.getMain().viewPhotosphere(action.photosphere);
  }
}

function* setAutoRotate(action: SetAutoRotateAction) {
  designer.getMain().controls.autoRotate = action.autoRotate;
}

function* screenshot(action: ScreenshotAction) {
  try {
    if (action.setSceneImage) {
      store.dispatch(ToastMessage('Saving Image', null));
      const blob = yield call(designer.getMain().getBlobAsync);
      const scene = yield select(layoutScene);

      const formData = new FormData();
      formData.append('file', blob);

      const data = yield call(damApi.postBlob, formData);
      scene.thumbnailUrl = data.parsedBody.url;

      yield put(UpdateScene(scene));
      store.dispatch(ToastMessage('Image Saved'));
    }

    if (action.setFloorplanImage) {
      store.dispatch(ToastMessage('Saving Image', null));
      const blob = yield call(designer.getMain().getBlobAsync);
      const placePlan: PlacezFixturePlan = yield select(
        (state: ReduxState) => state.designer.floorPlan
      );

      const formData = new FormData();
      formData.append('file', blob);

      const data = yield call(damApi.postBlob, formData);
      placePlan.thumbnailUrl = data.parsedBody.url;

      yield put(UpdateFloorPlan(placePlan));
      store.dispatch(ToastMessage('Image Saved'));
    }

    if (action.download) {
      store.dispatch(ToastMessage('Generating Download'));
      designer.getMain().screenCapture(action.download);
      store.dispatch(ToastMessage('Download Complete'));
    }

    yield put(ScreenshotSuccess());

  } catch (error) {
    yield put(ScreenshotFailure(error));
  }
}

function* debounceSave(action: NeedSaveAction) {
  if (action.needSave) {
    const saveDebounceTimeSeconds = yield select(getSaveDebounceTimeSeconds)
    yield delay(saveDebounceTimeSeconds * 1000);
    if (yield select(needSave)) yield call(save);
  }
}

function* save() {
  const globalViewState = yield select(getGlobalViewState);
  const fixtureViewState = yield select(getFixtureViewState);
  if (globalViewState === GlobalViewState.Fixtures) {
    if (fixtureViewState === FixtureViewState.Floorplan) {
      yield call(saveFloorplan);
    } else {
      yield call(saveFixture);
    }
  } else if (globalViewState === GlobalViewState.Layout) {
    yield call(saveLayout)
  }
}

function* reset() {
  yield put(CancelBatch());
  yield put(NeedSaveAction(false));
  const globalViewState = yield select(getGlobalViewState);
  yield put(BlueInitAction(false));
  if (globalViewState === GlobalViewState.Fixtures) {
    yield put(ResetFixturePlan());
  } else if (globalViewState === GlobalViewState.Layout) {
    yield put(ResetLayout());
  }
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {type:mime});
}

function* saveLayout() {
  yield put(SavingAction(true));

  // take screenshot
  const layout: PlacezLayoutPlan = yield select(getLayout);
  const items: Asset[] = designer.getModel().scene.getAssets(designer.getMain(), true);

  const currentCameraType: CameraType = yield select((state: ReduxState) => state.blue.cameraType);
  const cameraState: PlacezCameraState = designer.getMain().getCameraState();


  let dataUrl;
  if (currentCameraType !== CameraType.Orthographic) {
    yield put({ type: types.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
    designer.getMain().fitToView();
    dataUrl = designer.getMain().screenCapture();
    yield put({ type: types.SET_CAMERA_TYPE, cameraType: currentCameraType });
    designer.getMain().loadCameraState(cameraState);
  } else {
    designer.getMain().fitToView();
    dataUrl = designer.getMain().screenCapture();
    designer.getMain().loadCameraState(cameraState);
  }

  const blob = dataURLtoBlob(dataUrl);
  const formData = new FormData();
  formData.append('file', blob);
  const data = yield call(damApi.postBlob, formData, layout.id);
  const imageUrl = data.parsedBody.url;

  const dimensions = designer.getMain().shapes.map((dim: PlacezShape) => {
    return dim.serialize();
  });
  const price = 0;
  const newLayoutPlan: PlacezLayoutPlan = {
    ...layout,
    items,
    cameraState,
    dimensions,
    imageUrl,
    price,
  };

  yield put({ type: TypesOfLayoutsReducer.UPDATE_LAYOUT, layout: newLayoutPlan });
  yield race([
    take(UpdateLayoutSuccess),
    // TODO handle save failure
    take(UpdateLayoutFailure),
  ]);
  yield put({ type: RequireNewDesignExport });
}

function* saveFixture() {
  const placePlan: PlacezFixturePlan = yield select(
    (state: ReduxState) => state.designer.floorPlan
  );

  const fixtures: Asset[] = designer.getModel().scene.getAssets(designer.getMain(), true);
  const newBlueFloorPlan = designer.getModel().exportFloorPlan();
  const dimensions = designer.getMain().shapes.map((dim: PlacezShape) => {
    return dim.serialize();
  });

  const newPlacePlan: PlacezFixturePlan = {
    ...placePlan,
    ...newBlueFloorPlan,
    fixtures,
    dimensions,
  };

  if (placePlan.id) {
    yield put({ type: TypesOfFloorPlanReducer.UPDATE_FLOOR_PLAN, floorPlan: newPlacePlan });
    yield race([
      take(UpdateFloorPlanSuccess),
      // TODO handle save failure
      take(UpdateFloorPlanFailure),
    ]);
  } else {
    yield put({ type: TypesOfFloorPlanReducer.CREATE_FLOOR_PLAN, floorPlan: newPlacePlan });
    yield race([
      take(CreateFloorPlanSuccess),
      // TODO handle create failure
      take(CreateFloorPlanFailure),
    ]);
  }
}

function* saveFloorplan() {
  yield put(SavingAction(true));
  // Build / update new floorplan update photosphere and fixtures in it
  const newBlueFloorPlan = designer.getModel().exportFloorPlan();
  const floorPlan: PlacezFixturePlan = yield select( (state: ReduxState) => state.designer.floorPlan);

  const newPlacePlan: PlacezFixturePlan = {
    ...floorPlan,
    ...newBlueFloorPlan,
  };

  if (floorPlan.id) {
    yield put({ type: TypesOfFloorPlanReducer.UPDATE_FLOOR_PLAN, floorPlan: newPlacePlan });
    yield race([
      take(UpdateFloorPlanSuccess),
      // TODO handle save failure
      take(UpdateFloorPlanFailure),
    ]);
  } else {
    yield put({ type: TypesOfFloorPlanReducer.CREATE_FLOOR_PLAN, floorPlan: newPlacePlan });
    yield race([
      take(CreateFloorPlanSuccess),
      // TODO handle create failure
      take(CreateFloorPlanFailure),
    ]);
  }
}

function* changeCameraLayersState(action: ChangeCameraLayersAction) {
  const globalViewState = yield select(getGlobalViewState);
  const fixtureViewState = yield select(getFixtureViewState);
  const layoutViewState = yield select(getLayoutViewState);
  const userState = getFromLocalStorage('userState');
  if (!userState) return;
  if (globalViewState === GlobalViewState.Fixtures) {
    const newLayersSet = new Set(action.cameraLayersState.concat(RequiredLayers[globalViewState][fixtureViewState].cameraLayers));
    const newLayers = [...newLayersSet];
    userState[globalViewState][fixtureViewState].cameraLayers = newLayers;
  } else if (globalViewState === GlobalViewState.Layout) {
    const newLayersSet = new Set(action.cameraLayersState
      .concat(RequiredLayers[globalViewState][layoutViewState].cameraLayers)
      .filter((layer: CameraLayers) => !ProhibitedLayers[globalViewState][layoutViewState].cameraLayers.includes(layer))
    );
    const newLayers = [...newLayersSet];
    userState[globalViewState][layoutViewState] = {cameraLayers: newLayers};
  }
  saveToLocalStorage('userState', userState);
}

function* loadLayout() {
  yield put(SetSelectedItems([]));
  yield put({ type: types.LAYOUT_LOADING, layoutLoading: true });

  try {
    let floorPlan: PlacezFixturePlan = yield select(getFloorPlan);
    floorPlan = JSON.parse(JSON.stringify(floorPlan));
    if (floorPlan) {
      const wallMaterialResponse = yield call(damApi.getMaterial, 'defaultWallMaterial');
      const defaultWallMaterial = yield call(DefaultWallMaterialPromise, wallMaterialResponse.parsedBody);
      const floorMaterialResponse = yield call(damApi.getMaterial, 'defaultFloorMaterial');
      const defaultFloorMaterial = yield call(DefaultFloorMaterialPromise, floorMaterialResponse.parsedBody);

      designer.getModel().loadFloorplan(floorPlan, defaultWallMaterial, defaultFloorMaterial);
      if (floorPlan.fixtures) {
        try {
          yield call(() => designer.getModel().importFixturesAsync(floorPlan.fixtures));
        } catch (error) {
          console.error(error);
        }
      }
      if (floorPlan.sceneScans) {
        yield call(() => designer.getMain().loadSceneScans(floorPlan.sceneScans));
      } else {
        yield put(sceneScanLoadedAction());
      }
    }

    let layout: PlacezLayoutPlan = yield select(getLayout);
    layout = JSON.parse(JSON.stringify(layout));
    if (layout) {
      designer.getMain().initCameraAndControls(layout.cameraState);
      const main = designer.getMain();
      yield call(() => designer.getModel().scene.loadAsync(layout, main));
      yield put(BlueInitAction())
      yield put(GetAttendeesByLayoutId(layout.id));
      yield put(GetLabels());
      if (layout.layoutLabels) {
        yield put({ type: types.SELECTED_LABEL_ID, selectedLabelId: undefined });
      }
      main.clearDimensions();
      if (layout.dimensions) {
        main.drawDimensions(layout.dimensions);
      }
    }
  } catch (error) {
    console.warn(error);
  }

  yield put({ type: types.LAYOUT_LOADING, layoutLoading: false });
  const layout: PlacezLayoutPlan = yield select(getLayout);
  if (layout) {
    yield put(GetAttendeesByLayoutId(layout.id));
  }
}

function* loadFixturePlan() {
  yield put(SetSelectedItems([]));
  yield put({ type: types.LAYOUT_LOADING, layoutLoading: true });

  try {
    let floorPlan: PlacezFixturePlan = yield select(getFloorPlan);
    floorPlan = JSON.parse(JSON.stringify(floorPlan));
    if (floorPlan) {
      const wallMaterialResponse = yield call(damApi.getMaterial, 'defaultWallMaterial');
      const defaultWallMaterial = yield call(DefaultWallMaterialPromise, wallMaterialResponse.parsedBody);
      const floorMaterialResponse = yield call(damApi.getMaterial, 'defaultFloorMaterial');
      const defaultFloorMaterial = yield call(DefaultFloorMaterialPromise, floorMaterialResponse.parsedBody);

      designer.getModel().loadFloorplan(floorPlan, defaultWallMaterial, defaultFloorMaterial);
      designer.getFloorPlan().initCameraAndControls(floorPlan.cameraState);
      designer.getMain().initCameraAndControls(floorPlan.cameraState);

      if (floorPlan.sceneScans) {
        yield call(() => designer.getMain().loadSceneScans(floorPlan.sceneScans));
      } else {
        yield put(sceneScanLoadedAction());
      }
      if (floorPlan.fixtures) {
        const main = designer.getMain();
        yield call(() => designer.getModel().scene.loadAsync(floorPlan, main));
      }
      if (floorPlan.dimensions) {
        const main = designer.getMain();
        main.drawDimensions(floorPlan.dimensions);
      }
      yield put(BlueInitAction())
    }
  } catch (error) {
    console.error(error);
  }

  yield put({ type: types.LAYOUT_LOADING, layoutLoading: false });
}

function* waitBlueInitialized() {
  const blueInitialized: boolean = yield select(getBlueInitialized);
  if (!blueInitialized) {
    yield take(types.INIT_DESIGNER);
  }
}

export function* initAttendeeList() {
  yield call(waitBlueInitialized);

  const attendees: Attendee[] = yield select((state: ReduxState) => state.attendee.attendees);
  const main = designer.getMain();

  const model = main.getModel();

  const tableItems = model.scene.getItems()
    .filter((item: Item) => {
      return AttendeeSkuTypes.includes(SkuType[item.asset.skuType])
    });

  // TODO this is a hack and will not work if you remove the last table
  if (tableItems.length !== 0) {
    const chairsPerTable = tableItems
      .reduce(
        (acc, item: Item) => {
          if (SkuType[item.asset.skuType] === SkuType.TBL) {
            acc[item.asset.instanceId] = item.asset?.modifiers?.chairMod?.seats ?? 0;
          } else {
            acc[item.asset.instanceId] = undefined;
          }
          return acc;
        },
        {}
      );

    const updatedAttendees = attendees.map((attendee: Attendee) => {
      const tableItem = tableItems
        .find((item: Item) => {
          return item.asset.instanceId === attendee.tableId;
        });

      const foundChair = chairsPerTable[attendee.tableId] >= attendee.chairNumber;

      if (tableItem?.asset && (attendee.chairNumber === undefined || foundChair)) {
        return {
          ...attendee,
          tableInfo: tableItem.asset?.labels?.tableNumberLabel ?? '',
        };
      }
      return {
        ...attendee,
        tableId: undefined,
        tableInfo: undefined,
        chairNumber: undefined,
      };
    });

    if (JSON.stringify(updatedAttendees) !== JSON.stringify(attendees)) {
      yield put({ type:attendeeTypes.UPDATE_ATTENDEES, attendees: updatedAttendees });
    }
  }

  yield call(refreshAttendeeList);
}

function* refreshAttendeeList() {

  yield call(waitBlueInitialized);

  const attendees: Attendee[] = yield select((state: ReduxState) => state.attendee.attendees);
  const selectedattendeeId: number = yield select((state: ReduxState) => state.attendee.selectedId);
  const main = designer.getMain();
  main.buildAttendeeLocations(attendees, selectedattendeeId);
}

function* toastMessage(action: ToastMessageAction) {
  const toastService = ToastStateService.getInstance();
  toastService.$createMessage(action.message, action.duration);
}

function* tableNumber() {
  // check if nextTableNumber exists
  const assets: Asset[] = designer.getModel().scene.getAssets(designer.getMain(), true);
  const nextTableNumber: number = yield select((state: ReduxState) => state.blue.nextTableNumber);
  if (assets.find((asset: Asset) => {
    return parseInt(asset.labels.tableNumberLabel, 10) ===  nextTableNumber;
  })) {
    store.dispatch(ToastMessage(`Table Number: ${nextTableNumber} Is Already Used`));
  }
}

function* initBatchItem(action: InitBatchItemAction) {
  yield call(() => designer.getModel().scene.initBatchItem(action.asset, action?.cb));
}

function* takePhotosphere() {
  const photosphereUrl = yield call(designer.getMain().getEquirectangular);
  yield put({ type:types.SELECT_EQUIRECTANGULAR_PHOTO, url: photosphereUrl });
}

function* updateItem(action: UpdateItemAction) {
  yield call(() => designer.getModel().scene.updateItemModifiers(action.asset));
}

function* hideChair(action: HideChairAction) {
  const {chairNumber, tableId} = action.attendee;

  const items: Asset[] = designer.getModel().scene.getAssets(designer.getMain(), true);

  yield put({ type:attendeeTypes.UNSEAT_ATTENDEE, chairNumber, tableInstanceId: tableId, shiftSeats: true });
  yield take('UPDATE_ATTENDEES_SUCCESS')

  // Hide the Chair
  let newAsset = undefined;
  const newItems = items
    .map((assetInstance: Asset) => {
      if (assetInstance.instanceId === tableId) {

        newAsset = {
          ...assetInstance,
          modifiers: {
            ...assetInstance.modifiers,
            chairMod: {
              ...assetInstance.modifiers.chairMod,
              seatPositions: assetInstance.modifiers.chairMod.seatPositions.map((seat: SeatInstance): SeatInstance => {
                if (seat.chairNumber === chairNumber) {
                  return {
                    ...seat,
                    hidden: true,
                  }
                }
                return seat;
              })
            }
          }
        }
        return newAsset;
      } else {
        return assetInstance;
      }
    })

  if (newAsset) {
    yield put({ type:types.UPDATE_ITEM, asset: newAsset });
    const layout: PlacezLayoutPlan = yield select(getLayout);

    const newLayoutPlan: PlacezLayoutPlan = {
      ...layout,
      items: newItems,
    };

    yield put({ type: TypesOfLayoutsReducer.UPDATE_LAYOUT, layout: newLayoutPlan });
    yield take(UpdateLayoutSuccess);
    yield put(Save())
  }
}

function* disposeDesigner() {
  //TODO maybe check globalviewState before put
  yield put(GetDesignerFloorPlanSuccess(null));
  yield put(NeedSaveAction(false));
}

function* cancelBatch(action: CancelBatchAction ) {
  try {
    const main = designer.getMain();
    const batchController = main.getController()
    yield put({ type: types.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
    const selectedItems = yield select(getSelectedItems);
    if (selectedItems && selectedItems.length > 0) {
      yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.EditItem });
    } else {
      yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.AddItem });
    }
    batchController?.clearBatch();
  } catch (error) {
  }
}

function* applyBatch(action: ApplyBatchAction ) {
  try {
    const main = designer.getMain();
    yield put({ type: types.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
    const selectedItems = yield select(getSelectedItems);
    const batchGuid = designer.getModel().scene.setBatchGuid(selectedItems);
    main.getController().updateItemsList();
    const batchItems = main.getModel().scene.getItems()
      .filter((item: Item) => item.asset.groupId === batchGuid)
    main.getController()?.confirmBatch()
    main.getController().selectItems(batchItems)
  } catch (error) {
  }
}

function* setBatchSettings(action: SetBatchSettingsAction) {
  const main = designer.getMain();
  const currentController: ControllerType = yield select((state: ReduxState) => state.blue.activeController);
  if (action?.batchSettings) {
    if (currentController !== action.batchSettings.controllerType) {
      yield put({ type: types.SET_ACTIVE_CONTROLLER, controllerType: action.batchSettings.controllerType });
    }
    main.getController()?.updateBatch(action?.batchSettings)
  } else {
    main.getController()?.resetBatch()
  }
}

function* selectedItems(action: SetSelectedItemsAction) {
  if (action.selectedItems[0] !== undefined) {
    yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.EditItem });
    const main = designer.getMain();
    main.getModel().scene.collisionHandler.getCollisionItemsForSelected(action.selectedItems);
    main.getModel().scene.collisionHandler.getIntersectionItemsForSelected(action.selectedItems);
  } else {
    yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.AddItem });
  }
}

function* selectedSurface(action: SetSelectedSurfaces) {
  if (action.selectedSurfaces[0] !== undefined) {
    yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.EditSurface });
  } else {
    const selectedItems = yield select(getSelectedItems);
    if (selectedItems[0] !== undefined) {
      yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.EditItem });
    } else {
      yield put({ type: globalStateTypes.TOOL_STATE, toolState: ToolState.AddItem });
    }
  }
}

function zoomIn(action: ZoomInAction) {
  const main = designer.getMain();
  main.controls.dollyIn(main.controls.getZoomScale());
  main.controls.update();
  main.needsUpdate();
  const floorplanner = designer.getFloorPlan();
  if (floorplanner) {
    floorplanner.controls.dollyIn(main.controls.getZoomScale());
    floorplanner.controls.update();
    floorplanner.needsUpdate();
  }
}

function zoomOut(action: ZoomInAction) {
  const main = designer.getMain();
  main.controls.dollyOut(main.controls.getZoomScale());
  main.controls.update();
  main.needsUpdate();

  const floorplanner = designer.getFloorPlan();
  if (floorplanner) {
    floorplanner.controls.dollyOut(main.controls.getZoomScale());
    floorplanner.controls.update();
    floorplanner.needsUpdate();
  }
}

function* fitToView(action: FitToViewAction) {
  const fixtureViewState = yield select(getFixtureViewState);
  const globalViewState: GlobalViewState = yield select(getGlobalViewState);

  if (globalViewState === GlobalViewState.Fixtures) {
    if (fixtureViewState === FixtureViewState.Floorplan) {
      const floorPlanner = designer.getFloorPlan();
      floorPlanner.fitToView();
      floorPlanner.floorplan.setAzimuth(floorPlanner.controls.getAzimuthalAngle());
      floorPlanner.needsUpdate();
    } else {
      const main = designer.getMain();
      main.fitToView();
      main.needsUpdate();
    }
  } else {
    const main = designer.getMain();
    main.fitToView();
    main.needsUpdate();
  }
}

function* rotateCamera(action: RotateCameraAction) {
  const globalViewState = yield select(getGlobalViewState);
  const newAngle = isNaN(action.degreeAngle) ? 0 : action.degreeAngle;

  if (globalViewState === GlobalViewState.Fixtures) {
    const floorPlanner = designer.getFloorPlan();
    floorPlanner.controls.setCameraRotation(Utils.convertToRadians(-newAngle % 360));
    floorPlanner.floorplan.setAzimuth(floorPlanner.controls.getAzimuthalAngle());
    const main = designer.getMain();
    main.controls.setCameraRotation(Utils.convertToRadians(-newAngle % 360));
    main.needsUpdate();
  } else {
    const main = designer.getMain();
    main.controls.setCameraRotation(Utils.convertToRadians(-newAngle % 360));
    main.needsUpdate();
  }
}

function* refreshFloorPlan(action: UpdateFloorPlanAction) {
  yield put(SetSelectedItems([]));
  designer.getFloorPlan().floorplan.update();
  designer.getMain().roomLoaded();
  yield put(ChangeCopiedAssetsState(undefined));

  const designerFloorplan = yield select(getFloorPlan);
  designer.getFloorPlan().loadCameraState(designerFloorplan.cameraState);
  designer.getMain().loadCameraState(designerFloorplan.cameraState);

}
