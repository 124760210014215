import { useState, useEffect, useRef } from 'react';
import PlaceDesignerView from './PlaceDesignerView/PlaceDesignerView';
import { ChangeGlobalViewState, GlobalStateInitializing } from '../../../../reducers/globalState';

import { useDispatch, useSelector } from 'react-redux';

import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { GlobalViewState  } from '../../../../models/GlobalState';
import { ReduxState } from '../../../../reducers';

import {
  HandlesFromBlue,
  LayoutDesignerCallbacks,
} from '../../models';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    planDesignersContainer: {
      height: '100%',
    },
  })
);

interface Props {
  placeId: number;
  floorPlanId?: number;
}

const PlaceDesigner = (props: Props) => {
  const designerCallbacks = useRef<HandlesFromBlue>(new LayoutDesignerCallbacks());
  const globalStateInitialized = useSelector((state: ReduxState) => state.globalstate.globalStateInitialized);
  const dispatch = useDispatch();

  const [placePlannerMode, setPlacePlannerMode] = useState(0);


  useEffect(() => {
    dispatch(GlobalStateInitializing());
    dispatch(ChangeGlobalViewState(GlobalViewState.Fixtures));
  }, []);

  const {
    floorPlanId,
    placeId,
  } = props;

  const classes = styles(props);

  return (
    <div className={classes.planDesignersContainer}>
      {globalStateInitialized &&
        <PlaceDesignerView
          placeId={placeId}
          floorPlanId={floorPlanId}
          placePlannerMode={setPlacePlannerMode}
          designerCallbacks={designerCallbacks.current}
        />
      }
    </div>
  );
}

export default PlaceDesigner;
