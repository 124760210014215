import { HTMLAttributes } from 'react';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Card,
  CardMedia,
  CardContent,
  Grow,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

// Model
import animations from '../../../assets/themes/animations';
import classNames from 'classnames';
import PlacezFixturePlan from '../../../api/placez/models/PlacezFixturePlan';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      margin: '0px 6px 6px',
      height: 180,
      cursor:'pointer',
    },
    image: {
      width: 150,
      height: 150,
    },
    label: {
      width: 150,
      padding: `${theme.spacing() / 2}px ${theme.spacing()}px`,
      display: 'flex',
      justifyContent: 'center',
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: `${theme.palette.common.white} !important`,
    },
    name: {
      ...theme.typography.body2,
      color: `${theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary}`,
    },
  })
);

interface Props {
  fixturePlan: PlacezFixturePlan;
  onClick: any;
  timeout?: number;
  selected: boolean;
}

const PlaceLayoutCard = (props: Props & HTMLAttributes<HTMLDivElement>) => {
  const classes = styles(props);
  const { fixturePlan, onClick, timeout, selected } = props;
  const color = '#9e9e9e'; // Future capability to override color

  const url = fixturePlan?.thumbnailUrl ?? fixturePlan.floorplanImageUrl ?? '';
  return (
    <Grow
      in={true}
      style={{ transformOrigin: '0 0 0' }}
      timeout={ timeout ? timeout : animations.growDuration }>
      <Tooltip
        placement="top"
        title={fixturePlan.name}>
        <Card
          className={classes.root}
          onClick={onClick}>
          <div style={{
            background: `radial-gradient(#ffffff, ${color})`,
          }}>
            <CardMedia
                className={classes.image}
                image={url}
            />
          </div>
          <CardContent className={classNames(classes.label, selected ? classes.selected : '')}>
            <Typography className={classNames(classes.name, selected ? classes.selected : '')} noWrap>
              {fixturePlan.name}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    </Grow>
  );
}

export default PlaceLayoutCard;
