import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { useParams } from 'react-router';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { getPlaceById } from '../../reducers/place';

import PlaceForm from '../../components/Place/PlaceForm';

type Props = {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      overflow: 'none',
      overflowY: 'auto',
    },
    title: {
      ...theme.typography.h5,
      fontSize: 18,
      fontWeight: 'bold',
    },
  })
);

const EditPlace = (props: Props) => {
  const classes = styles(props);
  const { id } = useParams();
  const places = useSelector((state: ReduxState) => state.place.places);

  const place = getPlaceById(places, parseInt(id));

  return (
    <div className={classes.root}>
      {place && (
        <PlaceForm place={getPlaceById(places, parseInt(id))} />
      )}
    </div>
  );
}

export default EditPlace;
