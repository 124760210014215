import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createStyles,
  Theme,
  IconButton,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import classnames from 'classnames';

import {
  Print as PrintIcon,
  Share as ShareIcon,
  FavoriteBorder,
} from '@material-ui/icons';

// Loading
import { LoadingProgressService } from '../../../../blue/model/loading_progress';
import { Subscription } from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';

import { LikeLayout } from '../../../../reducers/designer';
import { ReduxState } from '../../../../reducers';
import { SaveBarState } from '../../../../models/GlobalState';
import PlacezLayoutPlan from '../../../../api/placez/models/PlacezLayoutPlan';
import { guestRole, userIsInRole } from '../../../../sharing/utils/userHelpers';
import { SimpleModal } from '../../../Modals/SimpleModal';
import ShareModal from '../../../Modals/ShareModal/ShareModal';
import PrintModal from '../../../Modals/PrintModal';
import { countTablesAndChairs } from '../../../../blue/model/scene'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 10,
    color: theme.palette.text.primary,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
  },
  buttons: {
    width: 258,
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  sceneInfo: {
    // color: theme.palette.text.primary,
    display: 'flex',
    flex: 2,
    paddingLeft: 5,
    fontSize: 14,
  },

  plan: {
    display: 'flex',
    flex: 2,
    fontWeight: 'bold',
    paddingLeft: 5,
    fontSize: 14,
    position: 'relative',
  },
  loadingBar: {
    background: 'transparent',
    padding: '3px',
  },
  button: {
    margin: '5px',
    width: '100%',
    borderColor: 'purple',
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 120,
  },
  guestLabel: {
    paddingRight: 5,
    fontSize: 14,
  },
  textField: {
    margin: '4px 0px',
    width: '40px',
    background: theme.palette.background.paper,
    '& input': {
      padding: '6px',
    },
  },
  layoutTitle: {
    fontSize: 14,
    alignSelf: 'center',
    paddingLeft: '10px',
  },
  layoutTitleInput: {
    position: 'absolute',
    top: '40px',
    padding: '5px',
    width: '300px',
    display: 'grid',
    gridTemplateColumns: '10fr 1fr 1fr',
  },
  layoutTitleInputField: {
    background: theme.palette.background.paper,
    border: '1px white',
  },
  dropDown: {
    padding: 0,
  },
  iconButton: {
    padding: 4,
    color: theme.palette.text.primary,
  },
  iconSaveTitle: {
    padding: 2,
    color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
    margin: `0px ${theme.spacing() / 2}px`,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  iconCancelTitle: {
    padding: 2,
    color: theme.palette.error.main,
    margin: `0px ${theme.spacing() / 2}px`,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  iconData: {
    alignSelf: 'flex-start',
    fontSize: '11px',
    marginLeft: '-6px',
    borderRadius: '50%',
    padding: 1,
  },
  loadingBarWrapper: {
    minHeight: '6px',
    background: theme.palette.background.paper,
  },
})
)

interface Props {
  layout: PlacezLayoutPlan;
  inventory?: any;
  generateAR?: () => void;
  onScreenshot?: any;
  saveBarState: SaveBarState;
}

const LayoutHeader = (props: Props) => {
  const dispatch = useDispatch();

  const guest = useSelector((state: ReduxState) => userIsInRole(state.oidc.user, guestRole));
  const layoutLoading = useSelector((state: ReduxState) => state.blue.layoutLoading);
  const chairs = useSelector((state: ReduxState) => state.blue.stats.chairs);
  const tables = useSelector((state: ReduxState) => state.blue.stats.tables);
  const price = useSelector((state: ReduxState) => state.blue.stats.price);
  const scene = useSelector((state: ReduxState) => state.scenes.currentScene);
  const selectedItems = useSelector((state: ReduxState) => state.blue.selectedItems);

  const [selectedChairs, setSelectedChairs] = useState<number>(0);
  const [selectedTables, setSelectedTables] = useState<number>(0);

  useEffect(() => {
    if (selectedItems.length < 1) {
      setSelectedChairs(0);
      setSelectedTables(0);
    } else {
      const tablesAndChairs = countTablesAndChairs(selectedItems);
      setSelectedChairs(tablesAndChairs.chairs);
      setSelectedTables(tablesAndChairs.tables);
    }
  }, [selectedItems])


  let progressSubscription: Subscription;
  let isLoadingSubscription: Subscription;

  const loadingProgressService = LoadingProgressService.getInstance();

  const [progress, setProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [layoutTitle, setLayoutTitle] = useState<string>(props.layout.name);

  const subscribe = () => {
    progressSubscription = loadingProgressService.progress$.pipe(
      withLatestFrom(loadingProgressService.simpleProgress$)
    )
      .subscribe(([progress, simpleProgress]: [number, number]) => {
        if (simpleProgress) {
          setProgress(simpleProgress)
        } else {
          setProgress(progress);
        }
      });

    isLoadingSubscription = loadingProgressService.isLoading$.pipe(
      withLatestFrom(loadingProgressService.isLoadingSimple$)
    )
      .subscribe(([isLoading, isLoadingSimple]: [boolean, boolean]) => {
        // this.setState({ isLoading: isLoading || isLoadingSimple });
        setIsLoading(isLoading)
      });
      return (() => {
        progressSubscription?.unsubscribe();
        isLoadingSubscription?.unsubscribe();
      })
  }

  useEffect(() => {
    // subscribe();
  },[])

  useEffect(() => {
    setLayoutTitle(props.layout.name)
  }, [props.layout])


  const formatDate = (dateStr: Date): string => {
    if (!dateStr) {
      return '';
    }
    const date = new Date(dateStr);
    const  options: Intl.DateTimeFormatOptions  = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    return date.toLocaleDateString('en-US', options);
  }

  const getSceneName = () =>  {
    if (scene) {
      return `${scene.name} |
        ${formatDate(scene.startUtcDateTime)}`;
    }
    return '';
  }

  const classes = styles(props);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.sceneInfo}>{getSceneName()}</Typography>
          <div className={classes.plan}>

            <Typography className={classes.layoutTitle}>
              {layoutTitle}
            </Typography>
          </div>

          <div className={classes.field}>
            {selectedItems.length > 0 &&
              <Typography className={classes.guestLabel}>Selected: </Typography>
            }
            {scene?.estimatedGuestCount > 0 &&
              <Typography className={classes.guestLabel}>Guests {scene.estimatedGuestCount}</Typography>
            }
            <Typography className={classes.guestLabel}>Tables {selectedItems.length > 0 ? selectedTables : tables}</Typography>
            <Typography
              className={classes.guestLabel}
              style={chairs > (scene ? scene.estimatedGuestCount : 0) ? {
                color: 'red',
              } : {}}
            >Chairs {selectedItems.length > 0 ? selectedChairs : chairs}</Typography>
            {selectedItems.length === 0 &&
              <Typography className={classes.guestLabel}>Price ${price}</Typography>
            }
          </div>

        </div>
        <div className={classes.buttons}>
          {
            guest ? (
              <IconButton
                onClick={() => {
                  dispatch(LikeLayout(props.layout.id))
                }}
                className={classnames(classes.iconButton)}>
                <FavoriteBorder/>
                <span className={classes.iconData}>
                  {props.layout.likesCount}
                </span>
              </IconButton>
            )
            : (<div style={{display: 'flex', alignItems:'center'}}>
              <IconButton
                className={classnames(classes.iconButton)}>
                <FavoriteBorder/>
                <span className={classes.iconData}>
                  {props.layout.likesCount}
                </span>
              </IconButton>
              <SimpleModal>
                <IconButton
                  disabled={layoutLoading}
                  className={classes.iconButton}>
                  <Tooltip title="Share Layout">
                    <ShareIcon />
                  </Tooltip>
                </IconButton>
                <ShareModal
                  title={props.layout.name}
                  layoutId={props.layout.id}
                  generateAR={props.generateAR}
                />
              </SimpleModal>

              <SimpleModal>
                <IconButton
                  disabled={layoutLoading}
                  className={classes.iconButton}>
                  <Tooltip title="Print Layout">
                    <PrintIcon />
                  </Tooltip>
                </IconButton>
                <PrintModal
                  layout={props.layout}
                  scene={scene}
                  inventory={props.inventory}
                  onScreenshot={props.onScreenshot}
                />
              </SimpleModal>

            </div>)
          }
        </div>
      </div>
      <div className={classes.loadingBarWrapper}>
      </div>
    </div>
  );
}

export default LayoutHeader;
