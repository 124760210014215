import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Tooltip,
  IconButton,
  makeStyles,
} from '@material-ui/core';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  AddCircleOutline, Edit,
} from '@material-ui/icons';

import { SortableComponent } from '../../components/SortableComponent';
import '../../assets/styles/accordion.css';
import { AddPickListOption } from '../../reducers/settings';

import classnames from 'classnames';
import { PickListOption } from '../../api';
import { ReduxState } from '../../reducers';
import Jumbotron from '../../components/Jumbotron';
import produce from 'immer';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      padding: theme.spacing(3),
    },
    controlLabel: {
      margin: `0px 0px ${theme.spacing()}px -10px`,
    },
    controlLabelSelected: {
      margin: `0px 0px ${theme.spacing()}px -10px`,
      backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.common.white,
      color: 'purple',
      borderRadius: '5%',
    },
    unchecked: {
      fontSize: 14,
    },
    labelInEdit: {
      cursor: 'default',
    },
    checked: {
      fontSize: 14,
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.main,
    },
    layout: {
      display: 'grid',
      gridTemplateColumns: '240px 1fr',
      gridTemplateRows: 'auto auto 1fr',
      gridGap: theme.spacing(3),
    },
    headingPanel: {
      ...theme.typography.h5,
      fontSize: 20,
      fontWeight: 'bold',
      gridColumn: '1 / span 2',
    },
    programArea: {
      display: 'flex',
      justifyContent: 'center',
    },
    sortingPanel: {
      display: 'flex',
      flexDirection: 'column',
    },
    actions: {
      gridColumn: '1 / span 2',
      marginTop: theme.spacing(4),
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    actionButton: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      margin: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      width: 200,
      height: 40,
    },
    pickHeading: {
      ...theme.typography.h5,
      fontWeight: 'bold',
      fontSize: 18,
      textAlign: 'center',
      padding: 4,
    },
    pickHeadingOverride: {
      textAlign: 'left',
      paddingLeft: theme.spacing(4),
    },
    itemButton: {
      color: `${theme.palette.text.primary} !important`,
    },
  })
);

interface Props {
}

const QuickPickSettings = (props: Props) => {
  const storePicklists = useSelector((state: ReduxState) => state.settings.pickLists);

  const dispatch = useDispatch();

  const [inEdit, setInEdit] = useState<boolean>(false);
  const [selectedQuickPick, setSelectedQuickPick] = useState<string>('');
  const [currentPickListId, setCurrentPickLIstId] = useState<number>(0);
  const [currentOptions, setCurrentOptions] = useState<PickListOption[]>([]);
  const [picklists, setPicklists] = useState<any>(storePicklists);
  const [optionsBak, setOptionsBak] = useState<PickListOption[]>([]);

  const getName = ():string => {
    switch (selectedQuickPick) {
      case 'setup-style':
        return 'Setup Style';
      case 'status':
        return 'Scene Type';
      case 'client-type':
        return 'Client Type';
      case 'place-type':
        return 'Place Type';
      default:
        return '';
    }
  }

  const changePickList = (selectedListName: string) => {
    const currentPickList =  picklists.find(
      list => {
        return list.name === selectedListName;
      }
    );
    if (!currentPickList) {
      return;
    }

    // setup ui with current picklist
    setSelectedQuickPick(selectedListName);
    setCurrentPickLIstId(currentPickList.id);
    setCurrentOptions(copyCurrentpicklistOptions(selectedListName))
  }

  const handleChangePickList = event => {
    if (inEdit) {
      return;
    }

    const selectedListName = event.target.value;
    changePickList(selectedListName);
  }

  const onEdit = () => {
    setOptionsBak(currentOptions);
    setInEdit(true);
  }

  const onCancel = () => {
    setCurrentOptions(optionsBak);
    setInEdit(false);
  }

  const onSave = () => {
    setInEdit(false);
    saveOptions();
  }

  const saveOptions = () => {
    const prevOptions = storePicklists.find(list => {
        return list.name === selectedQuickPick;
      }).picklistOptions;
    if (currentOptions) {
      dispatch(AddPickListOption(currentOptions, prevOptions))
    }
  }

  const updateOptions = changeOptions => {
    updateOrder(changeOptions);
  }

  const updateOptionName = changeOption => {
    const newOptions = produce(currentOptions, draftOptions => {
      draftOptions
      .map(draftOption => {
        if (draftOption.id === changeOption.id) {
          draftOption.name = changeOption.name;
        }
      });
    });

    updateOrder(newOptions);
  }

  const updateOrder = (options: PickListOption[]) => {
    setCurrentOptions(
      produce(options, (draftOptions: PickListOption[]) => {
        draftOptions
        .map((draftOption, index) => {
          draftOption.sortOrder = index;
        });
      })
    );
  }

  const addOption = () => {
    //  add new option to current options
    const newOptions = [...currentOptions];
    const newId = newOptions.filter(option => option.id < 0).length + 1;

    const newOption = {
      id: newId * -1,
      name: 'new',
      sortOrder: 9999,
      picklistId: currentPickListId,
    } as PickListOption;
    newOptions.push(newOption);

    const updatedPicklist = {
      ...(picklists.find(list => {
        return list.name === selectedQuickPick;
      })),
      picklistOptions: newOptions,
    };

    // update the current PickList with the new options
    const updatedPicklists = picklists.filter(
      list => {
        return list.name !== selectedQuickPick;
      }
    );
    updatedPicklists.push(updatedPicklist);

    setPicklists(updatedPicklists);
    setCurrentOptions(newOptions);
  }

  const renderRadio = (value: string, label: string) => {
    const classes = styles(props);
    const selected = selectedQuickPick === value;

    return (
      <FormControlLabel
        classes={{
          root: selected ? classes.controlLabelSelected : classes.controlLabel,
          label: `${selected ? classes.checked : classes.unchecked} ${inEdit ? classes.labelInEdit : ''}`,
        }}
        label={label}
        value={value}
        control={
          <Radio
            icon={null}
            checkedIcon={null} />
        }
      />
    );
  }

  const copyCurrentpicklistOptions = listName => {
    const list = picklists.find(list => {
      return list.name === listName;
    });
    if (list) {
      return [...list.picklistOptions].sort((a, b) => a.sortOrder - b.sortOrder);
    }
    return [];
  }

  useEffect(() => {
    if (selectedQuickPick === '') changePickList('SetupStyle');
  }, [picklists])

  useEffect(() => {
    setPicklists(storePicklists)
  }, [storePicklists])


  const classes = styles(props);
  return (
    <div className={classes.root}>
      <Jumbotron
        title="Picklist">
        { !inEdit &&
          <Tooltip title="Edit">
            <IconButton aria-label="Add New Client"
              disabled={inEdit}
              color="secondary"
              onClick={onEdit}>
              <Edit fontSize="large"/>
            </IconButton>
          </Tooltip>
        }
      </Jumbotron>
      <div className={classes.layout}>
        <h2 className={classes.headingPanel}>
          Program Area
        </h2>
        <div className={classes.programArea}>
          <Accordion
            allowZeroExpanded={true}
            allowMultipleExpanded={true}
            preExpanded={[
              'setup-style-accordion-item',
              'clients-accordion-item',
              'placez-library-accordion-item',
            ]}
          >
            <AccordionItem uuid={'setup-style-accordion-item'}>
              <AccordionItemHeading >
                  <AccordionItemButton className={classes.itemButton}>
                      Scenes
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
              <RadioGroup
                value={selectedQuickPick}
                onChange={handleChangePickList}>
                {renderRadio('SetupStyle', 'Setup Style')}
                {renderRadio('Status', 'Status')}
                {renderRadio('SceneType', 'Scene Type')}
                </RadioGroup>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'clients-accordion-item'}>
              <AccordionItemHeading>
                  <AccordionItemButton className={classes.itemButton}>
                      Clients
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RadioGroup
                  value={selectedQuickPick}
                  onChange={handleChangePickList}>
                  {renderRadio('ClientType', 'Client Type')}
                </RadioGroup>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={'placez-library-accordion-item'}>
              <AccordionItemHeading>
                  <AccordionItemButton className={classes.itemButton}>
                      Placez Library
                  </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RadioGroup
                  value={selectedQuickPick}
                  onChange={handleChangePickList}>
                    {renderRadio('PlaceType', 'Place Type')}
                  </RadioGroup>
              </AccordionItemPanel>
            </AccordionItem>

          </Accordion>
        </div>
        <div className={classes.sortingPanel}>
          <div style={{
            display: 'grid',
            gridTemplateColumns: '100px 1fr 100px',
          }}>
            <span className={classes.pickHeading}>Order</span>
            <span className={classnames(classes.pickHeading, classes.pickHeadingOverride)}>
              Picklist Item
            </span>
            <span className={classes.pickHeading}></span>
          </div>
          <SortableComponent
            disabled={!inEdit}
            updateOptions={updateOptions}
            updateOptionName={updateOptionName}
            items={ currentOptions }
            />
          {
            inEdit ?
            (<Button
                style={{
                  alignSelf: 'flex-end',
                  marginTop: 8,
                  letterSpacing: 1,
                  color: 'gray' }}
                onClick={addOption}>
                <AddCircleOutline color="secondary"
                  style={{
                    marginRight: 2,
                  }}/>
                Add New { getName() }
              </Button>) :
            null
          }
        </div>
        {
          inEdit &&
          <div className={classes.actions}>
            <Button
              onClick={onCancel}
              className={classes.actionButton}
              variant="contained">
              Cancel
            </Button>
            <Button
              onClick={onSave}
              className={classes.actionButton}
              variant="contained"
              color="primary">
              Save
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

export default QuickPickSettings;
