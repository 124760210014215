import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';
import { Checkbox } from '@material-ui/core';
import { CheckBoxOutlineBlank, CheckBox } from '@material-ui/icons'

export default class CheckboxCell extends GridCell {
  render() {
    return (
      <td>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlank fontSize="small" />}
          checkedIcon={<CheckBox fontSize="small" />}
        />
      </td>
    );
  }
}
