import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from '../../../reducers';
import { ChangeFixtureViewState, ChangeLayoutViewState, LicenseType, canEditLayout, isAdmin } from '../../../reducers/globalState';
import { SetShaderView, Screenshot } from '../../../reducers/blue';
import { ShaderView } from '../../Blue/models';
import Photosphere from '../../../assets/icons/Photosphere';
import { useNavigate } from 'react-router';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import {
  BackIcon,
  BWIcon,
  PlannerIcon,
  NotesIcon,
} from '../../../assets/icons';

import {
  PhotoCameraRounded as ScreenshotIcon,
  Chat,
  Refresh,
  Group,
  LooksOne,
  Streetview,
  Image,
  FormatShapes,
} from '@material-ui/icons';
import SideBarButton from '../SideBarButton';
import { ModalConsumer } from '../../Modals/ModalContext';
import ScreenshotModal from '../../../components/Modals/ScreenshotModal';

// TODO: In the future should import from Blue npm package
import { Box } from '@material-ui/core';
import ButtonController from './ButtonController';
import PlannerSideBarButton from './PlannerSideBarButton';
import { GlobalViewState, FixtureViewState, LayoutViewState } from '../../../models/GlobalState';
import { ToggleChatPanel } from '../../../reducers/chat';
import ScanModal from '../../Modals/ScanModal';
import { SimpleModal } from '../../Modals/SimpleModal';

type Props = {
  // TODO useselector guest
  guest?: boolean;
};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    textIcon: {
      fontFamily: 'Montserrat',
      fontSize: 20,
      fontStyle: 'normal',
      fontWeight: 'bold',
      height: 35,
      width: 35,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      height:35,
      width:35,
    },
  })
);

const enum ButtonLabels {
  Planner = 'Planner',
  SwitchTo2D = 'Switch to 2D',
  SwitchTo3D = 'Switch to 3D',
  Photosphere = 'Photosphere',
  Attendees = 'Attendees',
  Notes = 'Notes',
  NumberTables = 'Number Tables',
  StreetView = 'Street View',
  Shapes = 'Shapes',
}

const PlannerSidebar = (props: Props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = styles(props);

  const [floorplanButtonController, setFloorplanButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Planner, true, true, true, () => {}));
  const [twodButtonController, setTwodButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.SwitchTo2D, true, true, true, () => {}));
  const [threedButtonController, setThreedButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.SwitchTo3D, true, true, true, () => {}));
  const [photosphereButtonController, setPhotosphereButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Photosphere, true, true, true, () => {}));
  const [streetViewButtonController, setStreetViewButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Photosphere, true, true, true, () => {}));
  const [attendeeViewButtonController, setAttendeeViewButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Attendees, true, true, true, () => {}));
  const [labelButtonController, setLabelButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Notes, true, true, true, () => {}));
  const [numberButtonController, setNumberButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.NumberTables, true, true, true, () => {}));
  const [shapeButtonController, setShapeButtonController] = useState<ButtonController>(new ButtonController(ButtonLabels.Shapes, true, true, true, () => {}));
  const [initialize, setInitialize] = useState<boolean>(false);

  const shaderView = useSelector((state: ReduxState) => state.blue.shaderView);
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);
  const layoutViewState = useSelector((state: ReduxState) => state.globalstate.layoutViewState);
  const globalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const layoutLoading = useSelector((state: ReduxState) => state.blue.layoutLoading);
  const license = useSelector((state: ReduxState) => state.globalstate.licenseState);
  const plannerToolState = useSelector((state: ReduxState) => state.globalstate.plannerTool);
  const chatPanelState = useSelector((state: ReduxState) => state.chat.chatPanelState);
  const needSave = useSelector((state: ReduxState) => state.blue.needSave);
  const saving = useSelector((state: ReduxState) => state.blue.saving);
  const dirty = needSave || saving;
  const ready = useSelector((state: ReduxState) => state.blue.blueInitialized && state.blue.sceneScanLoaded);
  const navigationDisabled = useSelector((state: ReduxState) => state.globalstate.navigationDisabled);

  const admin = useSelector((state: ReduxState) => isAdmin(state));
  const guestCanEditLayout = useSelector((state: ReduxState) => canEditLayout(state));

  useEffect(() => {
      let floorplanButtonController = new ButtonController(ButtonLabels.Planner, true, false, false, () => {});
      let twodButtonController = new ButtonController(ButtonLabels.Planner, true, false, false, () => {});
      let threedButtonController = new ButtonController(ButtonLabels.Planner, true, false, false, () => {});
      let photosphereButtonController = new ButtonController(ButtonLabels.Planner, true, false, false, () => {});
      let streetViewButtonController = new ButtonController(ButtonLabels.StreetView, true, false, false, () => {});
      let attendeeViewButtonController = new ButtonController(ButtonLabels.Attendees, true, false, false, () => {});
      let labelButtonController = new ButtonController(ButtonLabels.Notes, true, false, false, () => {});
      let numberButtonController = new ButtonController(ButtonLabels.NumberTables, true, false, false, () => {});
      let shapeButtonController = new ButtonController(ButtonLabels.Shapes, true, false, false, () => {});

      // BASIC LICENSE
      if (license === LicenseType.Placez || license === LicenseType.PlacezPlus || license === LicenseType.PlacezPro) {
        twodButtonController = new ButtonController(ButtonLabels.SwitchTo2D, false, false, false, () => dispatch(ChangeFixtureViewState(FixtureViewState.TwoDView, fixtureViewState)));
        threedButtonController = new ButtonController(ButtonLabels.Planner, true, true, false, () => {});
        photosphereButtonController = new ButtonController(ButtonLabels.Planner, true, true, false, () => {});

        if (globalViewState === GlobalViewState.Fixtures) {
          floorplanButtonController = new ButtonController('Planner', false, false, false, () => dispatch(ChangeFixtureViewState(FixtureViewState.Floorplan, fixtureViewState)));
          labelButtonController = new ButtonController(ButtonLabels.Notes, false, false, false, () => dispatch(ChangeFixtureViewState(FixtureViewState.LabelView, fixtureViewState)));
          shapeButtonController = new ButtonController(ButtonLabels.Shapes, false, false, false, () => dispatch(ChangeFixtureViewState(FixtureViewState.ShapeView, fixtureViewState)));

          if (fixtureViewState === FixtureViewState.Floorplan) {
            floorplanButtonController = new ButtonController('Planner', false, false, true, () => {});
          }

          if (fixtureViewState === FixtureViewState.TwoDView) {
            twodButtonController = new ButtonController(ButtonLabels.SwitchTo2D, false, false, true, () => {});
          }

          if (fixtureViewState === FixtureViewState.LabelView) {
            labelButtonController = new ButtonController(ButtonLabels.Notes, false, false, true, () => {});
          }
          if (fixtureViewState === FixtureViewState.ShapeView) {   // b =2d
            shapeButtonController = new ButtonController(ButtonLabels.Shapes, false, false, true, () => {});
          }
        }

        if (globalViewState === GlobalViewState.Layout) {
          floorplanButtonController = new ButtonController('Planner', true, false, false, () => {});
          twodButtonController = new ButtonController(ButtonLabels.SwitchTo2D, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.TwoDView)));
          attendeeViewButtonController = new ButtonController(ButtonLabels.Attendees, false, layoutLoading, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.AttendeeView)));
          labelButtonController = new ButtonController(ButtonLabels.Notes, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.LabelView)));
          numberButtonController = new ButtonController(ButtonLabels.NumberTables, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.NumberView)));
          shapeButtonController = new ButtonController(ButtonLabels.Shapes, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.ShapeView)));

          if (layoutViewState === LayoutViewState.TwoDView) {   // b =2d
            twodButtonController = new ButtonController(ButtonLabels.SwitchTo2D, false, false, true,  () => {});
          }
          if (layoutViewState === LayoutViewState.AttendeeView) {
            attendeeViewButtonController = new ButtonController(ButtonLabels.Attendees, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.LabelView) {   // b =2d
            labelButtonController = new ButtonController(ButtonLabels.Notes, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.NumberView) {   // b =2d
            numberButtonController = new ButtonController(ButtonLabels.NumberTables, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.ShapeView) {   // b =2d
            shapeButtonController = new ButtonController(ButtonLabels.Shapes, false, false, true, () => {});
          }
        }

      // PLUS LICENSE
      }

      if (license === LicenseType.PlacezPlus || license === LicenseType.PlacezPro) {
        if (globalViewState === GlobalViewState.Fixtures) {
          threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, false, () =>  dispatch(ChangeFixtureViewState(FixtureViewState.ThreeDView, fixtureViewState)));

          if (fixtureViewState === FixtureViewState.TwoDView) {
            twodButtonController = new ButtonController(ButtonLabels.SwitchTo2D, false, false, true, () => {});
          }

          if (fixtureViewState === FixtureViewState.ThreeDView) {
            threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, true, () => {});
          }

          if (fixtureViewState === FixtureViewState.ThreeDView) {
            threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, true, () => {});
          }
        }

        if (globalViewState === GlobalViewState.Layout) {
          threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.ThreeDView)));
          streetViewButtonController = new ButtonController(ButtonLabels.StreetView, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.StreetView)));
          attendeeViewButtonController = new ButtonController(ButtonLabels.Attendees, false, layoutLoading, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.AttendeeView)));

          if (layoutViewState === LayoutViewState.TwoDView) {   // b =2d
            streetViewButtonController = new ButtonController(ButtonLabels.StreetView, false, true, false, () => {});
            threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.ThreeDView)));
          }
          if (layoutViewState === LayoutViewState.ThreeDView) {   // b =2d
            threedButtonController = new ButtonController(ButtonLabels.SwitchTo3D, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.StreetView) {
            streetViewButtonController = new ButtonController(ButtonLabels.StreetView, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.AttendeeView) {
            attendeeViewButtonController = new ButtonController(ButtonLabels.Attendees, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.NumberView) {   // b =2d
            numberButtonController = new ButtonController(ButtonLabels.NumberTables, false, false, true, () => {});
          }
        }
      }

      // PREMIUM LICENSE
      if (license === LicenseType.PlacezPro) {

        if (globalViewState === GlobalViewState.Fixtures) {
          photosphereButtonController = new ButtonController(ButtonLabels.Photosphere, false, false, false, () => dispatch(ChangeFixtureViewState(FixtureViewState.PhotosphereView, fixtureViewState)));

          if (fixtureViewState === FixtureViewState.PhotosphereView) {
            photosphereButtonController = new ButtonController(ButtonLabels.Photosphere, false, false, true, () => {});
          }
        }

        if (globalViewState === GlobalViewState.Layout) {

          photosphereButtonController = new ButtonController(ButtonLabels.Photosphere, false, layoutLoading, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.PhotosphereView)));
          streetViewButtonController = new ButtonController(ButtonLabels.StreetView, false, false, false, () => dispatch(ChangeLayoutViewState(LayoutViewState.StreetView)));

          if (layoutViewState === LayoutViewState.PhotosphereView) {
            photosphereButtonController = new ButtonController(ButtonLabels.Photosphere, false, false, true, () => {});
          }
          if (layoutViewState === LayoutViewState.StreetView) {
            streetViewButtonController = new ButtonController(ButtonLabels.StreetView, false, false, true, () => {});
          }
        }
      }

      setFloorplanButtonController(floorplanButtonController);
      setTwodButtonController(twodButtonController);
      setThreedButtonController(threedButtonController);
      setPhotosphereButtonController(photosphereButtonController);
      setStreetViewButtonController(streetViewButtonController);
      setAttendeeViewButtonController(attendeeViewButtonController);
      setLabelButtonController(labelButtonController);
      setNumberButtonController(numberButtonController);
      setShapeButtonController(shapeButtonController)
  }, [
    globalViewState,
    fixtureViewState,
    layoutLoading,
    layoutViewState,
    plannerToolState,
  ])

  const renderAddButtons = () => {
    const {
      guest,
    } = props;

    if (globalViewState !== GlobalViewState.Layout) {
      return null;
    }

    return (<>
      <Box m={1}/>
      {layoutViewState !== LayoutViewState.PhotosphereView && layoutViewState !== LayoutViewState.StreetView &&
        <SideBarButton
          label="Black and White"
          selected={shaderView === ShaderView.BlackAndWhite}
          onClick={() => {
            if (shaderView === ShaderView.None) {
              dispatch(SetShaderView(ShaderView.BlackAndWhite));
            } else {
              dispatch(SetShaderView(ShaderView.None));
            }
          }}>
          <BWIcon fontSize="large" />
        </SideBarButton>
      }
      {
      guest ?
        <SideBarButton
          label="Screenshot"
          onClick={() => { dispatch(Screenshot(true, false, false)); }}>
          <ScreenshotIcon fontSize="large" />
        </SideBarButton>
      :
        <SimpleModal>
          <SideBarButton
            label="Screenshot">
            <ScreenshotIcon fontSize="large"/>
          </SideBarButton>
          <ScreenshotModal/>
        </SimpleModal>

      }
      <SideBarButton
        label="Chat"
        selected={chatPanelState}
        onClick={() => dispatch(ToggleChatPanel())}>
        <Chat fontSize="large" />
      </SideBarButton>
    </>);
  }

  const {
    guest,
  } = props;

  return (
    <div>
      {
        guest ?
          (<SideBarButton
            label="Refresh"
            onClick={() => { window.location.reload(); }}>
            <Refresh fontSize="large" />
          </SideBarButton>)
          :
          (<SideBarButton
            disabled={dirty}
            label="Back"
            onClick={() => navigate(-1)}>
            <BackIcon fontSize="large" />
          </SideBarButton>)
      }
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={floorplanButtonController}>
        <PlannerIcon fontSize="large" />
      </PlannerSideBarButton>
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={twodButtonController}>
        <div className={classes.textIcon}>2D</div>
      </PlannerSideBarButton>
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={threedButtonController}>
        <div className={classes.textIcon}>3D</div>
      </PlannerSideBarButton>
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={photosphereButtonController}>
        <Photosphere className={classes.icon} />
      </PlannerSideBarButton>
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={streetViewButtonController}>
        <Streetview className={classes.icon} />
      </PlannerSideBarButton>
      <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={attendeeViewButtonController}>
        <Group className={classes.icon} />
      </PlannerSideBarButton>
      { (!guest || (guest && guestCanEditLayout)) &&
        <>
          <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={labelButtonController}>
            <NotesIcon className={classes.icon} />
          </PlannerSideBarButton>
          <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={numberButtonController}>
            <LooksOne className={classes.icon} />
          </PlannerSideBarButton>
          <PlannerSideBarButton disabled={!ready || navigationDisabled || dirty} buttonController={shapeButtonController}>
            <FormatShapes className={classes.icon} />
          </PlannerSideBarButton>
        </>
      }

      {ready && renderAddButtons()}
      { ready &&
        globalViewState === GlobalViewState.Fixtures &&
        fixtureViewState !== FixtureViewState.Floorplan &&
        !guest &&
        <SimpleModal>
          <SideBarButton
            label="Screenshot">
            <ScreenshotIcon fontSize="large"/>
          </SideBarButton>
          <ScreenshotModal/>
        </SimpleModal>
      }
      {ready &&
      globalViewState === GlobalViewState.Fixtures &&
      (license === LicenseType.PlacezPlus || license === LicenseType.PlacezPro) &&
      !guest && admin &&
        <ModalConsumer>
          { ({ showModal, props }) =>
            <SideBarButton
              disabled={!ready}
              label="Scene Scan"
              onClick={() => showModal(ScanModal, { ...props })}>
              <Image fontSize="large"/>
            </SideBarButton>
          }
        </ModalConsumer>
      }
    </div>
  );
}

export default PlannerSidebar;
