import SceneCard from './SceneCard';
import { Scene } from '../../../api';
import animations from '../../../assets/themes/animations';

interface Props {
  scenes: Scene[];
}

const SceneList = (props: Props) => {
  const { scenes } = props;
  return (
    <div>
      {scenes.map((scene:any, index) =>
      <SceneCard
        key={`card-${index}`}
        scene={scene}
        timeout={index * animations.zoomDuration} />)}
    </div>
  );
}

export default SceneList;
