import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
/* tslint:disable */
export const Send3DIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 128 128">
    <path d="M112.363 45.6425C111.32 45.6425 110.366 44.9688 110.012 43.99C109.659 43.0125 109.967 41.8783 110.77 41.2163C111.576 40.5525 112.736 40.4615 113.635 40.9915C114.533 41.5213 115.015 42.588 114.818 43.6125C114.594 44.7748 113.547 45.6425 112.363 45.6425Z"/>
    <path d="M113.536 30.0279C115.172 29.0129 115.178 26.5979 113.526 25.6099C113.513 25.6022 113.502 25.5964 113.495 25.5924L65.16 0.285188C64.4337 -0.0950625 63.5669 -0.0950625 62.8407 0.285188C62.8407 0.285188 14.4819 25.6052 14.4794 25.6064C13.6429 26.0462 13.1242 26.9184 13.1369 27.8634V27.8647V93.1392C13.1369 94.0837 13.6692 94.9477 14.5129 95.3722L30.2419 103.289V107.452C30.2419 108.346 30.7184 109.171 31.4919 109.617L62.7505 127.665C63.1372 127.888 63.5687 128 64.0005 128C64.4322 128 64.8637 127.888 65.2505 127.665L96.5089 109.617C97.2824 109.171 97.7589 108.346 97.7589 107.452V103.289L113.488 95.3722C114.331 94.9477 114.864 94.0837 114.864 93.1392V54.4842C114.864 53.1527 113.859 51.9899 112.53 51.9039C111.074 51.8097 109.864 52.9627 109.864 54.3984V91.5987L97.7589 97.6917V71.3587C97.7589 70.4654 97.2824 69.6402 96.5089 69.1937L69.0344 53.3312L113.497 30.0509C113.506 30.0467 113.519 30.0389 113.536 30.0279ZM64.0005 5.32194L106.974 27.8224L64.0005 50.3232L21.0267 27.8224L64.0005 5.32194ZM18.1369 91.5989V31.9534L58.9664 53.3314L31.4919 69.1939C30.7184 69.6404 30.2419 70.4657 30.2419 71.3589V97.6922L18.1369 91.5989ZM92.7587 106.009L64.0002 122.613L35.2417 106.009V72.8019L64.0002 56.1982L92.7587 72.8019V106.009Z"/>
    <path d="M56.6171 95.1845V94.4705C56.6171 91.9525 55.0763 91.464 53.0091 91.464C51.7316 91.464 51.3181 90.3367 51.3181 89.209C51.3181 88.0817 51.7316 86.9542 53.0091 86.9542C54.4373 86.9542 55.9403 86.7662 55.9403 83.7222C55.9403 81.5425 54.7003 81.0165 53.1596 81.0165C51.3183 81.0165 50.3788 81.4675 50.3788 82.933C50.3788 84.2107 49.8151 85.075 47.6353 85.075C44.9296 85.075 44.5911 84.5112 44.5911 82.7075C44.5911 79.7762 46.6956 75.9805 53.1596 75.9805C57.9323 75.9805 61.5403 77.7092 61.5403 82.7827C61.5403 85.5262 60.5253 88.0817 58.6463 88.946C60.8638 89.773 62.4798 91.4265 62.4798 94.4705V95.1845C62.4798 101.348 58.2331 103.678 52.9718 103.678C46.5078 103.678 44.0273 99.732 44.0273 96.5752C44.0273 94.884 44.7413 94.433 46.8086 94.433C49.2138 94.433 49.8151 94.959 49.8151 96.3872C49.8151 98.1535 51.4686 98.567 53.1598 98.567C55.7148 98.5667 56.6171 97.6272 56.6171 95.1845Z"/>
    <path d="M74.7287 75.9807C79.9147 75.9807 83.9735 78.386 83.9735 84.85V94.5835C83.9735 101.047 79.9147 103.452 74.7287 103.452H68.0767C66.536 103.452 65.5215 102.626 65.5215 101.686V77.747C65.5215 76.8075 66.536 75.9805 68.0767 75.9805H74.7287V75.9807ZM71.384 81.092V98.3417H74.7287C76.833 98.3417 78.1107 97.1392 78.1107 94.5837V84.8502C78.1107 82.2947 76.833 81.0922 74.7287 81.0922H71.384V81.092Z"/>
  </SvgIcon>
);

export default Send3DIcon;
