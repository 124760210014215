import React, { useEffect, useState } from 'react';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import classnames from 'classnames';
// Components
import {
  Button,
  ClickAwayListener,
  Paper,
  Grow,
  Popper,
  makeStyles,
} from '@material-ui/core';
import { Filter } from './Filters';
import MultiFilterMenu from './MultiFilterMenu';

// Icons
import { ExpandMore as ExpandIcon } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { SetSceneFilters } from '../../reducers/settings';

interface Props {
  filters: Filter<any>[];
  disabled?: boolean;
  className?: string;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    popup: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      padding: theme.spacing(),
      paddingTop: theme.spacing(2),
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    menu: {
      margin: `0px ${theme.spacing(4)}px`,
    },
    menuHeading: {
      ...theme.typography.body2,
      fontSize: 11,
      color: theme.palette.grey[400],
      textTransform: 'uppercase',
      paddingBottom: theme.spacing(),
      borderBottom: `solid 1px ${theme.palette.grey[400]}`,
    },
    menuItem: {
      padding: `0px ${theme.spacing(6)}px 0px ${theme.spacing()}px`,
      fontSize: 14,
      fontWeight: 'bold',
    },
    selectedMenuItem: {
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    button: {
      textEmphasis: 'bold',
      textTransform: 'capitalize',
    },
    expandIcon: {
      color: theme.palette.grey[500],
    },
    apply: {
      margin: '0px 8px 8px 0px',
    },
    cancleButton: {
      color: 'white',
    }
  })
)

const MultiFilter = (props: Props) => {
  const classes = styles(props);

  const [open, setOpen] = useState(false)
  const stateFilterMap  = useSelector((state: ReduxState) => state.settings.sceneFilters);
  const [filterMap, setFilterMap] = useState(stateFilterMap)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const dispatch = useDispatch();

  const resetFilters = () => {
    setFilterMap(stateFilterMap);
  }



  const handleToggle = event => {
    setAnchorEl(event.currentTarget);
    setOpen(!open)
    if (open) {
      resetFilters();
    }
  }

  const handleClose = (event: any) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false)
    resetFilters();
  }

  useEffect(() => {
    resetFilters();
  }, [])

  useEffect(() => {
    setFilterMap(stateFilterMap);
  }, [stateFilterMap])

  useEffect(() => {
    dispatch(SetSceneFilters(filterMap));
  }, [filterMap])


  const clearFilters = () => {
    dispatch(SetSceneFilters({}));
    setOpen(false);

  }

  const onChange = (filterName: string) => (value: any) => {
    const currentValue = filterMap[filterName];
    setFilterMap({
      ...filterMap,
      [filterName]: value === currentValue ? undefined : value
    })
  }

  const handleApply = (event: any) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setOpen(false);
  }

  const getFilterText = (): string => {
    const { filters } = props;
    const filterNames = [];
    if (filters && filterMap) {
      const keys = Object.keys(filterMap);
      filters.forEach((filter: Filter<any>) => {
        if (keys.includes(filter.name)) {
          const selectedId = filterMap[filter.name];
          if (selectedId !== undefined &&
            filter.items[selectedId] !== undefined) {
            filterNames.push(filter.items[selectedId].name);
          }
        }
      });
    }
    return filterNames.length > 0 ? filterNames.join(', ') : 'Filter By';
  }

    const { filters } = props;

    return (
      <div>
        <Button
          disabled={props.disabled}
          className={classnames(props.className, classes.button)}
          aria-owns={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          {getFilterText()}
          <ExpandIcon className={classes.expandIcon}/>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          style={{ zIndex: 999 }}
          placement="top-start">
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <div className={classes.popup}>
                    <div className={classes.menuContainer}>
                      {
                        filters.map((filter:Filter<any>) =>
                          <MultiFilterMenu
                            key={`filter-${filter.name}`}
                            selectedId={filterMap[filter.name]}
                            filter={filter}
                            onChange={onChange(filter.name)}/>)
                      }
                    </div>
                    <div>
                      <Button
                        onClick={() => {clearFilters()}}
                        classes={{
                          root: classes.cancleButton,
                        }}
                        className={classes.apply}
                        color="secondary"
                        variant="contained">
                        Clear
                      </Button>
                      <Button
                        onClick={handleApply}
                        className={classes.apply}
                        color="primary"
                        variant="contained">
                        Close
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
}

export default MultiFilter;
