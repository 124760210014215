import {
  Theme, createStyles,
} from '@material-ui/core';

const settingStyles = (theme: Theme) => createStyles({
  root: {
    padding: theme.spacing(3),
  },
  label: {
    fontSize: 16,
    marginBottom: theme.spacing(),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexWrap: 'wrap',
    marginTop: theme.spacing(3),
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(),
    padding: theme.spacing(2),
  },
  select: {
    width: 200,
  },
  actions: {
    marginTop: 4 * theme.spacing(),
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  actionButton: {
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    margin: 2 * theme.spacing(),
    borderRadius: theme.shape.borderRadius,
    width: 200,
    height: 40,
  },
});

export default settingStyles;
