import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormLabel, InputAdornment, InputLabel, MenuItem, Select, Theme, withStyles } from '@material-ui/core';
import { BillingRate } from '../../blue/core/utils';
import { PlacezFixturePlan } from '../../api';

import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import modalStyles from '../Blue/models/modalStyles.css';
import { makeStyles } from '@material-ui/styles'

interface Props {
  open: boolean;
  onSetPrice: (price?: number, rate?: BillingRate) => void;
  fixturePlan: PlacezFixturePlan;
}

const PlacePriceModal = (props: Props) => {

  const [price, setPrice] = useState(0);
  const [priceRateInHours, setPriceRateInHours] = useState(BillingRate.PerDay);

  const onSetRate = (event: React.ChangeEvent<{ value: BillingRate}>) => {
    setPriceRateInHours(event.target.value);
  }

  const handleCancel = () => {
    props.onSetPrice();
  }

  const handleContinue = () => {
    props.onSetPrice(price, priceRateInHours);

  }

  useEffect(() => {
    setPrice(props.fixturePlan?.price ?? 0);
    setPriceRateInHours(props.fixturePlan?.priceRateInHours ?? 0);
  }, [props.fixturePlan]);

  const styles = makeStyles<Theme>(modalStyles);
  const classes = styles(props);

  return (
    <Dialog
      open={props.open}
    >
      <DialogTitle>Set Rental Rate</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" >
          <div className={classes.rowContainer}>
            <FormLabel className={classes.fieldHeadingLight}>
              Price
            </FormLabel>
            <CurrencyTextField
              style={{flex: 1}}
              defaultValue={price}
              outputFormat="number"
              currencySymbol="$"
              onChange={e => setPrice(e.target.value)}
              />
          </div>
          <div className={classes.rowContainer}>
            <FormLabel className={classes.fieldHeadingLight}>
              Rate Per
            </FormLabel>
            <Select
              style={{flex: 1}}
              id="placeSelect"
              value={priceRateInHours}
              onChange={onSetRate}>
              {Object.values(BillingRate)
                .filter((v) => isNaN(Number(v)))
                .map((rate) =>
                  <MenuItem value={BillingRate[rate]}>{rate}</MenuItem>
                )}
            </Select>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button  variant="contained" onClick={handleContinue} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlacePriceModal;
