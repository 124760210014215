import {
  Theme,
  createStyles,
  makeStyles}
from '@material-ui/core/styles';

import GridCard from './GridCard';
import { Scene } from '../../../api';
import animations from '../../../assets/themes/animations';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: theme.spacing(3),
    },
    virtualCard: {
      width: gridCardWidth,
    },
  })
);

const gridCardWidth = 335;
const widescreenMaxCardsPerRow = [...Array(10).keys()];

interface Props {
  scenes: Scene[];
}

const SceneGrid = (props: Props) => {
  const classes = styles(props);
  const { scenes } = props;
  return (
    <div className={classes.root} >
      {
        scenes.map((scene:any, index) =>
          <GridCard
            key={`card-${index}`}
            scene={scene}
            timeout={index * animations.zoomDuration}
          />
        )
      }
      {
        widescreenMaxCardsPerRow.map(
          () => <div className={classes.virtualCard}></div>
        )
      }
    </div>
  );
}

export default SceneGrid;
