import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import {
  Typography,
  Input ,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Tabs,
  Tab,
  Paper,
  Tooltip,
  Theme,
  makeStyles,
} from '@material-ui/core';

import {
  Clear,
  Search,
  Favorite,
  LibraryBooks,
} from '@material-ui/icons';
import CategoryAccordion from './CategoryAccordion';

import { ApplicationStateService } from '../../../../../blue/model/state';
import ConfigureItemForm from './ConfigureItemForm';

// Models
import {
   PrimaryCategory,
   SecondaryCategory,
   Sku,
} from '../../../../../api';
import {
  Catalog,
  CatalogType
} from '../../../../../models';
import { Asset, SkuType } from '../../../../../blue/items/asset';
import { AddAssetControls } from '../../../models';

import { State  as AssetState, SetAssetFilter } from '../../../../../reducers/asset';

// Utils
import { ReduxState } from '../../../../../reducers';
import TabPanel from '../../../../Modals/TableConfigModal/TabPanel';
import ItemList from './ItemList';
import AssetGroupList from './AssetGroupList';
import { createSelector } from 'reselect';
import panelStyles from '../panels.css';
import { AssetGroup } from '../../../../../blue/items';
import { AssetGroupIcon } from '../../../../../assets/icons';
import { saveToLocalStorage } from '../../../../../sharing/utils/localStorageHelper';
import { useSessionStorageState } from '../../../../Hooks/useSessionStorageState'
import { LocalStorageKey, useLocalStorageState } from '../../../../Hooks/useLocalStorageState'

interface Props {
  addAssetControls: AddAssetControls;
  panelTitle?: string;
  catalogType: CatalogType;
};

const searchCategoryName = 'Search Result';

const AddItemPanel = (props: Props) => {
  const styles = makeStyles<Theme>(panelStyles);
  const dispatch = useDispatch();
  const classes = styles(props);

  const catalogs = useSelector((state: ReduxState) => state.asset.catalogs);
  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);
  const assetFilter = useSelector((state: ReduxState) => state.asset.assetFilter);
  const selectedItems = useSelector((state: ReduxState) => state.blue.selectedItems);
  const selectedSurfaces = useSelector((state: ReduxState) => state.blue.selectedSurfaces);
  const customSkus = useSelector((state: ReduxState) => filterLinens(state));
  const configuredAssets = useSelector((state: ReduxState) => state.blue.configuredAssets);
  const assetGroups = useSelector((state: ReduxState) => state.asset.assetGroups);

  const [selectedItem, setSelectedItem] = useState(undefined);

  const [tabIndex, setTabIndex] = useSessionStorageState("addItemIndex", 0);

  const [selectedCatalogName, setSelectedCatalogName] = useLocalStorageState(LocalStorageKey.SelectedCatalogName, 'Banquet Library');
  const [categoriesTree, setCategoriesTree] = useState<PrimaryCategory[]>([] as PrimaryCategory[]);

  const [categorySecondSelected, setCategorySecondSelected] = useLocalStorageState(LocalStorageKey.CategorySecondSelected, '');
  const [filteredCategories, setFilteredCategories] = useState<SecondaryCategory[]>([] as SecondaryCategory[]);
  const [allSkus, setAllSkus] = useState<Sku[]>([]);

  const appState: ApplicationStateService = ApplicationStateService.getInstance();

  useEffect(() => {
    if ((catalogs?.length > 0) && (assetsBySku)) {
      const availableCatalogs = catalogs
      .filter(isAvailable)
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder;
      });

      if (!selectedCatalogName) {
        setSelectedCatalogName(availableCatalogs[0].name)
      }
      const flatSkus = []
      catalogs
        .filter(isAvailable)
        .forEach((catalog: Catalog) => {
          catalog.categories.forEach(primaryCategory => {
            primaryCategory.subCategories.forEach(subCategory => {
              flatSkus.push(...subCategory.itemSkus);
            });
          });
        })

      setAllSkus(flatSkus);
    }
  }, [catalogs])

  useEffect(() => {
    if (selectedCatalogName) {
      saveToLocalStorage('selectedCatalogName', selectedCatalogName);
      updateCategoriesTree();
    }
  }, [
    selectedCatalogName, catalogs
  ])

  useEffect(() => {
    if (categoriesTree?.length > 0) {
      if (!categoriesTree.some((secondaryCategory) => {
        return secondaryCategory.name === categorySecondSelected
      })) {
        setCategorySecondSelected(categoriesTree[0].name)
      }
      filterCategories();
    }
  }, [
    categoriesTree
  ])

  useEffect(() => {
      filterCategories();
      if (categorySecondSelected) {
        saveToLocalStorage('categorySecondSelected', categorySecondSelected);
      }
  }, [
    categorySecondSelected
  ])

  const isAvailable = (catalog: Catalog) => catalog.owned;

  const searchSkus = (skus: Sku[], filterString: string): Sku[] => {
    if (filterString === '') return skus;
    const assetFilterLC = assetFilter.toLowerCase();
    const searchResult = skus
      .filter((itemSku: Sku) => {
        return searchIncludes(itemSku, assetFilterLC);
      })

    return Object.keys(searchResult).map(key => searchResult[key]);
  }

  const searchIncludes = (itemSku, filterString): boolean => {
    const { asset } = itemSku;
    let matchTags = false;
    let matchName = false;
    let matchTitle = false;
    if (asset?.tags) {
      matchTags = asset.tags
        .filter(tag => tag.toLowerCase().includes(filterString))
        .length > 0;
    }
    if (matchTags) return true;
    if (asset?.name) {
      matchName = asset.name.toLowerCase().includes(filterString);
    }
    if (matchName) return true;
    if (asset?.labels?.titleLabel) {
      matchTitle = asset.labels.titleLabel.toLowerCase().includes(filterString);
    }
    if (matchTitle) return true;
    return false;
  }

  const filterCategories = () => {
    if (assetFilter === '') {
      setFilteredCategories(
        categoriesTree
          .find((category: PrimaryCategory) => {
            return category.name === categorySecondSelected;
          })
          ?.subCategories
      )
    }
  }

  const updateCategoriesTree = () => {
    const primaries = [] as PrimaryCategory[];
    if (catalogs.length === 0) return;
    catalogs
      .filter(isAvailable)
      .find((catalog: Catalog) => catalog.name === selectedCatalogName)
      ?.categories
      ?.sort((a, b) =>
        { return a.sortOrder - b.sortOrder; })
      ?.forEach(category => {
          // attach asset to catalogSku
        category.subCategories.forEach(sub => {
          sub.itemSkus.forEach(catalogSku => {
            catalogSku.asset = assetsBySku[catalogSku.sku];
          });
        });
        primaries.push({
          name: category.name,
          subCategories : category.subCategories,
          sortOrder: category.sortOrder,
        });
      });
    setCategoriesTree(primaries);
  }

  const handleDragAsset = (event: any, dragType: string, asset: Asset) => {
    props.addAssetControls.onDragAndDrop(event, asset, dragType);
  }

  const handleDragAssetGroup = (event: any, dragType: string, assetGroup: AssetGroup) => {
    props.addAssetControls.onDragAndDropAssetGroup(event, assetGroup, dragType);
  }

  const setSelectedCatalog = e => {
    if (selectedCatalogName) {
      setSelectedCatalogName(e.target.value);
    } else {
      setSelectedCatalogName(
        catalogs
          .filter(isAvailable)[0].name
      )
    }
  }

  const setCategorySecond = e => {
    if (categorySecondSelected) {
      setCategorySecondSelected(e.target.value);
    } else {
      const cat = categoriesTree.filter(category => {
        return category.name === e.target.value;
      });
      if (cat.length > 0) {
        setCategorySecondSelected(cat[0].name)
      } else {
        console.error('############ catlog error');
      }
    }
  }

  const selectTab = (e: React.ChangeEvent, v: number) => {
    setTabIndex(v);
  }

  const handleSelect = (item:Asset) => {
    setSelectedItem(configuredAssets[item.id] ? { ...configuredAssets[item.id] } : { ...item } );
  }

  const renderConfigureItem = () => {
    return (
      <ConfigureItemForm
          item= {selectedItem}
      />
    );
  }

  const {
    panelTitle,
  } = props;


  return (
    <Paper className={classes.root}
      style={{ display: (selectedItems.length <= 0 && selectedSurfaces.length <= 0 ? 'flex' : 'none') }}>
      <div className={classes.panelUpper}>
        <div className={classes.mainHeadingContainer}>
          <Typography className={classes.title}>
            { panelTitle ?? 'Add Items' }
          </Typography>
        </div>

        <Tabs
          className={classes.tabs}
          value={tabIndex}
          onChange={selectTab}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="primary"
          aria-label="Table Configuration"
        >
          <Tooltip title="Items">
            <Tab
              className={classes.tabIcon}
              color="secondary"
              icon={<LibraryBooks/>} />
          </Tooltip>
          <Tooltip title="Favorites">
          <Tab
            className={classes.tabIcon}
            icon={<Favorite/>} />
          </Tooltip>
          <Tooltip title="Groups">
          <Tab
            className={classes.tabIcon}
            icon={<AssetGroupIcon/>}
          />
          </Tooltip>
        </Tabs>

        <FormControl className={classes.controls}>
          <Input
            placeholder="Search Library"
            id="adornment-password"
            value={assetFilter}
            onChange={event => { dispatch(SetAssetFilter(event.target.value)); }}
            endAdornment={
              <>
                { assetFilter !== '' &&
                  <IconButton
                    onClick={event => { dispatch(SetAssetFilter('')) }}
                  >
                    <Clear/>
                  </IconButton>
                }
                { assetFilter === '' &&
                  <IconButton>
                    <Search />
                  </IconButton>
                }
              </>
            }
          />
        </FormControl>
      </div>
      <div className={classes.panelLower} >
        <TabPanel
          value={tabIndex}
          index={0}
          className={classes.tabPanel}
          >
          {assetFilter === '' ?
          <>
            <FormControl className={classes.controls}>
              <Select
                value={selectedCatalogName}
                onChange={setSelectedCatalog}
              >
                { catalogs
                  .filter(isAvailable)
                  .map((cat, index) =>
                    <MenuItem
                      key={`place-select-${index}`}
                      value={cat.name}
                      >
                      {cat.name}
                    </MenuItem>
                  )
                }

              </Select>
            </FormControl>
            <FormControl className={classes.controls}>
              <Select
                value={categorySecondSelected}
                onChange={setCategorySecond}
              >
                { categoriesTree
                  .map((cat, index) =>
                    <MenuItem
                      key={`place-select-${index}`}
                      value={cat.name}
                      >
                      {cat.name}
                    </MenuItem>
                  )
                }

              </Select>
            </FormControl>
            <CategoryAccordion
              categories={filteredCategories}
              onDragAsset={handleDragAsset}
              onSelect={handleSelect}
              />
            </>
          :
            <ItemList
              skus={searchSkus(allSkus, assetFilter)}
              onDragAsset={handleDragAsset}
              onSelect={handleSelect}
              categories={assetFilter === ''}
            />
          }
        </TabPanel>

        <TabPanel
          value={tabIndex}
          index={1}
          className={classes.tabPanel}
          >
            <ItemList
              skus={searchSkus(customSkus, assetFilter)}
              onDragAsset={handleDragAsset}
              onSelect={handleSelect}
              categories={assetFilter === ''}
              canDelete
            />
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={2}
          className={classes.tabPanel}
          >
          <AssetGroupList
            assetGroups={assetGroups.filter((assetGroup: AssetGroup) => {
              if (assetFilter === '') return true;
              return assetGroup.name.toLowerCase().includes(assetFilter.toLowerCase());
            })}
            onDragAssetGroup={handleDragAssetGroup}
          />
        </TabPanel>
      </div>
      <div className={classes.panelFooter}>
        {
          renderConfigureItem()
        }
      </div>
    </Paper>
  );
}

const getCustomSkus = (state: ReduxState) => {
  return state.asset.customSkus;
};

const filterLinens = createSelector([getCustomSkus], customSkus => {
  return customSkus.filter((sku: Sku) => {
    return SkuType[sku.asset.skuType] !== SkuType.LIN
  });
});

export default AddItemPanel;
