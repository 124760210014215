import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  BluePlaceDesigner,
} from '../../components/Blue';
import { SelectPlace } from '../../reducers/place';
import { InitializeFloorPlanDesign } from '../../reducers/designer';
import { ChangeFixtureViewState } from '../../reducers/globalState';
import { FixtureViewState } from '../../models/GlobalState';
import { ReduxState } from '../../reducers';

type Props = {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      height: '100%',
      width: '100%',
      overflow: 'hidden',
    },
  })
);

const PlacePlanner = (props: Props) => {
  const dispatch = useDispatch();
  const classes = styles(props);
  const { id, floorPlanId } = useParams();
  const fixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);

  useEffect(() => {
    dispatch(ChangeFixtureViewState(FixtureViewState.Floorplan, fixtureViewState));
  }, [])

  useEffect(() => {
    if (id) {
      dispatch(SelectPlace(parseInt(id)));
    }
    if (floorPlanId) {
      dispatch(InitializeFloorPlanDesign(floorPlanId))
    } else {
      console.log('floorPlanId undefined');
    }
  }, [id, floorPlanId])

  return (
    <div className={classes.root}>
      {id && floorPlanId &&
        <BluePlaceDesigner
          placeId={parseInt(id)}
          floorPlanId={parseInt(floorPlanId)}
        />
      }
    </div>
  );
}

export default PlacePlanner;

