import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  TextField,
  Slide,
  makeStyles,
} from '@material-ui/core';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      margin: '0px 25px',
    },
    paragraph: {
      ...theme.typography.body1,
      fontSize: 12,
      margin: 0,
      marginBottom: `${theme.spacing()}px`,
    },
    formContainer: {
      marginBottom: `${theme.spacing(3)}px`,
    },
    field: {
      width: '100%',
      marginBottom: `${theme.spacing()}px`,
    },
  })
);

interface Props {
  email: string;
  name: string;
  onEmailChange: (event) => void;
  onNameChange: (event) => void;
};

const EmailForm = (props: Props) => {
  const {
    email,
    name,
    onEmailChange,
    onNameChange,
  } = props;

  const classes = styles(props);

  return(
    <Slide direction="left" in={true} timeout={500}>
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <TextField
            className={classes.field}
            id="email"
            label="Email"
            value={email}
            onChange={onEmailChange}
          />
          <TextField
            className={classes.field}
            id="name"
            label="Name"
            value={name}
            onChange={onNameChange}
          />
        </div>
      </div>
    </Slide>
  );
}

export default EmailForm;
