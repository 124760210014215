import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Theme,
}
  from '@material-ui/core/styles';
import {
  CloudDownload,
} from '@material-ui/icons';
import { Scene } from '../../../api';
import { Typography, Slide, Paper, makeStyles, IconButton, Tooltip } from '@material-ui/core';
import { sceneRoutes } from '../../../routes';
// Components
import { Grid, GridColumn, GridCell, GridCellProps } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from "@progress/kendo-react-excel-export";

import { ReduxState } from '../../../reducers';
import { tableStyles } from '../../Tables/tableSyles.css';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { PDFIcon } from '../../../assets/icons';
import ArrowNavigateCell from '../../Tables/ArrowNavigateCell';
import { LocalStorageKey, useLocalStorageSelector } from '../../Hooks/useLocalStorageState'

interface Props {
  scenes: Scene[];
  onRowClick?: (event: any) => void;
  hideClient?: boolean;
}

class ViewSceneByNameCell extends GridCell {
  render() {
    const { name, id } = this.props.dataItem;
    const { className } = this.props;
    return (
      <td>
        <a href={sceneRoutes.edit.path.replace(':id', id)} >
          <Typography className={className}>{name}</Typography>
        </a>
      </td>
    );
  }
}

class StartDateCell extends GridCell {
  render() {
    const { date } = this.props.dataItem;
    const utcDate = new Date(date);
    if (date === undefined || date === null) {
      return (<td></td>);
    }
    return (
      <td>
        {utcDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: 'numeric' })}
      </td>
    );
  }
}

class StartTimeCell extends GridCell {
  render() {
    const { time } = this.props.dataItem;
    if (time === undefined || time === null) {
      return (<td></td>);
    }
    return (
      <td>
        {time}
      </td>
    );
  }
}
class StatusCell extends GridCell {
  render() {
    const status = this.props.dataItem.status;
    return (<td>{status}</td>);
  }
}

const SceneTable = (props: Props) => {

  const styles = makeStyles<Theme>(tableStyles);
  const classes = styles(props);
  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);

  const clients = useSelector((state: ReduxState) => state.client.clients)
  const getClientById = (clientId: number) => clients
    .find(client => { return client.id === clientId; });

  const places = useSelector((state: ReduxState) => state.place.places)
  const getPlaceById = (placeId: number) => places
    .find(place => { return place.id === placeId; });

  const twentyFourHourTime = useLocalStorageSelector<boolean>(LocalStorageKey.TwentyFourHourTime)

  const grid = useRef();

  const ViewSceneByArrowCell = (props: GridCellProps) => {
    const { id } = props.dataItem;
    return(
      <ArrowNavigateCell {...props}
        path={sceneRoutes.edit.path.replace(':id', id)}
      />
    )
  };

  const columns = [
    {
      field: 'name',
      title: 'Scene Name',
      cell: ViewSceneByNameCell,
      className: `${classes.LinkStyle} ${classes.overflowEllipsis}`,
    },
    { field: 'date', title: 'Date', cell: StartDateCell, className: classes.overflowEllipsis, minShow: 400, width: 130 },
    { field: 'time', title: 'Time', cell: StartTimeCell, className: classes.overflowEllipsis, minShow: 1170, width: 130 },
    { field: 'status', title: 'Status', cell: StatusCell, className: classes.overflowEllipsis, minShow: 570, width: 130 },
    { field: 'placeName', title: 'Place', className: classes.overflowEllipsis, minShow: 970, width: 200 },
    { field: 'floorPlanNames', title: 'Floorplans', className: classes.overflowEllipsis, minShow: 970 },
    { field: '', cell: ViewSceneByArrowCell, className: classes.arrow, width: 60 },
  ];

  if (!props.hideClient) {
    columns.splice(1, 0,
      { field: 'clientName', title: 'Client Name', className: classes.overflowEllipsis, minShow: 770 },
    )
  }

  const [sort, setSort] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(columns);

  const checkColumnMaxWidth = () => {
    if (grid.current) {
      const currentVisibleColumns = columns?.filter(item => !item.minShow || item.minShow <= (grid?.current as any)?.offsetWidth) ?? columns;
      if (currentVisibleColumns.length !== visibleColumns.length) {
        setVisibleColumns(currentVisibleColumns);
      }
    }
  }

  useEffect(() => {
    grid.current = document.querySelector(`.${CSS.escape(classes.gridRoot)}`);
    window.addEventListener('resize', checkColumnMaxWidth);
    return (() => {
      window.removeEventListener('resize', checkColumnMaxWidth);
    })
  }, [])



  const { scenes,  onRowClick, } = props;

  const hydratedScenes = scenes
    .map(scene => {
      const client = getClientById(scene.clientId);
      const place = getPlaceById(scene.placeId);
      const floorPlanNames = [...new Set(scene?.floorPlans?.map((floorPlanInfo: {id: string, name: string}) => floorPlanInfo.name))];

      const timeParams: Intl.DateTimeFormatOptions = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: !twentyFourHourTime
      }
      const time = new Date(scene.startUtcDateTime).toLocaleString('en-US', timeParams);
      const date = scene.startUtcDateTime;

      return {
        ...scene,
        time,
        date,
        clientName: client ? client.name : '',
        placeName: place ? place.name : '',
        floorPlanNames: floorPlanNames ? floorPlanNames.join() : '',
      };
    })
    .filter(scene => scene.name.toLowerCase().includes(globalFilter?.toLowerCase()))

  let gridExcelExport: ExcelExport | null;
  const exportExcel = () => {
    if (gridExcelExport !== null) {
      gridExcelExport.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };

  const kGrid = (forPrint?: boolean) => {
    return (<Grid
      sortable
      sort={sort}
      onSortChange={e => {
        setSort(e.sort);
      }}
      className={classes.gridRoot}
      selectedField="selected"
      data={orderBy(
        hydratedScenes.map(
          scene => ({ ...scene })),
        sort)
      }
      onRowClick={onRowClick}>
        {
          visibleColumns
          .filter(column => forPrint ? (column.field !== '') : true)
          .map((column, index) => {
            return (
              <GridColumn
                field={column.field}
                title={column.title}
                cell={column.cell}
                key={index}
                className={column.className}
                width={column.width}
              />
            );
          })
        }
    </Grid>)
  }


  return (
    <div>
      <Paper
              style={{position: 'relative'}}>
        <Slide
          direction="up"
          timeout={300}
          in={true}>
          <div>
            <GridPDFExport
              ref={(pdfExport) => (gridPDFExport = pdfExport)}>
              {kGrid(true)}
            </GridPDFExport>
            <ExcelExport
              data={orderBy(
                hydratedScenes.map(
                  scene => ({ ...scene })),
                sort)}
              ref={(excelExport) => (gridExcelExport = excelExport)}>
              {kGrid()}
              <div
                style={{
                  position: 'absolute',
                  top: '4px',
                  right: '4px',
                  zIndex: 1000
                }}
              >
              <Tooltip title='Download Excel'>
                <IconButton
                  color='secondary'
                  onClick={exportExcel}>
                  <CloudDownload/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Download PDF'>
                <IconButton
                  color='secondary'
                  onClick={exportPDF}>
                  <PDFIcon/>
                </IconButton>
              </Tooltip>
              </div>
            </ExcelExport>
          </div>
        </Slide>
      </Paper>
    </div>
  );
}


export default SceneTable;
