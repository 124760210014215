import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import {
  Theme,
  createStyles }
from '@material-ui/core/styles';
import { useNavigate } from 'react-router';
import { CallbackComponent } from 'redux-oidc';
import userManager from '../userManager';
import { User } from 'oidc-client';
import {
  CircularProgress,
  makeStyles,
} from '@material-ui/core';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 1000,
      background: theme.palette.grey[100],
      width: '100vw',
      height: '100vh',
    },
    loading: {
      ...theme.typography.h4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    progress: {
      margin: theme.spacing(),
    },
  })
);

interface Props {};

const Redirect = (props: Props) => {

  const navigate = useNavigate();
  const classes = styles(props);

  const userProfile = useSelector((state: ReduxState) => state?.oidc?.user?.profile);

  useEffect(() => {
    const userLayoutId = userProfile?.['placez-layoutId'];
    if(userLayoutId) {
      localStorage.setItem('placez-layoutId', userLayoutId);
      successCallback();
    }
  }, [ userProfile ])

  const successCallback = (user: User = null):void => {

    if (user) {
      (window as any).gtag('event', 'login');
    }

    const redirectPathname = localStorage.getItem('redirectPathname')
    navigate(redirectPathname && redirectPathname !== '' ? redirectPathname : '/' );

    localStorage.setItem('redirectPathname', '/');
  }

  return (
    <CallbackComponent
      userManager={userManager}
      successCallback={successCallback}
      errorCallback={error => {

        if (!!error && !!error.message) {
          const clockIsBehind = error.message.includes('iat is in the future');
          const clockIsAhead = error.message.includes('exp is in the past');

          if (clockIsAhead || clockIsBehind) {
            alert('Your computer clock does not match the time on our servers. Please set your computer clock to the correct time and retry your connection.');
          }
        }

        console.error(error);
        navigate('/');
      }}
      >
      <div className={classes.root}>
        <div className={classes.loading}>
          <CircularProgress className={classes.progress} />
          Redirecting...
        </div>
      </div>
    </CallbackComponent>
  );
}

export default Redirect;
