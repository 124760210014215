import { RouteSection } from './Route';

// Icons
import {
  RulerIcon,
} from '../assets/icons';

import {
  SettingsOutlined as SettingsIcon,
  Language as LanguageIcon,
  ArrowDropDownCircleOutlined as QuickPicksIcon,
  ReportProblemOutlined as CollisionIcon,
  Restore,
} from '@material-ui/icons';

// Views
import MeasurementSettings from '../views/Settings/MeasurementSettings';
import FormattingSettings from '../views/Settings/FormattingSettings';
import QuickPickSettings from '../views/Settings/QuickPickSettings';
import CollisionSettings from '../views/Settings/CollisionSettings';
import { MainRoute } from '.';

type CustomRouteSection = {
  measurement: MainRoute,
  formatting: MainRoute,
  quickpicks: MainRoute,
  collision: MainRoute,
};

const baseUrl = '/settings';
export const settings: RouteSection & CustomRouteSection = {
  main: {
    path: `${baseUrl}/quickpicks`,
    name: 'Settings',
    icon: SettingsIcon,
    component: QuickPickSettings,
  },
  measurement: {
    path: `${baseUrl}/measurement`,
    name: 'Measurement',
    component: MeasurementSettings,
    icon: RulerIcon,
  },
  formatting: {
    path: `${baseUrl}/formatting`,
    name: 'Formatting',
    component: FormattingSettings,
    icon: LanguageIcon,
  },
  quickpicks: {
    path: `${baseUrl}/quickpicks`,
    name: 'Quickpicks',
    component: QuickPickSettings,
    icon: QuickPicksIcon,
  },
  collision: {
    path: `${baseUrl}/collision`,
    name: 'Collision',
    component: CollisionSettings,
    icon: CollisionIcon,
  },
};

export default settings;
