import { Filter, FilterItem } from './Filter';
import { Scene } from '../../../api';

const filterItems : FilterItem<Scene>[] = [
  {
    id: 0,
    name: '1-50',
    select: scene =>  1 <= scene.estimatedGuestCount && scene.estimatedGuestCount <= 50,
  },
  {
    id: 1,
    name: '50-100',
    select: scene => 50 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 100,
  },
  {
    id: 2,
    name: '100-150',
    select: scene => 100 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 150,
  },
  {
    id: 3,
    name: '150-200',
    select: scene => 150 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 200,
  },
  {
    id: 4,
    name: '200-250',
    select: scene => 200 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 250,
  },
  {
    id: 5,
    name: '250-300',
    select: scene => 250 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 300,
  },
  {
    id: 6,
    name: '300-350',
    select: scene => 300 <= scene.estimatedGuestCount && scene.estimatedGuestCount  <= 350,
  },
];

export class GuestCountFilter implements Filter<Scene>{
  name = 'Guest Count';
  items = filterItems;
}

export default GuestCountFilter;
