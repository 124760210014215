import { Button, FormLabel, Theme, Typography, makeStyles } from "@material-ui/core"
import { Item } from "../../../../blue/items/item"
import panelStyles from "../panels/panels.css"
import NumberEditor from "../../../NumberEditor"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GlobalViewState } from "../../../../models/GlobalState"
import { ReduxState } from "../../../../reducers"
import { Vector3 } from "three"

interface Props {
  itemSelected?: Item;
  width?: number;
  depth?: number;
  height?: number;
  onChange?: (width, depth, height) => void;
}

const EditSize = (props: Props) => {
  const {
    itemSelected,
    onChange,
  } = props;

  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const [itemWidth, setItemWidth] = useState<number>(0);
  const [itemDepth, setItemDepth] = useState<number>(0);
  const [itemHeight, setItemHeight] = useState<number>(0);

  const [size, setSize] = useState<Vector3>(new Vector3());
  const [newSize, setNewSize] = useState<Vector3>(new Vector3());

  const [sizeChange, setSizeChange] = useState<boolean>(false);

  useEffect(() => {
    setSizeChange(true);
    // set new size
  }, [
   itemWidth, itemDepth, itemHeight
  ])

  useEffect(() => {
    setItemWidth(Math.round(props.width ?? itemSelected.getWidth()));
    setItemDepth(Math.round(props.depth ?? itemSelected.getDepth()));
    setItemHeight(Math.round(props.height ?? itemSelected.getHeight()));
    setSize(new Vector3(
      Math.round(props.depth ?? itemSelected.getDepth()),
      Math.round(props.height ?? itemSelected.getHeight()),
      Math.round(props.width ?? itemSelected.getWidth())
    ));
  }, [
   itemSelected, props.width, props.depth, props.height
  ])

  const globalViewState: GlobalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);

  const flipDepth = () => {
    onChange(itemWidth, -itemDepth, itemHeight)
  }

  const flipWidth = () => {
    onChange(-itemWidth, itemDepth, itemHeight)
  }

  return (
    <div
      className={classes.modGroup}>
      <div className={classes.headingContainer}>
        <Typography className={classes.heading} align="center">
          Edit Size
        </Typography>
      </div>
      <div className={classes.fieldColumns}>
        <FormLabel className={classes.fieldHeading}>
          Width
        </FormLabel>
        <NumberEditor
          value={itemWidth}
          onChange={value => { setItemWidth(isNaN(value) ? 0 : value) }}
          step={1}
          round={1}
          dark
        />
      </div>
      <div className={classes.fieldColumns}>
        <FormLabel className={classes.fieldHeading}>
          Depth
        </FormLabel>
        <NumberEditor
          value={itemDepth}
          onChange={value => { setItemDepth(isNaN(value) ? 0 : value) }}
          step={1}
          round={1}
          dark
        />
      </div>
      <div className={classes.fieldColumns}>
        <FormLabel className={classes.fieldHeading}>
          Height
        </FormLabel>
        <NumberEditor
          value={itemHeight}
          onChange={value => { setItemHeight(isNaN(value) ? 0 : value) }}
          step={1}
          round={1}
          dark
        />
      </div>
      <div className={classes.buttonDiv}>
        <Button
          onClick={() => onChange(itemWidth, itemDepth, itemHeight)}
          className={classes.button}
          variant="outlined"
          disabled={!sizeChange}
          classes={{
            root: classes.button,
          }}>
          Set Size
        </Button>
      </div>
      {globalViewState === GlobalViewState.Fixtures &&
        <div className={classes.buttonDiv}>
          <Button
            onClick={flipDepth}
            className={classes.button}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Flip Depth
          </Button>
        </div>
      }
      {globalViewState === GlobalViewState.Fixtures &&
        <div className={classes.buttonDiv}>
          <Button
            onClick={flipWidth}
            className={classes.button}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Flip Width
          </Button>
        </div>
      }
    </div>
  )
}

export default EditSize;
