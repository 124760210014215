import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Slide, makeStyles,
} from '@material-ui/core';

import LinkCopier from './LinkCopier';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    paragraph: {
      ...theme.typography.body1,
      fontSize: 12,
      margin: 0,
      marginBottom: `${theme.spacing()}px`,
    },
  })
);

interface Props {
  link: string;
};

const LinkForm = (props: Props) => {
  const {
    link,
  } = props;

  const classes = styles(props);

  return(
    <Slide direction="left" in={true} timeout={500}>
      <div className={classes.root}>
        <p className={classes.paragraph}>
          Copy and paste this link to open experience.
        </p>
        <LinkCopier link={link}/>
      </div>
    </Slide>
  );
}

export default LinkForm;
