const isColor = (rgb) => {
	return rgb.red === 0 && rgb.green === 0 && rgb.blue === 0;
}

const getRGB = (data, image, x, y) => {
	return {
		red: data[((image.width * y) + x) * 4],
		green: data[((image.width * y) + x) * 4 + 1],
		blue: data[((image.width * y) + x) * 4 + 2]
	};
}

const scanX = (data, image, left) => {
	var offset = (left) ? 1 : -1;

	for (var x = ((left) ? 0 : data.width - 1); ((left) ? (x < data.width) : (x > -1)); x += offset) {
		for (var y = 0; y < data.height; y++) {
			if (!isColor(getRGB(data.data, image, x, y))) {
				return x;
      }
		}
	}

	return null;
}

const scanY = (data, image, top) => {
	var offset = (top) ? 1 : -1;

	for (var y = ((top) ? 0 : data.height - 1); ((top) ? (y < data.height) : (y > -1)); y += offset) {
		for (var x = 0; x < data.width; x++) {
			if (!isColor(getRGB(data.data, image, x, y))) {
				return y;
			}
		}
	}

	return null;
}

export const autoCropper = (imageUrl) => {
  return new Promise(function(resolve, reject) {
    // HTML image element
    const imageElement = new Image();
    imageElement.src = imageUrl;

    imageElement.onload = () => {
      // Create a canvas element
      var canvas = document.createElement('canvas');

      // Get the 2D rendering context of the canvas
      var ctx = canvas.getContext('2d');

      // Set the canvas dimensions to match the image element
      canvas.width = imageElement.width;
      canvas.height = imageElement.height;

      // Draw the image onto the canvas
      ctx.drawImage(imageElement, 0, 0);

      // Get the image data from the canvas
      var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

      var top = scanY(imageData, imageElement, true);
      var bottom = scanY(imageData, imageElement, false);
      var left = scanX(imageData, imageElement, true);
      var right = scanX(imageData, imageElement, false);

      var new_width = right - left;
      var new_height = bottom - top;

      canvas.width = new_width;
      canvas.height = new_height;

      ctx.drawImage(imageElement, left, top, new_width, new_height, 0, 0, new_width, new_height);
      resolve(canvas.toDataURL('image/png'))
    }
  })
}
