import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const RulerIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      d="M 2 6 C 0.9069372 6 0 6.9069372 0 8 L 0 16 C 0 17.093063 0.9069372 18 2 18 L 22 18 C 23.093063 18 24 17.093063 24 16 L 24 8 C 24 6.9069372 23.093063 6 22 6 L 2 6 z M 2 8 L 3 8 L 3 10 L 5 10 L 5 8 L 7 8 L 7 12 L 9 12 L 9 8 L 11 8 L 11 10 L 13 10 L 13 8 L 15 8 L 15 12 L 17 12 L 17 8 L 19 8 L 19 10 L 21 10 L 21 8 L 22 8 L 22 16 L 2 16 L 2 8 z" // tslint:disable-line
      />
  </SvgIcon>
);

export default RulerIcon;
