import { useSelector } from 'react-redux';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import { PickListOption } from '../../../api';
import { ReduxState } from '../../../reducers';
import { makeStyles, Paper } from '@material-ui/core';

interface Props {
  sceneTypeId: number;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      padding: `${theme.spacing() / 2}px ${theme.spacing(3)}px`,
      marginRight: theme.spacing(),
      textTransform: 'uppercase',
      borderRadius: '4px',
    },
    name: {
      ...theme.typography.body1,
      fontSize: 11,
      color: theme.palette.common.white,
    },
  })
);

const categoryColors = [
  '#9013FE', // purple
  '#4A90E2', // blue
  '#FFBE0B', // yellow
  '#FB5607', // orange
  '#FF006E', // magenta
  '#587291', // gray
];

const CategoryTag = (props: Props) => {
  const classes = styles(props);

  const sceneTypePickList = useSelector((state: ReduxState) => state.settings.pickLists
    .find(list => {
      return list.name === 'SceneType';
    })
  );

  const getColor = (allSceneTypes: PickListOption[], sceneType: PickListOption): string => {
    const optionIndex = allSceneTypes.findIndex(option => option === sceneType);
    const colorCount = categoryColors.length;
    const xTimesMore = Math.floor(optionIndex / colorCount);
    const adjustedIndex = optionIndex - (colorCount * xTimesMore);

    return categoryColors[adjustedIndex];
  }

  const { sceneTypeId } = props;
  if (!sceneTypePickList) {
    return (<></>);
  }

  const sceneTypeOption = sceneTypePickList.picklistOptions.find(option => option.id === sceneTypeId);
  if (!sceneTypeOption) {
    return (<></>);
  }

  return (
    <Paper
      className={classes.root}
      style={{ backgroundColor: getColor(sceneTypePickList.picklistOptions, sceneTypeOption) }}>
      <span className={classes.name}>
          {sceneTypeOption.name}
        </span>
    </Paper>
  );
}

export default CategoryTag;
