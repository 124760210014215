import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  createStyles,
  makeStyles}
from '@material-ui/core/styles';
import {
  BlueLayoutDesigner,
} from '../../components/Blue';
import { InitializeLayoutDesign } from '../../reducers/designer';
import { ReduxState } from '../../reducers';
import { GetScene } from '../../reducers/scenes';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        display: 'block',
        width: '8px !important',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderRadius: '5px !important',
        backgroundClip: 'content-box !important',
        border: '2px solid transparent !important',
      },
    },
    root: {
      height: '100%',
    },
  })
);

interface Props {};

const DesignPreview = (props: Props) => {
  const dispatch = useDispatch();
  const classes = styles(props);

  const scene = useSelector((state: ReduxState) => state.scenes.currentScene);
  const layout = useSelector((state: ReduxState) => state.designer.layout);

  useEffect(() => {
    if (layout?.sceneId && scene === null) {
      dispatch(GetScene(layout.sceneId));
    }
  }, [layout, scene])

  useEffect(() => {
    const countDesignerView = true;
    const layoutId = localStorage.getItem('placez-layoutId');
    dispatch(InitializeLayoutDesign(layoutId, countDesignerView));
  }, [])

  const layoutId = localStorage.getItem('placez-layoutId');
  return (
    <div className={classes.root}>
      {layoutId && scene?.id &&
        <BlueLayoutDesigner/>
      }
    </div>
  );
}

export default DesignPreview;
