import { useState, useEffect,  createRef, Fragment } from 'react';
import {
  Typography,
  Tooltip,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ModalConsumer } from '../../../Modals/ModalContext';
import AttendeeModal from '../../../Modals/AddAttendeeModal';

import panelStyles from './panels.css';
import { HandlesFromBlue } from '../../models';
import { AccountCircle, Cancel } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateAttendee, UpdateAttendees, SelectAttendee, UnseatAttendee, SelectTable  } from '../../../../reducers/attendee';
import { ReduxState } from '../../../../reducers';
import { Attendee  } from '../../../../api';
import { guestUpdateAttendee, isGuest } from '../../../../reducers/globalState';
import { HideChairAction } from '../../../../reducers/blue';

interface Props {
  handlesFromBlue: HandlesFromBlue;
  onBack: Function;
  exitAttendees: Function;
};

const AttendeePanel = (props: Props) => {
  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const [moved, setMoved] = useState<boolean>(false);
  const [refs, setRefs] = useState({});
  const [seatsChanged, setSeatsChanged] = useState(false);


  const attendees = useSelector((state: ReduxState) => state.attendee.attendees);
  const selectedAttendeeId = useSelector((state: ReduxState) => state.attendee.selectedId);
  const selectedTableId = useSelector((state: ReduxState) => state.attendee.selectedTableId);
  const allowUpdateAttendee = useSelector((state: ReduxState) => guestUpdateAttendee(state));
  const isAGuest = useSelector((state: ReduxState) => isGuest(state));



  const onMove = (e: TouchEvent) => {
    if (moved) return;
    setMoved(true);
  }

  const onSetAttendee = (e: TouchEvent & MouseEvent, attendee: Attendee) => {
    if (allowUpdateAttendee || !isAGuest) {
      props.handlesFromBlue.onDragAttendee(attendee);
      setSeatsChanged(true);
    }
  }

  const handleListItemClick = (event, selectedId) => {
    dispatch(SelectAttendee(selectedId));
    setMoved(false);
  }

  const handleAddAttendees = (attendees: Attendee[]) => {
    dispatch(UpdateAttendees(attendees));
  }

  const clearTable = (selectedTableId: string, attendees: Attendee[]): void => {
    const unseatedAttendees = attendees.map((attendee: Attendee): Attendee => {
      if (attendee.tableId === selectedTableId) {
        return {
          ...attendee,
          chairNumber: undefined,
          tableId: undefined,
          tableInfo: undefined,
        }
      }
      return attendee;
    })
    dispatch(UpdateAttendees(unseatedAttendees));
    dispatch(SelectAttendee(undefined));
  }

  useEffect(() => {
    updateRefs(attendees);
  }, []);

  const updateRefs = (attendees: Attendee[]) => {
    const refs = attendees.reduce(
      (acc, attendee: Attendee) => {
        const ref = createRef();
        acc[attendee.id] = ref;
        return acc;
      },
      {}
    );
    setRefs(refs);
  }

  useEffect(() => {
    updateRefs(attendees);
  }, [attendees])

  useEffect(() => {
    if (selectedAttendeeId) {
      refs[selectedAttendeeId].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedAttendeeId])


  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.target.blur();
    }
  }

  const AttendeeListItemText: React.FC<{ attendee: any }> = ({ attendee }) => { // Replace "any" with the correct type of "attendee"
  let secondaryText = '';

  if (attendee.tableId) {
    if (attendee.tableInfo) {
      secondaryText += `Table: ${attendee.tableInfo}`;
    }
    if (attendee.chairNumber) {
      secondaryText += secondaryText ? ` Chair: ${attendee.chairNumber}` : `Chair: ${attendee.chairNumber}`;
    }
  }

  return (
    <ListItemText
      primary={`${attendee.firstName} ${attendee.lastName}`}
      secondary={secondaryText}
    />
  );
};

  const attendeelistItems = attendees
    .filter((attendee: Attendee) => {
      if (selectedTableId !== undefined) {
        return attendee.tableId === selectedTableId;
      }
      return true;
    })
    .map(attendee =>
    <Fragment key={attendee.id}>
    <ListItem
      ref={refs[attendee.id]}
      selected={attendee.id === selectedAttendeeId}
      className={classes.attendeeContainer}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
      draggable={true}
      onClick={event => handleListItemClick(event, attendee.id)}
      onDragEnd={(e: any) =>  onSetAttendee(e, attendee)}
      onTouchMove={(e: any) => onMove(e)}
      onMouseMove={(e: any) => onMove(e)}
      onTouchEnd= {(e: any) => onSetAttendee(e, attendee)}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <ListItemAvatar
          style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start'}}>
          <Avatar
            style={{ alignSelf: 'start'}}
            className={attendee.tableId ? classes.seated : undefined}>
            <AccountCircle/>
          </Avatar>
        </ListItemAvatar>
      { (attendee.id === selectedAttendeeId) ?
        <Typography variant="body1">
          {`${attendee.firstName} ${attendee.lastName}`}
        </Typography>
        :
        <AttendeeListItemText attendee={attendee}/>
      }
      </div>
      { (attendee.id === selectedAttendeeId) &&
        <Typography variant="body2" style={{display: 'flex', flexDirection: 'column', margin: '10px'}}>
          <div><b className={classes.secondaryColor}>Table:</b> {attendee.tableInfo ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Chair:</b> {attendee.chairNumber ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Group:</b> {attendee.group ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Meal:</b> {attendee.meal ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Allergies:</b> {attendee.allergies ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Email:</b> {attendee.email ?? '?'}</div>
          <div><b className={classes.secondaryColor}>Phone:</b> {attendee.phone ?? '?'}</div>
          <div><b className={classes.secondaryColor}>RSVP:</b> {attendee.rsvp ?? '?'}</div>
        </Typography>
      }
      { allowUpdateAttendee && attendee.id === selectedAttendeeId && attendee.chairNumber !== undefined &&
        <div style={{display: 'flex', marginLeft: '-15px', marginRight: '-15px', marginBottom: '-5px'}}>
          <Button
            classes={{
              root: classes.button,
            }}
            aria-label="removeAttendee"
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              dispatch(UnseatAttendee(attendee.chairNumber, attendee.tableId));
              dispatch(SelectAttendee(undefined));
              setSeatsChanged(true)
            }}>
            <Typography variant="caption">
              Unseat
            </Typography>
          </Button>
          <Button
            classes={{
              root: classes.button,
            }}
            aria-label="removeChair"
            variant="contained"
            onClick={(e) => {
            dispatch(HideChairAction(attendee))
            setSeatsChanged(true)
          }}>
            <Typography variant="caption">
              Remove Seat
            </Typography>
          </Button>
        </div>
      }
    </ListItem>
    </Fragment>
  );

  return (
    <div className={classes.root}>
      <div className={classes.panelUpper}>
        <div className={classes.mainHeadingContainer}>
          <Typography className={classes.title}>
            Assign Guests
          </Typography>
        </div>
      </div>
      { selectedTableId &&
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <div style={{minWidth: '40px'}}></div>
          <Typography variant="h6" style={{alignSelf: 'center'}}>
            Table Selected
          </Typography>
          <IconButton
            onClick={() => {
              dispatch(SelectTable(undefined));
            }}>
            <Cancel/>
          </IconButton>
        </div>
      }
      <div className={classes.panelLower}>
        <List className={classes.attendeeList}>
          {attendeelistItems}
        </List>
      </div>
      <div className={classes.panelFooter}>
        { selectedTableId &&
          <div className={classes.fieldContainer}>
            <Button
              className={classes.button}
              classes={{
                root: classes.primaryContainedButton,
              }}
              variant="contained"
              onClick={(e) => {
                clearTable(selectedTableId, attendees);
              }}>
              Clear Table
            </Button>
          </div>
        }
        <div className={classes.fieldContainer}>
          <Button
            className={classes.button}
            classes={{
              root: classes.button,
            }}
            onClick={(e) => {
              dispatch(UpdateAttendees());
            }}>
            Refresh
          </Button>
        </div>
        {(allowUpdateAttendee || !isAGuest) &&
          <div className={classes.groupContainer}>
              <ModalConsumer>
              { ({ showModal, props }) =>
              <Tooltip title="Edit Attendees List">
                <Button
                  className={classes.button}
                  onClick={() => {
                    dispatch(SelectAttendee(undefined));
                    showModal(
                      AttendeeModal,
                      { ...props, handleAddAttendees: handleAddAttendees, currentAttendees: props.attendees }
                    )
                  }}
                  variant="outlined"
                  onKeyDown={handleKeyDown}
                  classes={{
                    root: classes.button,
                  }}>
                  Edit Attendees
                </Button>
              </Tooltip>
              }
              </ModalConsumer>
          </div>
        }
      </div>
    </div>
  );
}

export default AttendeePanel;
