import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import Photosphere from './Photosphere.svg';

export const PhotosphereIcon = (props: any) => (
  <SvgIcon {...props} viewBox="70 202 150 150">
    <g>
      <path d="M208.5,273.5c0,4.34,0,8.67,0,13.01c-2.58,12.15-6.23,22.83-15.9,32.5c-17.31,17.29-36.4,25.83-60.47,21.5
        c-21.93-3.94-36.57-18.36-47.76-36.41c-3.07-4.96-3.32-11.67-4.86-17.58c0-4.34,0-8.67,0-13.01c0.26-0.78,0.6-1.54,0.76-2.35
        c2.19-10.87,3.3-22.29,14.64-28.48c0.67-0.36,1.01-1.3,1.54-1.93c17.59-21.1,45.72-27.83,71.37-17.74
        c13.4,5.27,21.59,16.76,32.31,25.2c1.19,0.94,1.86,2.79,2.33,4.35C204.56,259.5,206.5,266.51,208.5,273.5z M143.99,291.82
        c1.74-1.87,3.2-3.4,4.61-4.97c6.83-7.59,5.11-6.68,12.07-0.09c5.74,5.43,11.77,10.91,18.58,14.75c7.51,4.24,15.3-0.01,17.01-8.13
        c2.46-11.63,1.99-23.07-2.42-34.32c-1.52-3.86-3.73-5.72-7.71-6.64c-28.57-6.57-57.09-6.37-85.54,0.44
        c-2.17,0.52-4.6,2.7-5.62,4.76c-4.65,9.37-5.32,19.48-4.35,29.73c0.84,8.84,7.15,12.31,14.59,7.41c4.92-3.24,9.08-7.72,13.31-11.92
        c3.36-3.34,6.3-7.1,9.89-11.21C133.79,278.59,138.72,284.99,143.99,291.82z M153.95,237.27c-0.77-5.93-5.61-11.04-10.25-10.91
        c-4.33,0.12-9,5.36-9.66,10.91C140.68,237.27,147.31,237.27,153.95,237.27z M154.57,322.68c-6.8,0-13.1-0.01-19.39,0.01
        c-0.45,0-0.9,0.16-1.36,0.25c1.61,6.05,5.66,10.45,9.73,10.68C147.88,333.85,151.78,330.15,154.57,322.68z M179.44,321.4
        c-0.2-0.45-0.41-0.89-0.61-1.34c-15.68,2.02-15.68,2.02-18.84,12.26C167.06,328.35,173.25,324.87,179.44,321.4z M127.63,332.17
        c-1.34-13.67-11.33-10.37-18.45-12.27c-0.26,0.45-0.51,0.9-0.77,1.36C114.58,324.76,120.75,328.27,127.63,332.17z M127.66,227.75
        c-6.96,4.02-13.12,7.58-19.27,11.14c0.25,0.43,0.51,0.87,0.76,1.3C116.25,238.21,126.33,241.46,127.66,227.75z M178.62,240.17
        c0.28-0.53,0.55-1.05,0.83-1.58c-6.26-3.47-12.52-6.95-19.13-10.62C161.49,241.23,171.5,238.16,178.62,240.17z"/>
      <path d="M188.84,270.1c-0.82,0.63-2.37,2.73-4.24,3.07c-3.65,0.66-8.13-4.67-8.1-9.07c0.02-3.22,1.44-5.51,4.95-5.52
        C185.25,258.58,188.94,263.59,188.84,270.1z"/>
    </g>
  </SvgIcon>
);

export default PhotosphereIcon;
