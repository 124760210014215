import {
  createStyles,
  Theme,
  Button,
  makeStyles,
} from '@material-ui/core';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: 24,
    },
  })
);

interface Props {
  handleFileSubmit: Function;
  accept: string; // ".xls,.xlsx" or "image/*" or "video/*"
  customID: string;
  disabled?: boolean;
  buttonText?: string;
}

const FileSelector = (props: Props) => {
  const classes = styles(props);

  const handleChange = (selectorFiles: FileList) => {
    props.handleFileSubmit(selectorFiles);
  }

  return (
    <div>
    <label htmlFor={props.customID}>

      {props.customID &&
        <input style={{ display: 'none' }}
          id={props.customID }
          type="file"
          onChange={ e => handleChange(e.target.files) }
          accept={props.accept}
        />
      }
      <Button
        color="secondary"
        variant="contained"
        disabled={props.disabled}
        component="span">
        {props.buttonText ?? 'Upload File'}
      </Button>
    </label>
  </div>
  );
}

export default FileSelector;
