import withModalContext, { WithModalContext } from '../withModalContext';

import {
  Dialog,
} from '@material-ui/core';
import PlaceLayoutForm from './PlaceLayoutForm';
import { AssetCatalog } from '../../../api';

interface Props extends WithModalContext {
  catalog: AssetCatalog;
}

const PlaceLayoutModal = (modalProps: Props) => {
  const { props } = modalProps.modalContext;

  return(
    <Dialog
      open={true}
      aria-labelledby="scene-place-modal"
      fullWidth={true}
      scroll="paper"
      {...props}>
      <PlaceLayoutForm
        modalContext={modalProps.modalContext}
      />
    </Dialog>
  );
};

export default withModalContext(PlaceLayoutModal);
