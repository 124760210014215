import { Asset } from '../../../blue/items/asset';
import { PlacezMaterial } from '../../../api/placez/models/PlacezMaterial';
import { Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Tab, Tabs, Theme, Tooltip, Typography } from '@material-ui/core';
import { ItemPreview } from '../../../blue/ItemPreview';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { Utils } from '../../../blue/core/utils';
import { ColorLens, Edit, SquareFoot, Tune } from '@material-ui/icons';
import MaterialPanel from './MaterialPanel';
import ItemInfoPanel from './ItemInfoPanel';
import { SaveCustomAsset } from '../../../reducers/asset';
import produce from 'immer';
import { createRef, useEffect, useState } from 'react';
import modalStyles from '../models/modalStyles.css';
import EditSize from './utility/EditSize'
import { Matrix4, Vector3 } from 'three'

const localStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
      overflowX: 'hidden',
    },
    root: {
      margin: 0,
      padding: 0,
      overflowX: 'hidden',
    },
    panel: {
      minWidth: '260px',
      display: 'flex',
      flexDirection: 'column',
      height: '550px',
      overflow: 'hidden',
    },
    tabs: {
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
    },
    tabIcon: {
      // color: textColor,
      minWidth: '72px',
    },
    viewer: {
      flex: 1,
      height: '550px',
      backgroundImage: 'radial-gradient(#f5f5f5, #909090)',
    },
    editMaterial: {
      overflow: 'scroll',
      flex: 1,
    },
    headingText: {
      marginLeft: theme.spacing(),
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
    },
    button: {
      margin: '5px',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    icon: {
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  })
);

interface Props {
  asset: Asset;
  onSaveAsset?(asset: Asset): void;
  disableSave?: boolean;
}

let blueRef = undefined;

const CustomAssetDialog = (props: Props) => {
  const localClasses = localStyles(props);
  const styles = makeStyles<Theme>(modalStyles);
  const classes = {
    ...styles(props),
    ...localClasses,
  }
  const blueElementRef = createRef<HTMLDivElement>();

  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [materialsBak, setMaterialsBak] = useState([]);
  const [customAsset, setCustomAsset] = useState(undefined);

  const dispatch = useDispatch();

  const userProfile = useSelector((state: ReduxState) => state.oidc.user.profile);

  const handleOpen = e => {
    e.stopPropagation();
    setOpen(true);
    setCustomAsset(produce(props.asset, (draft) => draft));
  }

  useEffect(() => {
    setCustomAsset(produce(props.asset, (draft) => draft));
  }, [])


  const saveAs = (newSave: boolean) => () => {
    const { onSaveAsset } = props;

    blueRef.takePic((previewPath: string) => {
      let transformation = null;
      let id = customAsset.id;

      const extensionProperties = { ...customAsset.extensionProperties };
      if (newSave) {
        if (!extensionProperties.progenitorId) {
          extensionProperties.progenitorId = customAsset.id;
        }
      }
      if (newSave) {
        if (customAsset.transformation) {
          transformation = [...customAsset.transformation];
          transformation[3] = 0;
          transformation[7] = 0;
          transformation[11] = 0;
        }
        id = Utils.guid();
      } else {
        if (customAsset.transformation) {
          transformation = [...customAsset.transformation];
        }
      }

      const modifiedAsset = {
        ...customAsset,
        id,
        transformation,
        previewPath,
        extensionProperties,
      }

      setCustomAsset(modifiedAsset);

      if (onSaveAsset && !newSave) {
        onSaveAsset(modifiedAsset);
      } else {
        dispatch(SaveCustomAsset(modifiedAsset));
      }
      handleClose(true)();
    });
  }

  const handleClose = (keepChanges: boolean) => () => {
    blueRef.dispose();
    setOpen(false);
    setMaterialsBak([]);
  }

  const initBlueRef = () => {
    const host = window.env['REACT_APP_DAM'];
    const mediaAssetUrl = `${host}/Organization/${userProfile.organization_id}/MediaAssetFile/${props.asset.id}`;
    blueRef = new ItemPreview(
      mediaAssetUrl,
      props.asset,
      blueElementRef.current,
      getMaterialsBak
    );
  }

  //update blueref on coustomasset update
  useEffect(() => {
    blueRef?.update(customAsset, getMaterialsBak);
  }, [customAsset])

  const getMaterialsBak = item => () => {
    const materialsBak = blueRef?.getMaterialsBak();
    setMaterialsBak(materialsBak);
    const newMaterialMask = customAsset.materialMask && customAsset.materialMask?.length !== 0 ? customAsset.materialMask : materialsBak.map(() => null)
    setCustomAsset(produce(customAsset, (draft) => {draft.materialMask = newMaterialMask}))
  }

  const handleTabSelect = (event, index: number) => {
    if (index !== selectedTab) {
      setSelectedTab(index);
    }
  }

  const updateCustomAssetMaterialMask = (materialMask: PlacezMaterial[]) => {
    setCustomAsset(produce(customAsset, (draft) => {draft.materialMask = materialMask}));
  }

  const updateAssetInfo = (asset: Asset) => {
    setCustomAsset(produce(asset, (draft) => {draft.materialMask = customAsset.materialMask}));
  }

  const updateAssetTransformation = (transformation: Matrix4) => {
    const transformationArray = new Matrix4().copy(transformation).multiplyScalar(1 / 100).toArray();
    setCustomAsset(produce(customAsset, (draft) => {draft.transformation = transformationArray}));

  }

  const { asset, disableSave } = props;


  return (
    <div>
    {asset &&
      <>
      <Tooltip title="Customize">
        <Edit
          className={classes.icon}
          onClick={handleOpen}
        />
      </Tooltip>
      <Dialog
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onEnter={initBlueRef}
        fullWidth
        maxWidth="md"
        onClick={e => { e.stopPropagation(); }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h5">Item Editor</Typography>
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.root,
          }}
        >
          <div className={classes.body}>
            <div
              id="contentViewer"
              ref={blueElementRef}
              className={classes.viewer}
            />
            <div
              className={classes.panel}
            >
              <Tabs
                className={classes.tabs}
                value={selectedTab}
                onChange={handleTabSelect}
                variant="fullWidth"
                scrollButtons="off"
                aria-label="scrollable prevent tabs example"
              >
                <Tab icon={<Tune />} className={classes.tabIcon} aria-label="Settings" />
                <Tab icon={<SquareFoot />} className={classes.tabIcon} aria-label="Settings" />
                <Tab icon={<ColorLens />} className={classes.tabIcon} aria-label="Materials" />
              </Tabs>
              {selectedTab === 0 &&
                <ItemInfoPanel
                  asset={customAsset}
                  updateAsset={updateAssetInfo}
                />
              }
              {selectedTab === 1 &&
                <EditSize
                  width={blueRef?.getSize()?.width ?? 1}
                  depth={blueRef?.getSize()?.depth ?? 1}
                  height={blueRef?.getSize()?.height ?? 1}
                  onChange={(width, depth, height) => {
                    const scaleVec = new Vector3(
                      depth / blueRef.getSize().depth,
                      height / blueRef.getSize().height,
                      width / blueRef.getSize().width,
                    )
                    blueRef.item.scale.copy(scaleVec.multiplyScalar(100));
                    blueRef.item.updateMatrix();
                    updateAssetTransformation(blueRef.item.matrix);
                  }}
                />
              }
              {selectedTab === 2 &&
                <MaterialPanel
                  materialsBak={materialsBak}
                  asset={customAsset}
                  updateMaterialMask={updateCustomAssetMaterialMask}
                />
              }
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            disabled={disableSave}
            onClick={saveAs(false)}>
            Save
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClose(false)}>
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.button}
            variant="contained"
            onClick={saveAs(true)}>
            Create New
          </Button>
        </DialogActions>
      </Dialog>
      </>
    }
    </div>
  );
}

export default CustomAssetDialog;
