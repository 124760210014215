
import {
  withStyles,
  InputBase,
} from '@material-ui/core';

const borderColor = '#FFFFFF';

// Larger Font
export const PlannerComboInput = withStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    width: '100%',
    outline: 'none',
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(151, 151, 151, 0.5)',
    fontSize: 16,
    fontWeight: 'bold',
    padding: '6px 26px 2px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 2,
      borderColor,
      outline: 'none',
    },
  },
}))(InputBase);

export const PlannerSelectInput = withStyles(theme => ({
  root: {
    width: '100%',
  },
  input: {
    width: 'auto',
    outline: 'none',
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(151, 151, 151, 0.5)',
    fontWeight: 'bold',
    padding: '6px 26px 2px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 2,
      borderColor,
      outline: 'none',
    },
  },
}))(InputBase);

export const PlannerInput = withStyles(theme => ({
  root: {
    width: '100%',
    '&:focus': {
      outline: 'none',
    },
  },
  input: {
    width: '95%',
    outline: 'none',
    borderRadius: 2,
    position: 'relative',
    border: '1px solid rgba(151, 151, 151, 0.5)',
    padding: '4px 16px 4px 16px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 2,
      borderColor,
      outline: 'none',
    },
  },
  focused: {
    outline: 'none',
  },
}))(InputBase);
