import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import Place from '../../api/placez/models/Place';
import { ReduxState } from '../../reducers';

interface Props {
  open: boolean;
  onPlaceSelected: (placeId: number) => void;
}

const PlacePickerModal = (props: Props) => {
  const places = useSelector((state: ReduxState) => state.place.places);
  const [selectedPlace, setSelectedPlace] = useState<number>(undefined);

  const onSelectPlace = (event: React.ChangeEvent<{ value: number}>) => {
    setSelectedPlace(event.target.value);
  }

  const handleMoveCancel = () => {
    props.onPlaceSelected(undefined);
  }

  const handleMoveContinue = () => {
    props.onPlaceSelected(selectedPlace);
  }

  return (
    <Dialog
      open={props.open}
    >
      <DialogTitle>Move Floorplan to other Place</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" >
          <FormControl
              style={{
                width: '100%',
              }}>
            <InputLabel>Pick Place</InputLabel>
            <Select
              id="placeSelect"
              value={selectedPlace}
              onChange={onSelectPlace}>
                {places.map((place:Place) => {
                  return (
                    <MenuItem
                      value={place.id}>
                      {place.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleMoveCancel} color="primary">
          Cancel
        </Button>
        <Button  variant="contained" onClick={handleMoveContinue} color="primary">
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PlacePickerModal;
