import { Asset } from '../../../blue/items/asset';
import { DefaultMaterial, PlacezMaterial, GetImgUrlForMap } from '../../../api/placez/models/PlacezMaterial';
import { createStyles, makeStyles, Theme, Typography, useTheme } from '@material-ui/core';
import { Utils } from '../../../blue/core/utils';
import EditMaterial from './panels/EditMaterial';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { useEffect, useState } from 'react'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    body: {
      display: 'flex',
    },
    root: {
      margin: 0,
      padding: 2,
    },
    panel: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      padding: '4px',
      overflow: 'hidden',
      marginBottom: '10px',
    },
    editMaterial: {
      overflow: 'scroll',
      flex: 1,
    },
    title: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      margin: 0,
      padding: theme.spacing(2),
      fontSize: '40px',
      textAlign: 'center',
    },
    headingText: {
      marginLeft: theme.spacing(),
      fontSize: 16,
      fontWeight: theme.typography.fontWeightMedium,
    },
    button: {
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    actions: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.secondary.main,
    },
    gridList: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap:'4px',
      paddingTop: 8,
      paddingLeft: 10,
      paddingRight: 8,
      minHeight: '70px',
      maxHeight: '125px',
      overflow: 'scroll',
      overflowX: 'hidden',
    },
    tile: {
      cursor: 'grab',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70px',
      maxWidth: '70px',
      overflow: 'hidden',
      minHeight: '70px',
      maxHeight: '70px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

interface Props {
  materialsBak: PlacezMaterial[];
  asset: Asset;
  updateMaterialMask?(materialMask: PlacezMaterial[]): void;
}

const MaterialPanel = (props: Props) => {
  const classes = styles(props);

  const [selectedMaterialIndex, setSelectedMaterialIndex] = useState<number>(null);
  const [selectedMaterial, setSelectedMaterial] = useState<PlacezMaterial>(null);
  const materialsById = useSelector((state: ReduxState) => state.material.byId);
  const theme: Theme = useTheme();

  const selectMaterialIndex = (index: number) => () => {
    if (index === null) {
      return;
    }

    const { asset, materialsBak } = props;
    if (asset.materialMask === null) return;
    const selectMaterial = asset.materialMask[index] ? asset.materialMask[index] : DefaultMaterial;

    const materialBak = materialsBak[index];
    if (!asset.materialMask[index] && materialBak) {
      if (materialBak.threeJSMaterial.color) {
        selectMaterial.threeJSMaterial.color = materialBak.threeJSMaterial.color;
      }
      if (materialBak.threeJSMaterial.metalness) {
        selectMaterial.threeJSMaterial.metalness = materialBak.threeJSMaterial.metalness;
      }
      if (materialBak.threeJSMaterial.roughness) {
        selectMaterial.threeJSMaterial.roughness = materialBak.threeJSMaterial.roughness;
      }
    }

    setSelectedMaterialIndex(index)
    setSelectedMaterial(selectMaterial)

  }

  useEffect(() => {
    selectMaterialIndex(selectedMaterialIndex)()
  }, [props.asset])


  const setMaterial = (index: number) => (material: PlacezMaterial) => {
    if (index === null) return;
    const { asset, updateMaterialMask } = props;

    const newMask = produce(asset.materialMask, draft => {
      draft[index] = material;
    });

    updateMaterialMask(newMask);
  }

  const getImage = (materialMask: PlacezMaterial, index: number): string => {
    let textureImage = '';
    if (materialMask?.id) {
      const foundMaterial = materialsById[materialMask.id];
      if (foundMaterial) {
        textureImage = GetImgUrlForMap(foundMaterial, 'map');
      }
    } else {
      textureImage = GetImgUrlForMap(props.materialsBak[index], 'map');
    }
    return `url(${textureImage})`;
  }

  const getColor = (materialMask: PlacezMaterial, index: number): string => {
    if (materialMask && materialMask.threeJSMaterial && materialMask.threeJSMaterial.color) {
      return Utils.decColorToHex(materialMask.threeJSMaterial.color);
    }
    if (props.materialsBak && props.materialsBak[index] && props.materialsBak[index].threeJSMaterial && props.materialsBak[index].threeJSMaterial.color) {
      return Utils.decColorToHex(props.materialsBak[index].threeJSMaterial.color);
    }
    return '#ffffff';
  }

    return (
      <div
        className={classes.panel}
        >
        <div>
          <Typography className={classes.headingText} align="center">
            Select Material Below
          </Typography>
        </div>
        { props?.asset?.materialMask &&
          <div
            className={classes.gridList}>
            {props.asset.materialMask.map((material: PlacezMaterial, index) => {
              if (material || props.materialsBak[index]) {
                const textureImage = getImage(material ?? props.materialsBak[index], index);
                const tileColor = getColor(material, index);
                return (
                  <div
                    key={index}
                    className={classes.tile}
                    style={{
                      borderRadius: '50%',
                      backgroundColor:  `${tileColor}`,
                      backgroundImage:  `${textureImage}`,
                      backgroundBlendMode: 'multiply',
                      borderColor:  `${selectedMaterialIndex === index ? theme.palette.primary.main : theme.palette.secondary.main}`,
                      borderWidth: '4px',
                      borderStyle: 'solid',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    onClick={selectMaterialIndex(index)}
                    >
                  </div>
                );
              }
              return true;
            })}
          </div>
        }
        <div
          className={classes.editMaterial}>
            <EditMaterial
              setMaterial={setMaterial(selectedMaterialIndex)}
              material={selectedMaterial}
            />
        </div>
      </div>
    );
}

export default MaterialPanel
