import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

// Components
import { CircularProgress, makeStyles, Tooltip, Typography } from '@material-ui/core';
import CatalogList from '../../components/AssetCatalog/CatalogList';
import CatalogDetail from '../../components/AssetCatalog/CatalogDetail';

// Models
import { Visibility, VisibilityOff } from '@material-ui/icons';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
      paddingBottom: '0px',
      display: 'grid',
      height: '100%',
      overflow: 'hidden',
      gridTemplateColumns: '350px auto',
    },
    list: {
      height: '100%',
      borderRight: `solid ${theme.palette.grey[200]} 1px`,
      paddingRight: 2 * theme.spacing(),
      overflow: 'hidden',
    },
    detail: {
      height: '100%',
      paddingLeft: 2 * theme.spacing(),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      overflow: 'hidden',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    listHeading: {
      ...theme.typography.h6,
      margin: 0,
      paddingLeft: theme.spacing(),
    },
    detailHeading: {
      ...theme.typography.h6,
      margin: 0,
      paddingLeft: theme.spacing(),
    },
    sortFilter: {
      displat: 'flex',
      flexDirection: 'column',
      width: '70%',
      display: 'flex',
      flexDirectio : 'column',
    },
    settingLabel: {
      flex: 1,
    },
    setting: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '5px',
    },
    noAssets: {
      padding: theme.spacing(),
    },
    button: {
      position: 'absolute',
      right: '40px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

interface Props {}

const AssetsPreview = (props: Props) => {
  const classes = styles(props);

  const [selectedCatalog, setSelectedCatalog] = useState(null);
  const [showHidden, setShowHidden] = useState(false);

  const catalogsById = useSelector((state: ReduxState) => state.asset.catalogsById);
  const catalogsLoading = useSelector((state: ReduxState) => state.asset.catalogsLoading);
  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);
  const assetsLoading = useSelector((state: ReduxState) => state.asset.assetsLoading);
  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);

  useEffect(() => {
    selectDefaultCatalog();
  }, [])

  const selectDefaultCatalog = () => {
    if (!assetsLoading && !catalogsLoading && !selectedCatalog) {
      const catalogIds = Object.keys(catalogsById);
      if (catalogIds.length > 0) {
        selectCatalog(catalogIds[0]);
      }
    }
  }

  const selectCatalog = catalogId => {
    setSelectedCatalog(catalogsById[catalogId]);
  }

  const handleShowHidden = e => {
    setShowHidden(!showHidden);
  }

  console.error(selectedCatalog);
  return (
    <div className={classes.root}>
      <div className={classes.list}>
        <div className={classes.title}>
          <h1 className={classes.listHeading}>
            Asset Catalog Manager
          </h1>
        </div>
        {
        catalogsLoading ? <CircularProgress color="primary"/> :
        <CatalogList
          selectedId={selectedCatalog ? selectedCatalog.id : null}
          onCardClick={selectCatalog}
          catalogsById={catalogsById} />
        }
      </div>
      <div className={classes.detail}>
        { !showHidden &&
          <Tooltip title="Show Hidden">
            <VisibilityOff
              className={classes.button}
              onClick={handleShowHidden}
            />
          </Tooltip>
        }
        { showHidden &&
          <Tooltip title="Hide Hidden">
            <Visibility
              className={classes.button}
              onClick={handleShowHidden}
            />
          </Tooltip>
        }
        {
          assetsLoading && catalogsLoading && !selectedCatalog ?
          <CircularProgress color="primary"/> :
          selectedCatalog && selectedCatalog.assetCount <= 0 ? (
          <Typography
            variant="body1"
            className={classes.noAssets}>
            No Assets
          </Typography>) : (
          <CatalogDetail
            catalog={selectedCatalog}
            assetsBySku={assetsBySku}
            globalFilter={globalFilter}
            showHidden={showHidden}
            />
          )
        }
      </div>
    </div>
  );
}

export default AssetsPreview;
