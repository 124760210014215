import { ReduxState } from '../../../../reducers';

import {
  createStyles,
  Theme,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

interface Props {
  mode?: string;
}

const Header = (props: Props) => {

  const currentFixturePlan = useSelector((state: ReduxState) => state.designer.floorPlan);

  const classes = styles(props);

  return (
    currentFixturePlan &&
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.mode}>{props.mode}</Typography>
          <div style={{flex: 1}}></div>
          <div className={classes.plan}>
            <Typography className={classes.layoutTitle}>
              {currentFixturePlan.name}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.paper,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      fontSize: 10,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flex: 1,
    },
    buttons: {
      width: 258,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    mode: {
      color: theme.palette.text.primary,
      display: 'flex',
      flex: 2,
      paddingLeft: 5,
      fontSize: 14,
    },
    plan: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      paddingLeft: 5,
      fontSize: 14,
    },
    loadingBar: {
      background: 'transparent',
      padding: '3px',

    },
    iconButton: {
      padding: 4,
    },
    layoutTitle: {
      color: theme.palette.text.primary,
      fontSize: 14,
      alignSelf: 'center',
      paddingLeft: '10px',
    },
  })
);

export default Header;
