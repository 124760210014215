import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const BackIcon = (props: any) => (
  <SvgIcon {...props}>
    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" // tslint:disable-line
    />
  </SvgIcon>
);

export default BackIcon;
