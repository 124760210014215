import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import {
  Theme,
} from '@material-ui/core/styles';

import {
  DialogActions,
  DialogContent,
  Button,
  Switch,
  Dialog,
  AppBar,
  makeStyles,
} from '@material-ui/core';

import { placezApi, ShareLayoutRequest } from '../../../api';
import { ShareMode } from './ShareMode';
import ShareChoices from './ShareChoices';
import EmailForm from './EmailForm';
import LinkForm from './LinkForm';
import { ExportDesign } from '../../../reducers/designer';
import { GetLayoutChatSessions } from '../../../reducers/chat';
import { GuestAccessClaims } from '../../../api/placez/models/GuestAccessClaims';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { SimpleModalProps } from '../SimpleModal';
import modalStyles from '../../Blue/models/modalStyles.css';

type Props = SimpleModalProps & {
  title: string;
  layoutId: string;
  generateAR: () => void;
};

const ShareModal = (props: Props) => {

  const [shareMode, setShareMode] = useState(ShareMode.None);
  const [sendUpdateNotificationsToTokenCreator, setSendUpdateNotificationsToTokenCreator] = useState(false);
  const [users, setUsers] = useState([{
    email: '',
    name: '',
    expirationUtcDateTime: new Date(),
    guestAccess: [],
  }]);

  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(modalStyles);
  const classes = styles(props);

  const exportStatus = useSelector((state: ReduxState) => state.designer.exportStatus);
  const exportPath = useSelector((state: ReduxState) => state.designer.exportPath);

  useEffect(() => {setShareMode(ShareMode.None)}, [props.open]);

  useEffect(() => {
    const expirationUtcDateTime = new Date();
    expirationUtcDateTime.setFullYear(expirationUtcDateTime.getFullYear() + 1);
    handleExpirationDateChange(expirationUtcDateTime);
  }, []);

  const mapPermissions = (guestAccessClaim: GuestAccessClaims): string => {
    switch (guestAccessClaim) {
      case GuestAccessClaims.UpdateLayout:
        return 'Allow Your Client to Update Layout';
      case GuestAccessClaims.UpdateAttendee:
        return 'Allow Your Client to Manage Attendee List';
      default:
        return '';
    }
  }

  const onShare = () => {
    const { layoutId, setOpen } = props;
    switch (shareMode) {
      case ShareMode.OpenAR:
        window.open(getARLink(exportPath));
        break;
      case ShareMode.ShareAR:
        // placezApi.shareAR({
        //   layoutId: this.props.layoutId,
        //   email: this.state.email,
        //   name: this.state.name,
        // });
        break;
      case ShareMode.Share3D:
        placezApi.shareLayout({
          layoutId,
          users,
          sendUpdateNotificationsToTokenCreator, // TODO
        }).then(response => {
          if (response && response.ok) {
            dispatch(GetLayoutChatSessions(layoutId));
          }
        });
        break;
      default:
        break;
    }
    setOpen(false);
  }

  const handleEmailChange = e => {
    const updatedUsers = [...users];
    updatedUsers[0].email = e.target.value;
    setUsers(updatedUsers);
  }

  const handleNameChange = e => {
    const updatedUsers = [...users];
    updatedUsers[0].name = e.target.value;
    setUsers(updatedUsers);
  }

  const handleExpirationDateChange = (expirationUtcDateTime: Date) => {
    const updatedUsers = [...users];
    updatedUsers[0].expirationUtcDateTime = expirationUtcDateTime;
    setUsers(updatedUsers);
  }

  const handleSwitchMode = (shareMode: ShareMode) => {
    setShareMode(shareMode);
  }

  const switchUpdateNotifications = (e, checked) => {
    setSendUpdateNotificationsToTokenCreator(checked)
  }

  const togglePermission = (guestRole: GuestAccessClaims) => (e, checked) => {
    const newGuestAccess = users[0].guestAccess.filter(guestAccessClaim => {
      return guestAccessClaim !== guestRole;
    });
    if (checked) newGuestAccess.push(guestRole);
    const updatedUsers = [...users];
    updatedUsers[0].guestAccess = newGuestAccess;
    setUsers(updatedUsers);
  }

  const handleExport = e => {
    const { generateAR } = props;
    dispatch(ExportDesign());
    generateAR?.()
  }

  const getARLink = link => {
    const damUrl = window.env['REACT_APP_DAM'];
    const fullArUrl = `${damUrl.replace(/\/$/, '')}/${link.replace(/^\//, '')}`;
    const usdzLink = fullArUrl.replace('gltf', 'usdz').replace('glb', 'usdz');
    return `${window.location.origin}/ar.html?src=${fullArUrl}&ios-src=${usdzLink}`;
  }

  const renderTitle = () => {
    switch (shareMode) {
      case ShareMode.OpenAR:
        return 'Open in AR';
      case ShareMode.ShareAR:
        return 'Send in AR';
      case ShareMode.Share3D:
        return 'Share Layout';
      case ShareMode.None:
      default:
        return 'Share';
    }
  }


  const renderContent = () => {
    const { name, email, guestAccess, expirationUtcDateTime } = users[0];
    switch (shareMode) {
      case ShareMode.OpenAR:
        return (
          <LinkForm link={getARLink(exportPath)} />
        );
      case ShareMode.ShareAR:
      case ShareMode.Share3D:
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
            <EmailForm
              email={email}
              name={name}
              onEmailChange={handleEmailChange}
              onNameChange={handleNameChange}
            />
            {Object.values(GuestAccessClaims).map((permission, index) => {
              return (
                <div
                  key={index}
                  className={classes.setting}>
                  {mapPermissions(permission)}
                  <Switch
                    size="small"
                    checked={guestAccess.includes(permission)}
                    onChange={togglePermission(permission)}
                    value={permission}
                  />
                </div>
              );
            })}
            <div className={classes.setting}>
              Share Link Expires
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  variant="inline"
                  margin="normal"
                  value={expirationUtcDateTime}
                  onChange={handleExpirationDateChange}
                  format="MMM d yyyy"
                  autoOk
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.setting}>
              Notify Me of Client Changes to Layout
              <Switch
                size="small"
                checked={sendUpdateNotificationsToTokenCreator}
                onChange={switchUpdateNotifications}
                value={sendUpdateNotificationsToTokenCreator}
              />
            </div>
          </div>
        );
      case ShareMode.None:
      default:
        return (
          <ShareChoices
            exportStatus={exportStatus}
            onGenerateAR={handleExport}
            switchMode={handleSwitchMode}
          />
        );
    }
  }

  return (
    <Dialog
      fullWidth={true}
      open={props.open}>
      <AppBar className={classes.dialogTitle} position="static">
        {renderTitle()}
      </AppBar>
      <DialogContent className={classes.content}>
        {renderContent()}
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => props.setOpen(false)}>
          Cancel
        </Button>
        {
          shareMode !== ShareMode.None ?
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              onClick={onShare}>
              {
                shareMode === ShareMode.OpenAR ?
                  'Open' :
                  'Send'
              }
            </Button> :
            <></>
        }
      </DialogActions>
    </Dialog>
  );
}

export default ShareModal;

