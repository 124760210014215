import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Theme } from "@material-ui/core";
import { PropsWithChildren, useState } from "react"
import modalStyles from "../Blue/models/modalStyles.css";

interface Props {
  modalHeader: string;
  onContinue?: Function;
  onDelete?: Function;
}

export const UniversalModalWrapper = (props: PropsWithChildren<Props>) => {
  const styles = makeStyles<Theme>(modalStyles);
  const [open, setOpen] = useState(false);
  const {modalHeader, onContinue, onDelete} = props;
  const classes = styles(props);
  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center'
        }}
        onClick={e => setOpen(true)}>
        {props.children[0]}
      </div>
      <Dialog
        open={open}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle className={classes.dialogTitle} id="alert-dialog-slide-title">{modalHeader}</DialogTitle> */}
        <DialogTitle id="alert-dialog-slide-title">{modalHeader}</DialogTitle>
        <DialogContent>
          {props.children[1]}
        </DialogContent>
        {/* <DialogActions className={classes.actions}> */}
        <DialogActions>
          <Button
            variant="contained"
            onClick={e => setOpen(false)}>
            Cancel
          </Button>
          {onContinue &&
            <Button
              variant="contained"
              onClick={e => {
                setOpen(false);
                onContinue();
              }}
              color="primary">
              Continue
            </Button>
          }
          {onDelete &&
            <Button
              variant="contained"
              classes={{
                root: classes.error,
              }}
              onClick={e => {
                setOpen(false);
                onDelete();
              }}>
              Delete
            </Button>
          }
        </DialogActions>
      </Dialog>
      </>
  )
}
