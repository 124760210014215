import { AssetCollection } from '../api';

export class CatalogLicense {
  constructor(collection: AssetCollection) {
    this.id = collection.id;
    this.catalogId = collection.assetCollectionApplication;
    this.catalogName = collection.assetCollectionName;
    this.created = collection.createdUtcDateTime;
    this.lastModified = collection.lastModifiedUtcDateTime;
  }

  id: number;
  catalogId: string;
  catalogName: string;
  created: Date;
  lastModified: Date;
}
