import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  makeStyles,
  Input,
  IconButton,
} from '@material-ui/core';
import PlaceLayoutCard from './PlaceLayoutCard';
import { CreateLayout } from '../../../reducers/layouts';
import { useNavigate } from 'react-router';
import { sceneRoutes } from '../../../routes';
import { ReduxState } from '../../../reducers';
import PlacezFixturePlan from '../../../api/placez/models/PlacezFixturePlan';
import PlacezLayoutPlan from '../../../api/placez/models/PlacezLayoutPlan';
import modalStyles from '../../Blue/models/modalStyles.css';
import { Clear, Search } from '@material-ui/icons';

const localStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
      fontSize: '40px',
      textAlign: 'center',
    },
    heading: {
      ...theme.typography.h2,
      marginTop: theme.spacing(2),
      fontSize: '20px',
      height: '40px',
      backgroundColor: theme.palette.secondary.main,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: `0px ${theme.spacing(3)}px`,
    },
    field: {
      minWidth: '40%',
      margin: '10px 24px',
    },
    container: {
      padding: theme.spacing(),
      textAlign: 'center',
      maxHeight: '600px',
      overflowY: 'scroll',
      display: 'flex',
      flexWrap: 'wrap',
      alignSelf: 'center',
      justifyContent: 'center',
    },
    imageDetails: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    logo: {
    },
    leftColumn: {
      flexDirection: 'column',
      width: '50%',
      padding: theme.spacing(),
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    inputField: {
      marginBottom: '0px',
    },
    inputCheckField: {
      marginBottom: '-15px',
    },
    paper: {
      zIndex: 1,
      position: 'relative',
      margin: theme.spacing(1),
    },
    fullWidthField: {
      width: '100%',
      margin: '0px 0px 14px 0px',
      color: 'white',
      alignItems: 'stretch',
    },
  })
);

type Props = {
  modalContext: any;
};

const PlaceLayoutForm = (props: Props) => {
  const styles = makeStyles<Theme>(modalStyles);
  const localClasses = localStyles(props);
  const classes = {
    ...styles(props),
    ...localClasses
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scene = useSelector((state: ReduxState) => state.scenes.currentScene);
  const fixturePlans = useSelector((state: ReduxState) => state.floorPlans.unsorted);
  const createdLayout = useSelector((state: ReduxState) => state.layouts.newLayout);

  const [filteredFloorPlans, setFilteredFloorPlans] = useState<PlacezFixturePlan[]>(
    [...fixturePlans.filter(floorPlan => floorPlan.placeId === scene.placeId)]
  );

  const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string>(null);
  const [newLayoutName, setNewLayoutName] = useState<string>(undefined);

  const handleCardClick = id => {
    setSelectedFloorPlanId(id);
  }

  useEffect(() => {
    if (createdLayout && selectedFloorPlanId !== null) {
    props.modalContext.hideModal();
      navigate(
        sceneRoutes.planner.path
          .replace(':id', scene.id.toString())
          .replace(':planId', createdLayout.id.toString())
      );
    }
    setSelectedFloorPlanId(null);

  }, [createdLayout])

  const createLayout = () => {
    (window as any).gtag('event', 'create_layout');
    // TODO Default Layout
    const layout: PlacezLayoutPlan = {
      sceneId: scene.id,
      name: newLayoutName ?? scene.name,
      floorPlanId: selectedFloorPlanId,
      items: [],
      price: 0,
    };
    dispatch(CreateLayout(layout));
  }

  const { hideModal } = props.modalContext;

  const createDisabled = selectedFloorPlanId === null;
  const [floorplanFilter, setFloorplanFilter] = useState('');

  useEffect(() => {
    setFilteredFloorPlans(
      [...fixturePlans.filter(floorPlan => floorPlan.placeId === scene.placeId)]
    );
  }, [fixturePlans])

  return (filteredFloorPlans.length > 0 &&
    <Fragment>
      <div>
        <DialogTitle
          id="place-modal-dialog"
          className={classes.dialogTitle}>
          Select Floorplan
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Input
            placeholder="Search Floorplans"
            id="adornment-password"
            value={floorplanFilter}
            onChange={event => { setFloorplanFilter(event.target.value) }}
            endAdornment={
              <>
                { floorplanFilter !== '' &&
                  <IconButton
                    onClick={event => { setFloorplanFilter('')}}
                  >
                    <Clear/>
                  </IconButton>
                }
                { floorplanFilter === '' &&
                  <IconButton>
                    <Search />
                  </IconButton>
                }
              </>
            }
          />
          <div className={classes.container}>
            {
              filteredFloorPlans
              .filter(floorPlan => floorPlan.name.toLowerCase().includes(floorplanFilter.toLowerCase()))
              .map(floorPlan =>
                <PlaceLayoutCard
                  selected={selectedFloorPlanId === floorPlan.id}
                  key={floorPlan.id}
                  fixturePlan={floorPlan}
                  timeout={(1 + 1) * 0.5}
                  onClick={() => handleCardClick(floorPlan.id)}
                />)
            }
          </div>
          <TextField
            className={classes.fullWidthField}
            id="New Layout Name"
            label={'New Layout Name (Optional)'}
            value={newLayoutName}
            onChange={(e) => {setNewLayoutName(e.target.value)}}
            inputProps={{
              maxLength: 200,
            }}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button className={classes.button} variant="contained" onClick={hideModal}>Cancel</Button>
          <Button color="primary" disabled={createDisabled} className={classes.button} variant="contained" onClick={createLayout}>Create</Button>
        </DialogActions>
      </div>
    </Fragment>

  );
}

export default PlaceLayoutForm;
