import {
  Theme,
  createStyles,
  Button,
  Tooltip,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import {
  BorderColorOutlined,
  ControlCameraSharp,
  DeleteOutlined,
  AspectRatio,
  DeleteSweep,
  CheckBoxOutlineBlankOutlined,
  Image,
} from '@material-ui/icons';

import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';

import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../reducers';
import { FloorPlanModes, SetFloorPlanMode } from '../../../../reducers/floorPlan';
import { UniversalModalWrapper } from '../../../Modals/UniversalModalWrapper'
import { AreYouSureDelete } from '../../../Modals/UniversalModal'

const backgroundHighLight = '#bcb9b6';
const backgroundColor = '#2A2C32';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      right: 5,
      margin: theme.spacing(),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    border: {
      margin: `${theme.spacing() / 2}px 0px`,
      backgroundColor,
      '&:hover': {
        background: 'rgba(42, 44, 50, 0.4)',
      },
      border: `2px solid ${theme.palette.primary.main}`,
      borderTopRightRadius: '4px !important',
      borderTopLeftRadius: '4px !important',
      borderBottomRightRadius: '4px !important',
      borderBottomLeftRadius: '4px !important',
      borderLeft: `2px solid ${theme.palette.primary.main} !important`,
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      padding: theme.spacing(),
      minWidth: 'unset',
      height: 'unset',
    },
    modeButtonGroup: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'transparent',
    },
    selected: {
      backgroundColor: `${backgroundHighLight} !important`,
      color: `${theme.palette.primary.main} !important`,
    },
  })
);

interface Props {
  handleSetFloorPlanImage: Function;
  onScaleFloorPlanClick?: (event:any) => void;
  onDeleteAllClick?: (event:any) => void;
  onSetFloorPlanImageClick?: (event:any) => void;
  onSetWallHeightClick?: (event:any) => void;
}

const FloorPlanBar = (props: Props) => {
  const floorPlanMode = useSelector((state: ReduxState) => state.floorPlan.mode);
  const dispatch = useDispatch();

  const handleModeChange = (event, newFloorPlanMode) => {
    console.log(newFloorPlanMode);

    dispatch(SetFloorPlanMode(newFloorPlanMode ?? FloorPlanModes.MOVE))
  }

  const classes = styles(props);

  return (
    <div className={classes.root}>
    <ToggleButtonGroup
      value={floorPlanMode}
      exclusive
      className={classes.modeButtonGroup}
      onChange={handleModeChange}
      aria-label="floor plan mode">
        <ToggleButton  classes={{ selected:  classes.selected }}
            className={classes.border}
            value={FloorPlanModes.MOVE}>
          <Tooltip title="Move Line">
          <ControlCameraSharp/>
          </Tooltip>
        </ToggleButton>
        <ToggleButton classes={{ selected:  classes.selected }}
            className={classes.border}
            value={FloorPlanModes.DRAW}>
        <Tooltip title="Draw Line">
          <BorderColorOutlined/>
        </Tooltip>
        </ToggleButton>
        <ToggleButton  classes={{ selected:  classes.selected }}
            className={classes.border}
            value={FloorPlanModes.DELETE}>
          <Tooltip title="Delete Line">
          <DeleteOutlined/>
          </Tooltip>
        </ToggleButton>
        <ToggleButton classes={{ selected:  classes.selected }}
            className={classes.border}
            value={FloorPlanModes.SCALE}>
        <Tooltip title="Scale">
          <AspectRatio/>
        </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>

      <hr style={{width: '100%'}}/>

      <UniversalModalWrapper
        onDelete= {props.onDeleteAllClick}
        modalHeader='Are you sure?'
      >
        <Tooltip title="Delete All">
          <IconButton
            key={`delete-Floorplans-rooms`}
            className={classes.border}
            >
            <DeleteSweep fontSize="small" />
          </IconButton>
        </Tooltip>
        {AreYouSureDelete('Floorplans Rooms')}
      </UniversalModalWrapper>
      <Tooltip title="Floor Plan Image">
        <Button className={classes.border}
          onClick={props.onSetFloorPlanImageClick}>
          <Image/>
        </Button>
      </Tooltip>
      <Tooltip title="Set Wall Information">
        <Button className={classes.border}
          onClick={props.onSetWallHeightClick}>
          <CheckBoxOutlineBlankOutlined/>
        </Button>
      </Tooltip>
    </div>
  );
}

export default FloorPlanBar;
