import { useState, useEffect } from 'react';
import withModalContext, { WithModalContext } from '../withModalContext';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  AppBar,
  Button,
  CircularProgress,
  Dialog, makeStyles,
} from '@material-ui/core';
import FileSelector from '../../Utils/FileSelector';
import { damApi } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { Save } from '../../../reducers/blue';
import { ReduxState } from '../../../reducers';
import { SetFloorPlan } from '../../../reducers/designer';
import { SceneScan } from '../../../blue/items/sceneScan';
import { Matrix4 } from 'three';

interface Props extends WithModalContext{
  onClose,
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: theme.shape.borderRadius,
      border: `solid 2px ${theme.palette.primary.main}`,
      overflowY: 'initial',
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    actionButton: {
      margin: '10px',
    },
    heading: {
      backgroundColor: `${theme.palette.secondary.main}`,
      width: '100%',
      margin: '0px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    innerContent: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: '8px',
      fontSize: '24px',
      textAlign: 'center',
    },
  })
);

const ScanModal = (props: Props) => {
  const [fileName, setFileName] = useState('');

  const [scanUrl, setScanUrl] = useState<string>(undefined);
  const [saving, setSaving] = useState<boolean>(false);
  const [formData, setFormData] = useState(undefined);

  const currentScan = useSelector((state: ReduxState) => state.designer.floorPlan?.sceneScans?.[0]);

  useEffect(() => {
    setFileName(currentScan?.name)
  }, [currentScan])

  const dispatch = useDispatch();


  useEffect(() => {
    if (saving) {
      damApi.postBlob(formData).then(data => {
        console.log('data', data);
        setScanUrl(data.parsedBody.url);
        setSaving(false);
      });
    }
  }, [
    saving, formData
  ]);

  const handleFileSubmit = (files: FileList) => {
    const formData = new FormData();
    const trimmedFileName = files[0].name.split('.')[0];
    setFileName(trimmedFileName);
    formData.append('file', files[0], files[0].name);

    setFormData(formData);
    setSaving(true);
  }

  const handleImport = (scanUrl?) => {
    if (scanUrl) {
      const sceneScan: SceneScan = {
        transformation: new Matrix4().toArray(),
        sceneScanUrl: scanUrl,
        name: fileName,
      }
      dispatch(SetFloorPlan({
        sceneScans: [sceneScan],
      }));
    } else {
      dispatch(SetFloorPlan({
        sceneScans: [],
      }));
    }
    dispatch(Save());
    props.modalContext.hideModal();
  }

  const handleSetScan = () => {
    console.log('handlesetscanx');
  }

  const classes = styles(props);

  return(
    <Dialog
      open={true}
      keepMounted
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
    <>
      <AppBar position="static" className={classes.title}>Scene Scan Preview</AppBar>
      <div className={classes.content}>
        <div className={classes.container}>
          <div className={classes.innerContent}>
            {!saving && fileName && fileName !== '' &&
              <div>{fileName}</div>
            }
            {saving &&
              <CircularProgress/>
            }
          </div>
        </div>
      </div>
      {handleSetScan !== undefined &&
        <div className={classes.actions}>
          <Button
            className={classes.actionButton}
            variant="contained"
            classes={{
              root: classes.error,
            }}
            onClick={props.modalContext.hideModal}>
            Cancel
          </Button>
          {currentScan !== undefined &&
            <Button
              className={classes.actionButton}
              variant="contained"
              classes={{
                root: classes.error,
              }}
              onClick={() => {
                handleImport()
              }}
              >
              Remove Scan
            </Button>
          }
          <FileSelector
            customID={'load Room Sacan'}
            handleFileSubmit={handleFileSubmit}
            accept=".gltf, .glb" />
            <Button
              className={classes.actionButton}
              variant="contained"
              color="primary"
              disabled={scanUrl === undefined}
              onClick={() => handleImport(scanUrl)}>
                Save
            </Button>
        </div>
      }
    </>
    </Dialog>
  );
};

export default withModalContext(ScanModal);
