import { useEffect } from 'react';
import Authenticate from './auth/components/Authenticate';
import { Environment } from './sharing/environment';

// Theming
import { createTheme, MuiThemeProvider, PaletteType } from '@material-ui/core';
import { themeGenerator } from './assets/themes';
import '@progress/kendo-theme-material/dist/all.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.min.css';

// Redux
import { useDispatch, useSelector } from 'react-redux';

// Planner

// Logging
import { ReduxState } from './reducers';
import { getOrgTheme } from './api/placez/models/UserSetting';
import { AppToast } from './components/Blue/components/toast';
import ChromeAlertDialog from './components/Modals/ChromeAlertDialog'
import { LocalStorageKey, useLocalStorageSelector } from './components/Hooks/useLocalStorageState'


const App = () => {
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    window.location.reload();
  });

  const themeType = useLocalStorageSelector<PaletteType>(LocalStorageKey.ThemeType);

  const orgId  = useSelector((state: ReduxState) => state.oidc?.user?.profile?.organization_id ?? '');
  const dispatch = useDispatch();

  let appliedTheme;
  if (orgId) {
    const orgTheme = getOrgTheme(orgId);
    appliedTheme = createTheme(themeType === 'dark' ? themeGenerator('dark', orgTheme.primaryColor, orgTheme.secondaryColor) : themeGenerator('light', orgTheme.primaryColor, orgTheme.secondaryColor));
  } else {
    appliedTheme = createTheme(themeType === 'dark' ? themeGenerator('dark') : themeGenerator('light'));
  }
  return (
    <MuiThemeProvider theme={appliedTheme}>
      <ChromeAlertDialog/>
      <Authenticate/>
      <AppToast/>
    </MuiThemeProvider>
  );
}

export default App;
