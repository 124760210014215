import { GridHelper, Scene } from "three";
import { GridCell, Utils } from "../core/utils";
import { CameraLayers } from "../../models/BlueState";
import { getFromLocalStorage } from "../../sharing/utils/localStorageHelper";
import { store } from "../..";
import { SetGridCellSize } from "../../reducers/blue";

export class PlacezGrid {
  private scene: Scene;
  private controls;

  private gridHelper: GridHelper;
  private gridSize: GridCell;

  constructor(scene) {
    this.scene = scene;
  }

  public update = (controls) => {
    const gridCellLocked = getFromLocalStorage('gridCellLocked');
    if (gridCellLocked !== undefined && gridCellLocked) return;

    const newGridSize = Utils.getGridCellSize(controls.getViewSize(), Utils.getUnit());

    if (newGridSize?.cmSize && (!this?.gridSize?.cmSize || newGridSize.cmSize !== this.gridSize.cmSize)) {
      this.gridSize = newGridSize;
      store.dispatch(SetGridCellSize(newGridSize));
      this.setGrid(300000, newGridSize);
    }
  }

  private setGrid = (size: number, gridCell: GridCell = { cmSize: 100, units: 'cm' }) => {
    this.scene.remove(this.gridHelper);
    const divisions = size / gridCell.cmSize;
    this.gridHelper = new GridHelper(size, divisions);
    this.gridHelper.layers.set(CameraLayers.Grid);
    this.scene.add(this.gridHelper);
  };

}
