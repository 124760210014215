import { Slider, Theme, Tooltip, makeStyles } from "@material-ui/core"
import { ToggleButton } from "@material-ui/lab"
import { ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "../../../../../reducers"
import { CameraLayers } from "../../../../../models/BlueState"
import { ChangeCameraLayersState } from "../../../../../reducers/blue"
import viewOptionsStyles from "./ViewOptions.css"
import { LocalStorageKey, useLocalStorageState } from "../../../../Hooks/useLocalStorageState"

interface Props {
  children: ReactNode;
  layers: CameraLayers[];
  excludeLayers?: CameraLayers[];
  label: LocalStorageKey;
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  showLabel?: boolean;
  scale?: boolean;
  disabled?: boolean;
}

const CameraLayerToggle = (props: Props) => {

  const styles = makeStyles<Theme>(viewOptionsStyles);
  const classes = styles(props);

  const cameraLayers = useSelector((state: ReduxState) => state.blue.cameraLayers)
  const dispatch = useDispatch();

  const [layerValue, setLayerValue] = useLocalStorageState(props.label, props.defaultValue || 0);

  const toggleLayers = (layers: CameraLayers[]) => () => {
    let newLayers: CameraLayers[] = [...cameraLayers];

    for (const layer of layers) {
      if (!cameraLayers.includes(layer)) {
        newLayers.push(layer);
        if (props.excludeLayers) {
          for (const excludeLayer of props.excludeLayers) {
            newLayers = newLayers.filter(camLayer => camLayer !== excludeLayer);
          }
        }
      } else {
        newLayers = newLayers.filter(camLayer => camLayer !== layer);
        if (props.excludeLayers) {
          for (const excludeLayer of props.excludeLayers) {
            newLayers.push(excludeLayer);
          }
        }
      }
    }

    dispatch(ChangeCameraLayersState(newLayers, true));
  };

  return (
    <Tooltip title={props.label}>
      <ToggleButton
        name="labelSelected"
        aria-label="Chair Number"
        selected={props.layers.some(cameraLayer => cameraLayers.includes(cameraLayer))}
        onClick={!props.disabled ? toggleLayers(props.layers) : () => {}}
        classes={{
          root: classes.button,
          selected: classes.selected,
        }}
        >{props.children}
        { props.layers.some(cameraLayer => cameraLayers.includes(cameraLayer)) && (props.defaultValue || props.scale) &&
          <div className={classes.moreSettings}>
            {!props.scale &&
              <Slider
                value={layerValue}
                onChange={(e, v) => setLayerValue(v)}
                onClick={e => e.stopPropagation()}
                min={props.min}
                max={props.max}
                step={0.2}
                valueLabelDisplay="auto"
              />
            }
            {props.scale &&
              <Slider
                value={layerValue}
                onChange={(e, v) => setLayerValue(v)}
                onClick={e => e.stopPropagation()}
                min={-5}
                max={10}
                step={1}
                valueLabelDisplay="off"
                track={false}
                marks={[{value: 0}]}
              />
            }
          </div>
        }
      </ToggleButton>
    </Tooltip>
  )
}

export default CameraLayerToggle;
