import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import {
  Theme,
  createStyles }
from '@material-ui/core/styles';
import { DateRange } from '../../sharing/utils/DateHelper';

// Components
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import {
  AddCircleOutline as AddIcon
} from '@material-ui/icons';
import { PlaceTable } from '../../components/Tables';
import PlaceCharts from '../../components/PlaceCharts/PlaceCharts';

// Models
import { SelectPlace, GetPlaceMetrics } from '../../reducers/place';

// Routes
import { placeRoutes } from '../../routes';

// Util
import findInSearchableFeilds from '../../sharing/utils/findInSearchableFeilds';
import { ReduxState } from '../../reducers';
import Jumbotron from '../../components/Jumbotron';
import { createSelector } from 'reselect';
import { getOrgTheme } from '../../api/placez/models/UserSetting';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      overflow: 'auto',
      padding: theme.spacing(3),
    },
    body: {
      overflow: 'auto',
      display: 'grid',
      gridGap: theme.spacing(3),
      gridTemplateColumns: '4fr 1fr',
      gridTemplateAreas: '"header1 header2" "content sidebar"',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto',
      },
    },
    header1: {
      gridArea: 'header1',
    },
    header2: {
      gridArea: 'header2',
    },
    content: {
      gridArea: 'content',
    },
    sidebar: {
      gridArea: 'sidebar',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

  })
);

type Props = {};

const PlaceLibrary = (props: Props) => {
  const classes = styles(props);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const places = useSelector((state: ReduxState) => state.place.places);
  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);
  const metrics = useSelector((state: ReduxState) => state.place.metrics);
  const selectedPlaceMetric = useSelector((state: ReduxState) => state.place.selectedPlaceMetric);
  const user = useSelector((state: ReduxState) => getUser(state));

  const goToAdd = () => {
    dispatch(SelectPlace(undefined));
    (window as any).gtag('event', 'add_new_place');
    navigate(placeRoutes.new.path);
  }

  useEffect(() => {
    const allTimeRange: DateRange = { startDate: null, endDate: null };
    dispatch(GetPlaceMetrics(allTimeRange));
  }, [])

  const filteredPlaces = places.filter(places => findInSearchableFeilds(places, globalFilter));

  return (
    <div className={classes.root}>
      <Jumbotron title={`${getOrgTheme(user.profile.organization_id).name} Library`}>
        <Tooltip title="Add New Place">
          <IconButton aria-label="Add New Place"
            color="secondary"
            onClick={goToAdd}>
            <AddIcon fontSize="large"/>
          </IconButton>
        </Tooltip>
      </Jumbotron>
      <div className={classes.body} >
        <div className={classes.content}>
          <PlaceTable
            places={filteredPlaces}
          />
        </div>
        <div className={classes.sidebar}>
          <PlaceCharts metrics={metrics} selectedMetric={selectedPlaceMetric} />
        </div>
      </div>
    </div>
  );
}


const getUserState = state => {
  return state.oidc.user;
};

const getUser = createSelector(
  [getUserState],
  user => user
);

export default PlaceLibrary;
