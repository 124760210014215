import { CanvasTexture, Sprite, SpriteMaterial, SRGBColorSpace, Texture } from 'three';
import { CameraLayers } from '../../models/BlueState';
import { Utils } from '../core/utils';
import BlueLabel from '../model/blueLabel';

export class LabelMaker {
  public sprite: Sprite;
  private resolution: number;

  private canvas: HTMLCanvasElement;

  public parameters: BlueLabel;
  public cleanParameters: BlueLabel;

  public defaultParameters: BlueLabel = {
    fontSize: 32,
    textColor: '#000000',
    labelText: '',
    fontface: 'Arial',
    borderThickness: 0,
    backgroundColor: '#ffffff',
    borderRadius: 0,
    lineSpacing: 0,
    margin: 0,
    labelPosition: 'center',
    rotation: 0,
    justify: 'center',
  }

  constructor(
    inputParameters?: BlueLabel,
    layers?: CameraLayers,
    userData?
  ) {
    this.resolution = 1;

    this.parameters = inputParameters;
    this.cleanParameters = this.fixParameters(this.parameters);


    if (inputParameters) {
      const cachedSprite = Utils.getLabelSprite(this.cleanParameters);
      if (cachedSprite) {
        this.sprite = cachedSprite.clone();
      } else {
        this.canvas = document.createElement('canvas');
        this.drawText(this.canvas, this.cleanParameters);
        this.sprite = this.createSprite(this.canvas, this.cleanParameters);
        Utils.addLabelSprite(this.cleanParameters, this.sprite);
        this.sprite = this.sprite.clone();
      }
      if (userData) {
        this.sprite.userData = userData
      }
      this.sprite.layers.set(layers);
    }
  }

  public setResolution = (resolution: number) => {
    this.resolution = resolution;
    this.updateSprite(this.sprite);
  }

  public fixParameters = (parameters: BlueLabel): BlueLabel => {
    let params = {...parameters} ? {...this.defaultParameters, ...parameters} : {...this.defaultParameters};
    // THIS ^^^^ does not work for input props undefined properties
    params.fontSize = params.fontSize ?? 24;

    params = this.setMargins(params);
    params = this.scaleParameters(params, this.resolution);
    return params;
  }

  private setMargins = (parameters: BlueLabel): BlueLabel => {
    const { margin } = parameters;
    return {
      marginTop: margin + 3,
      marginBottom: margin,
      marginLeft: margin,
      marginRight: margin,
      ...parameters,
    }
  }

  private scaleParameters = (parameters: BlueLabel, resolution: number): BlueLabel => {
    return {
      ...parameters,
      fontSize: parameters.fontSize * resolution,
      borderThickness: parameters.borderThickness * resolution,
      borderRadius: parameters.borderRadius * resolution,
      lineSpacing: parameters.lineSpacing * resolution,
      margin: parameters.margin * resolution,
      marginTop: parameters.marginTop * resolution,
      marginBottom: parameters.marginBottom * resolution,
      marginLeft: parameters.marginLeft * resolution,
      marginRight: parameters.marginRight * resolution,
    }
  }

  public drawText(canvas, parameters: BlueLabel) {
    const {fontSize, fontface, lineSpacing, marginLeft, marginRight, marginBottom, marginTop, borderThickness, borderColor, backgroundColor, textColor, labelPosition, borderRadius} = parameters;
    const context = canvas.getContext('2d');

    const font = `Bold ${fontSize}px ${fontface} `;
    context.font = font;

    const textLines = parameters.labelText.split('\n');

    const longestLine = textLines
      .map(textLIne => context.measureText(textLIne))
      .reduce((acc, line) => {
        if (line.width > acc) acc = line.width
        return acc;
      },
      0
    )

    const lineHeight = fontSize + lineSpacing;
    const blockHeight =
      lineHeight * textLines.length - lineSpacing + marginBottom + marginTop + borderThickness * 2 ;

    const blockWidth = longestLine + marginLeft + marginRight + borderThickness * 2;

    canvas.width = 2 ** Math.ceil(Math.log2(blockWidth));
    canvas.height = 2 ** Math.ceil(Math.log2(2 * blockHeight));

    if (borderColor) {
      context.strokeStyle = borderColor;
      context.lineWidth = borderThickness;
    }
    if (backgroundColor) {
      context.fillStyle = backgroundColor;
    }

    switch (labelPosition) {
      case 'top':
        context.translate(0, canvas.height / 2 - blockHeight);
        break;
      case 'bottom':
        context.translate(0, canvas.height / 2);
        break;
      default:
        context.translate(0, canvas.height / 2 - blockHeight / 2);
        break;
    }
    this.drawBackground(context, parameters, borderThickness / 2, borderThickness / 2, longestLine, blockHeight - borderThickness, borderRadius, canvas.width);

    context.font = font;
    context.textAlign = parameters.justify;
    context.textBaseline = 'middle';

    context.moveTo(0, 0);
    context.translate(canvas.width / 2, fontSize / 2 + borderThickness + marginTop);
    context.fillStyle = textColor;

    switch (parameters.justify) {
      case 'left':
        textLines.forEach((line, index) => {
          context.fillText(line, -blockWidth / 2 + marginLeft, index * lineHeight);
        });
        break;
      case 'center':
        textLines.forEach((line, index) => {
          context.fillText(line, 0, index * lineHeight);
        });
        break;
      case 'right':
        textLines.forEach((line, index) => {
          context.fillText(line, blockWidth / 2 - marginRight, index * lineHeight);
          // context.fillText(line, 0, index * lineHeight);
        });
        break;
      default:
        break;
    }
  }

  private createSpriteTexture = (canvas: HTMLCanvasElement): Texture => {
    const texture = new CanvasTexture(canvas);
    texture.colorSpace = SRGBColorSpace;
    return texture;
  }

  private createSpriteMaterial = (map: Texture, rotation: number): SpriteMaterial => {
    return new SpriteMaterial({
      map,
      depthTest: false,
      rotation: Math.PI / 180 * rotation,
    });
  }

  public createSprite = (canvas: HTMLCanvasElement, parameters: BlueLabel): Sprite => {
    const map = this.createSpriteTexture(canvas);

    const spriteMaterial = this.createSpriteMaterial(map, parameters.rotation);
    const sprite = new Sprite(spriteMaterial);
    sprite.renderOrder = 999;
    sprite.scale.set(canvas.width / this.resolution, canvas.height / this.resolution, 1.0);
    sprite.position.y = 50;
    return sprite;
  }

  public updateSprite = (sprite: Sprite) => {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawText(this.canvas, this.fixParameters(this.parameters));
    sprite.material.map = this.createSpriteTexture(this.canvas);
  }

  public getSprite = () => {
    return this.sprite;
  }

  private drawBackground(ctx, parameters: BlueLabel, x, y, textWidth, h, r, canvasWidth) {
    const { borderThickness, marginRight, marginLeft,  backgroundColor, borderColor } = parameters;
    const center = canvasWidth / 2;
    const halfWidth = textWidth / 2;
    const bt = borderThickness;
    ctx.moveTo(center, y);
    ctx.beginPath();
    ctx.lineTo(center + halfWidth + marginRight + bt - r, y);
    ctx.quadraticCurveTo(center + halfWidth + marginRight + bt , y, center + halfWidth + marginRight + bt, y + r);
    ctx.lineTo(center + halfWidth + marginRight + bt, h - r);
    ctx.quadraticCurveTo(center + halfWidth + marginRight + bt, h, center + halfWidth + marginRight + bt - r, h);
    ctx.lineTo(center - halfWidth - marginLeft - bt + r, h);
    ctx.quadraticCurveTo(center - halfWidth - marginLeft - bt, h, center - halfWidth - marginLeft - bt, h - r);
    ctx.lineTo(center - halfWidth - marginLeft - bt, y + r);
    ctx.quadraticCurveTo(center - halfWidth - marginLeft - bt, y, center - halfWidth - marginLeft - bt + r, y);
    ctx.closePath();
    if (backgroundColor) {
      ctx.fill();
    }
    if (borderColor) {
      ctx.stroke();
    }
  }

}
