export function escapeJSON(str: any) {
  // TODO: escape %x75 4HEXDIG ?? chars
  return str
    .replace(/[\"]/g, '\"')
    .replace(/[\\]/g, '\\')
    .replace(/[\/]/g, '\/')
    .replace(/[\b]/g, '\b')
    .replace(/[\f]/g, '\f')
    .replace(/[\n]/g, '\n')
    .replace(/[\r]/g, '\r')
    .replace(/[\t]/g, '\t');
}

export function isNotEmpty(arr: any) {
  if (arr) {
    return arr;
  }
  return [];
}
