import withModalContext, { WithModalContext } from '../withModalContext';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Dialog, makeStyles,
} from '@material-ui/core';
import ImageForm from './ImageForm';

interface Props extends WithModalContext {
  handleSetImage: (imageUrl: string, name?: string) => void;
  setName?: boolean;
  imageLabel:  string;
  currentImage: string;
  imageTypes?: string;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: theme.shape.borderRadius,
    },
  })
);

const LoadImageModal = (modalProps: Props) => {
  const { props } = modalProps.modalContext;
  const classes = styles(props);
  return(
    <Dialog
      classes={{
        paper: classes.modal,
      }}
      open={true}
      aria-labelledby="place-modal"
      fullWidth={true}
      scroll="paper"
      {...props}>
      <ImageForm
        imageLabel={modalProps.imageLabel}
        setAName={modalProps?.setName}
        handleSetImage={modalProps.handleSetImage}
        onClose={modalProps.modalContext.hideModal}
        currentImage={modalProps.currentImage}
        imageTypes={modalProps.imageTypes}
        />
    </Dialog>
  );
};

export default withModalContext(LoadImageModal);
