import {
  Theme, createStyles,
} from '@material-ui/core';

const panelStyles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: '1 0 0',
    minHeight: '0px',
    borderRadius: '0px',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    minWidth: '260px',
    overflow: 'hidden',
  },
  panelUpper: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
  },
  panelLower: {
    flex: 1,
    minHeight: '0px',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    padding: '0px',
    width: '100%',
  },
  panelFooter: {
    flexDirection: 'column',
  },
  titleContainer: {
    // background: theme.palette.background.paper,
    flexDirection: 'row',
    alignItems: 'stretch',
    padding: '2px',
    // margin: '0px 5px',
    display: 'flex',
    alignSelf: 'stretch',
    minHeight: '0px',
  },
  photosphereTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    padding: '2px',
    marginBottom: '5px',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    flex: 1,
    alignContent: 'center',
    textAlign: 'center',
  },
  photosphereName: {
    fontSize: 15,
    color: theme.palette.text.primary,
    alignContent: 'center',
    minWidth: '0px',
    overflow: 'auto',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: '5px',
  },
  photosphereHeight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.text.primary,
    height: '20px',
    width: '100%',
    justifyContent: 'space-around',
    margin: '6px 0px',
  },
  mainHeadingContainer: {
    borderLeft: `${theme.spacing()}px solid ${theme.palette.primary.main}`,
    padding: theme.spacing() / 2,
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  itemHeadingContainer: {
    padding: theme.spacing() / 2,
    display: 'flex',
    flexDirection: 'row',
  },
  headingContainer: {
    borderLeft: `${theme.spacing() / 2}px solid transparent`,
    marginTop: theme.spacing(),
    padding: theme.spacing() / 2,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headingContainerLight: {
    borderLeft: `${theme.spacing() / 2}px solid transparent`,
    padding: theme.spacing() / 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  heading: {
    color: theme.palette.text.primary,
    marginLeft: theme.spacing(),
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
  },
  lightHeading: {
    marginLeft: theme.spacing(),
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },

  // Isolate into Component
  itemIconBar: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(),
  },
  iconButton: {
    margin: 0,
    marginRight: theme.spacing() / 2,
    padding: theme.spacing() / 4,
  },
  hidePanelButton: {
    color: theme.palette.text.primary,
    padding: 0,
    justifyContent: 'start',
    float: 'left',
    minWidth: '0px',
  },
  cancelButton: {
    color: theme.palette.text.primary,
    width: '30px',
    fontSize: '30',
    borderRadius: '4px',
    minWidth: '0px',
  },
  deletePhotosphereButton: {
    color: theme.palette.text.primary,
    padding: '5px !important',
    fontSize: '20',
    borderRadius: '4px',
  },
  // Image
  itemImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
    alignItems: 'center',
  },
  photosphereImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    border: `3x solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '150px',
    width: '248px',
    border: `3x solid ${theme.palette.primary.main}`,
  },
  attendeeContainer: {
    display: 'flex',
    border: `3x solid ${theme.palette.primary.main}`,
    color: theme.palette.text.primary,
    whiteSpace: 'pre',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  seated: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  itemImage: {
    minHeight: '160px',
    maxHeight: '160px',
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    width: '100%',
    background: `radial-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
    borderRadius: '6px 6px 0px 0px',
  },
  viewImage: {
    width: '100%',
    background: `radial-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
    borderRadius: '6px 6px 6px 6px',
    cursor: 'pointer',
  },

  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(),
    paddingBottom: 0,
    justifyContent: 'center',
    minHeight: '46px',
  },
  fieldColumns: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5%',
    paddingBottom: 0,
    paddingTop: 0,
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(),
    paddingBottom: 0,
  },

  groupContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing() / 2}px ${theme.spacing(2)}px`,
  },
  bigLabel: {
  },
  controls: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: `${theme.spacing() / 2}px ${theme.spacing()}px`,
  },
  itemFieldHeading: {
    margin: theme.spacing() / 2,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.primary,
    flex: '1',
  },
  batchFieldHeading: {
    margin: '8px 8px 8px 0px',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.primary,
    flex: '1',
  },
  fieldHeading: {
    margin: theme.spacing(),
    marginTop: theme.spacing() / 2,
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.palette.text.primary,
    flex: '1',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldHeadingLight: {
    margin: theme.spacing(),
    fontWeight: 'bold',
    fontSize: 14,
    flex: '1',
  },
  input: {
    margin: theme.spacing() / 2,
    width: 64,
    outline: 'none',
  },
  labelInput: {
    width: "100%",
    outline: 'none',
    textAlignLast: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  rotationInput: {
    margin: theme.spacing() / 2,
    width: "100px",
    outline: 'none',
    textAlignLast: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  labelInputLight: {
    margin: theme.spacing() / 2,
    width: 164,
    outline: 'none',
    textAlignLast: 'center',
  },
  buttonDiv: {
    display: 'flex',
    margin: '5px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deleteButton: {
    borderColor: 'red',
    borderRadius: '4px',
    margin: '5px',
  },
  button: {
    margin: '5px',
    flex: 1,
    borderRadius: '4px',
    alignSelf: 'stretch',
  },
  primaryContainedButton: {
    color: `${theme.palette.common.white} !important`,
    background: theme.palette.primary.main,
  },
  pickerButton: {
    borderColor: 'purple',
    flex: 1,
    color: 'white',
    textShadow: '1px 1px 0 #333, -1px 1px 0 #333, 2px 2px 5px rgba(0,0,0,0.65)',
  },
  picker: {

  },
  overlayButton: {
    alignSelf: 'center',
    color: '#f5f5f5 !important',
    margin: '5px',
    borderColor: 'purple',
    borderRadius: '4px',
    position: 'relative',
    top: '-60px',
    marginBottom: '-40px',
    zIndex: 3,
  },
  overlayCancelButton: {
    position: 'relative',
    right: '-110px',
    top: '0px',
    color: theme.palette.primary.main,
    marginBottom: '-32px',
    zIndex: 3,
  },
  libraryHeadingContainer: {
    borderLeft: `${theme.spacing() / 2}px solid ${theme.palette.primary.main}`,
    padding: theme.spacing() / 2,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0px 5px 0px',
  },
  modGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flex: 1,
  },
  panel: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 0',
    overflowX: 'hidden',
    minHeight: 0,
  },
  itemSettings: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  settings: {
    color: theme.palette.primary.main,
    position: 'absolute',
    right: '23px',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  sliderDiv: {
    margin: "0px 5px",
  },
  spacingSlider: {
  },
  swatch: {
    backgroundColor: 'yellow',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    boxShadow: 'inset -5px -5px 4px rgba(0,0,0,.5)',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  form: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  // Isolate into Component
  setupTemplate: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'row',
    width: '100%',
    padding: theme.spacing(),
  },
  setupTemplateControls: {
    width: '100%',
    display: 'flex',
    margin: `${theme.spacing() / 2}px ${theme.spacing()}px`,
  },
  field: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: theme.palette.text.primary,
    flex:'1',
  },
  gridList: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    rowGap: '5px',
    columnGap: '4px',
    overflow: 'hidden',
  },
  selected: {
    minHeight: '120px',
    display: 'flex',
    alignItems: 'flex-end',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.02)',
    },
  },
  unSelected: {
    minHeight: '120px',
    display: 'flex',
    alignItems: 'flex-end',
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.02)',
    },
  },
  gridTitle: {
    backgroundColor: 'grey',
    opacity: 0.7,
    width: '115px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& span': {
      margin: '0px 5px',
      whiteSpace: 'nowrap',
      transform: 'translateX(0px)',
      transition: '1s',
      width: '150px',
    },
    '&:hover span': {
      transform: 'translateX(calc(105px - 100%))',
      transition: '1s',
    },
  },
  bigButton: {
    width: '100px',
    height: '100px',
  },
  bigButtonSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  gridImgSelected: {
    background: `radial-gradient(${theme.palette.primary.main} 50%, ${'#FFFFFF00'} 70%)`,
    minWidth:  '112px',
    maxWidth:  '112px',
    minHeight: '112px',
    maxHeight: '112px',
    objectFit: 'cover',
  },
  gridImgUnSelected: {
    minWidth:  '112px',
    maxWidth:  '112px',
    minHeight: '112px',
    maxHeight: '112px',
    objectFit: 'cover',
  },
  setting: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#f5f5f5 !important',
    marginLeft: '15px',
  },
  pickerContainer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '15px',
  },
  circlePickerContainer: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  panelBody: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '0px',
    flex: '1',
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.paper,
    borderRadius:'6px',
    '&:disabled': {
      opacity: 0.5,
    },
  },
  comment: {
    alignSelf: 'center',
    color: theme.palette.text.primary,
    marginTop: '5px',
  },
  attendeeList: {
    maxHeight: '50%',
  },
  layoutLabelsList: {
    maxHeight: '50%',
  },
  textArea: {
    flex: 1,
    minHeight: '100px',
    margin: theme.spacing(),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    textAlign: 'center',
  },
  select: {
    margin: '5px',
  },
  basicContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
  },
  tabs: {
    display: 'flex',
  },
  tabIcon: {
    minWidth: '72px',
  },
  tabPanel: {
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'stretch',
    padding: 0,
  },
  materialList: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.primary,
  },
  squareButton: {
    height: '100%',
    width: '100%',
  },
  lightThemePanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0',
    ...theme.typography.body1,
    width: '100%',
  },
  expansionPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  tableNumber: {
    width: '160px',
    alignSelf: 'center',
    backgroundColor: theme.palette.background.paper,
    marginTop: '40px',
  },
  tablePanel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  libraryControls: {
    margin: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '8px',
  },
  grouping: {
    margin: `${theme.spacing() / 2}px 0px`,
    alignSelf: 'flex-start',
  },
  libraryDefault: {
    padding: '15px 0px 15px 0px',
  },
  accordionWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing() / 2}px ${theme.spacing(2)}px`,
    backgroundColor: theme.palette.background.paper,
  },
  tableNumberLabel: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    minWidth: '200px',
    minHeight: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  secondaryColor: {
    color: theme.palette.secondary.main,
  }
});

export default panelStyles;
