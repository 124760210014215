import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  TextField,
  Tooltip,
  makeStyles,
  Theme,
} from '@material-ui/core';

import panelStyles from './panels.css';
import { HandlesFromBlue } from '../../models';
import { ReduxState } from '../../../../reducers';
import { SetControllerMode, SetNextTableNumber, ToggleDeleteTableNumberModeAction, } from '../../../../reducers/blue';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { BorderColorOutlined, ControlCameraSharp, Delete, DeleteOutlined } from '@material-ui/icons';
import { ControllerMode } from '../../../../models/BlueState'


type Props = {
  handlesFromBlue: HandlesFromBlue;
  onClosePanel: Function;
  onBack: Function;
};

const  ShapePanel = (props: Props) => {


  const styles = makeStyles<Theme>(panelStyles);

  const dispatch = useDispatch();

  const controllerMode = useSelector((state: ReduxState) => state.blue.controllerMode);
  const handleModeChange = (event, newControllerMode) => {
    dispatch(SetControllerMode(newControllerMode ?? ControllerMode.MOVE))
  }

  const classes = styles(props);
  return (
      <div className={classes.root}>
        <div className={classes.tablePanel}>
          <div className={classes.panelUpper}>
            <div className={classes.headingContainer}>
              <Typography className={classes.title} align="center">Shape</Typography>
            </div>
            <div className={classes.fieldContainer}>
              <ToggleButtonGroup
                value={controllerMode}
                exclusive
                onChange={handleModeChange}
                aria-label="floor plan mode">
                  <ToggleButton
                      value={ControllerMode.MOVE}>
                    <Tooltip title="Move Line">
                    <ControlCameraSharp/>
                    </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                      value={ControllerMode.CREATE}>
                  <Tooltip title="Draw Line">
                    <BorderColorOutlined/>
                  </Tooltip>
                  </ToggleButton>
                  <ToggleButton
                      value={ControllerMode.DELETE}>
                    <Tooltip title="Delete Line">
                    <DeleteOutlined/>
                    </Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
          </div>
          <div className={classes.fieldHeading}>
          </div>
        </div>
      </div>
  );
}

export default ShapePanel;
