import { PaletteType } from '@material-ui/core'
import { createTheme, Theme } from '@material-ui/core/styles';

const appName = (appName?: string): string => appName ? appName : 'Placez';

const drawerWidth: number = 240;
const minimizedDrawerWidth: number = 78;

const themeGenerator = (themeType: PaletteType, primary?, secondary?): Theme => createTheme(
  {
    palette: {
      type: themeType,
      primary: {
        main: primary ? primary : '#5C236F',
      },
      secondary: {
        main: secondary ? secondary : '#afa0b3',
      },
    },
    shape: {
      borderRadius: 4,
    },
    overrides: {
      MuiDrawer: {
        root: {
          width: drawerWidth,
          minimizedWidth: minimizedDrawerWidth,
        }
      }
    },
  }
);

export { themeGenerator, appName };
