import { useState, useEffect } from 'react';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { MediaAsset } from '../../api/';
import { damApi } from '../../api';
import { Edit as EditIcon, FilterList as FilterIcon } from '@material-ui/icons';
import { Grid, GridColumn as Column, GridCell } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import Jumbotron from '../../components/Jumbotron';
import { tableStyles } from '../../components/Tables/tableSyles.css';
import { SkuType } from '../../blue/items/asset'

interface Props { }

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading...</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

const ListProducts = (props: Props) => {
  const [DAMProducts, setDAMProducts] = useState([] as MediaAsset[]);
  const [sort, setSort] = useState([]);
  const [filter, setFilter] = useState(undefined);
  const [isFilterable, setIsFilterable] = useState(true);

  const handleSortChange = event => {
    setSort(event.sort);
  }

  const handleFilterChange = event => {
    setFilter(event.filter);
  }

  const onFilterClick = () => {
    setIsFilterable(!isFilterable);
  }

  useEffect(() => {
    damApi.getMediaAssets(true).then(response => {
      setDAMProducts(response.parsedBody);
    });
  }, [])


  const classes = styles(props);

  if (DAMProducts.length === 0) {
    return loadingPanel;
  }

  return (
    <div className={classes.root}>
      <Jumbotron
        title="Product List">
        <IconButton
          aria-label="Toggle Filter"
          onClick={onFilterClick}>
          <FilterIcon
            color="primary"
            fontSize="large"
          />
        </IconButton>
      </Jumbotron>
      <div className={classes.form}>
        <Grid
          className={classes.gridRoot}
          data={process(DAMProducts, {
            sort,
            filter,
          })}
          filterable={true && isFilterable}
          filter={filter}
          onFilterChange={handleFilterChange}
          sortable={true}
          sort={sort}
          onSortChange={handleSortChange}>
          <Column
            field="id"
            title="Product Id" />
          <Column
            field="name"
            title="Product Name" />
          <Column
            field="resizable"
            title="Resizable"
            filter="boolean"
            cell={ResizableCell} />
          <Column
            field="catalogs"
            title="Catalogs"
            cell={CatalogsCell} />
          <Column
            field="resourceSize"
            title="Resource Size"
            cell={ResourceSizeCell} />
          <Column
            field="species"
            title="Inventory Type" />
          <Column
            field="skuType"
            title="Sku Type"
            cell={SkuTypeCell}
          />
          <Column
            field="edit"
            title="Edit"
            className={classes.LinkStyle}
            width="80px"
            cell={EditDetailsCell}
            sortable={false}
            filterable={false} />
        </Grid>
      </div>
    </div>
  );
}
class ResizableCell extends GridCell {
  render() {
    const { resizable } = this.props.dataItem;
    return (
      <td>
        {resizable ? 'Resizable' : ''}
      </td>
    );
  }
}

class SkuTypeCell extends GridCell {
  render() {
    const { skuType } = this.props.dataItem;
    return (
      <td>
        {skuType in SkuType ? SkuType[skuType] : '000 FIX ME'}
      </td>
    );
  }
}

class CatalogsCell extends GridCell {
  render() {
    const { catalogs } = this.props.dataItem;
    return (
      <Tooltip title={catalogs == null ? '' : catalogs.join('\t')}>
        <td>
          {`${catalogs == null ? '0' : catalogs.length} catalogs`}
        </td>
      </Tooltip>
    );
  }
}

class ResourceSizeCell extends GridCell {
  render() {
    const { resourceSize } = this.props.dataItem;
    return (
      <td>
        {`${Math.round(resourceSize / 1024 / 1024 * 100) / 100} mb`}
      </td>
    );
  }
}

class EditDetailsCell extends GridCell {
  render() {
    const { id } = this.props.dataItem;
    return (
      <td>
        <Link to={`/media/${id}`}>
          <EditIcon color="primary"/>
        </Link>
      </td>
    );
  }
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  ...tableStyles(theme),
  root: {
  },
  form: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    margin: 0,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  filterButtonContainer: {
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    background: theme.palette.grey[300],
    justifyContent: 'space-between',
  },
})
);

export default ListProducts;
