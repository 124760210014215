import {
  Theme,
  createStyles,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ItemList from './ItemList';
import { Asset } from '../../../../../blue/items/asset';
import { SecondaryCategory } from '../../../../../api';
import { PlacezAccordion, PlacezAccordionDetails, PlacezAccordionSummary } from '../../../../PlacezUIComponents/PlacezAccordion'
import { ExpandMore } from '@material-ui/icons'

interface Props {
  onDragAsset: (event: any, dragType: string, asset: Asset) => void;
  categories: SecondaryCategory[];
  onSelect: (item: any) => void;
}

const CategoryAccordion = (props: Props) => {

  const { categories } = props;
  const classes = styles(props);
  const isSearchResult = categories && categories.length === 1 && categories[0].sortOrder === -1;

  return (
    <>
    {isSearchResult &&
      <div className={classes.itemList}>
        <ItemList
          key={categories[0].itemSkus.length}
          skus={categories[0].itemSkus}
          onDragAsset={props.onDragAsset}
          onSelect={props.onSelect}
          includeCustomSkus
        ></ItemList>
      </div>
    }
    {!isSearchResult &&
      <div className={classes.accordionList}>
        {categories?.length > 0 &&
          categories.map(category => (
            <PlacezAccordion key={category.name}>
              <PlacezAccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="body1" className={classes.categoryName}>{category.name}</Typography>
              </PlacezAccordionSummary>
              <PlacezAccordionDetails>
                <ItemList
                  skus={category.itemSkus}
                  onDragAsset={props.onDragAsset}
                  onSelect={props.onSelect}
                ></ItemList>
              </PlacezAccordionDetails>
            </PlacezAccordion>
        ))}
      </div>
    }
    </>
  );
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    categoryName: {
      display: 'inline',
      color: theme.palette.text.primary,
    },
    accordionList: {
      display: 'grid',
      width: '100%',
      minWidth: '0px',
      gridTemplateColumns: '1fr',
      rowGap: '2px',
    },
    itemList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      padding: '0px',
      width: '100%',
    },
  })
)

export default CategoryAccordion;
