import { DateRange } from './DateRange';
import { WeeklyOption } from './FriendlyDateRanges';

const date = new Date();

const lastWeek = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(date.getDate() - date.getDay() - 6);
  endDate.setDate(date.getDate() - date.getDay() + 1);
  return {
    startDate,
    endDate,
  };
};

const thisWeek = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(date.getDate() - date.getDay() + 1);
  endDate.setDate(date.getDate() - date.getDay() + 8);
  return {
    startDate,
    endDate,
  };
};

const nextWeek = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();
  startDate.setDate(date.getDate() - date.getDay() + 8);
  endDate.setDate(date.getDate() - date.getDay() + 15);
  return {
    startDate,
    endDate,
  };
};

const allWeeks = () :  DateRange => {
  const startDate = new Date(0);
  startDate.setFullYear(2019);
  const endDate = new Date();
  endDate.setDate(date.getDate());
  return {
    startDate,
    endDate,
  };
};

const lastMonth = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();

  startDate.setMonth(date.getMonth() - 1);
  startDate.setDate(0);

  endDate.setDate(0);
  return {
    startDate,
    endDate,
  };
};

const thisMonth = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();

  startDate.setDate(0);

  endDate.setMonth(date.getMonth() + 1);
  endDate.setDate(0);
  return {
    startDate,
    endDate,
  };
};

const nextMonth = () :  DateRange => {
  const startDate = new Date();
  const endDate = new Date();

  startDate.setMonth(date.getMonth() + 1);
  startDate.setDate(0);

  endDate.setMonth(date.getMonth() + 2);
  endDate.setDate(0);
  return {
    startDate,
    endDate,
  };
};

export function getWeekRange(option: WeeklyOption): DateRange {
  switch (option) {
    case WeeklyOption.LastWeek:
      return lastWeek();
    case WeeklyOption.ThisWeek:
      return thisWeek();
    case WeeklyOption.NextWeek:
      return nextWeek();
    case WeeklyOption.AllTime:
      return allWeeks();
    case WeeklyOption.LastMonth:
      return lastMonth();
    case WeeklyOption.ThisMonth:
      return thisMonth();
    case WeeklyOption.NextMonth:
      return nextMonth();
    default:
      return thisWeek();
  }
}
