import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Dialog, makeStyles,
} from '@material-ui/core';
import ClientForm from './ClientForm';
import { PickList } from '../../../api';
import { SimpleModalProps } from '../SimpleModal';

interface Props extends SimpleModalProps {
  handleSetNewClient: (value: any) => void;
  clientName?: string;
  picklists: PickList[];
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  })
);

const AddClientModal = (props: Props) => {
  const { picklists, open, setOpen } = props;
  const classes = styles(props);
  return(
    <Dialog
      classes={{
        paper: classes.modal,
      }}
      open={open}
      aria-labelledby="place-modal"
      fullWidth={true}
      scroll="paper"
      {...props}>
      <ClientForm
        handleSetNewClient={props.handleSetNewClient}
        setOpen={setOpen}
        clientName={props.clientName}
        picklists={picklists}
       />
    </Dialog>
  );
};

export default AddClientModal;
