import { HTMLAttributes } from 'react';
import {
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

interface Props {
  label: string;
  value: string;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    ...theme.typography.body2,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
  },
  value: {
    ...theme.typography.body2,
    color: theme.palette.text.primary,
  },
})
);

const CatalogCardLabel = (props: Props & HTMLAttributes<HTMLDivElement>) => {
  const classes = styles(props);
  const { label, value } = props;
  return (
    <div className={classes.root}>
      <span className={classes.label}>
        {label}
      </span>
      <span className={classes.value}>
        {value}
      </span>
    </div>
  );
}

export default CatalogCardLabel;
