import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, makeStyles,
} from '@material-ui/core';
import PlacezLayoutPlan from '../../../api/placez/models/PlacezLayoutPlan';
import { SimpleModalProps } from '../SimpleModal';
import ReactToPrint from 'react-to-print';
import { Print } from '@material-ui/icons';
import { createRef } from 'react';
import { SceneDetail } from '../../../api';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';

interface Props extends SimpleModalProps {
  scene: SceneDetail;
  layouts: PlacezLayoutPlan[];
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    printDiv: {
      color: '#000000',
    },
    page: {
      size: '7in 9.25in !important',
      display: 'flex',
      flexDirection: 'column',
    },
    sceneDate: {
      color: theme.palette.primary.main,
      pageBreakAfter: 'always',
    },
    sceneName: {
      marginTop: 10,
      fontSize: 26,
      textTransform: 'uppercase',
    },
    layoutName: {
      color: theme.palette.primary.main,
    },
    printContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    screenshotContainer: {
      width: '100%',
      height: '780px !important',
      pageBreakInside: 'avoid',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
      fontSize: '40px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  })
);

const formatStartEndDate = (startDateStr: Date, endDateStr: Date): string => {
  if (!startDateStr) {
    return '';
  }
  const startDate = new Date(startDateStr);
  const  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formatedStartDate = startDate.toLocaleDateString('en-US', options);
  const startTimeSplited = formatedStartDate.split(',');

  if (!endDateStr) {
    return `${startTimeSplited[0]} ${startTimeSplited[startTimeSplited.length - 1]}`;
  }

  const endDate = new Date(endDateStr);
  const formatedEndDate = endDate.toLocaleTimeString('en-US', options);
  const endTimeSplited = formatedEndDate.split(',');

  if (endDate.getFullYear() === startDate.getFullYear()
      && endDate.getMonth() === startDate.getMonth()
      && endDate.getDay() === startDate.getDay()) {
    return `${startTimeSplited[0]} ${startTimeSplited[startTimeSplited.length - 1]} ${'-'} ${endTimeSplited[endTimeSplited.length - 1].slice(8)}`;
  }

  return `${startTimeSplited[0]} ${startTimeSplited[startTimeSplited.length - 1]} ${'-'} ${endTimeSplited[0]} ${endTimeSplited[endTimeSplited.length - 1].slice(8)}`;
}

const PrintLayoutsModal = (props: Props) => {
  const classes = styles(props);
  const designerElementRef = createRef<HTMLDivElement>();
  const floorplansById = useSelector((state: ReduxState) => state.floorPlans.byId);
  return(
    <Dialog
      className={classes.dialog}
      open={props.open}
      fullWidth={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'paper'}
      maxWidth="lg"
      {...props}>
      <DialogTitle className={classes.title}>
        Print Layout Images
      </DialogTitle>
      <DialogContent>
          <div className={classes.printContent}>
            <div className={classes.printDiv} ref={designerElementRef}>
              {props.layouts && props.layouts.map((layout, index) => {
                return (
                  <div className={classes.page}>
                    <div
                      className={classes.screenshotContainer}
                      style={{
                        backgroundImage: `url(${layout.imageUrl}?${(new Date()).getTime()})`,
                      }}>
                    </div>
                    {/* <img
                      className={classes.screenshotContainer}
                      src={layout.imageUrl}
                      alt=''
                    /> */}
                    <div className={classes.sceneName}>
                      {props.scene.name}
                    </div>
                    <div>
                      <span className={classes.layoutName}>Layout Name:</span> {layout?.name}
                    </div>
                    <div>
                      <span className={classes.layoutName}>Floorplan:</span> {floorplansById?.[layout?.floorPlanId]?.name}
                    </div>
                    <div className={classes.sceneDate}>
                      {formatStartEndDate(props.scene.startUtcDateTime, props.scene.endUtcDateTime)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            variant="contained"
            onClick={() => props.setOpen(false)}>
            Close
          </Button>
          <ReactToPrint
            trigger={() =>
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              onClick = { () => (window as any).gtag('event', 'print-layouts') }
              ><Print/>
            </Button>}
            content={() => designerElementRef.current }
          />
        </DialogActions>
      </Dialog>
  );
};

export default PrintLayoutsModal;
