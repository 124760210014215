import { put, all, takeLatest, race, take, call, select, takeEvery } from 'redux-saga/effects';
import { SagaReady } from '../reducers/lifecycle';
import {
  types as globalStateTypes,
  ChangeGlobalViewStateAction,
  ChangeFixtureViewStateAction,
  ChangeLayoutViewStateAction,
  ChangePlannerToolAction,
  getFixtureViewState,
  getSaveBarState,
  SetGlobalStateInitialized,
  ChangeFixtureViewState,
  ChangeLayoutViewState,
  canEditLayout,
  ChangeToolStateAction,
} from '../reducers/globalState';
import blue, { types as blueStateTypes, getBlueInitialized, LoadLayout, InitDesigner, LoadFixturePlan, BlueInitAction, getDesignerReady, getSceneScanLoaded, sceneScanLoadedAction, getActiveController } from '../reducers/blue';
import { types as layoutTypes } from '../reducers/layouts';
import { types as designerTypes, getFloorPlan as getFloorPlanForDesigner, getLayout  } from '../reducers/designer';

import { LayoutViewState, GlobalViewState, PlannerTool, SaveBarState, FixtureViewState, ToolState } from '../models/GlobalState';
import { CameraType } from '../components/Blue/models/CameraType';
import { ControlsType } from '../components/Blue/models/ControlsType';
import { InitialUserState, ControllerType, CameraLayers, ProhibitedLayers, RequiredLayers } from '../models/BlueState';

import { getFromLocalStorage, saveToLocalStorage } from '../sharing/utils/localStorageHelper';
import { initAttendeeList } from './blue';
import { ReduxState } from '../reducers';
import { filter } from 'mathjs'

let prevFixtureViewState: FixtureViewState;

export default function* globalstateSaga() {
  yield all([
    takeLatest(globalStateTypes.GLOBAL_VIEW_STATE, changeGlobalViewState),
    takeLatest(globalStateTypes.FIXTURE_VIEW_STATE, changeFixtureViewState),
    takeLatest(globalStateTypes.LAYOUT_VIEW_STATE, changeLayoutViewState),
    takeLatest(globalStateTypes.PLANNER_TOOL, ChangePlannerTool),
    takeLatest(globalStateTypes.TOOL_STATE, changeToolState),
  ]);
  yield put(SagaReady('globalState'));
  yield call(setPrevFixtureViewState);
}

const getCameraLayers = (globalViewState: GlobalViewState, fixtureOrLayoutViewState: FixtureViewState | LayoutViewState): CameraLayers[] => {
  const userState = getFromLocalStorage('userState');
  const localCameraLayers = userState[globalViewState]?.[fixtureOrLayoutViewState]?.cameraLayers;

  const requiredLayers = RequiredLayers[globalViewState]?.[fixtureOrLayoutViewState]?.cameraLayers;
  const prohibitedLayers = ProhibitedLayers[globalViewState]?.[fixtureOrLayoutViewState]?.cameraLayers;
  const defaultCameraLayers = InitialUserState[globalViewState][fixtureOrLayoutViewState].cameraLayers;

  const newLayers = [...new Set(localCameraLayers.concat(requiredLayers))] as CameraLayers[];
  const filteredCameraLayers = newLayers.filter(layer => !(prohibitedLayers?.includes(layer)));

  return filteredCameraLayers ?? defaultCameraLayers;
}

export function* setPrevFixtureViewState() {
  while (true) {
    prevFixtureViewState = yield select(getFixtureViewState);
    yield take(globalStateTypes.FIXTURE_VIEW_STATE);
  }
}

function* changeGlobalViewState(action: ChangeGlobalViewStateAction) {
  yield put(SetGlobalStateInitialized(true));

  const { globalViewState } = action;
  const userState = getFromLocalStorage('userState');

  if (!userState) {
    saveToLocalStorage('userState', InitialUserState);
  } else {


    // this updates the userState when new keys are added to initialState
    function mergeStates(userState, initialState) {
      for (const key in initialState) {
        if (initialState[key] && typeof initialState[key] === 'object' && !Array.isArray(initialState[key])) {
          if (!userState.hasOwnProperty(key)) {
            userState[key] = {};
          }
          mergeStates(userState[key], initialState[key]);
          } else {
            if (!userState.hasOwnProperty(key)) {
            userState[key] = initialState[key];
          }
        }
      }
    }

    mergeStates(userState, InitialUserState);
    saveToLocalStorage('userState', userState);
  }

  let designerReady;

    let floorplan = yield select(getFloorPlanForDesigner);
    if (!floorplan) yield take(designerTypes.GET_DESIGNER_FLOOR_PLAN_SUCCESS);
    let blueReady = false;

  switch (globalViewState) {
    case GlobalViewState.Layout:
      const layout = yield select(getLayout);
      if (!layout) yield take(designerTypes.GET_DESIGNER_LAYOUT_SUCCESS);

      designerReady = yield select(getDesignerReady);
      if (!designerReady) yield take(blueStateTypes.INIT_DESIGNER);
      yield put(LoadLayout());
      blueReady = yield select(getBlueInitialized);
      if (!blueReady) yield take (blueStateTypes.BLUE_INIT);
      yield put(ChangeLayoutViewState(LayoutViewState.TwoDView));
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      break;
    case GlobalViewState.Fixtures:
      designerReady = yield select(getDesignerReady);
      if (!designerReady) yield take(blueStateTypes.INIT_DESIGNER);
      yield put(LoadFixturePlan());
      blueReady = yield select(getBlueInitialized);
      if (!blueReady) yield take (blueStateTypes.BLUE_INIT);
      yield put(ChangeFixtureViewState(FixtureViewState.Floorplan, FixtureViewState.Floorplan));
      break;
  }

}

function* changeFixtureViewState(action: ChangeFixtureViewStateAction) {
  const { fixtureViewState } = action;
  const saveBarState = yield select(getSaveBarState);

  const currentControls: ControlsType = yield select((state: ReduxState) => state.blue.controlsType);
  const currentCameraType: CameraType = yield select((state: ReduxState) => state.blue.cameraType);
  const currentPlannerTool: PlannerTool = yield select((state: ReduxState) => state.globalstate.plannerTool);




  yield put({ type: globalStateTypes.GLOBAL_STATE_INITIALIZED, globalStateInitialized: true });
  switch (fixtureViewState) {
      case FixtureViewState.Floorplan :
        yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.Floorplan)})
        break;
    case FixtureViewState.TwoDView :
      if (currentPlannerTool !== PlannerTool.Designer) yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Designer });
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.TwoDView)})
      break;
    case FixtureViewState.ThreeDView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Perspective });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.PerspectiveControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
      if (currentPlannerTool !== PlannerTool.Designer) yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Designer });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.ThreeDView)})
      break;
    case FixtureViewState.PhotosphereView :
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Photosphere });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.Photosphere });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.PhotosphereView)
          .filter(layer => {
            const prohibitedLayers = [CameraLayers.Walls];
            return !prohibitedLayers.includes(layer)
          })
      });
      yield put({ type:blueStateTypes.SET_SECTION_VIEW, sectionView:false });
      break;
    case FixtureViewState.LabelView :
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Label });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Label });
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.LabelView)})
      break;
    case FixtureViewState.ShapeView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Drawing });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Shape });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Fixtures, FixtureViewState.ShapeView)})
      break;
    default:
  }

  if ( (action.currentViewState !== FixtureViewState.TwoDView && action.currentViewState !== FixtureViewState.ThreeDView) &&
    (fixtureViewState === FixtureViewState.TwoDView || fixtureViewState === FixtureViewState.ThreeDView)) {
      yield put({ type: designerTypes.REFRESH_FIXTURE_PLAN});
    }

  if ( (action.currentViewState !== FixtureViewState.Floorplan) &&
    (fixtureViewState === FixtureViewState.Floorplan)) {
      yield put({ type: designerTypes.REFRESH_FIXTURE_PLAN});
    }
}

function* changeLayoutViewState(action: ChangeLayoutViewStateAction) {
  const { layoutViewState } = action;
  const canEdit = yield select(canEditLayout);

  const currentControls: ControlsType = yield select((state: ReduxState) => state.blue.controlsType);
  const currentCameraType: CameraType = yield select((state: ReduxState) => state.blue.cameraType);
  const currentPlannerTool: PlannerTool = yield select((state: ReduxState) => state.globalstate.plannerTool);

  put({ type: globalStateTypes.GLOBAL_STATE_INITIALIZED, globalStateInitialized: true });
  switch (layoutViewState) {
    case LayoutViewState.TwoDView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
      if (canEdit) {
        if (currentPlannerTool !== PlannerTool.Designer) yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Designer });
      } else {
        yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.None });
      }
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.TwoDView)})
      break;
    case LayoutViewState.ThreeDView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Perspective });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.PerspectiveControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
      if (canEdit) {
        if (currentPlannerTool !== PlannerTool.Designer) yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Designer });
      } else {
        yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.None });
      }
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.ThreeDView)})
      break;
    case LayoutViewState.PhotosphereView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.FPVCamera });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.Photosphere });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Photosphere });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.None });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.PhotosphereView)
          .filter(layer => {
            const prohibitedLayers = [CameraLayers.Walls, CameraLayers.FloorplaneImage, CameraLayers.Floorplanes, CameraLayers.BasePlanes];
            return !prohibitedLayers.includes(layer)
          })
      });
      yield put({ type:blueStateTypes.SET_SECTION_VIEW, sectionView:false });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.None });
      break;
    case LayoutViewState.StreetView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.FPVCamera });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.PointerLock });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.None });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.None });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.StreetView)})
      yield put({ type:blueStateTypes.SET_SECTION_VIEW, sectionView:false });
      break;
    case LayoutViewState.AttendeeView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Attendee });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Attendee });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.AttendeeView)})
      yield call(initAttendeeList);
      break;
    case LayoutViewState.LabelView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Label });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Label });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.LabelView)})
      break;
    case LayoutViewState.NumberView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Number });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Number });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.NumberView)})
      break;
    case LayoutViewState.ShapeView :
      yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Drawing });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.Shape });
      yield put({ type: blueStateTypes.CAMERA_LAYERS_STATE,
        cameraLayersState: getCameraLayers(GlobalViewState.Layout, LayoutViewState.ShapeView)})
      break;
    default:
      if (currentControls !== ControlsType.OrthographicControls) yield put({ type: blueStateTypes.SET_CONTROLS_TYPE, controlsType: ControlsType.OrthographicControls });
      // if (currentCameraType !== CameraType.Orthographic) yield put({ type: blueStateTypes.SET_CAMERA_TYPE, cameraType: CameraType.Orthographic });
      yield put({ type: globalStateTypes.PLANNER_TOOL, plannerTool: PlannerTool.None });
      break;
  }
}

function* ChangePlannerTool(action: ChangePlannerToolAction) {
  if (action.plannerTool === PlannerTool.Designer) {
    const activeController = yield select(getActiveController);
    if (activeController !== ControllerType.Main && activeController !== ControllerType.Batch) {
      yield put({ type: blueStateTypes.SET_ACTIVE_CONTROLLER, controllerType: ControllerType.Main });
    }
  }
}

function* changeToolState(action: ChangeToolStateAction) {
  const currentToolState: ToolState = yield select((state: ReduxState) => state.globalstate.toolState);
  if (currentToolState === ToolState.AddBatch || currentToolState === ToolState.NewBatch) {
    yield put ({ type : blueStateTypes.SET_MULTISELECT, multiSelect: false});
    yield put({ type: globalStateTypes.DISABLE_NAVIGATION, disable: true });
  } else {
    yield put({ type: globalStateTypes.DISABLE_NAVIGATION, disable: false });
  }
}
