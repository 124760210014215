export interface InvoiceLineItem {
  id?: string,
  description?: string,
  quantity?: number,
  price?: number,
  priceRateInHours?: number,
  notes?: string,
  total?: number,
  sortOrder?: number,
  inEdit?: boolean,
  group?: string,
}

export const keysOfInvoiceLineItem = [
  'id',
  'description',
  'quantity',
  'price',
  'priceRateInHours',
  'notes',
  'total',
  'sortOrder',
  'inEdit',
  'group',
]
