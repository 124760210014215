import { useSelector } from 'react-redux';

import { ReduxState } from '../../reducers';
import { useParams } from 'react-router';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { getClientById } from '../../reducers/client';

import ClientForm from '../../components/ClientForm';

interface Props {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      height: '100%',
      width: '100%',
      overflow: 'none',
      overflowY: 'auto',
    },
    title: {
      ...theme.typography.h5,
      fontSize: 18,
      fontWeight: 'bold',
    },
  })
);

const EditClient = (props: Props) => {
  const classes = styles(props);
  const { id } = useParams();
  const clients = useSelector((state: ReduxState) => state.client.clients);

  const client = getClientById(clients, parseInt(id));
  return (
    <div className={classes.root}>
      <ClientForm client={client}/>
    </div>
  );
}

export default EditClient;
