import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ScenesIcon = (props: any) => (
  <SvgIcon {...props}>
    <path
      d="M5.76 10H20v8H4V6.47M22 4h-4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4z" // tslint:disable-line
    />
  </SvgIcon>
);

export default ScenesIcon;
