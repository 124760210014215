import copy from 'clipboard-copy';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  Button, makeStyles,
} from '@material-ui/core';
import {
  FileCopyOutlined as CopyToClipboardIcon,
} from '@material-ui/icons';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      marginBottom: `${theme.spacing(3)}px`,
    },
    linkScroll: {
      maxWidth: 300,
      overflowX: 'scroll',
      overflowY: 'hidden',
      margin: 0,
      border: `1px solid ${theme.palette.divider}`,
      minHeight: 40,
      maxHeight: 40,
      display: 'flex',
    },
    link: {
      ...theme.typography.body2,
      whiteSpace: 'nowrap',
      margin: 0,
      minWidth: 40,
      minHeight: 40,
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    copyButton: {
      minWidth: 40,
      minHeight: 40,
      margin: 0,
    },
  })
)

interface Props {
  link: string;
};

const LinkCopier = (props: Props) => {
  const classes = styles(props);
  const {
    link,
  } = props;

  return(
    <div className={classes.root}>
      <div className={classes.linkScroll}>
        <span
          className={classes.link}>
          {link}
        </span>
      </div>
      {
        document.queryCommandSupported('copy') &&
        <Button
          onClick={() => { copy(link); }}
          className={classes.copyButton}
          variant="outlined"
          color="secondary">
          <CopyToClipboardIcon/>
        </Button>
      }
    </div>
  );
}

export default LinkCopier;
