import React, { PropsWithChildren, useState } from "react"

interface Props {}

export interface SimpleModalProps {
  open?: boolean;
  setOpen?: (boolean) => {};
}

export const SimpleModal = (props: PropsWithChildren<Props>) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div onClick={e => setOpen(true)}>
        {props.children[0]}
      </div>
      {React.cloneElement(props.children[1], {open: open, setOpen: setOpen})}
    </>
  )
}
