
import withModalContext, { WithModalContext } from '../withModalContext';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import {
  Dialog, makeStyles,
} from '@material-ui/core';
import ModelForm from './ModelForm';
import { Sku } from '../../../api/';

interface Props extends WithModalContext {
  item: Sku;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    modal: {
      borderRadius: theme.shape.borderRadius,
      border: `solid 2px ${theme.palette.primary.main}`,
      overflowY: 'initial',
    },
  })
);

const ModelModal = (modalProps: Props) => {
  const { props } = modalProps.modalContext;
  const classes = styles(props);
  return(
    <Dialog
      classes={{
        paper: classes.modal,
      }}
      open={true}
      aria-labelledby="place-modal"
      fullWidth={true}
      scroll="paper"
      {...props}>
      <ModelForm item={modalProps.item} modalContext={modalProps.modalContext} />
    </Dialog>
  );
};

export default withModalContext(ModelModal);
