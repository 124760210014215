import {
  mainRoutes,
} from '../../routes';
import SideBarNavLink from './SideBarNavLink';
import animations from '../../assets/themes/animations';
import { MainNavState } from '../../models/GlobalState';

interface Props {}

const DefaultSidebar = (props: Props) => {
  return (
    <>
      <SideBarNavLink
          key={0}
          route={mainRoutes[0]}
          mainNavStateType={MainNavState.Home}
        />
      <SideBarNavLink
          key={1}
          route={mainRoutes[1]}
          mainNavStateType={MainNavState.Scenes}
        />
      <SideBarNavLink
          key={2}
          route={mainRoutes[2]}
          timeout={2 * animations.growDuration}
          mainNavStateType={MainNavState.Clients}
        />
      <SideBarNavLink
          key={3}
          route={mainRoutes[3]}
          timeout={3 * animations.growDuration}
          mainNavStateType={MainNavState.Places}
        />
      <SideBarNavLink
          key={4}
          route={mainRoutes[4]}
          timeout={4 * animations.growDuration}
          mainNavStateType={MainNavState.AssetLibrary}
        />
      <SideBarNavLink
          key={5}
          route={mainRoutes[5]}
          timeout={5 * animations.growDuration}
          mainNavStateType={MainNavState.Settings}
        />
      </>
  );
}

export default DefaultSidebar;
