import { useState, useEffect, useRef, cloneElement, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, MenuItem, Select, Tab, Tabs, Tooltip, Typography, useTheme } from '@material-ui/core';
import { PlacezFixturePlan, PlacezLayoutPlan, Scene } from '../../api';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import { makeStyles } from '@material-ui/styles';

import {
  Theme,
 }
  from '@material-ui/core/styles';
import {
  CloudDownload,
  } from '@material-ui/icons';

// Components
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { InvoiceLineItem } from '../Invoicing/InvoiceModels';
import { SeatInstance } from '../../blue/itemModifiers/ChairMod';
import NewLineItemInput from './NewLineItemInput';

import {
  Grid,
  GridColumn,
  GridItemChangeEvent,
  GridCellProps,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { tableStyles } from '../Tables/tableSyles.css';
import { MyCommandCell } from '../Tables/InlineEditableGrid/myCommandCell';
import { BillingRate } from '../../blue/core/utils';
import TabPanel from '../Modals/TableConfigModal/TabPanel';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { ReduxState } from '../../reducers';
import modalStyles from '../Blue/models/modalStyles.css';
import PDFIcon from '../../assets/icons/DownloadPDF';
import { UpdateLayouts } from '../../reducers/layouts'
import { SkuType } from '../../blue/items/asset'

const MyRateCell = (props) => {
  const { priceRateInHours } = props.dataItem;
  const { dataItem } = props;

  const rate =BillingRate[priceRateInHours];
  return (
    <td>
      {dataItem.inEdit ? (
        <Select id="placeSelect"
          value={BillingRate[rate]}
          style={{
            width: '100px',
          }}
          onChange={props.onSetRate(dataItem)}>
          {Object.values(BillingRate)
            .filter((v) => isNaN(Number(v)))
            .map((rate) =>
              <MenuItem value={BillingRate[rate]}>{rate}</MenuItem>
            )}
        </Select>
      ) : (
        rate
      )}
    </td>
  );
}


enum KeyRows {
  Layout = 'Layout:',
  Assets = 'Assets:',
}

 export const getSceneTime = (scene: Scene): number => {
    if (scene) {
      const start = new Date(scene.startUtcDateTime).getTime();
      const end = new Date(scene.endUtcDateTime).getTime();
      const time = Math.ceil(Math.round((end - start)/(1000 * 60) / 15) / 4);
      return time;
    }
    return 0;
  }

export const updateComputedInvoiceLineItems = (layouts: PlacezLayoutPlan[], floorPlans: PlacezFixturePlan[], scene: Scene, assetsBySku): {[layoutId: string]: InvoiceLineItem[]} => {
  let computedLineItems: {[layoutId: string]: InvoiceLineItem[]} = {};
  layouts
    .forEach((layout: PlacezLayoutPlan) => {
      computedLineItems[layout.id] = formatLayoutPricing(layout, floorPlans, getSceneTime(scene), assetsBySku);
    })
  return computedLineItems;
}

export const updateInvoiceLineItems = (layouts: PlacezLayoutPlan[]): {[layoutId: string]: InvoiceLineItem[]} => {
  let invoiceLineItems: {[layoutId: string]: InvoiceLineItem[]} = {};
  layouts
    .forEach((layout: PlacezLayoutPlan) => {
      if (layout.invoiceLineItems && layout.invoiceLineItems.length > 0) {
        invoiceLineItems[layout.id] = layout.invoiceLineItems;
      }
    })
  return invoiceLineItems;
}

export const buildSceneLineItems = (layouts, computedLayoutLineItems, layoutLineItems, hiddenLayouts?): InvoiceLineItem[] => {
  let updatedData: InvoiceLineItem[] = [];
  layouts
    .filter((layout: PlacezLayoutPlan) => {
      if (hiddenLayouts) {
        return !hiddenLayouts[layout.id];
      }
      return  !layout.hideInInvoice;
    })
    .forEach((layout: PlacezLayoutPlan) => {
      updatedData = updatedData.concat(computedLayoutLineItems[layout.id] ?? []);
      updatedData = updatedData.concat(layoutLineItems[layout.id] ?? []);
      updatedData.push({})
    })
  return updatedData;
}

export const rollUpPrice = (invoiceLineItems: InvoiceLineItem[]): number => {
  let totalPrice = 0;
  for (let index = 0; index < invoiceLineItems.length - 1; index++) {
    const lineItem = invoiceLineItems[index];
    if (lineItem.total !== undefined) {
      totalPrice += lineItem.total;
    }
  }
  return cleanUpDecimal(totalPrice);
}
const cleanUpDecimal = (number): number => {
  return parseFloat(number.toFixed(2));
}

const calcTotal = (lineItem: InvoiceLineItem, timeInHours: number): number => {
  if (lineItem === undefined) return 0;

  const quantity = lineItem.quantity ?? 1;
  const price = lineItem.price ?? 0;
  const priceRateInHours = lineItem.priceRateInHours ?? BillingRate.FlatRate;

  if (price) {
    switch (priceRateInHours) {
      case BillingRate.PerHour:
        return cleanUpDecimal(timeInHours * price * quantity);
      case BillingRate.PerDay:
        return cleanUpDecimal(Math.ceil(timeInHours / 24) * price * quantity);
      case BillingRate.FlatRate:
      default:
        return cleanUpDecimal(price * quantity);
    }
  } else {
    return undefined;
  }
}

const getPriceFromDB = (assetID, assetsBySku): number => {
  return assetsBySku[assetID]?.price ?? 0;
}

const getPriceRateFromDB = (assetID, assetsBySku): number => {
  return assetsBySku[assetID]?.priceRateInHours ?? BillingRate.FlatRate;
}


export const formatLayoutPricing = (layout: PlacezLayoutPlan, floorPlans: PlacezFixturePlan[], timeInHours: number, assetsBySku, onlyAssets?: boolean): InvoiceLineItem[] => {
  let layoutPricing = [];
  if (!onlyAssets) {
    layoutPricing.push({
      description: `${KeyRows.Layout} ${layout.name}`,
      // total: floorPlan.price,
    })
    // get floorplan price for layout
    const floorPlan = floorPlans.find((floorPlan: PlacezFixturePlan) => {
      return floorPlan.id === layout.floorPlanId;
    })
    if (floorPlan) {
      const floorPlanLineItem = {
        description: floorPlan.name,
        price: floorPlan.price,
        priceRateInHours: floorPlan.priceRateInHours,
        total: 0,
      }
      floorPlanLineItem.total = calcTotal(floorPlanLineItem, timeInHours);
      layoutPricing.push(floorPlanLineItem);
    }
    layoutPricing.push({
      description: 'Assets:',
    })
  }

  //get Asset Prices
  if (layout.items.length === 0) {
    return layoutPricing;
  }

  const assets = layout.items.reduce((accumulator: { [id: string]: InvoiceLineItem }, asset) => {
    if (getPriceFromDB(asset.id, assetsBySku)) {
      if (accumulator[asset.id]) {
        accumulator[asset.id].quantity ++;
      } else {
        accumulator[asset.id] = {
          description: asset.name,
          quantity: 1,
          price: getPriceFromDB(asset.id, assetsBySku),
          priceRateInHours: getPriceRateFromDB(asset.id, assetsBySku),
          group: SkuType[asset.skuType],
        }
      }
    }

    //Chairs
    if (asset.modifiers?.chairMod?.seatPositions && getPriceFromDB(asset.modifiers?.chairMod?.chairAsset?.id, assetsBySku)) {
      const chairMod = asset.modifiers.chairMod;

      const modifierChairAsset = asset.modifiers.chairMod?.chairAsset;
      if (accumulator[modifierChairAsset.id]) {
        accumulator[modifierChairAsset.id].quantity += chairMod.seatPositions
          .filter((chair: SeatInstance) => {
            return !chair.hidden && chair.position.length > 0;
          })
          .length
      } else {
        accumulator[modifierChairAsset.id] = {
          description: modifierChairAsset.name,
          quantity: chairMod.seatPositions
          .filter((chair: SeatInstance) => {
            return !chair.hidden && chair.position.length > 0;
          })
          .length,
          price: getPriceFromDB(modifierChairAsset.id, assetsBySku),
          priceRateInHours: getPriceRateFromDB(modifierChairAsset.id, assetsBySku),
        }
      }

      const modifierPlaceSettingAsset = asset.modifiers.placeSettingMod?.placeSettingAsset;
      if (getPriceFromDB(modifierPlaceSettingAsset?.id, assetsBySku)) {
        if (accumulator[modifierPlaceSettingAsset.id]) {
          accumulator[modifierPlaceSettingAsset.id].quantity += chairMod.seatPositions
            .filter((chair: SeatInstance) => {
              return !chair.hidden && chair.position.length > 0;
            })
            .length
        } else {
          accumulator[modifierPlaceSettingAsset.id] = {
            description: modifierPlaceSettingAsset.name,
            quantity: chairMod.seatPositions
            .filter((chair: SeatInstance) => {
              return !chair.hidden && chair.position.length > 0;
            })
            .length,
            price: getPriceFromDB(modifierPlaceSettingAsset.id, assetsBySku),
            priceRateInHours: getPriceRateFromDB(modifierPlaceSettingAsset.id, assetsBySku),
          }
        }
      }
    }

    // centerpiece
    const modifierCenterpieceAsset = asset.modifiers?.centerpieceMod?.centerpieceAsset;
    if (getPriceFromDB(modifierCenterpieceAsset?.id, assetsBySku)) {
      if (accumulator[modifierCenterpieceAsset.id]) {
        accumulator[modifierCenterpieceAsset.id].quantity += asset.modifiers.centerpieceMod.numberOfCenterpieces;
      } else {
        accumulator[modifierCenterpieceAsset.id] = {
          description: modifierCenterpieceAsset.name,
          quantity: asset.modifiers.centerpieceMod.numberOfCenterpieces,
          price: getPriceFromDB(modifierCenterpieceAsset.id, assetsBySku),
          priceRateInHours: getPriceRateFromDB(modifierCenterpieceAsset.id, assetsBySku),
        }
      }
    }

    //
    const modifierLinenAsset = asset.modifiers?.linenMod?.linenAsset;
    if (getPriceFromDB(modifierLinenAsset?.id, assetsBySku)) {
      const modifierLinenAsset = asset.modifiers.linenMod?.linenAsset;
      if (accumulator[modifierLinenAsset.id]) {
        accumulator[modifierLinenAsset.id].quantity ++;
      } else {
        accumulator[modifierLinenAsset.id] = {
          description: modifierLinenAsset.name,
          quantity: 1,
          price: getPriceFromDB(modifierLinenAsset.id, assetsBySku),
          priceRateInHours: getPriceRateFromDB(modifierLinenAsset.id, assetsBySku),
        }
      }
    }


    return accumulator;
  },
  { }
  );
  Object.keys(assets).forEach(key => {
    layoutPricing.push(assets[key]);
  });
  layoutPricing = layoutPricing.map((invoiceLineItem: InvoiceLineItem) => {
    invoiceLineItem.total = calcTotal(invoiceLineItem, timeInHours);
    return invoiceLineItem;
  })
  return layoutPricing;
}

interface Props {
  floorPlans: PlacezFixturePlan[];
  scene: Scene;
  open: boolean;
  onInvoiceComplete: (guaranteedSpend?: number) => void;
}

const InvoiceModal = (props: Props) => {
  const theme: Theme = useTheme();
  const dispatch = useDispatch();

  const [guaranteedSpend, setGuaranteedSpend] = useState<number>(0);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [hiddenLayouts, setHiddenLayouts] = useState<{[layoutId: string] : boolean}>({});

  const layouts = useSelector((state: ReduxState) => state.layouts.scene.unsorted);

  const rowRender = (
    trElement: ReactElement<HTMLTableRowElement>,
    props: GridRowProps
    ) => {
      const themeStyle = {
        backgroundColor: theme.palette.secondary.light,
        fontWeight: 'bold',
        fontSize: '16px',
        whiteSpace: 'nowrap',
      };
      const assetStyle = {
        fontWeight: 'bold',
      };

      let rowStyle = {};
      if (props.dataItem.description) {
        if (props.dataItem.description.search(KeyRows.Layout) !== -1){
          rowStyle = themeStyle
        };
        if (props.dataItem.description.search(KeyRows.Assets) !== -1){
          rowStyle = assetStyle
        };
      }

      const trProps: any = { style: rowStyle };

      return cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
  };

  const handleInvoiceCancel = () => {
    props.onInvoiceComplete();
  }


  const handleMoveContinue = () => {
    const updatedLayouts = Object.keys(hiddenLayouts).map((layoutId: string) => {
      return {
      ...layouts.find((layout: PlacezLayoutPlan) => layout.id === layoutId),
      invoiceLineItems: layoutLineItems[layoutId],
      hideInInvoice: hiddenLayouts[layoutId],
      }
    });
    dispatch(UpdateLayouts(updatedLayouts))
    props.onInvoiceComplete(guaranteedSpend);
  }


  const onSetGuaranteedSpend = (guaranteedSpend: number) => {
    setGuaranteedSpend(guaranteedSpend);
  }

  const tableStyle = makeStyles<Theme>(tableStyles);
  const tableClasses = tableStyle(props);
  const modalStyle = makeStyles<Theme>(modalStyles);
  const modalClasses = modalStyle(props);
  const classes = {
    ...modalClasses,
    ...tableClasses,
  }

  const grid = useRef();

  const CommandCell = (props: GridCellProps) => (
    <MyCommandCell
      {...props}
      edit={enterEdit}
      remove={remove}
      add={add}
      discard={discard}
      update={update}
      cancel={cancel}
      editField={editField}
    />
  );

  const onSetRate = (dataItem: InvoiceLineItem) => (event: React.ChangeEvent<{ value: number}>) => {
    const newData = data.map((item) =>
      item.id === dataItem.id
        ? { ...item,
            priceRateInHours: event.target.value,
            inEdit: true,
          }
        : item
    );

    setData(newData);
  }

  const RateCell = (props: GridCellProps) => (
    <MyRateCell
      {...props}
      onSetRate={onSetRate}
    />
  );

  const columns = [
    { field: 'description', title: 'Description', className: classes.overflowEllipsis},
    { field: 'price', title: 'Price', className: classes.overflowEllipsis, minShow: 770, editor: 'numeric', width: '100px' },
    { field: 'quantity', title: 'Count', className: classes.overflowEllipsis, minShow: 770, editor: 'numeric', width: '100px' },
    { field: 'priceRateInHours', title: 'Rate', cell: RateCell, className: classes.overflowEllipsis, minShow: 570, width: '120px' },
    { field: 'notes', title: 'Notes', className: classes.overflowEllipsis, minShow: 440 },
    { field: 'total', title: 'Total', className: classes.overflowEllipsis, minShow: 200, editable: false, width: '140px' },
  ];


  // const [sort, setSort] = useState<SortDescriptor[]>([{field: 'order', dir: 'desc'}]);
  const [sort, setSort] = useState<SortDescriptor[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<any[]>(columns);
  const [data, setData] = useState<InvoiceLineItem[]>([]);
  const [computedLayoutLineItems, setComputedLayoutLineItems] = useState<{[layoutId: string]: InvoiceLineItem[]}>({});
  const [layoutLineItems, setLayoutLineItems] = useState<{[layoutId: string]: InvoiceLineItem[]}>({});
  const {floorPlans, scene } = props;
  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);

  const checkColumnMaxWidth = () => {
    if (grid.current) {
      const currentVisibleColumns = columns?.filter(item => !item.minShow || item.minShow <= (grid?.current as any)?.offsetWidth) ?? columns;
      if (currentVisibleColumns.length !== visibleColumns.length) {
        setVisibleColumns(currentVisibleColumns);
      }
    }
  }



  const formatData = (layouts: PlacezLayoutPlan[], floorPlans: PlacezFixturePlan[], scene: Scene, assetsBySku) => {
    setComputedLayoutLineItems(updateComputedInvoiceLineItems(layouts, floorPlans, scene, assetsBySku));
    setLayoutLineItems(updateInvoiceLineItems(layouts));
  }

  useEffect(() => {
    grid.current = document.querySelector(`.${CSS.escape(classes.gridRoot)}`);
    window.addEventListener('resize', checkColumnMaxWidth);
    checkColumnMaxWidth();
    return () => {
      window.removeEventListener('resize', checkColumnMaxWidth);
    }
  }, [])

  useEffect(() => {
    onSetGuaranteedSpend(rollUpPrice(data));
  }, [
    data,
  ])



  useEffect(() => {
    const sceneLineItems = buildSceneLineItems(layouts ?? [], computedLayoutLineItems, layoutLineItems, hiddenLayouts)
    sceneLineItems
      .push({
        notes: `Total @${getSceneTime(scene)}hrs:`,
        total: rollUpPrice(sceneLineItems),
      })
    setData(sceneLineItems);
  }, [
    computedLayoutLineItems, layoutLineItems, hiddenLayouts
  ])

  useEffect(() => {
    if (layouts && layouts.length !== 0 && floorPlans && floorPlans.length !== 0) {
      formatData(layouts ?? [], floorPlans, scene, assetsBySku);
    }
    setSelectedTab(0);
  }, [
    layouts,
    floorPlans,
    scene,
    props.open
  ])

  useEffect(() => {
    setHiddenLayouts((layouts ?? []).reduce((acc, layout: PlacezLayoutPlan) => {
        acc[layout.id] = layout.hideInInvoice;
        return acc;
      }, {})
    );

  }, [
    layouts,
  ])

  const editField = "inEdit";
  // const [inEdit, setInEdit] = React.useState<boolean>(false);

  // modify the data in the store, db etc
  const remove = (dataItem: InvoiceLineItem) => {
    const newLayoutsLineItems = JSON.parse(JSON.stringify(layoutLineItems));

    const layoutId = Object.keys(layoutLineItems).find((layoutId: string) => {
      return layoutLineItems[layoutId].find((invoiceLineItem: InvoiceLineItem) => {
        return invoiceLineItem.id === dataItem.id;
      })
    })

    newLayoutsLineItems[layoutId] = layoutLineItems[layoutId].filter((invoiceLineItem: InvoiceLineItem) => {
      return invoiceLineItem.id !== dataItem.id;
    })
    setLayoutLineItems(newLayoutsLineItems);
  };

  const add = (dataItem: InvoiceLineItem) => {
    dataItem.inEdit = true;
    // const newData = insertItem(data)(dataItem);
    // setData(newData);
  };

  const addInvoiceLineItem = (layoutId: string, invoiceLineItem: InvoiceLineItem) => {
    const newLayoutsLineItems = JSON.parse(JSON.stringify(layoutLineItems));
    const newLineItem = {
      ...invoiceLineItem,
      total: calcTotal(invoiceLineItem, getSceneTime(scene)),
    }
    if (newLayoutsLineItems[layoutId]) {
      newLayoutsLineItems[layoutId].push(newLineItem);
    } else {
      newLayoutsLineItems[layoutId] = [newLineItem];
    }
    setLayoutLineItems(newLayoutsLineItems);
  };

  const update = (dataItem: InvoiceLineItem) => {
    const newLayoutsLineItems = JSON.parse(JSON.stringify(layoutLineItems));

    const layoutId = Object.keys(layoutLineItems).find((layoutId: string) => {
      return layoutLineItems[layoutId].find((invoiceLineItem: InvoiceLineItem) => {
        return invoiceLineItem.id === dataItem.id;
      })
    })

    newLayoutsLineItems[layoutId] = layoutLineItems[layoutId].map((invoiceLineItem: InvoiceLineItem) => {
      if (dataItem.id === invoiceLineItem.id) {
        const newDataItem = dataItem;
        newDataItem.inEdit = false;
        newDataItem.total = calcTotal(dataItem, getSceneTime(scene));
        return newDataItem;
      }
      return invoiceLineItem;
    })
    setLayoutLineItems(newLayoutsLineItems);
  };

  // Local state operations
  const discard = (dataItem: InvoiceLineItem) => {

  };

  const cancel = (dataItem: InvoiceLineItem) => {
    // this forces rerender
    setComputedLayoutLineItems({...computedLayoutLineItems});
  };

  const enterEdit = (dataItem: InvoiceLineItem) => {
    let newData = data.map((item) =>
      item.id === dataItem.id ? { ...item, inEdit: true } : item
    );
    setData(newData);
  };

  const itemChange = (event: GridItemChangeEvent) => {

    const field = event.field || "";
    const newData = data.map((item) =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );

    setData(newData);
  };

  const handleHideLayout = (event) => {
    const updatedHiddenLayouts = {
      ...hiddenLayouts,
      [event.target.id]: !event.target.checked
    }
    setHiddenLayouts(updatedHiddenLayouts);
  }

  let gridExcelExport: ExcelExport | null;
  const exportExcel = () => {
    if (gridExcelExport !== null) {
      gridExcelExport.save();
    }
  };

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };


  const invoiceGrid = (
    <Grid
      sortable
      sort={sort}
      onSortChange={e => {
        setSort(e.sort);
      }}
      onItemChange={itemChange}
      className={classes.gridRoot}
      editField={editField}
      data={orderBy(
        data,
        sort)
      }
      rowRender={rowRender}
      // onRowClick={onRowClick}
      >
        {
          visibleColumns.map((column, index) => {
            return (
              <GridColumn
                field={column.field}
                title={column.title}
                cell={column.cell}
                key={index}
                className={column.className}
                width={column.width}
                editor={column.editor}
                editable={column.editable}
              />
            );
          })
        }
        <GridColumn cell={CommandCell} width="100px" />
    </Grid>
  )

  return (
    <Dialog
        open={props.open}
        scroll={'paper'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth='lg'
      >
        <DialogTitle className={classes.dialogTitle} style={{padding: '0px', border: '0px'}}>
          <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v) } aria-label="simple tabs example">
            <Tab value={0} label="Layouts" />
            <Tab value={1} label="Invoice" />
          </Tabs>
        </DialogTitle>
        <DialogContent className={classes.content} dividers={true}>
          <TabPanel value={selectedTab} index={0}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Typography variant="h4" style={{marginBottom: '15px'}}>Layouts for Invoice</Typography>
              {Object.keys(hiddenLayouts).map((layoutId: string) =>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleHideLayout}
                      checked={!hiddenLayouts[layoutId]}
                      id={layoutId}
                    />
                  }
                  label={layouts?.find((layout) => layout.id === layoutId)?.name}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
              <NewLineItemInput
                layouts={layouts}
                hiddenLayouts={hiddenLayouts}
                addInvoiceLineItem={addInvoiceLineItem}
              />
              <div
                className={classes.newInput}>
              </div>
              <ExcelExport
                data={data}
                ref={(excelExport) => (gridExcelExport = excelExport)}>
                {invoiceGrid}
              </ExcelExport>
              <GridPDFExport
                ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                {invoiceGrid}
              </GridPDFExport>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  margin: '10px 0px 0px 0px'
                }}
              >
              {/* <CurrencyTextField
                defaultValue={guaranteedSpend}
                outputFormat="number"
                currencySymbol="$"
                readonly
                label="Total"
                /> */}
              </div>
          </TabPanel>
        </DialogContent>
        <DialogActions
        className={classes.actions}>
          <div style={{
            flex: 1
          }}>
          {selectedTab === 1 &&
            <>
              <Tooltip title='Download Excel'>
                <IconButton
                  className={classes.toolbarButton}
                  color='primary'
                  onClick={exportExcel}>
                  <CloudDownload/>
                </IconButton>
              </Tooltip>
              <Tooltip title='Download PDF'>
                <IconButton
                  className={classes.toolbarButton}
                  color='primary'
                  onClick={exportPDF}>
                  <PDFIcon/>
                </IconButton>
              </Tooltip>
            </>
          }
          </div>
          {selectedTab === 0 &&
            <>
              <Button
                variant="contained"
                onClick={handleInvoiceCancel}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={() => setSelectedTab(1)}>Next</Button>
            </>
          }
          {selectedTab === 1 &&
            <>
              <Button
                variant="contained" onClick={handleInvoiceCancel}>Cancel</Button>
              <Button color="primary" variant="contained" onClick={handleMoveContinue}>Save</Button>
            </>
          }
          <div style={{
            flex: 1,
          }}></div>

        </DialogActions>
      </Dialog>
  )
}

export default InvoiceModal
