import { useState } from 'react';
import {
  Theme,
} from '@material-ui/core/styles';

import {
  Button,
  Select,
  MenuItem,
  Tooltip,
  Paper,
  Typography,
  Switch,
  makeStyles,
} from '@material-ui/core';

import { ValidUnits } from '../../api/placez/models/UserSetting';
import Jumbotron from '../../components/Jumbotron';

import settingStyles from './SettingStyles.css';
import { LocalStorageKey, useLocalStorageState } from '../../components/Hooks/useLocalStorageState'

interface Props {};

const MeasurementSettings = (props: Props) => {

  const styles = makeStyles<Theme>(settingStyles);
  const [inEdit, setInEdit] = useState<boolean>(false);
  const classes = styles(props);

  const [unitSetting, setUnitSetting] = useLocalStorageState(LocalStorageKey.Units);
  const [twentyFourHourTime, setTwentyFourHourTime] = useLocalStorageState(LocalStorageKey.TwentyFourHourTime);

  const onCancel = () => {
    setInEdit(false);
  }

  const onSubmit = () => {
    setInEdit(false);
  }


  return (
    <div className={classes.root}>
      <Jumbotron
        title="Display"/>
      <div className={classes.form}>
        <Paper className={classes.formControl}>
          <Typography variant="body1">Unit Type</Typography>
          <Tooltip title="Current Units">
            <Select
              className={classes.select}
              value={unitSetting ?? 'ft'}
              onChange={(e) => setUnitSetting(e.target.value)}
            >
              { Object.values(ValidUnits).map((unit: string, index: number) => {
                return (
                    <MenuItem key={index} value={unit}>{unit === ValidUnits.ftIn ? 'ft in' : unit}</MenuItem>
                );
              })
              }
            </Select>
          </Tooltip>
        </Paper>
        <Paper className={classes.formControl}>
          <Typography variant="body1">24 Hour Time</Typography>
          <Tooltip title="24 Hour Time">
            <Switch
              color="secondary"
              checked={twentyFourHourTime}
              value={twentyFourHourTime}
              onChange={(e) => setTwentyFourHourTime(e.target.checked)}
            />
          </Tooltip>
        </Paper>
        {
          inEdit &&
          <div className={classes.actions}>
            <Button
              onClick={onCancel}
              className={classes.actionButton}
              variant="contained">
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              className={classes.actionButton}
              variant="contained"
              color="primary">
              Save
            </Button>
          </div>
        }
      </div>
    </div>
  );
}

export default MeasurementSettings;
