import { ReactElement } from 'react';
import {
  Grow,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
} from '@material-ui/core';
import sideBarStyles from './sideBarStyles.css';
import { makeStyles } from '@material-ui/styles'

interface Props {
  onClick?: Function;
  label: any;
  children?: ReactElement<any>;
  disabled?: boolean;
  selected?: boolean;
  timeout?: number;
}

const SideBarButton = (props: Props) => {
  const handleClick = e => {
    const { disabled, onClick } = props;
    if (disabled) {
      e.preventDefault();
    } else {
      if (onClick) onClick();
    }
  }

  const { label, selected, disabled, timeout } = props;

  const styles = makeStyles<Theme>(sideBarStyles);
  const classes = styles(props);

  return (
    <Grow in={true} timeout={timeout}>
      <div className={classes.root}>
        <ListItem
          disabled={disabled || false}
          button
          selected={selected}
          className={classes.listItem}
          onClick={handleClick}
          classes={{ selected: classes.activeLink }}
          >
          <Tooltip title={label}>
            <ListItemIcon
              style={{ background: 'transparent', fontSize: '35px' }}
              classes={{ root: classes.icon }}>
              { props.children }
            </ListItemIcon>
          </Tooltip>
          <ListItemText
              style={{ background: 'transparent' }}
            classes={{ primary: classes.label }}
            primary={label} />
        </ListItem>
      </div>
    </Grow>
  );
}

export default SideBarButton;
