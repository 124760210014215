import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { clientRoutes } from '../../routes';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import {
  Button,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
  Slide,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

// Models
import {
  Client,
} from '../../api';
import {
  CreateClient,
  UpdateClient,
  DeleteClient
} from '../../reducers/client';
import AutoSuggest from '../AutoSuggest';
import { ReduxState } from '../../reducers';
import { matchPath, useLocation, useNavigate } from 'react-router';
import SceneTable from '../ScenesDisplays/TableDisplay/SceneTable'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      width: '100%',
    },
    titleBar: {
      background: theme.palette.secondary.main,
      padding: '5px 15px 5px 15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '60px',
    },
    details: {
      paddingBottom: 2 * theme.spacing(),
    },
    tableHeading: {
      ...theme.typography.h2,
      height: 44,
      fontSize: 12,
      fontWeight: 'bold',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    detailFields: {
      padding: theme.spacing(3),
    },
    floorplanButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 250,
      margin: `${theme.spacing()}px ${2 * theme.spacing()}px`,
    },
    floorplanButton: {
      height: 30,
      width: 200,
    },
    field: {
      width: '100%',
      margin: `${theme.spacing()}px ${2 * theme.spacing()}px`,
    },
    fieldCustom: {
      width: '100%',
    },
    stateZipField: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      margin: `${theme.spacing()}px ${2 * theme.spacing()}px`,
    },
    stateField: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '55px',
      margin: '0px 14px',
    },
    stateFieldDisabled: {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '55px',
      margin: '0px 14px',
    },
    fullWidthTable: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(),
      width: '100%',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'space-between',
    },
    notes: {
      margin: `${theme.spacing()}px ${2 * theme.spacing()}px`,
      width: '100%',
    },
    miniTables: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: theme.spacing(),
      marginRight: -5 * theme.spacing(),
    },
    halfWidthTable: {
      flexGrow: 1,
      marginRight: 5 * theme.spacing(),
      minWidth: 400,
    },
    actions: {
      marginTop: 2 * theme.spacing(),
      borderTop: `1px solid ${theme.palette.divider}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    actionButton: {
      padding: `${theme.spacing()}px ${2 * theme.spacing()}px`,
      margin: 2 * theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
      height: 40,
    },
    deleteButton: {
      padding: `${theme.spacing()}px ${2 * theme.spacing()}px`,
      margin: 2 * theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
      height: 40,
      color: 'white',
      backgroundColor: '#bb0000',
      '&:hover': {
        backgroundColor: '#880000',
      },
    },
    selectionStats: {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: `${theme.spacing()}px ${3 * theme.spacing()}px`,
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    clientTableContainer: {
      padding: '8px 40px',
      marginTop: 2 * theme.spacing(),
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    clientTableHeader: {
      ...theme.typography.h2,
      fontSize: '20px',
      margin: 0,
      height: 55,
      display: 'flex',
      alignItems: 'center',
      padding: '40px 0px',
      justifyContent: 'space-between',
    },
    errors: {
      fontSize: '12px',
      color: '#CD0000',
    },
    errorsDiv: {
      position: 'relative',
      bottom: '5px',
      left: '15px',
    },
    fieldspacing: {
      padding: 10,
      marginLeft: '-14px',
      marginRight: '30px',
    },
    mainFormGrid: {
      width: '100%',
      paddingRight: '25px',
      paddingLeft: '25px',
    },
    formThreeColGrid: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    notesColumn: {
      gridColumn: '1 / span 3',
    },
  })
);

const phoneNumberMask = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    />
  );
}

interface Props {
  client?: Client;
};

const ClientForm = (props:Props) => {
  const classes = styles(props);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const scenes = useSelector((state: ReduxState) => state.scenes.ascending);
  const picklists = useSelector((state: ReduxState) => state.settings.pickLists);

  const [client, setClient] = useState<Client>(props?.client ?? {} as Client);
  const [inEdit, setInEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    name: '',
    type: '',
    email: '',
    phone: '',
  });

  const goToClients = () => navigate(clientRoutes.main.path);

  const onCancel = () => {
    const { client } = props;
    const isNewClient = !client;

    if (isNewClient) {
      navigate(-1);
    } else {
      setInEdit(false);
      setClient(client as Client);
    }
  }

  const onSubmit = () => {
    if ((errors.name.length === 0) && (errors.type.length === 0) && (errors.email.length === 0) && (errors.phone.length === 0)) {
      if (props.client && client.id && client.id !== 0) {
        dispatch(UpdateClient(
          {
            ...props.client,
            ...client,
          })
        );
      } else {
        dispatch(CreateClient(
          {
            ...client,
            id: 0,
            isActive: true,
          })
        )
      }
      goToClients();
    }
  }

  const onDeleteClient = () => {
    dispatch(DeleteClient(props.client.id));
    goToClients();
  }

  const handleDetailChange = (name: string) => (event: any) => {
    const { value } = event.target;
    updateDetailChange(name, value);
  }

  const updateDetailChange = (name: string, value: string) => {
    const validEmailRegex =
    RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
    const validPhoneRegex =
    RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

    switch (name) {
      case 'type':
        errors.type =
          value.length < 3
            ? 'Must contain at least three characters'
            : '';
        break;

      case 'name':
        errors.name =
          value.length < 3
          ? 'Must contain at least three characters'
          : '';
        break;

      case 'email':
        errors.email =
          validEmailRegex.test(value)
            ? ''
            : 'Invalid email';
        break;

      case 'phone':
        errors.phone =
          validPhoneRegex.test(value)
            ? ''
            : 'Invalid phone number';
        break;

      default:
        break;
    }

    setClient({
      ...client,
      [name]: value,
    })
  }

  useEffect(() => {
    if (matchPath('clients/new', pathname)) { // TODO: is there a better way
      setInEdit(true);
    }
  }, [])

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <div className={classes.details}>
          <div className={classes.titleBar}>
            <Typography variant="h4">Client Details</Typography>
            { !inEdit &&
              <Tooltip title="Switch to Edit">
                <IconButton
                disabled={inEdit}
                aria-label="Switch to Edit"
                onClick={() => { setInEdit(true) }}
                color="primary">
                  <EditIcon fontSize="large"/>
                </IconButton>
              </Tooltip>
            }
          </div>
        <div>
          <div className={classes.mainFormGrid}>
            <div className={classes.formThreeColGrid}>
              <div className={classes.fieldspacing}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  autoFocus
                  id="client-name"
                  label="Client Name"
                  value={client.name || ''}
                  onChange={handleDetailChange('name')}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
                {errors.name.length > 0 &&
                  <div className={classes.errorsDiv}>
                    <Typography className={classes.errors}>{errors.name}</Typography>
                  </div>
                }
              </div>

                {inEdit ?
                  <div className={classes.fieldspacing}>
                    <FormControl className={classes.field}>
                      <InputLabel htmlFor="type">Client Type</InputLabel>
                      <Select
                        disabled={!inEdit}
                        value={client.type || '' }
                        onChange={handleDetailChange('type')}
                        inputProps={{
                          name: 'Client Type',
                          id: 'client-type',
                        }}
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                        }}>
                        {
                          picklists.find(list => {
                            return list.name === 'ClientType';
                          })?.picklistOptions
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((clienttype, index) =>
                            <MenuItem
                              key={`${clienttype.name}-${index}`}
                              value={clienttype.name}>{clienttype.name}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                  :
                  <div className={classes.fieldspacing}>
                    <TextField
                      disabled={true}
                      className={classes.field}
                      id="client-type"
                      label="Client Type"
                      value={client.type || ''}
                    />
                  </div>
                }
              <div className={classes.fieldspacing}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="address-1"
                  label="Street Address"
                  value={client.address1 || ''}
                  onChange={handleDetailChange('address1')}
                  inputProps={{
                    maxLength: 150,
                  }}
                />
              </div>
              </div>
              <div className={classes.formThreeColGrid}>
                <div className={classes.fieldspacing}>
                  <TextField
                    disabled={!inEdit}
                    className={classes.field}
                    id="email"
                    label="Email"
                    value={client.email || ''}
                    onChange={handleDetailChange('email')}
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                  {errors.email.length > 0 &&
                  <div className={classes.errorsDiv}>
                    <Typography className={classes.errors}>{errors.email}</Typography>
                  </div>}
                </div>
                <div className={classes.fieldspacing}>
                  <FormControl className={classes.field}>
                    <InputLabel htmlFor="phone-number">
                      Phone Number
                    </InputLabel>
                    <Input
                      disabled={!inEdit}
                      value={client.phone || ''}
                      onChange={handleDetailChange('phone')}
                      id="phone-number"
                      inputComponent={phoneNumberMask}
                    />
                  </FormControl>
                  { errors.phone.length > 0 &&
                    <div className={classes.errorsDiv}>
                      <Typography className={classes.errors}>{errors.phone}</Typography>
                    </div>
                  }
                </div>
                <div className={classes.fieldspacing}>
                  <div className={classes.stateZipField}>
                    <TextField
                      disabled={!inEdit}
                      id="city"
                      label="City"
                      value={client.city || ''}
                      onChange={handleDetailChange('city')}
                      inputProps={{
                        maxLength: 60,
                      }}
                    />
                    <FormControl className={classes.stateField}>
                    <AutoSuggest
                      disabled={!inEdit}
                      value={client.stProv || ''}
                      onChange={(value: string) => {
                        updateDetailChange('stProv', value);
                      }}/>
                    </FormControl>
                    <TextField
                      disabled={!inEdit}
                      id="zip"
                      label="Postal"
                      value={client.postal || ''}
                      onChange={handleDetailChange('postal')}
                      inputProps={{
                        maxLength: 30,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.notesColumn}>
                  <div className={classes.fieldspacing}>
                    <TextField
                      disabled={!inEdit}
                      className={classes.notes}
                      id="notes"
                      label="Notes"
                      value={client.notes || ''}
                      onChange={handleDetailChange('notes')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        { inEdit &&
          <div className={classes.actions}>
            <Button
              onClick={onCancel}
              className={classes.actionButton}
              variant="contained">
              Cancel
            </Button>
            <Button
              disabled={!client.name || client.name.length < 3}
              onClick={onSubmit}
              className={classes.actionButton}
              variant="contained"
              color="primary">
              Save
            </Button>
            <Button
              onClick={onDeleteClient}
              disabled={!client.id}
              className={classes.deleteButton}
              color="inherit"
              variant="contained">
              Delete
            </Button>
          </div>
        }
        { !inEdit &&
          <div className={classes.clientTableContainer}>
            <h2 className={classes.clientTableHeader}>
              Client Scenes
            </h2>
            <SceneTable
              scenes={scenes
                .filter(scene => client.id && client.id === scene.clientId)
              }
              hideClient
            />
          </div>
        }
      </div>
    </Slide>
  );
}

export default ClientForm;
