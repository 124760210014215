import { withStyles } from '@material-ui/core';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

export const PlacezAccordion = withStyles(theme => ({
  root: {
    alignSelf: 'stretch',
    margin: '0px 4px',
    border: 'none !important',
    // boxShadow: 'none !important',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',       // Adding some box-shadow for the paper effect
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: '0px 4px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',       // Adding some box-shadow for the paper effect
    },
    backgroundColor: 'transparent !important',
  },
  expanded: {
    // boxShadow: 'none !important',
  },
}))(MuiAccordion);

export const PlacezAccordionSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,  // Using the paper color from the theme
    // boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',       // Adding some box-shadow for the paper effect
    borderRadius: '4px',
  },
  expanded: {},
}))(MuiAccordionSummary);


export const PlacezAccordionDetails = withStyles((theme) => ({
  root: {
    padding: '0',
    paddingTop: theme.spacing(2),
    border: 'none',
    boxShadow: 'none !important',
  },
}))(MuiAccordionDetails);
