import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate, matchPath, useLocation } from 'react-router';
import NumberFormat from 'react-number-format';
import { placeRoutes } from '../../routes';
import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  AddCircleOutline as AddIcon,
} from '@material-ui/icons';
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Slide,
  Typography,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import PlacePickerModal from './PlacePickerModal';
import PlacePriceModal from './PlacePriceModal';
import NewFloorPlanModal from './../Modals/NewFloorPlanModal';

// Models
import {
  Place,
} from '../../api';
import { CreatePlace, UpdatePlace, DeletePlace } from '../../reducers/place';
import { CreateFloorPlan, DeleteFloorPlan, CopyFloorPlan, UpdateFloorPlan, GetFloorPlans } from '../../reducers/floorPlans';
import { ModalConsumer } from '../Modals/ModalContext';
import { isNotEmpty } from '../Utils';
import FloorplansTable from '../Tables/FloorplansTable';
import { ReduxState } from '../../reducers';
import PlacezFixturePlan from '../../api/placez/models/PlacezFixturePlan';
import { BillingRate, Utils } from '../../blue/core/utils';
import Jumbotron from '../Jumbotron';
import { createSelector } from 'reselect';
import { getOrgTheme } from '../../api/placez/models/UserSetting';
import { User } from 'oidc-client';
import { userIsInRole } from '../../sharing/utils/userHelpers';
import { AreYouSureDelete } from '../Modals/UniversalModal';
import LoadImageModal from '../Modals/LoadImageModal';
import { UniversalModalWrapper } from '../Modals/UniversalModalWrapper';
import { SimpleModal } from '../Modals/SimpleModal';
import SceneTable from '../ScenesDisplays/TableDisplay/SceneTable'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      margin: theme.spacing(3),
    },
    details: {
      paddingBottom: 2 * theme.spacing(),
    },
    editButtonContainer: {
      justifyContent: 'space-between',
      display: 'flex',
    },
    tableHeading: {
      ...theme.typography.h2,
      fontSize: 12,
      fontWeight: 'bold',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    detailFields: {
      maxWidth: 1000,
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    floorplanButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: `${theme.spacing()}px ${2 * theme.spacing()}px`,
    },
    field: {
      flex: 1,
    },
    oneColumnField: {
      display: 'flex',
      gridColumn: 'span 12',
    },
    twoColumnField: {
      display: 'flex',
      gridColumn: 'span 6',
    },
    threeColumnField: {
      display: 'flex',
      gridColumn: 'span 4',
    },
    fullWidthTable: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(),
      minHeight: 'calc(100vh - 525px)',
      [theme.breakpoints.down('sm')]: {
        flex: 1,
        flexFlow: 'column',
        height: 'initial',
      },
    },
    miniTables: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginTop: theme.spacing(),
      marginRight: -5 * theme.spacing(),
    },
    halfWidthTable: {
      flexGrow: 1,
      marginRight: 5 * theme.spacing(),
      minWidth: 400,
    },
    actions: {
      marginTop: 2 * theme.spacing(),
      borderTop: `1px solid ${theme.palette.divider}`,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    actionButton: {
      padding: `${theme.spacing()}px ${2 * theme.spacing()}px`,
      margin: 2 * theme.spacing(),
      borderRadius: theme.shape.borderRadius,
      width: '200px',
      height: 40,
    },
    mainFormSection: {
      marginTop: theme.spacing(3),
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
      },
    },
    mainFormInputs: {
      display: 'grid',
      gap: '16px',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    image: {
      margin: '0px 10px',
      padding: '25px 40px',
    },
    imageOverlay: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        height: 188,
        width: '100%',
        margin: 'auto',
        padding: 0,
      },
    },
    imageContainer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
      background: 'linear-gradient(45deg, rgb(0,0,0,0.3), transparent)',
    },
    imageButtonOverlay: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
    },
    imageButton: {
      position: 'absolute',
      bottom: '40px',
      right: '40px',
      textTransform: 'none',
    },
    tableHeaderContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    floorplanTableContainer: {
      padding: `${theme.spacing()}px 0`,
      width: '34%',
      marginRight: theme.spacing(3),
      '& .k-grid-container': {
        paddingBottom: 'initial',
        hight: '100%',
      },
      '& .k-widget.k-grid' : {
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: '0 auto',
        maxWidth: 'initial',
      },
    },
    sceneTableContainer: {
      width: '66%',
      padding: `${theme.spacing()}px 0`,
      '& .k-grid-container': {
        paddingBottom: 'initial',
        hight: '100%',
      },
      '& .k-widget.k-grid' : {
        height: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '90%',
        margin: '0 auto',
      },
    },
    tableHeader: {
      ...theme.typography.h2,
      fontSize: '20px',
      margin: 0,
      height: 55,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    errors: {
      fontSize: '12px',
      color: '#CD0000',
    },
    errorsDiv: {
      position: 'relative',
      bottom: '5px',
      left: '15px',
    },
    iconButton: {
      height: 20,
      width: 20,
      padding: 2,
      fontSize: 16,
    },
    headerIcons: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 5,
    },
    addNewFloorplan: {
      color: 'gray',
      marginTop: 3,
    },
    addNewFloorplanLabel: {
      marginLeft: 3,
    },
  })
);

const numberFormatCustom = (props: any) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
    />
  );
};

interface Props {
  place?: Place,
};

const PlaceForm = (props: Props) => {
  const dispatch = useDispatch();
  const classes = styles(props);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const picklists = useSelector((state: ReduxState) => state.settings.pickLists);
  const selectedId = useSelector((state: ReduxState) => state.place.selectedId);
  const settingsReady = useSelector((state: ReduxState) => state.settings.settingsReady);
  const fixturePlans = useSelector((state: ReduxState) => state.floorPlans.unsorted);
  const scenes = useSelector((state: ReduxState) => state.scenes.ascending);
  const user = useSelector((state: ReduxState) => getUser(state));

  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);

  const [isActive, setIsActive] = useState<boolean>(props?.place?.isActive ?? true);
  const [detail, setDetail] = useState<Place>(props?.place ?? {} as Place);
  const [inEdit, setInEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({ location: '', name: '' });
  const [placeimage, setPlaceimage] = useState<string>(props?.place?.imageUrl ?? getOrgTheme(user.profile.organization_id).defaultPlaceImage);
  const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);
  const [priceDialogOpen, setPriceDialogOpen] = useState<boolean>(false);
  const [floorPlanSelected, setFloorPlanSelected] = useState<PlacezFixturePlan>(undefined);

  const onCancel = () => {
    const { place } = props;
    const isNewPlace = !place;

    if (isNewPlace) {
      navigate(-1);
    } else {
      setInEdit(false);
      setDetail(place);
    }
  }

  const onSubmit = () => {
    const updatedPlace = { ...detail, imageUrl: placeimage };

    if ((errors.location.length === 0) && (errors.name.length === 0)) {
      if (props.place || detail.id && detail.id !== 0) {
        dispatch(UpdatePlace(
          {
            ...props.place,
            ...detail,
            ...updatedPlace,
          })
        );
      } else {
        dispatch(CreatePlace(
          {
            ...detail,
            imageUrl: placeimage,
            id: 0,
            isActive,
            cost: 100,
          })
        );
      }
      setInEdit(false);
    }
  }

  const handleDetailChange = (name: keyof Place) => (event: any) => {
    const { value } = event.target;
    let newValue = value

    switch (name) {
      case 'location':
        errors.location =
          value.length < 3
            ? 'Must contain at least three characters'
            : '';
        break;

      case 'name':
        errors.name =
          value.length < 3
            ? 'Must contain at least three characters'
            : '';
        break;

      case 'taxRate':
        newValue = value / 100;
        break;

      default:
        break;
    }

    setDetail({
      ...detail,
      [name]: newValue,
    });
  }

  const handleImageChange = (value: any) => {
    setPlaceimage(value);
  }

  const newFloorPlan = () => {
    navigate(placeRoutes.newFloorPlan.path.replace(':id', detail.id.toString()));
  }

  const isFloorPlanInUse = (floorPlanId: string): boolean => {
    const { place } = props;
    const floorPlanScenes =
      scenes.filter(scene => scene.placeId === (place ? place.id : 0)
        && (scene.layouts && scene.layouts.filter(layout => layout.floorPlanId === floorPlanId).length !== 0));

    return floorPlanScenes.length !== 0;
  }

  const onCopyFloorPlan = floorPlan => {
    const floorPlanCopy = {
      ...floorPlan,
      name: `${floorPlan.name} - Copy`,
    };
    floorPlanCopy.id = null;
    dispatch(CopyFloorPlan(floorPlanCopy));

  }

  const onEditFloorPlan = floorPlan => {
    dispatch(UpdateFloorPlan(floorPlan));
  }

  const onEditPrice = (floorPlan: PlacezFixturePlan) => {
    setPriceDialogOpen(true);
    setFloorPlanSelected(floorPlan);
  }

  const onSetPrice = (price: number, priceRateInHours: BillingRate) => {
    setPriceDialogOpen(false);
    if (price !== undefined) {
      const modifiedFloorplan = {
        ...floorPlanSelected,
        price,
        priceRateInHours,
      }
      dispatch(UpdateFloorPlan(modifiedFloorplan));
    }
  }

  const onMoveFloorPlan = (floorPlan: PlacezFixturePlan) => {
    setMoveDialogOpen(true);
    setFloorPlanSelected(floorPlan);
  };

  const onFloorPlanSelectedForMove = (placeId: number) => {
    if (placeId) {
      dispatch(CreateFloorPlan({
        ...floorPlanSelected,
        placeId,
        id: Utils.guid(),
      }));
    }
    setMoveDialogOpen(false);
  };

  const onPriceModalClose = () => {
    setPriceDialogOpen(false);
  }

  const onDeletePlace = placeId => {
    if (placeId) {
      dispatch(DeletePlace(placeId));
      navigate(placeRoutes.main.path);
    }
  }

  useEffect(() => {
    if (matchPath('places/new', pathname)) { // TODO: is there a better way
      setInEdit(true);
    }
    if (place) {
      dispatch(GetFloorPlans(place.id));
    }
  }, [])

  useEffect(() => {
    if (selectedId) {
      const editPlacePathname = placeRoutes.edit.path.replace(':id', selectedId.toString());
      navigate(editPlacePathname);
    }
  }, [selectedId])


  const { place } = props;

  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <div className={classes.root}>
        <div className={classes.details}>
          <Jumbotron
            title="Place Details"
          >
            {!inEdit &&
              <div className={classes.editButtonContainer}>
                <Tooltip title="Switch to Edit">
                  <IconButton
                    disabled={inEdit}
                    aria-label="Switch to Edit"
                    color="secondary"
                    onClick={() => { setInEdit(true); }}>
                    <EditIcon
                      fontSize="large"/>
                  </IconButton>
                </Tooltip>
                <UniversalModalWrapper
                  onDelete= {() => onDeletePlace(place.id)}
                  modalHeader='Are you sure?'
                >
                  <IconButton
                    color="secondary"
                    >
                    <DeleteIcon fontSize="large"/>
                  </IconButton>
                  {AreYouSureDelete('Place')}
                </UniversalModalWrapper>
              </div>
            }
          </Jumbotron>
          <div className={classes.mainFormSection}>
            <div className={classes.imageOverlay}>
              <div className={classes.imageContainer}
                style={{
                  backgroundImage: `url(${placeimage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                  backgroundSize: 'contain',
                }}>
                {inEdit ? (
                  <ModalConsumer>
                    {({ showModal, props }) =>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.imageButton}
                        onClick={() => showModal(
                          LoadImageModal,
                          { ...props,
                            imageLabel: 'Place Image',
                            currentImage: placeimage,
                            handleSetImage: handleImageChange })}
                      >
                        Modify Image
                        </Button>
                    }
                  </ModalConsumer>
                ) : null}
              </div>
            </div>
            <div className={classes.mainFormInputs} >
              <div className={classes.oneColumnField}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  autoFocus
                  id="place-name"
                  label="Place Name"
                  value={detail.name || ''}
                  onChange={handleDetailChange('name')}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
                {errors.name.length > 0 &&
                  <div className={classes.errorsDiv}>
                    <Typography className={classes.errors}>{errors.name}</Typography>
                  </div>}

              </div>
              <div style={{
                display: 'flex',
                gridColumn: 'span 9',
                }}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="location"
                  label="Location"
                  value={detail.location || ''}
                  onChange={handleDetailChange('location')}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
                {errors.location.length > 0 &&
                  <div className={classes.errorsDiv}>
                    <Typography className={classes.errors}>{errors.location}</Typography>
                  </div>}

              </div>
              <div style={{
                display: 'flex',
                gridColumn: 'span 3',
                }}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="taxRate"
                  label="Tax Rate %"
                  value={Utils.roundDigits(detail.taxRate * 100, 2) || 0}
                  onChange={handleDetailChange('taxRate')}
                  type="number"
                />
              </div>
              <div className={classes.threeColumnField}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="contactRep"
                  label="Contact Rep"
                  value={detail.contact || ''}
                  onChange={handleDetailChange('contact')}
                  inputProps={{
                    maxLength: 200,
                  }}
                />
              </div>
              <div className={classes.threeColumnField}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="capacity"
                  label="Max Capacity"
                  value={detail.capacity || ''}
                  onChange={handleDetailChange('capacity')}
                  InputProps={{
                    inputComponent: numberFormatCustom,
                  }}
                />
              </div>
              <div className={classes.threeColumnField}>
                {settingsReady ?
                  <FormControl
                    className={classes.field}>
                    <InputLabel htmlFor="type">Type</InputLabel>
                    <Select
                      disabled={!inEdit}
                      id="type"
                      value={detail.type || ''}
                      onChange={handleDetailChange('type')}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}>
                      {
                        isNotEmpty(picklists).find(list => {
                          return list.name === 'PlaceType';
                        }).picklistOptions
                          .sort((a, b) => a.sortOrder - b.sortOrder)
                          .map((clienttype, index) =>
                            <MenuItem
                              key={`${clienttype.name}-${index}`}
                              value={clienttype.name}>{clienttype.name}</MenuItem>)
                      }

                    </Select>
                  </FormControl> : null}
              </div>
              <div className={classes.oneColumnField}>
                <TextField
                  disabled={!inEdit}
                  className={classes.field}
                  id="notes"
                  label="Notes"
                  value={detail.notes || ''}
                  onChange={handleDetailChange('notes')}
                />
              </div>
            </div>
          </div>
          {inEdit &&
            <div className={classes.actions}>
              <Button
                onClick={onCancel}
                className={classes.actionButton}
                variant="contained">
                Cancel
            </Button>
              <Button
                disabled={!detail.name || detail.name.length < 3}
                onClick={onSubmit}
                className={classes.actionButton}
                variant="contained"
                color="primary">
                Save
            </Button>
            </div>
          }
        </div>
        {!inEdit &&
          <div className={classes.fullWidthTable}>
            <div className={classes.floorplanTableContainer}>
              <div className={classes.tableHeaderContainer}>
                <h2 className={classes.tableHeader}>
                  Floorplans
                </h2>
                {!inEdit ? (
                  <div>
                    <SimpleModal>
                      <Tooltip title="New Floorplan">
                        <IconButton aria-label="New floorplan"
                          color="secondary"
                          >
                          <AddIcon fontSize="large"/>
                        </IconButton>
                      </Tooltip>
                      <NewFloorPlanModal
                        place={place}
                      />
                    </SimpleModal>

                  </div>) : null
                }
              </div>

              <FloorplansTable
                fixturePlans={fixturePlans.filter(floorplan => detail.id === floorplan.placeId)}
                isFloorPlanInUse={isFloorPlanInUse}
                onCopyFloorPlan={onCopyFloorPlan}
                onDeleteFloorPlan={(id) => dispatch(DeleteFloorPlan(id))}
                onMoveFloorPlan={onMoveFloorPlan}
                onEditFloorPlan={onEditFloorPlan}
                onEditPrice={onEditPrice}
                user={user}
                globalFilter={globalFilter}
              />
              <PlacePickerModal
                open={moveDialogOpen}
                onPlaceSelected={onFloorPlanSelectedForMove}
              />
              { userIsInRole(user, 'admin') &&
                <PlacePriceModal
                  fixturePlan={floorPlanSelected}
                  open={priceDialogOpen}
                  onSetPrice={onSetPrice}
                />
              }
            </div>
            <div className={classes.sceneTableContainer}>
              <h2 className={classes.tableHeader}>
                Scenes
              </h2>
              <SceneTable
                scenes={scenes.filter(scene => scene.placeId === (place ? place.id : 0))}
              />
            </div>
          </div>
        }
      </div>
    </Slide>
  );
}

const getUserState= state => {
  return state.oidc.user;
};

const getUser = createSelector(
  [getUserState],
  user => user
);

export default PlaceForm;
