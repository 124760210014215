import PlacezFixturePlan from '../api/placez/models/PlacezFixturePlan';

export const defaultFloorplan: PlacezFixturePlan = {
  name:  'default',
  corners: {
    'f90da5e3-9e0e-eba7-173d-eb0b071e838e': {
      x: 0,
      y: 927.2,
      position: [0, 0, 927.2]
    },
    'da026c08-d76a-a944-8e7b-096b752da9ed': {
      x: 927.2,
      y: 927.2,
      position: [927.2, 0, 927.2]
    },
    '4e3d65cb-54c0-0681-28bf-bddcc7bdb571': {
      x: 927.2,
      y: 0,
      position: [927.2, 0, 0]
    },
    '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2': {
      x: 0,
      y: 0,
      position: [0, 0, 0]
    },
  },
  walls: [
    {
      corner1: '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2',
      corner2: 'f90da5e3-9e0e-eba7-173d-eb0b071e838e',
    },
    {
      corner1: 'f90da5e3-9e0e-eba7-173d-eb0b071e838e',
      corner2: 'da026c08-d76a-a944-8e7b-096b752da9ed',
    },
    {
      corner1: 'da026c08-d76a-a944-8e7b-096b752da9ed',
      corner2: '4e3d65cb-54c0-0681-28bf-bddcc7bdb571',
    },
    {
      corner1: '4e3d65cb-54c0-0681-28bf-bddcc7bdb571',
      corner2: '71d4f128-ae80-3d58-9bd2-711c6ce6cdf2',
    },
  ],
  floorTextures: {},
  hideWalls: true,
  wallHeight: 304.8,
  fixtures: [],
  floorplanImageScale: 1,
};
