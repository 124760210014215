import {
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core/styles';

import {
  OpenARIcon,
  Send3DIcon,
  SendARIcon,
} from '../../../assets/icons';

import { ShareMode } from './ShareMode';

import LargeIconButton from './LargeIconButton';
import { ExportStatus, exportInProgress } from '../../Blue';
import { useTheme } from '@material-ui/styles'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
      minHeight: '0px',
      alignItems: 'stretch',
      flex: '1',
    },
    icon: {
      height: 100,
      width: 100,
    },
  })
);

type Props = {
  exportStatus: ExportStatus,
  onGenerateAR: (event) => void,
  switchMode: (shareMode: ShareMode) => void,
};

const ShareChoices = (props: Props) => {
  const classes = styles(props);
  const theme = useTheme<Theme>();
  const {
    onGenerateAR,
    switchMode,
    exportStatus,
  } = props;
  return(
    <div className={classes.root}>
      {
        exportStatus === ExportStatus.Uploaded ?
        <>
          <LargeIconButton
            label="Regenerate AR"
            onClick={onGenerateAR}
            loading={exportInProgress(exportStatus)}>
            <OpenARIcon className={classes.icon} />
          </LargeIconButton>
          <LargeIconButton
            label="Generated AR link"
            onClick={() => switchMode(ShareMode.OpenAR)}
            color={theme.palette.secondary.main}>
            <SendARIcon className={classes.icon} />
          </LargeIconButton>
        </> : <>
          <LargeIconButton
            label="Generate AR"
            onClick={onGenerateAR}
            color={theme.palette.secondary.main}
            loading={exportInProgress(exportStatus)}>
            <OpenARIcon className={classes.icon} />
          </LargeIconButton>
        </>
      }
      <LargeIconButton
        label="Send Layout"
        onClick={() => {
          (window as any).gtag('event', 'share_3d');
          switchMode(ShareMode.Share3D);
        }}
        color={theme.palette.secondary.main}>
        <Send3DIcon className={classes.icon} />
      </LargeIconButton>
    </div>
  );
}

export default ShareChoices;
