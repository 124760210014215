import PlacezApi from './placez/PlacezApi';
import DAMApi from './dam/DigitalAssetManagementApi';

// TODO Migrate API and Models into NPM package
export * from './placez/models';
export * from './dam/models';
export * from './placez/selects';

export const placezApi = new PlacezApi(window.env['REACT_APP_PLACEZ_API_URL'] || '');
export const damApi = new DAMApi(window.env['REACT_APP_DAM'] || '');
