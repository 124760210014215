import { useDispatch, useSelector } from 'react-redux';
import {
  Tooltip,
  InputBase,
  makeStyles,
} from '@material-ui/core';
import {
  Search,
} from '@material-ui/icons';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { SetGlobalFilter } from '../../reducers/settings';
import { ReduxState } from '../../reducers';

interface Props {}

const color = '#808080';
const backgroundColor = '#F5F5F5';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(backgroundColor, 1.0),
      '&:hover': {
        backgroundColor: fade(backgroundColor, 1.0),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(6),
      color,
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      color,
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
    },
  })
);

const SearchBar = (props: Props) => {
  const classes = styles(props);
  const dispatch = useDispatch();

  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);

  return (
    <Tooltip title={''}>
      <div className={classes.root}>
        <div className={classes.searchIcon}>
          <Search />
        </div>
        <InputBase
          onChange={(event: any) => {
            dispatch(SetGlobalFilter(event.target.value))
          }}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          placeholder="Search"
          // defaultValue={globalFilter}
          value={globalFilter}
        />
      </div>
    </Tooltip>
  );
}

export default SearchBar;
