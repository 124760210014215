import {
  Theme,
  createStyles,
  Typography,
  makeStyles,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { Settings, Delete, ExpandMore } from "@material-ui/icons";
import { Asset, SkuType } from "../../../../../blue/items/asset";
import { Sku } from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { DeleteCustomAsset } from "../../../../../reducers/asset";
import { ReduxState } from "../../../../../reducers";
import TableConfigModal from "../../../../Modals/TableConfigModal";
import { useEffect, useMemo, useState } from "react";
import {
  PlacezAccordion,
  PlacezAccordionDetails,
  PlacezAccordionSummary,
} from "../../../../PlacezUIComponents/PlacezAccordion";
import { format } from "mathjs";

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: "grid",
      width: "100%",
      minWidth: "0px",
      gridTemplateColumns: "1fr",
      rowGap: "2px",
    },
    settings: {
      position: "absolute",
      cursor: "pointer",
      right: 0,
      top: 0,
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      margin: "3px",
    },
    delete: {
      position: "absolute",
      cursor: "pointer",
      right: 0,
      bottom: 0,
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.secondary.main,
      },
      margin: "3px",
    },
    itemList: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      width: "100%",
      gap: "2px",
      padding: "2px",
    },
    item: {
      cursor: "grab",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      overflow: "hidden",
      minHeight: "116px",
      width: "100%",
    },
    selectedImageContainer: {
      border: `solid 3px ${theme.palette.primary.main}`,
      borderColor: theme.palette.primary.main,
      borderStyle: "solid",

      display: "flex",
      position: "relative",
      minWidth: "100%",
      widht: "100%",
      minHeight: "112px",
      maxHeight: "112px",
      borderRadius: "8px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",

    },
    imageContainer: {
      // border: 'solid 2px transparent',
      display: "flex",
      position: "relative",
      minWidth: "100%",
      widht: "100%",
      minHeight: "112px",
      maxHeight: "112px",
      borderRadius: "8px",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      "&:hover": {
        borderColor: theme.palette.secondary.main,
        borderStyle: "solid",
      },
    },
    itemText: {
      maxWidth: "112px",
      marginTop: 4,
      fontWeight: "bold",
      fontSize: 12,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      textAlign: "center",
    },
    progress: {
      position: "absolute",
      top: "35px",
      left: "35px",
    },
  }),
);

interface Props {
  catalogSku?: Sku;
  onDragAsset?: (event: any, dragType: string, asset: Asset) => void;
  selectedAsset?: Asset;
  onAssetSelect?: (asset) => void;
  configuredAssets?: any;
  setTableConfigModalOpen?: (open: boolean) => void;
  canDelete?: boolean;
}

const SkuItem = (props: Props) => {
  const classes = styles(props);
  const { catalogSku, onDragAsset, selectedAsset, configuredAssets, setTableConfigModalOpen, canDelete, onAssetSelect } = props;
  const [selected, setSelected] = useState(selectedAsset);

  const preferConfigruedAssets = (asset) => {
    if (configuredAssets && configuredAssets[asset.id]) {
      return configuredAssets[asset.id];
    }
    return asset;
  };

  const onSelect = (asset) => {
    setSelected(asset);
    onAssetSelect(asset);
  }

  useEffect(() => {
    setSelected(selectedAsset);
  }, [props.selectedAsset] );

  const dispatch = useDispatch();
  const theme = useTheme();

  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    if (onDragAsset) {
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = (e: React.MouseEvent) => {
    if (onDragAsset) {
      const moveDistance = Math.sqrt(
        Math.pow(e.clientX - startPosition.x, 2) + Math.pow(e.clientY - startPosition.y, 2)
      );
      if (moveDistance < 10) { // Threshold to consider it a drag or click
        onSelect(catalogSku.asset);
      } else {
        onDragAsset(e, "stop", catalogSku.asset);
      }
    }
  };

  const handleTouchStart = (e) => {
    if (onDragAsset) {
      const touch = e.touches[0];
      setStartPosition({ x: touch.clientX, y: touch.clientY });
    }
  };

  const handleTouchEnd = (e) => {
    if (onDragAsset) {
      const touch = e.changedTouches[0];
      const moveDistance = Math.sqrt(Math.pow(touch.clientX - startPosition.x, 2) + Math.pow(touch.clientY - startPosition.y, 2));
      if (moveDistance < 10) { // Threshold to consider it a drag or click
        onSelect(catalogSku.asset);
      } else {
        onDragAsset(e, "stop", preferConfigruedAssets(catalogSku.asset));
      }
    }
  };

  const handleDragEnd = (e) => {
    if (onDragAsset) {
      const moveDistance = Math.sqrt(Math.pow(e.clientX - startPosition.x, 2) + Math.pow(e.clientY - startPosition.y, 2));
      if (moveDistance < 10) { // Threshold to consider it a drag or click
        onSelect(catalogSku.asset);
      } else {
        onDragAsset(e, "stop", preferConfigruedAssets(catalogSku.asset));
      }
    }
  };

  return (
    catalogSku.asset !== undefined &&
    (!catalogSku.asset?.extensionProperties ||
      !catalogSku.asset.extensionProperties?.hidden) && (
      <div
        className={classes.item}
        key={catalogSku.sku}
        draggable={true}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onDragEnd={handleDragEnd}
        onTouchEnd={handleTouchEnd}
      >
        <div
          className={
            catalogSku.asset.id === selected?.id
              ? classes.selectedImageContainer
              : classes.imageContainer
          }
          style={{
            // backgroundImage: `url(${window.env['REACT_APP_DAM']}/${configuredAssets && configuredAssets[catalogSku.asset.id] ? configuredAssets[catalogSku.asset.id].previewPath : catalogSku.asset.previewPath}), radial-gradient(${theme.palette.background.paper}, ${theme.palette.background.default})`,
            backgroundImage: `url(${window.env["REACT_APP_DAM"]}/${
              configuredAssets && configuredAssets[catalogSku.asset.id]
                ? configuredAssets[catalogSku.asset.id].previewPath
                : catalogSku.asset.previewPath
            })`,
          }}
        >
          {/* {catalogSku.asset.id === showSpinner &&
            <CircularProgress variant="indeterminate" color="primary" className={classes.progress}/>
          } */}
          {catalogSku.asset.modifiers &&
            catalogSku.asset.modifiers.chairMod && (
              <Settings
                className={classes.settings}
                style={{
                  color:
                    configuredAssets &&
                    configuredAssets[catalogSku.asset.id] &&
                    (configuredAssets[catalogSku.asset.id].modifiers.chairMod
                      .chairAsset ||
                      configuredAssets[catalogSku.asset.id].modifiers
                        .placeSettingMod.placeSettingAsset ||
                      configuredAssets[catalogSku.asset.id].modifiers
                        .centerpieceMod.centerpieceAsset ||
                      configuredAssets[catalogSku.asset.id].modifiers.linenMod
                        .linenAsset)
                      ? theme.palette.primary.main
                      : "",
                }}
                onClick={() => {
                  setTableConfigModalOpen(true);
                }}
              />
            )}
          {
          canDelete && ( <Delete
              className={classes.delete}
              style={{
                color:
                  configuredAssets && configuredAssets[catalogSku.asset.id]
                    ? theme.palette.primary.main
                    : "",
              }}
              onClick={() => dispatch(DeleteCustomAsset(catalogSku.asset.id))}
            />
          )}
        </div>
        <Typography className={classes.itemText}>
          {catalogSku.asset.name}
        </Typography>
      </div>
    )
  );
};


export default SkuItem;
