import createReducer from './helpers/createReducer';
import { PhotosphereSetup } from '../components/Blue/models';
import { GlobalViewState, FixtureViewState, LayoutViewState, MainNavState, PlannerTool, SaveBarState, ToolState } from '../models/GlobalState';
import { ReduxState } from '.';
import { guestRole, userIsInRole, allowUpdateAttendee, allowUpdateLayout, adminRole } from '../sharing/utils/userHelpers';

// Action Types]
const GLOBAL_STATE_INITIALIZING = 'GLOBAL_STATE_INITIALIZING';
const GLOBAL_STATE_INITIALIZED = 'GLOBAL_STATE_INITIALIZED';
const GLOBAL_VIEW_STATE = 'GLOBAL_VIEW_STATE';
const FIXTURE_VIEW_STATE = 'FIXTURE_VIEW_STATE';
const LAYOUT_VIEW_STATE = 'LAYOUT_VIEW_STATE';
const UPDATE_PHOTO_SPHERE_SETUP = 'UPDATE_PHOTO_SPHERE_SETUP';
const PLANNER_TOOL = 'PLANNER_TOOL';
const TOOL_STATE = 'TOOL_STATE';
const MAIN_NAV_STATE = 'MAIN_NAV_STATE';
const LICENSE_TYPE_STATE = 'LICENSE_TYPE_STATE';
const DISABLE_NAVIGATION = 'DISABLE_NAVIGATION';

export enum LicenseType {
  Placez = 'Placez',
  PlacezPlus = 'Placez Plus',
  PlacezPro = 'Placez Pro',
}

export const types = {
  GLOBAL_STATE_INITIALIZING, GLOBAL_STATE_INITIALIZED,
  GLOBAL_VIEW_STATE, FIXTURE_VIEW_STATE,
  LAYOUT_VIEW_STATE,
  UPDATE_PHOTO_SPHERE_SETUP, PLANNER_TOOL, MAIN_NAV_STATE,
  TOOL_STATE,
  LICENSE_TYPE_STATE,
  DISABLE_NAVIGATION,
};

type Types =
  typeof GLOBAL_STATE_INITIALIZING | typeof GLOBAL_STATE_INITIALIZED |
  typeof GLOBAL_VIEW_STATE | typeof FIXTURE_VIEW_STATE |
  typeof LAYOUT_VIEW_STATE |
  typeof UPDATE_PHOTO_SPHERE_SETUP | typeof PLANNER_TOOL | typeof TOOL_STATE |
  typeof MAIN_NAV_STATE |
  typeof LICENSE_TYPE_STATE | typeof DISABLE_NAVIGATION;

// State
export type State = {
  globalStateInitialized: boolean;
  globalViewState: GlobalViewState;
  fixtureViewState: FixtureViewState;
  layoutViewState: LayoutViewState;
  photoSphereSetup: PhotosphereSetup;
  plannerTool: PlannerTool;
  mainNavState: MainNavState;
  saveBarState: SaveBarState;
  licenseState: LicenseType;
  toolState: ToolState;
  navigationDisabled: boolean;
};

const initialState: State = {
  globalStateInitialized: false,
  globalViewState: GlobalViewState.Fixtures,
  fixtureViewState: FixtureViewState.Floorplan,
  layoutViewState: LayoutViewState.TwoDView,
  photoSphereSetup: PhotosphereSetup.Home,
  plannerTool: PlannerTool.None,
  mainNavState: MainNavState.Home,
  saveBarState: SaveBarState.None,
  licenseState: LicenseType.Placez,
  toolState: ToolState.AddItem,
  navigationDisabled: false,
};

// Action Creators
export const GlobalStateInitializing = () => ({
  type: GLOBAL_STATE_INITIALIZING as typeof GLOBAL_STATE_INITIALIZING,
});

export const SetGlobalStateInitialized = (globalStateInitialized: boolean) => ({
  type: GLOBAL_STATE_INITIALIZED as typeof GLOBAL_STATE_INITIALIZED,
  globalStateInitialized,
});

export const ChangeGlobalViewState = (globalViewState: GlobalViewState) => ({
  type: GLOBAL_VIEW_STATE as typeof GLOBAL_VIEW_STATE,
  globalViewState,
});

export const ChangeFixtureViewState = (fixtureViewState: FixtureViewState, currentViewState: FixtureViewState) => ({
  type: FIXTURE_VIEW_STATE as typeof FIXTURE_VIEW_STATE,
  fixtureViewState,
  currentViewState,
});

export const ChangeLayoutViewState = (layoutViewState: LayoutViewState) => ({
  type: LAYOUT_VIEW_STATE as typeof LAYOUT_VIEW_STATE,
  layoutViewState,
});

export const ChangePlannerTool = (plannerTool: PlannerTool) => ({
  type: PLANNER_TOOL as typeof PLANNER_TOOL,
  plannerTool,
});

export const ChangeToolState = (toolState: ToolState) => ({
  type: TOOL_STATE as typeof TOOL_STATE,
  toolState,
});

export const UpdatePhotosphereSetup = (photoSphereSetup: PhotosphereSetup) => ({
  type: UPDATE_PHOTO_SPHERE_SETUP as typeof UPDATE_PHOTO_SPHERE_SETUP,
  photoSphereSetup,
});

export const ChangeMainNavState = (mainNavState: MainNavState) => ({
  type: MAIN_NAV_STATE as typeof MAIN_NAV_STATE,
  mainNavState,
});

export const ChangeLicenseTypeState = (licenseState: LicenseType) => ({
  type: LICENSE_TYPE_STATE as typeof LICENSE_TYPE_STATE,
  licenseState,
});

export const DisableNavigation = (disable: boolean) => ({
  type: DISABLE_NAVIGATION as typeof DISABLE_NAVIGATION,
  disable,
});

export type GlobalStateInitializingAction = ReturnType<typeof GlobalStateInitializing>;
export type SetGlobalStateInitializedAction = ReturnType<typeof SetGlobalStateInitialized>;
export type ChangeGlobalViewStateAction = ReturnType<typeof ChangeGlobalViewState>;
export type ChangeFixtureViewStateAction = ReturnType<typeof ChangeFixtureViewState>;
export type ChangeLayoutViewStateAction = ReturnType<typeof ChangeLayoutViewState>;
export type UpdatePhotosphereSetupAction = ReturnType<typeof UpdatePhotosphereSetup>;
export type ChangePlannerToolAction = ReturnType<typeof ChangePlannerTool>;
export type ChangeToolStateAction = ReturnType<typeof ChangeToolState>;
export type ChangeMainNavStateAction = ReturnType<typeof ChangeMainNavState>;
export type ChangeLicenseTypeState = ReturnType<typeof ChangeLicenseTypeState>;
export type DisableNavigationAction = ReturnType<typeof DisableNavigation>;

export type Action = GlobalStateInitializingAction | SetGlobalStateInitializedAction |
  ChangeGlobalViewStateAction | ChangeFixtureViewStateAction |
  ChangeLayoutViewStateAction | SetGlobalStateInitializedAction |
  ChangeGlobalViewStateAction | ChangeMainNavStateAction |
  ChangeLicenseTypeState | DisableNavigationAction;

// Reducer
export default createReducer<State, Types, Action>(
  initialState,
  {
    [GLOBAL_STATE_INITIALIZING]: (state: State, action: GlobalStateInitializingAction): State =>
      ({
        ...state,
        // globalStateInitialized: false,
      }),
    [GLOBAL_STATE_INITIALIZED]: (state: State, action: SetGlobalStateInitializedAction): State =>
      ({
        ...state,
        globalStateInitialized: action.globalStateInitialized,
      }),
    [GLOBAL_VIEW_STATE]: (state: State, action: ChangeGlobalViewStateAction): State =>
      ({
        ...state,
        globalViewState: action.globalViewState,
      }),
    [FIXTURE_VIEW_STATE]: (state: State, action: ChangeFixtureViewStateAction): State =>
      ({
        ...state,
        fixtureViewState: action.fixtureViewState,
      }),
    [LAYOUT_VIEW_STATE]: (state: State, action: ChangeLayoutViewStateAction): State =>
      ({
        ...state,
        layoutViewState: action.layoutViewState,
        fixtureViewState: FixtureViewState.Floorplan,
      }),
    [UPDATE_PHOTO_SPHERE_SETUP]: (state: State, action: UpdatePhotosphereSetupAction): State =>
      ({
        ...state,
        photoSphereSetup: action.photoSphereSetup,
      }),
    [PLANNER_TOOL]: (state: State, action: ChangePlannerToolAction): State =>
      ({
        ...state,
        plannerTool: action.plannerTool,
      }),
    [TOOL_STATE]: (state: State, action: ChangeToolStateAction): State =>
      ({
        ...state,
        toolState: action.toolState,
      }),
    [MAIN_NAV_STATE]: (state: State, action: ChangeMainNavStateAction): State =>
      ({
        ...state,
        mainNavState: action.mainNavState,
      }),
    [LICENSE_TYPE_STATE]: (state: State, action: ChangeLicenseTypeState): State =>
      ({
        ...state,
        licenseState: action.licenseState,
      }),
    [DISABLE_NAVIGATION]: (state: State, action: DisableNavigationAction): State =>
      ({
        ...state,
        navigationDisabled: action.disable,
      }),
  }
);

export const getGlobalViewState = (state: ReduxState) => {
  return state.globalstate.globalViewState;
};

export const getLayoutViewState = (state: ReduxState) => {
  return state.globalstate.layoutViewState;
};

export const getPlannerToolState = (state: ReduxState) => {
  return state.globalstate.plannerTool;
};

export const getFixtureViewState = (state: ReduxState) => {
  return state.globalstate.fixtureViewState;
};

export const getSaveBarState = (state: ReduxState) => {
  return state.globalstate.saveBarState;
};

export const isGuest = (state: ReduxState) => {
  return userIsInRole(state.oidc.user, guestRole);
};

export const isAdmin = (state: ReduxState) => {
  return userIsInRole(state.oidc.user, adminRole);
};

export const guestUpdateAttendee = (state: ReduxState) => {
  if(userIsInRole(state.oidc.user, guestRole)) {
    return allowUpdateAttendee(state.oidc.user);
  } else {
    return true;
  }
};

export const guestAllowUpdateLayout = (state: ReduxState): boolean => {
  return allowUpdateLayout(state.oidc.user);
};

export const canEditLayout = (state: ReduxState): boolean => {
  return !isGuest(state) || (isGuest(state) && guestAllowUpdateLayout(state));
}
