import { RouteSection } from './Route';

// Icons
import {
  HomeIcon as DashboardIcon,
} from '../assets/icons';

// Views
import Dashboard from '../views/Dashboard/Dashboard';

const baseUrl = '/';
export const dashboard: RouteSection = {
  main: {
    path: `${baseUrl}`,
    name: 'Dashboard',
    icon: DashboardIcon,
    component: Dashboard,
  },
};

export default dashboard;
