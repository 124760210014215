import { Switch, Theme, Tooltip, Typography, makeStyles } from "@material-ui/core"
import panelStyles from "../panels/panels.css"
import { useEffect, useState } from "react"
import ColorPicker from "./ColorPicker"
import { Colors, MinifiedColors, MaterialCategories, GetImgUrlForMap } from '../../../../api/placez/models/PlacezMaterial';

import { ChromePicker } from 'react-color';

interface Props {
  color: string;
  onChange: (e) => void;
  width?: string;
  advanced?: boolean;
}

const AdvancedColorPicker = (props: Props) => {
  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const [showAllColors, setShowAllColors] = useState(false);
  const [showAdvancedColors, setShowAdvancedColors] = useState(props?.advanced || false);


  useEffect(() => {
    setShowAdvancedColors(props.advanced);
  }, [props.advanced])

return (
   <>
    {props.advanced === undefined &&
      <div className={classes.headingContainerLight}>
        <Typography className={classes.heading} align="center">
          Advanced Colors
        </Typography>
        <Switch
          size="small"
          checked={showAdvancedColors}
          onChange={(e, v) => setShowAdvancedColors(v)}
        />
      </div>
    }
    {showAdvancedColors &&
      <div
        className={classes.pickerContainer}
        id="nfc"
        style={{
          marginTop: '5px',
        }}
      >
        <ChromePicker
          color={props.color}
          onChangeComplete={props.onChange}
        />
      </div>
    }
    {!showAdvancedColors&&
      <>
        <div className={classes.headingContainerLight}>
          <Typography className={classes.heading} align="center">
            Color
          </Typography>
          <Tooltip
            title="Show All">
            <Switch
              size="small"
              checked={showAllColors}
              onChange={(e, v) => setShowAllColors(v)}
            />
          </Tooltip>
        </div>
        <div className={classes.circlePickerContainer}>
          {!showAllColors &&
            <ColorPicker
              width={'240px'}
              color={props.color}
              colors={MinifiedColors}
              // className={classes.picker}
              onChange={props.onChange}
            />
          }
          {showAllColors &&
            <ColorPicker
              width={'240px'}
              color={props.color}
              colors={Colors}
              // className={classes.picker}
              onChange={props.onChange}
            />
          }
        </div>
      </>
    }
   </>
  )
}

export default AdvancedColorPicker;
