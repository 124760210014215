import { red } from '@material-ui/core/colors';
import {
  Theme,
  createStyles }
from '@material-ui/core/styles';

export const tableStyles = (theme: Theme) => createStyles({
  gridRoot: {
    fontFamily: theme.typography.fontFamily,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: `${theme.palette.text.primary} !important`,

    '& .k-master-row': {
      backgroundColor: `${theme.palette.background.paper}`,
      color: `${theme.palette.text.primary}`,
    },
    '& .k-master-row:hover': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
    '& .k-grid-header': {
      backgroundColor: `${theme.palette.background.paper} !important`,
      borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0px 0px`,
      color: theme.palette.text.primary,
    },
    '& .k-grid-header :hover': {
      color: theme.palette.secondary.main,
    },
    '& .k-grid-header .k-sorted': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '& .k-grid-header .k-i-sort-asc-sm': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '& .k-grid-header-wrap': {
      border: 'none',
    },
    '& .k-detail-row .k-grid tbody tr:hover': {
      // backgroundColor: 'rgba(92, 35, 111, 0.09) !important',
    },
    '& .k-grid-norecords': {
      // display: 'none',
    },
    '& .k-grid td': {
      paddingTop: 4,
      paddingBottom: 4,
    },
    '& .k-grid-toolbar': {
      padding: '0px',
      display: 'flex',
      justifyContent: 'end',
    },
    '& .k-grid-content': {
      backgroundColor: `${theme.palette.background.paper} !important`,
      color: theme.palette.text.primary,
      overflow: 'hidden',
    },
  },
  LinkStyle: {
    fontSize: 14,
    '&:hover': {
      textDecoration: 'underline',
      color: `${theme.palette.text.primary} !important`,
    },
  },
  toolbarButton: {
    margin: '10px',
  },
  arrow: {
    // float: 'right',
    color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.text.primary,
    textOverflow: '',
    overflow: 'hidden',
  },
  overflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  newInput: {
    marginBottom: '10px',
  },
});
