import {
  Theme,
  createStyles,
  Tooltip,
  Paper,
  IconButton,
  TextField,
  makeStyles,

} from '@material-ui/core';

import {
  Add,
  Remove,
  AspectRatio,
  RotateRight,
  RotateLeft,
  Lock,
  LockOpen,
} from '@material-ui/icons';

import classnames from 'classnames';
import { ReduxState } from '../../../../reducers';
import { GridCell, Utils } from '../../../../blue/core/utils';
import { CameraLayers } from '../../../../models/BlueState';
import { FixtureViewState, GlobalViewState, LayoutViewState } from '../../../../models/GlobalState';
import { useDispatch, useSelector } from 'react-redux';
import { FitToView, NeedSaveAction, RotateCamera, ZoomIn, ZoomOut } from '../../../../reducers/blue';
import { canEditLayout } from '../../../../reducers/globalState';
import { LocalStorageKey, useLocalStorageState } from '../../../Hooks/useLocalStorageState'

interface Props {
  fullScreen?: boolean;
  globalState: GlobalViewState;
}

const ZoomBar = (props: Props) => {
  const rotateBy: number = 22.5;
  const dispatch = useDispatch();


  const designerRotation: number = useSelector((state: ReduxState) => state.designer?.floorPlan?.cameraState?.orthographicState?.rotation);
  const layoutRotation: number = useSelector((state: ReduxState) => state.designer?.layout?.cameraState?.orthographicState?.rotation);

  let rotation = 0;
  if (props.globalState === GlobalViewState.Fixtures) {
    rotation = designerRotation;
  } else {
    rotation = layoutRotation;
  }

  const gridCellSize: GridCell = useSelector((state: ReduxState) => state.blue.gridCellSize);
  const cameraLayers: CameraLayers[] = useSelector((state: ReduxState) => state.blue.cameraLayers);
  const fixtureViewState: FixtureViewState = useSelector((state: ReduxState) => state.globalstate.fixtureViewState);
  const layoutViewState: LayoutViewState = useSelector((state: ReduxState) => state.globalstate.layoutViewState);
  // const globalViewState: GlobalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const allowUpdateLayout: boolean = useSelector((state: ReduxState) => canEditLayout(state));

  const [gridCellLocked, setGridCellLocked] = useLocalStorageState(LocalStorageKey.GridCellLocked)

  const {
    fullScreen,
  } = props;

  const classes = styles(props);

  return (
    <div className={fullScreen ? classnames(classes.root, classes.fullScreen) : classes.root}>
      {(cameraLayers.includes(CameraLayers.Grid) || fixtureViewState === FixtureViewState.Floorplan ) &&
        <Tooltip title="Grid Cell Size">
          <Paper
            style={{paddingLeft: '18px'}}
            className={classes.buttonContainer}>
            {Utils.unitsOutString(gridCellSize.cmSize)}
            {gridCellLocked &&
              <IconButton
                className={classes.controlButtons}
                aria-label="Clone Scene"
                onClick={() => setGridCellLocked(false)}>
                <Lock fontSize="small" />
              </IconButton>
            }
            {!gridCellLocked &&
              <IconButton
                className={classes.controlButtons}
                aria-label="Clone Scene"
                onClick={() => setGridCellLocked(true)}>
                <LockOpen fontSize="small" />
              </IconButton>
            }
          </Paper>
        </Tooltip>
      }
      <Tooltip title="Zoom Out">
        <Paper className={classes.buttonContainer}>
          <IconButton
            className={classnames(classes.iconButton)}
            onClick={() => dispatch(ZoomIn())}>
            <Remove/>
          </IconButton>
        </Paper>
      </Tooltip>
      <Tooltip title="Zoom In">
        <Paper className={classes.buttonContainer}>
          <IconButton
            className={classnames(classes.iconButton)}
            onClick={() => dispatch(ZoomOut())}>
            <Add/>
          </IconButton>
        </Paper>
      </Tooltip>
      { ((props.globalState === GlobalViewState.Layout &&
        layoutViewState === LayoutViewState.TwoDView) ||
        (props.globalState === GlobalViewState.Fixtures &&
        (fixtureViewState === FixtureViewState.TwoDView ||
        fixtureViewState === FixtureViewState.Floorplan
      ))) &&
      <>
        <Tooltip title="Rotate Clockwise">
          <Paper className={classes.buttonContainer}
            onClick={() => {
              dispatch(RotateCamera(Utils.convertToDegrees( -rotation ?? 0) - rotateBy))
              if (allowUpdateLayout) dispatch(NeedSaveAction(true));
            }}>
            <IconButton
              className={classnames(classes.iconButton)}
            >
              <RotateRight/>
            </IconButton>
          </Paper>
        </Tooltip>
        <Paper className={classes.buttonContainer}>
          <form className={classes.labelInput} noValidate autoComplete="off">
            <TextField
              id="standard-basic"
              onChange={e => {
                dispatch(RotateCamera(360 - parseInt(e.target.value, 10)));
                if (allowUpdateLayout) dispatch(NeedSaveAction(true));
              }}
              onKeyPress={e => { if (e.key === 'Enter') { e.stopPropagation(); e.preventDefault(); } }}
              label="View Angle"
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 3,
              }}
              value={Utils.convertToDegrees( rotation ?? 0)}/>
          </form>
        </Paper>
        <Tooltip title="Rotate Counterclockwise">
          <Paper className={classes.buttonContainer}
            onClick={() => {
              dispatch(RotateCamera(Utils.convertToDegrees( -rotation ?? 0) + rotateBy))
              if (allowUpdateLayout) dispatch(NeedSaveAction(true));
            }}
            >
            <IconButton
              className={classnames(classes.iconButton)}
              >
              <RotateLeft/>
            </IconButton>
          </Paper>
        </Tooltip>
      </>
      }
      <Paper className={classes.buttonContainer}>
        <Tooltip title="Fit to View">
          <IconButton
            className={classnames(classes.iconButton)}
            onClick={() => dispatch(FitToView())}>
            <AspectRatio />
          </IconButton>
        </Tooltip>
      </Paper>
    </div>
  );
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      margin: theme.spacing(),
      display: 'flex',
      flexDirection: 'row',
    },
    fullScreen: {
      right: 0,
    },
    border: {
      textTransform: 'none',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.common.white,
      padding: 2,
      minWidth: 'unset',
    },
    iconButton: {
      padding: theme.spacing(),
    },
    zoom: {
      marginLeft: 4,
      marginRight: 4,
      fontSize: 14,
    },
    labelInput: {
      outline: 'none',
      width: '60px',
    },
    buttonContainer: {
      margin: `0px ${theme.spacing() / 2}px`,
      border: `2px solid ${theme.palette.common.black}`,
      borderRadius: 4,
      '&:hover': {
        background: theme.palette.secondary.main,
      },
      display: 'flex',
      alignItems: 'center',
      padding: '5px',
      minWidth: '50px',
      justifyContent: 'center',
    },
  })
);

export default ZoomBar
