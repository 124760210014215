import {
  Dialog, MuiThemeProvider, Theme, createTheme, makeStyles,
} from '@material-ui/core';
import PrintPane from './PrintPane';
import { Scene } from '../../../api/';
import PlacezLayoutPlan from '../../../api/placez/models/PlacezLayoutPlan';
import { themeGenerator } from '../../../assets/themes';
import { SimpleModalProps } from '../SimpleModal';
import modalStyles from '../../Blue/models/modalStyles.css';

interface Props extends SimpleModalProps {
  layout: PlacezLayoutPlan;
  scene: Scene;
  inventory: any;
  onScreenshot: Function;
}

const PrintModal = (props: Props) => {
  const styles = makeStyles<Theme>(modalStyles);
  const classes = styles(props);
  return(
    <Dialog
      className={classes.dialog}
      open={props.open}
      fullWidth={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={'paper'}
      maxWidth="lg"
      {...props}>

      <MuiThemeProvider theme={createTheme(themeGenerator('light'))}>
        <PrintPane
          layout={props.layout}
          scene={props.scene}
          inventory={props.inventory}
          onScreenshot={props.onScreenshot}
          hideModal={() => props.setOpen(false)}
        />
      </MuiThemeProvider>
      </Dialog>
  );
};

export default PrintModal;
