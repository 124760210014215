import React from 'react';
import { GridCell } from '@progress/kendo-react-grid';

export default class ActiveCell extends GridCell {
  render() {
    const isActive = this.props.dataItem.isActive;
    return (
      <td>
        {isActive ? 'Yes' : 'No'}
      </td>
    );
  }
}
