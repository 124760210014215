import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const NotesIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg version="1.1" id="Layer_1" viewBox="0 0 24 24">
    <path d="M16,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2v-8L16,4z M4,18V6h11v5h5v7H4z"/>
    </svg>
  </SvgIcon>
);

export default NotesIcon;
