export interface FilterItem<T> {
  id: number;
  name: string;
  select: (value: T, value2?: Date, value3?: Date) => boolean;
}

export interface Filter<T> {
  name: string;
  items: FilterItem<T>[];
}

export interface FilterMap {
  [filterName: string]: number;
}

export const shouldFilter =
  <T>(filterMap: FilterMap, filters: Filter<T>[], itemToBeFiltered: T, customMonth: Date, customYear: Date): boolean => {
    if (!filterMap) {
      return true;
    }
    const keys = Object.keys(filterMap);
    let result = true;
    filters.forEach((filter: Filter<T>) => {
      if (keys.includes(filter.name)) {
        const selectedId = filterMap[filter.name];
        if (!(selectedId === undefined) && !filter.items
          .filter(item => item.id === selectedId)[0]
          .select(itemToBeFiltered, selectedId === 5 ? customMonth : customYear)
          ) {
          result = false;
        }
      }
    });
    return result;
  };
