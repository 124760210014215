import {useEffect} from 'react';
import { mainRoutes, settingRoutes } from '../../routes';

import SideBarNavLink from './SideBarNavLink';
import animations from '../../assets/themes/animations';
import { MainNavState } from '../../models/GlobalState';
import { ChangeMainNavState } from '../../reducers/globalState';
import { useDispatch } from 'react-redux';

interface Props {};

const SettingsSidebar = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ChangeMainNavState(MainNavState.SettingsQuickPicks));
  }, [])

  return (
    <div>
      <SideBarNavLink
          key={0}
          route={mainRoutes[0]}
          mainNavStateType={MainNavState.Home}
        />
      <SideBarNavLink
        timeout={2 * animations.growDuration}
        route={settingRoutes.quickpicks}
        mainNavStateType={ MainNavState.SettingsQuickPicks }
      />
      <SideBarNavLink
        timeout={3 * animations.growDuration}
        route={settingRoutes.collision}
        mainNavStateType={ MainNavState.SettingsCollisionDetection }
      />
      <SideBarNavLink
        timeout={3 * animations.growDuration}
        route={settingRoutes.measurement}
        mainNavStateType={ MainNavState.SettingsMeasurment }
      />
    </div>
  );
}

export default SettingsSidebar;
