import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Theme,
  createStyles,
} from '@material-ui/core/styles';

import {
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/lab';

// Icons
import {
  ViewStream as CardDisplayIcon,
  ViewModule as GridDisplayIcon,
  ViewHeadline as TableDisplayIcon,
} from '@material-ui/icons';

// Components
import MultiFilter from '../../components/MultiFilter/MultiFilter';
import Jumbotron from '../../components/Jumbotron';

import DateFnsUtils from '@date-io/date-fns';

// Filters
import {
  Filter,
  shouldFilter,
  DateFilter,
  SceneValueFilter,
  GuestCountFilter,
  SceneCategoryFilter,
} from '../../components/MultiFilter/Filters';

// Models
import {
  ScenesDisplay,
  CardDisplay,
  GridDisplay,
  TableDisplay,
} from '../../components/ScenesDisplays';
import { Scene } from '../../api';

// Util
import findInSearchableFeilds from '../../sharing/utils/findInSearchableFeilds';
import { ReduxState } from '../../reducers';
import { makeStyles, Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GetFloorPlans } from '../../reducers/floorPlans';
import { SetSceneFilters } from '../../reducers/settings';
import { LocalStorageKey, useLocalStorageState } from '../../components/Hooks/useLocalStorageState'

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      overflow: 'auto',
      color: theme.palette.text.primary,
      padding: theme.spacing(3),
    },
    controls: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      alignItems: 'center',
      padding: theme.spacing(),
    },
    datePicker: {
      display: 'flex',
      alignItems: 'center',
    },
    dateSelect: {
      marginRight: '20px',
    },
    displayButton: {
      border: 'none',
      color: theme.palette.secondary.main,
    },
    displayButtonSelected: {
      border: 'none',
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    selector: {
      marginTop: '8px',
    }
  })
);

type ScenesState = LocalStorageState & {
  filters: Filter<Scene>[];
};

type LocalStorageState = {
  display: ScenesDisplay;
};

const defaultState: ScenesState = {
  display: ScenesDisplay.Card,
  filters: [],
};

const defaultStorageState: LocalStorageState = {
  display: ScenesDisplay.Card,
};

interface Props {}

enum tabValue {
  upcoming = 'upcoming',
  complete = 'complete',
  all = 'all'
}

const Scenes = (props: Props) => {
  const dispatch = useDispatch();
  const classes = styles(props);

  const [filters, setFilters] = useState<Filter<Scene>[]>([]);
  const filterMap  = useSelector((state: ReduxState) => state.settings.sceneFilters ?? {})
  const [display, setDisplay] = useState<ScenesDisplay>(ScenesDisplay.Card);
  const [customMonthUtcDateTime, setCustomMonthUtcDateTime]  = useState <Date>(new Date(new Date().getFullYear(), new Date().getMonth()));
  const [customYearUtcDateTime, setCustomYearUtcDateTime]  = useState <Date>(new Date());

  const [completionFilter, setCompletionFilter] = useLocalStorageState<tabValue>(LocalStorageKey.SceneViewSelection, tabValue.upcoming);

  useEffect(() => {
    (window as any).gtag('event', 'list_scenes', {
      'list_type': display,
    });
  }, [display])

  const scenes = useSelector((state: ReduxState) => state.scenes.ascending);
  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);
  const sceneTypes = useSelector((state: ReduxState) => state.settings.pickLists
    .find(list => {
      return list.name === 'SceneType';
    })
  );

  const handleDisplayChange = (event, newDisplay) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  }

  const renderScenes = (scenes: Scene[]) => {
    switch (display) {
      case ScenesDisplay.Card:
        return (<CardDisplay scenes={scenes} />);
      case ScenesDisplay.Grid:
        return (<GridDisplay scenes={scenes} />);
      case ScenesDisplay.Table:
        return (<TableDisplay scenes={scenes} />);
    }
  }

  const resetFilters = () => {
    setFilters([
      new DateFilter,
      new SceneCategoryFilter(sceneTypes),
      new GuestCountFilter,
      new SceneValueFilter,
    ]);
  }

  useEffect(() => {
    resetFilters();
    dispatch(GetFloorPlans());
  }, [])

  useEffect(() => {
    resetFilters();
  }, [sceneTypes])


  const handleSetCustomMonth = (customMonthUtcDateTime: Date) => {
    dispatch(SetSceneFilters({
      ...filterMap,
      customMonth: customMonthUtcDateTime.getTime(),
      customYear: undefined,
    }))
  }

  const handleSetCustomYear = (customYearUtcDateTime: Date) => {
    dispatch(SetSceneFilters({
      ...filterMap,
      customMonth: undefined,
      customYear: customYearUtcDateTime.getTime(),
    }))
  }

  const filteredScenes = scenes
    .filter(scene =>
      globalFilter ? findInSearchableFeilds(scene, globalFilter) : true
    )
    .filter(scene =>
      shouldFilter<Scene>(filterMap, filters, scene, customMonthUtcDateTime, customYearUtcDateTime)
    )
    .filter(scene => {
      const date = new Date(scene.startUtcDateTime);
      const currentDate = new Date();
      if (date > currentDate && completionFilter === tabValue.upcoming) {
        return true;
      }
      if (date < currentDate && completionFilter === tabValue.complete) {
        return true;
      }
      if (completionFilter === tabValue.all) {
        return true;
      }
      return false;
    })
    .sort((scene1, scene2) => {
      const date1 = new Date(scene1.lastModifiedUtcDateTime);
      const date2 = new Date(scene2.lastModifiedUtcDateTime);
      return date2.getTime() - date1.getTime();
    });

    useEffect(() => {
      if (filterMap.customMonth) {
        setCustomMonthUtcDateTime(new Date(filterMap.customMonth));
      }
      if (filterMap.customYear) {
        setCustomYearUtcDateTime(new Date(filterMap.customYear));
      }
    }, [filterMap])


  return (
    <div className={classes.root}>
      <Jumbotron
        title="Scenes"
        description="View your upcoming scenes or start a new one!">
      </Jumbotron>

      <Paper className={classes.controls}>
        <div className={classes.datePicker}>
          <MultiFilter
            className={classes.dateSelect}
            filters={filters}/>
          {filterMap.Date === 5 &&
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.selector}
                variant="inline"
                views={['month']}
                margin="normal"
                openTo="month"
                value={customMonthUtcDateTime}
                onChange={handleSetCustomMonth}
                format="MMMM yyyy"
                autoOk
              />
            </MuiPickersUtilsProvider>
          }
          {filterMap.Date === 6 &&
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.selector}
                variant="inline"
                views={['year']}
                margin="normal"
                openTo="year"
                value={customYearUtcDateTime}
                onChange={handleSetCustomYear}
                format="yyyy"
                autoOk
              />
            </MuiPickersUtilsProvider>
          }
        </div>
        <Tabs
          className={classes.tabs}
          value={completionFilter}
          onChange={(e, v) => setCompletionFilter(v)}
          variant="fullWidth"
          scrollButtons="off"
          aria-label="scrollable prevent tabs example"
        >
          <Tab value={tabValue.upcoming} label="Upcoming" />
          <Tab value={tabValue.complete} label="Complete" />
          <Tab value={tabValue.all} label="All" />
        </Tabs>
        <ToggleButtonGroup
          value={display}
          onChange={handleDisplayChange}
          exclusive>
          <Tooltip title="Grid Display">
            <ToggleButton
              classes={{
                root: classes.displayButton,
                selected: classes.displayButtonSelected,
              }}
              selected={display === ScenesDisplay.Grid}
              value={ScenesDisplay.Grid}>
              <GridDisplayIcon color="inherit"/>
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Card Display">
            <ToggleButton
              classes={{
                root: classes.displayButton,
                selected: classes.displayButtonSelected,
              }}
              selected={display === ScenesDisplay.Card}
              value={ScenesDisplay.Card}>
              <CardDisplayIcon color="inherit"/>
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Table Display">
            <ToggleButton
              classes={{
                root: classes.displayButton,
                selected: classes.displayButtonSelected,
              }}
              selected={display === ScenesDisplay.Table}
              value={ScenesDisplay.Table}>
              <TableDisplayIcon color="inherit"/>
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
      </Paper>
      {renderScenes(filteredScenes)}
    </div>
  );
}

export default Scenes;
