import { useState } from 'react';
import Select from 'react-select';
import { createStyles, WithStyles } from '@material-ui/core/styles';
import {
  Typography,
  NoSsr,
  TextField,
  Paper,
  MenuItem,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
// Note: Most of this is adapted from the Demo:
// https://material-ui.com/demos/autocomplete/#react-select

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minWidth: '46.7%',
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 30,
      '& div': {
        color: 'inherit',
      },
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
      color: 'inherit',
    },
    noOptionsMessage: {
      padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
    },
    singleValue: {
      fontSize: 14,
      color: 'inherit',
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 14,
      color: 'inherit',
    },
    menu: {
      position: 'absolute',
      zIndex: 1,
      marginTop: theme.spacing(),
      left: 0,
      right: 0,
    },
  })
)

const NoOptionsMessage = props => {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

const Control = props => {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps }
    />
  );
};

const Option = props => {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
     {props.children}
    </MenuItem>
  );
};

const Placeholder = props => {
  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
};

const SingleValue = props => {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
};

const ValueContainer = props => {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
};

const Menu = props => {
  return (
    <Paper square className={props.selectProps.classes.menu} {...props.innerProps}>
      {props.children}
    </Paper>
  );
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

interface Props {
  label: string;
  suggestions: { label: string, value: any }[];
  value: any;
  handleChange: any;
  disabled?: boolean;
  createMode?: CreatableMode;
}

const AutoComplete = (props: Props) => {
  const classes = styles(props);
  const theme = useTheme();

    const {
      label,
      suggestions,
      value,
      handleChange,
      disabled,
    } = props;

    const suggestion = suggestions.filter(x => x.value === value)[0];
    const selection = {
      value: suggestion ? suggestion.value || '' : '',
      label: suggestion ? suggestion.label || '' : '',
    };
    const selectStyles = {
      input: base => ({
        ...base,
        color: disabled ? 'rgba(255, 255, 255, 0.5)' : theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
      dropdownIndicator: base => {
        return {
          ...base,
          display: 'none',
        };
      },
    };

  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          options={suggestions}
          components={components}
          value={selection}
          onChange={handleChange}
          isDisabled={disabled}
          placeholder={`Search ${label}...`}
          isClearable={!disabled}
          textFieldProps={{
            label,
            disabled,
            InputLabelProps: {
              shrink: true,
              disabled,
            },
          }}
        />
      </NoSsr>
    </div>
  );
}

export default AutoComplete;

export enum CreatableMode {
  None = '',
  Modal = 'modal',
  BuiltIn = 'built-in',
}
