import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import { useNavigate } from 'react-router';

import {
  Card,
  IconButton,
  makeStyles,
  Zoom,
} from '@material-ui/core';

import {
  KeyboardArrowRight as RightChevronIcon,
  RemoveRedEye as ViewIcon,
  FavoriteBorder as LikeIcon,
  ModeCommentOutlined as CommentIcon,
  EventNoteSharp as SceneIcon,
} from '@material-ui/icons';

import CategoryTag from '../CardDisplay/CategoryTag';

import { Scene } from '../../../api';
import animations from '../../../assets/themes/animations';
import { SelectScene } from '../../../reducers/scenes';
import { SetGlobalFilter } from '../../../reducers/settings';
import { ReduxState } from '../../../reducers';
import { LocalStorageKey, useLocalStorageSelector } from '../../Hooks/useLocalStorageState'

interface Props {
  scene: Scene;
  timeout?: number;
};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'nowrap',
      flexDirection: 'row',
      marginBottom: 2 * theme.spacing(),
      height: 200,
      [theme.breakpoints.down('sm')]: {
        minHeight: 200,
      },
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        flexFlow: 'column',
        height: 'auto',
      },
    },
    thumbnailContainer: {
      width: 320,
      position: 'relative',
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[900],
      [theme.breakpoints.down('sm')]: {
        minWidth: 320,
      },
      [theme.breakpoints.down('xs')]: {
        width: 'fit-content',
      },
    },
    thumbnail: {
      width: '100%',
      height: '100%',
      background: `linear-gradient(to top left, ${theme.palette.primary.main}, ${theme.palette.background.paper})`,
    },
    dateDetail: {
      display: 'flex',
      flexDirection: 'row',
      height: 60,
      width: '100%',
      position: 'absolute',
      bottom: 0,
      left: 0,
      zIndex: 1,
      color: theme.palette.getContrastText(theme.palette.grey[900]),
      backgroundColor: theme.palette.grey[900],
    },
    detailContainer: {
      flex: 1,
      display: 'flex',
      padding: `${theme.spacing()}px ${2 * theme.spacing()}px
                                    0px ${2 * theme.spacing()}px`,
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        height: 200,
      },
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    categories: {
      minHeight: 27,
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    title: {
      ...theme.typography.h5,
    },
    metricContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '100%',
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        padding: theme.spacing(),
        justifyContent: 'space-around',
      },
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 64,
      padding: theme.spacing(),
      margin: `${theme.spacing()}px  0px ${theme.spacing()}px ${theme.spacing()}px`,
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    metric: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 18,
      color: theme.palette.grey[500],
    },
    sceneIconContainer: {
      backgroundColor: theme.palette.grey[800],
      padding: theme.spacing(),
      marginLeft: theme.spacing(),
    },
    sceneIcon: {
      color: theme.palette.grey[100],
      height: '100%',
      width: 40,
    },
    icon: {
      marginRight: `${theme.spacing() / 2}px`,
      height: 26,
      width: 26,
    },
    dateHolder:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `${theme.spacing() / 2}px ${2 * theme.spacing()}px`,
    },
    dayOfTheMonth:{
      ...theme.typography.h5,
      color: theme.palette.getContrastText(theme.palette.grey[900]),
      marginRight: theme.spacing(),
    },
    month: {
      ...theme.typography.h6,
      color: theme.palette.getContrastText(theme.palette.grey[900]),
    },
    time: {
      ...theme.typography.body2,
      color: theme.palette.getContrastText(theme.palette.grey[900]),
      textTransform: 'uppercase',
    },
    addPhotoButton: {
      fontSize: 40,
      padding: 4,
      color: theme.palette.common.white,
      margin: 6,
      right: 0,
      position: 'absolute',
    },
  })
);

const defaultImage = 'https://placez-cdn.azurewebsites.net/models/defaultevent.jpg';

const SceneCard = (props: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = styles(props);

  const twentyFourHourTime = useLocalStorageSelector<boolean>(LocalStorageKey.TwentyFourHourTime)

  const onClick = () => {
    dispatch(SelectScene(props.scene.id));
    editScene();
  }

  const editScene = () => {
    dispatch(SetGlobalFilter(''));
    navigate(`/scenes/${props.scene.id}/edit`);
  }

  const { scene, timeout } = props;
  scene.startUtcDateTime = new Date(scene.startUtcDateTime);
  scene.endUtcDateTime = new Date(scene.endUtcDateTime);
  const dayOfTheMonth = scene.startUtcDateTime.getDate();
  const day = (new Intl.DateTimeFormat('en-US', { weekday: 'long' })
    .format(scene.startUtcDateTime)).substring(0, 3);
  const month = (new Intl.DateTimeFormat('en-US', { month: 'long' })
  .format(scene.startUtcDateTime)).substring(0, 3);
  const time = scene.startUtcDateTime
    .toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: !twentyFourHourTime });
  return (
    <Zoom
      in={true}
      timeout={timeout ? timeout : animations.zoomDuration}>
      <Card className={classes.root} >
        <div className={classes.thumbnailContainer} onClick={onClick}>
          <img
            className={classes.thumbnail}
            src={scene.thumbnailUrl ? scene.thumbnailUrl : defaultImage }
            alt=''
            />
          <div className={classes.dateDetail}>
            <div className={classes.sceneIconContainer}>
              <SceneIcon className={classes.sceneIcon}/>
            </div>
            <div className={classes.dateHolder}>
              <div>
                <span className={classes.dayOfTheMonth}>{dayOfTheMonth}</span>
                <span className={classes.month}>{month}</span>
              </div>
              <span className={classes.time}>
                {day}, {time}
              </span>
            </div>
          </div>
        </div>

        <div className={classes.detailContainer}>

          <div className={classes.categories}>
            {scene.categories && scene.categories.map((sceneTypeId, index) =>
              (<CategoryTag
                key={`tag-${scene.id}-${sceneTypeId}-${index}`}
                sceneTypeId={sceneTypeId} />))
            }

          </div>

          <h2 className={classes.title}>{scene.name}</h2>
          <div className={classes.metricContainer}>
              <div className={classes.metric}>
                <ViewIcon className={classes.icon}/>
                {scene.aggregateLayoutViewCount}
              </div>
              <div className={classes.metric}>
                <LikeIcon className={classes.icon}/>
                {scene.aggregateLayoutLikeCount}
              </div>
              <div className={classes.metric}>
                <CommentIcon className={classes.icon}/>
                {scene.aggregateLayoutChatMessagesCount}
              </div>
          </div>
        </div>
        <div className={classes.actionContainer}>
          <IconButton onClick={onClick}>
            <RightChevronIcon/>
          </IconButton>
        </div>
      </Card>
    </Zoom>
  );
}

export default SceneCard;
