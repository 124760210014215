import { CameraLayers } from '../../models/BlueState';
import DimensionParams from '../model/dimensionParams';
import { LabelMaker } from '../three/labelMaker';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';
import { store } from '../..';
import { ReduxState } from '../../reducers';
import { getUserSettingValue, ValidUnits } from '../../api/placez/models/UserSetting';
import { Utils } from '../core/utils';
import { getFromLocalStorage } from '../../sharing/utils/localStorageHelper'
import { LocalStorageKey } from '../../components/Hooks/useLocalStorageState'
import { BufferGeometry, Material, Object3D, Points, PointsMaterial, Sprite, Vector3 } from 'three'
import { LineParams, ShapeParams } from '../model/shapeParams'

export class PlacezShape {
  public id: string = Utils.guid();

  public placezShapeObject: Object3D;

  public viewHeight: number;
  public viewWidth: number;
  public themeColor: string;

  public positions: Vector3[] = [];
  public points: Points[] = [];
  public lines: Line2[] = [];
  public labels: LabelMaker[] = [];

  constructor(
    parameters: ShapeParams,
    width: number,
    height: number,
    themeColor: string
  ) {
    this.themeColor = themeColor;

    const pointsMaterial = new PointsMaterial({
      color: Utils.hexColorToDec(this.themeColor),
      size: 5.0,
    });

    this.placezShapeObject = new Object3D();

  }

  public lineMaterial: Material;
  public fillMaterial: Material;

  public update = () => {}

  public updateDimensionLabel = () => {}

  public serialize: () => DimensionParams;
  // public serialize: () => ShapeParams;

  public getShape = (): Object3D => {
    return this.placezShapeObject
  }

  public delete = () => {
    this.points.forEach((point: Points) => {
      this.placezShapeObject.remove(point);
      point.geometry.dispose();
    })
    this.points = [];
    this.lines.forEach((line: Line2) => {
      this.placezShapeObject.remove(line);
      line.geometry.dispose();
    });
    this.lines = [];
    this.labels.forEach((label: LabelMaker) => {
      this.placezShapeObject.remove(label.getSprite());
      label.getSprite().geometry.dispose();
      label.getSprite().material.dispose();
    })
    this.labels = [];


  }


}
