import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, NavLink, useLocation } from 'react-router-dom';
import DefaultSidebar from './DefaultSidebar';
import PlannerSidebar from './PlannerSidebar/PlannerSidebar';
import SettingsSidebar from './SettingsSidebar';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import {
  createStyles
} from '@material-ui/core/styles';
import { sceneRoutes, placeRoutes, mainRoutes } from '../../routes';
import { createSelector } from 'reselect';
import { ReduxState } from '../../reducers';
import { getOrgTheme } from '../../api/placez/models/UserSetting';

interface Props {
  guest?: boolean;
}

const logoSize = 52;

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      flex: 1,
    },
    logoContainer: {
      height: '68px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      padding: 0,
    },
    logo: {
      width: logoSize,
      height: logoSize,
      margin: '10px',
      marginRight: '14px',
    },
    logoText:{
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      padding: 0,
    },
  })
);

const SideBar = (props: Props) => {
  const classes = styles(props);
  const location = useLocation();

  const user = useSelector((state: ReduxState) => getUser(state));

  useEffect(() => {
    window.dispatchEvent(new Event('navigationToAnInScopePage'));
    (window as any).gtag('event', 'navigate', {
      'path': location.pathname,
    });
  }, [location])


  const { guest } = props;
  return (
    <List className={classes.root}>
        <Tooltip title="Home">
      <ListItem className={classes.logoContainer}>
          <NavLink
            to={mainRoutes[0].path}
          >
            <ListItemIcon>
              <img
                className={classes.logo}
                alt="logo"
                src={ getOrgTheme(user.profile.organization_id).logo } />
            </ListItemIcon>
          </NavLink>
        <ListItemText className={classes.logoText}>
          <div style={{ fontSize: getOrgTheme(user.profile.organization_id).fontStyle }}>
          {/* <Typography variant="h4"> */}
            {getOrgTheme(user.profile.organization_id).name}
          {/* </Typography> */}
          </div>
        </ListItemText>
      </ListItem>
        </Tooltip>
      { guest
      ? <PlannerSidebar guest={guest} />
      : <Routes>
          <Route
            path={sceneRoutes.planner.path}
            element={<PlannerSidebar guest={guest}/> } />
          <Route
            path={placeRoutes.editFloorPlan.path}
            element={<PlannerSidebar guest={guest}/> } />
          <Route
            path={placeRoutes.newFloorPlan.path}
            element={<PlannerSidebar guest={guest}/> } />
          <Route
            path={'/settings/*'}
            element={<SettingsSidebar/> } />
          <Route path={'/*'} element={<DefaultSidebar/>} />
        </Routes>
      }
    </List>
  );
}

const getUserState = state => {
  return state.oidc.user;
};

const getUser = createSelector(
  [getUserState],
  user => user
);

export default SideBar;
