import {
  Theme,
  createStyles,
  makeStyles}
from '@material-ui/core/styles';

import CatalogCard from './CatalogCard';
import animations from '../../assets/themes/animations';
import { Catalog } from '../../models';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../reducers';
import { userIsInRole } from '../../sharing/utils/userHelpers';

interface Props {
  catalogsById: { [catalogId: string]: Catalog };
  selectedId: string;
  onCardClick: (id: string) => void;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      overflowY: 'auto',
      padding: theme.spacing(),
    },
  })
);

const reorderCatalogsByOwnVsNot = function (catalogsById, user) {
  const catalogs = [] as Catalog[];
  Object.keys(catalogsById).map((catalogId:string) => {
    catalogs.push(catalogsById[catalogId]);
  });
  const own = catalogs.filter(catalog => {
    return catalog.owned;
  });
  const notOwned = catalogs.filter(catalog => {
    return !catalog.owned;
  });

  if (userIsInRole(user, 'admin')) {
    return [...own.sort((a, b) => a.sortOrder - b.sortOrder), ...notOwned.sort((a, b) => a.sortOrder - b.sortOrder)];
  } else {
    return [...own.sort((a, b) => a.sortOrder - b.sortOrder)];
  }
};

const CatalogList = (props: Props) => {
  const { catalogsById, onCardClick, selectedId } = props;
  const user = useSelector((state: ReduxState) => state.oidc.user);

  const reorderedCatatlogs = reorderCatalogsByOwnVsNot(catalogsById, user);
  const classes = styles(props);

  return (
    <div className={classes.root} >
      {reorderedCatatlogs.map((catalog: Catalog, index) =>
      <CatalogCard
        selected={catalog.id === selectedId}
        onClick={() => onCardClick(catalog.id)}
        timeout={index * animations.zoomDuration}
        key={catalog.id}
        catalog={catalog}/>)}
    </div>
  );
}

export default CatalogList;
