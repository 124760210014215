import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DesignerView from '../DesignerView';
import { blobToFile } from '../../../../sharing/utils/blobToFile';

import {
  CatalogType
} from '../../../../models';

import {
  createStyles,
  Theme,
  Tooltip,
  Button,
  makeStyles
} from '@material-ui/core';
import { ArrowLeft } from '@material-ui/icons';
import {
  HandlesFromBlue,
  ControlsType,
} from '../../models';
import { ZoomBar, UndoRedo, FPVBar, ViewOptions } from '../bars';
import LayoutHeader from './LayoutHeader';
import {
  BatchPanel,
  AttendeePanel,
  LabelPanel,
  EditItemPanel,
  AddItemPanel,
  ChatPanel,
  NumberPanel,
} from '../panels';
import PhotospherePanel from '../panels/PhotospherePanel';
import { ChangeLayoutViewState, ChangeToolState } from '../../../../reducers/globalState';
import { PlannerTool, LayoutViewState, SaveBarState, ToolState, GlobalViewState } from '../../../../models/GlobalState';
import { ReduxState } from '../../../../reducers';
import { ExportDesignComplete } from '../../../../reducers/designer';
import NewBatchPanel from '../panels/NewBatchPanel';
import ShapePanel from '../panels/ShapePanel'

const headerHeight = '35px';
const itempanelWidth = '258px';
const chatWidth = '258px';
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '100%',
  },
  designerContainer: {
    flex: '1 0',
    display: 'flex',
    minHeight: 0,
  },
  designerViewContainerWithTool: {
    width: `calc(100% - ${itempanelWidth})`,
  },
  designerViewContainerWithChat: {
    width: `calc(100% - ${chatWidth})`,
  },
  designerViewContainerWithChatAndTool: {
    width: `calc(100% - ${chatWidth} - ${itempanelWidth})`,
  },
  designerViewContainerFullscreen: {
    width: '100%',
  },
  itempanels: {
    width: itempanelWidth,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '0px',
  },
  chatpanel: {
    width: chatWidth,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  displayNone: {
    display: 'none',
  },
  headerContainer: {
    width: '100%',
    height: headerHeight,
  },
  headerTiles: {
    overflowX: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '5px solid red',
  },
  leftZoomBar: {
    float: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '15px',
  },
  showPanelButton: {
    position: 'absolute',
    right: 0,
    margin: 0,
    padding: 0,
    minWidth: 'auto',
    justifyContent: 'flex-end',
    background: '#0e0e0e3d',
  },
})
)

interface Props {
  designerCallbacks: HandlesFromBlue;
}

const LayoutDesigner = (props: Props) => {

  const classes = styles(props);

  const [fullScreen, setFullScreen] = useState<boolean>(false);

  const plannerTool = useSelector((state: ReduxState) => state.globalstate.plannerTool);
  const toolState = useSelector((state: ReduxState) => state.globalstate.toolState);
  const blueControlsType = useSelector((state: ReduxState) => state.blue.controlsType);
  const chatPanelState = useSelector((state: ReduxState) => state.chat.chatPanelState);
  const saveBarState = useSelector((state: ReduxState) => state.globalstate.saveBarState);
  const blueReady = useSelector((state: ReduxState) => state.blue.blueInitialized);
  const sceneScanLoaded = useSelector((state: ReduxState) => state.blue.sceneScanLoaded);
  const layout = useSelector((state: ReduxState) => state.designer.layout);

  const dispatch = useDispatch();

  const resetToolToDefault = () => {
    dispatch(ChangeToolState(ToolState.AddItem));
  }

  const closePanel = () => {
    dispatch(ChangeLayoutViewState(LayoutViewState.ThreeDView));
  }

  const setFullScreenTrue = () => {
    setFullScreen(true);
  }

  const showToolPanel = () => {
    const hasTool = plannerTool !== PlannerTool.None;
    if (hasTool) {
      setFullScreen(false);
    }
  }

  const renderPanels = () => {
    const {
      designerCallbacks,
    } = props;
    switch (plannerTool) {
      case PlannerTool.Designer:
        switch (toolState) {
          case ToolState.EditItem:
            return (
              <EditItemPanel
                handlesFromBlue={designerCallbacks}
                onBack={resetToolToDefault} />
            );
          case ToolState.AddBatch:
            return (
              <BatchPanel
                onBack={resetToolToDefault} />
            );
          case ToolState.NewBatch:
            return (
              <NewBatchPanel
                onBack={resetToolToDefault} />
            );
          case ToolState.AddItem:
            return (
              <AddItemPanel
                catalogType={CatalogType.GeneralPurpose}
                addAssetControls={designerCallbacks}
              />
            );
          default:
            return (<></>);
          }
      case PlannerTool.Photosphere:
        return (
          <PhotospherePanel
            handlesFromBlue={designerCallbacks}
            setFullScreen={setFullScreenTrue}
            edit={false}
          />
        );
      case PlannerTool.Attendee:
        return (
          <AttendeePanel
            handlesFromBlue={designerCallbacks}
            exitAttendees={closePanel}
            onBack={() => { }}
          />
        );
      case PlannerTool.Label:
        return (
          <LabelPanel/>
        );
      case PlannerTool.Number:
        return (
          <NumberPanel
            handlesFromBlue={designerCallbacks}
            onClosePanel={closePanel}
            onBack={() => { }}
          />
        );
      case PlannerTool.Shape:
        return (
          <ShapePanel
            handlesFromBlue={designerCallbacks}
            onClosePanel={closePanel}
            onBack={() => { }}
          />
        );
      default:
        return (<></>);
    }
  }

  useEffect(() => {
    const needFullScreen = plannerTool === PlannerTool.None;
    if (fullScreen !== needFullScreen) {
      setFullScreen(needFullScreen);
    };
  }, [plannerTool])

  const hasTool = plannerTool !== PlannerTool.None;
  const { designerCallbacks } = props;

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        {blueReady && layout &&
          <LayoutHeader
            saveBarState={saveBarState}
            layout={layout}
            inventory={designerCallbacks.getInventory}
            onScreenshot={designerCallbacks.onScreenshot}
            // TODO Change to redux call passed in
            generateAR={
              () => designerCallbacks.onExport((blob: Blob) => {
                dispatch(ExportDesignComplete(blobToFile(blob, 'export.gltf')));
              })
            }
          />
        }
      </div>
      <div className={classes.designerContainer}>
        <div className={!hasTool && chatPanelState || hasTool && chatPanelState && fullScreen ? classes.designerViewContainerWithChat : hasTool && chatPanelState ? classes.designerViewContainerWithChatAndTool : hasTool && !chatPanelState && !fullScreen ? classes.designerViewContainerWithTool : classes.designerViewContainerFullscreen}>
          <UndoRedo />
          <div className={classes.leftZoomBar}>

            {blueReady &&
              <ViewOptions/>
            }

            {blueReady && blueControlsType !== ControlsType.Photosphere && blueControlsType !== ControlsType.PointerLock &&
              <ZoomBar
                fullScreen={fullScreen && !chatPanelState}
                globalState={GlobalViewState.Layout}
              />
            }
            {blueControlsType === ControlsType.PointerLock &&
              <FPVBar
                onRotate={ designerCallbacks.rotatePointerLockCamera }
                onMove={ designerCallbacks.movePointerLockCamera }
              />
            }
          </div>
          <DesignerView
            showFloorplan={false}
            designerCallbacks={designerCallbacks} />
        </div>
        <div className={(fullScreen || !blueReady || !sceneScanLoaded) ? classes.displayNone : classes.itempanels}>
          {renderPanels()}
        </div>
        {
          chatPanelState && (
            <div className={classes.chatpanel}>
              <ChatPanel/>
            </div>
          )
        }
        {
          fullScreen && hasTool &&
          (
            <Tooltip title="Show panel">
              <Button className={classes.showPanelButton}>
                <ArrowLeft onClick={showToolPanel} fontSize={'large'} />
              </Button>
            </Tooltip>
          )
        }
      </div>
    </div>

  );
}

export default LayoutDesigner;
