import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Theme,
  Typography,
  FormLabel,
  FormControl,
  MenuItem,
  Select,
  Button,
  Tooltip,
  TextField,
  makeStyles,
} from '@material-ui/core';

import {
  PlannerComboInput,
} from '../inputs/PlannerInput';

import grid from '../../../../assets/images/grid.png';
import banquet from '../../../../assets/images/banquet.png';
import cabaret from '../../../../assets/images/cabaret.png';
import hollowSquare from '../../../../assets/images/hollowSquare.png';
import family from '../../../../assets/images/family.png';
import theater from '../../../../assets/images/theater.png';
import uShape from '../../../../assets/images/uShape.png';
import circle from '../../../../assets/images/circle.png';
import horseshoeDiagram from '../../../../assets/images/horseshoeDiagram.png';

import { RotateRight, RotateLeft, Cancel, Help } from '@material-ui/icons';
import panelStyles from './panels.css';
import { getUserSettingValue } from '../../../../api/placez/models/UserSetting';
import { ReduxState } from '../../../../reducers';
import { Utils } from '../../../../blue/core/utils';
import { BatchAddParams, BatchTypes } from '../../../../blue/model/batchPatterns';
import { TableTypes } from '../../../../blue/itemModifiers/ChairMod';
import { ApplyBatch, CancelBatch, SetBatchSettings } from '../../../../reducers/blue';
import { SkuType } from '../../../../blue/items/asset'
import NumberEditor from '../../../NumberEditor'

interface Props {
  onBack: Function;
}

const BatchPanel = (props: Props) => {
  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(panelStyles);
  const userProfile = useSelector((state: ReduxState) => state.oidc.user.profile);

  const batchSettings: BatchAddParams = useSelector((state: ReduxState) => state.blue.batchSettings);

  useEffect(() => {
    // this kicks off batching in controller since tool state has been set
    dispatch(SetBatchSettings(batchSettings));
  }, [])


  const confirmBatch = () => {
    dispatch(ApplyBatch());
  }

  const cancel = () => {
    dispatch(SetBatchSettings({
      ...batchSettings,
      maxGuest: 0,
    }));
    dispatch(SetBatchSettings());
  }

  const configureBatch = e => {
    switch (e.target.value) {
      case ('grid'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 0,
          rowAisleSpacing: 0,
          numberOfRowAisles: 0,
          rowSpacing: Utils.convertUnitsFromTo(36, 'in', 'cm'),
          colSpacing: Utils.convertUnitsFromTo(36, 'in', 'cm'),
        }))
        break;
      case ('banquet'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 0,
        }))
        break;
      case ('family'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
        }))
        break;
      case ('theater'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 30 / 180 * Math.PI,

          rowAisleSpacing: 48,
          numberOfRowAisles: 2,
          rowSpacing: 0,
          rotation: 0,
        }))
        break;
      case ('uShape'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 0,
        }))
        break;
      case ('hollowSquare'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 0,
        }))
        break;
      case ('cabaret'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
          skew: 0,
          rowSpacing: 24,
          colSpacing: 24,
        }))
        break;
      case ('circle'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
        }))
        break;
      case ('horseshoe'):
        dispatch(SetBatchSettings({
          ...batchSettings,
          batchType: e.target.value,
        }))
        break;
      default:
        break;
    }
  }

  const backToItem = () => {
    dispatch(CancelBatch());
  }

  const navigateHelp = () => {
    window.open('http://support.getplacez.com/helpmenu/content/Creating%20Layouts/Designing%20a%20New%20Layout.htm');
  }

  const setLength = (prop) => value => {
    if (isNaN(value)) return;
    dispatch(SetBatchSettings({
      ...batchSettings,
      [prop]: value ?? 0,
    }))
  }

  const setAngle = (prop, currentValue: number, adjustmentAngle: number, direction: -1 | 1) => () => {
    dispatch(SetBatchSettings({
      ...batchSettings,
      [prop]: currentValue + direction * adjustmentAngle,
    }))
  }

  const setSpacing = value => e => {
    if (isNaN(value) || value === 0) return;
    dispatch(SetBatchSettings({
      ...batchSettings,
      colSpacing: value || 0,
      rowSpacing: value || 0,
    }))
  }

  const classes = styles(props);

  const [tabIndex, setTabIndex] = useState<number>(0);

  const {
    asset,
    batchType,
    rowSpacing,
    colSpacing,
    rowAisleSpacing,
    columnAisleSpacing,
    numberOfRowAisles,
    numberOfColumnAisles,
    rotation,
    skew,
    maxGuest,
    batchRows,
    batchColumns
  } = batchSettings;

  return (
    <div className={classes.root}>
      <div className={classes.panelUpper}>
        <div className={classes.mainHeadingContainer}>
          <Tooltip title="Batching Help">
            <Help onClick={navigateHelp} color="primary"/>
          </Tooltip>
          <Typography className={classes.title}>
            Batch
          </Typography>
          <Tooltip title="Stop Batching">
            <Cancel onClick={backToItem} className={classes.cancelButton} />
          </Tooltip>
        </div>
        <div className={classes.titleContainer}>
          <Typography className={classes.itemFieldHeading} align="center">
            {asset?.name}
          </Typography>
        </div>
      </div>

      <div className={classes.panelLower}>
        <div
          className={classes.itemImage}
          style={{
            backgroundImage: `url(${window.env['REACT_APP_DAM']}/Organization/${userProfile.organization_id}/MediaAssetFile/${asset?.id}/preview), radial-gradient(#f5f5f5, #909090)`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
        </div>
        <div className={classes.setupTemplate}>
          <FormControl className={classes.setupTemplateControls}>
            <Select
              value={batchType}
              onChange={configureBatch}
              input={<PlannerComboInput id="object-input" />}
            >
              <MenuItem value={'grid'}>Grid</MenuItem>
              <MenuItem value={'banquet'}>Banquet</MenuItem>
              {([SkuType.CHR, SkuType.BEN].includes(SkuType[asset?.skuType]) || (SkuType[asset?.skuType] === SkuType.TBL && asset?.modifiers?.chairMod && [TableTypes.Banquet, TableTypes.Square].includes(asset?.modifiers?.chairMod.tableType))) &&
                <MenuItem value={'theater'}>Theater</MenuItem>
              }
              {SkuType[asset?.skuType] === SkuType.TBL && asset?.modifiers?.chairMod && [TableTypes.Banquet, TableTypes.Square].includes(asset?.modifiers?.chairMod.tableType) &&
                <MenuItem value={'family'}>Family</MenuItem>
              }
              {SkuType[asset?.skuType] === SkuType.TBL && asset?.modifiers?.chairMod && [TableTypes.Banquet, TableTypes.Square].includes(asset?.modifiers?.chairMod.tableType) &&
                <MenuItem value={'hollowSquare'}>Hollow Square</MenuItem>
              }
              {SkuType[asset?.skuType] === SkuType.TBL && asset.modifiers?.chairMod && [TableTypes.Banquet, TableTypes.Square].includes(asset.modifiers?.chairMod.tableType) &&
                <MenuItem value={'uShape'}>U-shape</MenuItem>
              }
              {SkuType[asset?.skuType] === SkuType.TBL && asset?.modifiers?.chairMod && [TableTypes.Round, TableTypes.Oval].includes(asset?.modifiers?.chairMod.tableType) &&
                <MenuItem value={'cabaret'}>Cabaret</MenuItem>
              }
              <MenuItem value={'circle'}>Circle</MenuItem>
              <MenuItem value={'horseshoe'}>Horseshoe</MenuItem>
            </Select>
          </FormControl>
        </div>
        {/* <div className={classes.itemImageContainer}>
          <img
            className={classes.itemImage}
            alt={'image'}
            src={batchtype}
          />
        </div> */}
        {[BatchTypes.grid, BatchTypes.banquet, BatchTypes.cabaret].includes(batchType) &&
          <div className={classes.groupContainer}>
            <span className={classes.batchFieldHeading}>
              Rotation
            </span>
            <div className={classes.field}>
              <Button
                onClick={setAngle('rotation', rotation, Math.PI / 4, -1)}
                variant="outlined"
                classes={{
                  root: classes.button,
                }}>
                <RotateRight />
              </Button>
              <Button
                onClick={setAngle('rotation', rotation, Math.PI / 4, 1)}
                variant="outlined"
                classes={{
                  root: classes.button,
                }}>
                <RotateLeft />
              </Button>
            </div>
          </div>
        }
        {[BatchTypes.grid, BatchTypes.banquet, BatchTypes.cabaret, BatchTypes.family].includes(batchType) &&
          <div className={classes.fieldColumns}>
            <TextField
              onChange={(e) => {
                dispatch(SetBatchSettings({
                  ...batchSettings,
                  maxGuest: isNaN(parseInt(e.target.value, 10)) ? 0 : parseInt(e.target.value, 10),
                }))
              }}
              label={(batchSettings?.asset?.modifiers?.chairMod?.seatPositions?.length > 0) ? "Guest Count (Optional)" : "Item Count (Optional)"}
              style={{marginBottom: '5px'}}
              inputProps={{
                style: { textAlign: 'center' },
                maxLength: 3,
              }}
              value={maxGuest} />
          </div>
        }
        {[BatchTypes.theater].includes(batchType) &&
          <div className={classes.groupContainer}>
            <span className={classes.batchFieldHeading}>
              Skew
            </span>
            <div className={classes.field}>
              <Button
                onClick={setAngle('skew', skew, Math.PI / 90, -1)}
                variant="outlined"
                classes={{
                  root: classes.button,
                }}>
                <RotateRight />
              </Button>
              <Button
                onClick={setAngle('skew', skew, Math.PI / 90, 1)}
                variant="outlined"
                classes={{
                  root: classes.button,
                }}>
                <RotateLeft />
              </Button>
            </div>
          </div>
        }
        {[BatchTypes.grid, BatchTypes.banquet, BatchTypes.theater, BatchTypes.cabaret].includes(batchType) &&
          <>
            <div className={classes.headingContainer}>
              <Typography className={classes.heading} align="center">
                Row
              </Typography>
              { tabIndex === 1 &&
                <NumberEditor
                  value={batchRows}
                  unitless
                  onChange={(value) => {
                    dispatch(SetBatchSettings({
                      ...batchSettings,
                      batchRows: value,
                    }))
                  }}
                  step={1}
                  round={1}
                  dark
                  allowZero
                />
              }
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                Spacing
              </FormLabel>
              <NumberEditor
                value={rowSpacing}
                onChange={setLength('rowSpacing')}
                step={1}
                round={1}
                dark
                allowZero
              />
            </div>
          </>
        }
        {[BatchTypes.circle, BatchTypes.horseshoe].includes(batchType) &&
          <>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                Spacing
              </FormLabel>
              <NumberEditor
                value={rowSpacing}
                onChange={setLength('rowSpacing')}
                step={1}
                round={1}
                dark
                allowZero
              />
            </div>
          </>
        }
        {[BatchTypes.grid, BatchTypes.banquet, BatchTypes.family, BatchTypes.theater, BatchTypes.cabaret].includes(batchType) &&
          <>
            <div className={classes.headingContainer}>
              <Typography className={classes.heading} align="center">
                Column
              </Typography>
              { tabIndex === 1 &&
                <NumberEditor
                  value={batchColumns}
                  unitless
                  onChange={(value) =>
                    dispatch(SetBatchSettings({
                      ...batchSettings,
                      batchColumns: value,
                    }))
                  }
                  step={1}
                  round={1}
                  dark
                  allowZero
                />
              }
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                Spacing
              </FormLabel>
              <NumberEditor
                value={colSpacing}
                onChange={setLength('colSpacing')}
                step={1}
                round={1}
                dark
                allowZero
              />
            </div>
          </>
        }
        <div className={classes.fieldColumns}>
          <Button
            onClick={setSpacing(Utils.convertUnitsFromTo(36, 'in', 'cm'))}
            className={classes.button}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Set Covid Spacing
          </Button>
        </div>
        {[BatchTypes.grid, BatchTypes.banquet, BatchTypes.theater, BatchTypes.cabaret].includes(batchType) &&
          <>
            <div className={classes.headingContainer}>
              <Typography className={classes.heading} align="center">
                Row Aisles
              </Typography>
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                Spacing
              </FormLabel>
              <NumberEditor
                value={rowAisleSpacing}
                onChange={setLength('rowAisleSpacing')}
                step={1}
                round={1}
                dark
                allowZero
              />
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                # Of Aisles
              </FormLabel>
              <NumberEditor
                value={numberOfRowAisles}
                onChange={setLength('numberOfRowAisles')}
                step={1}
                round={1}
                dark
                allowZero
                unitless
              />
            </div>
            <div className={classes.headingContainer}>
              <Typography className={classes.heading} align="center">
                Column Aisles
              </Typography>
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                Spacing
              </FormLabel>
              <NumberEditor
                value={columnAisleSpacing}
                onChange={setLength('columnAisleSpacing')}
                step={1}
                round={1}
                dark
                allowZero
              />
            </div>
            <div className={classes.fieldColumns}>
              <FormLabel className={classes.fieldHeading}>
                # Of Aisles
              </FormLabel>
              <NumberEditor
                value={numberOfColumnAisles}
                onChange={setLength('numberOfColumnAisles')}
                step={1}
                round={1}
                dark
                allowZero
                unitless
              />
            </div>
          </>
        }
      </div>
      <div className={classes.footer}>
        <div className={classes.field}>
          <Button
            className={classes.button}
            onClick={cancel}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Reset
          </Button>
          <Button
            className={classes.button}
            onClick={confirmBatch}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Apply
          </Button>
        </div>
        <div className={classes.field}>
          <Button
            className={classes.button}
            onClick={backToItem}
            variant="outlined"
            classes={{
              root: classes.button,
            }}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BatchPanel;
