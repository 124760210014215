import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from '../reducers';
import {
  Theme,
  makeStyles,
  Tooltip,
  createStyles,
  PaletteType,
} from '@material-ui/core';
import classNames from 'classnames';

// Routing
import { Route, Routes } from 'react-router-dom';
import { default as routes } from '../routes';

import { createSelector } from 'reselect';

// Components
import {
  Brightness3,
  Menu as HamburgerIcon,
 } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import SideBar from '../components/SideBar/SideBar';
import TopBar from '../components/TopBar/TopBar';
import ModalInjector from '../components/Modals/ModalInjector';
import { ModalProvider } from '../components/Modals/ModalContext';
import Dashboard from '../views/Dashboard/Dashboard';

// Theming
import { SunnyIcon } from '../assets/icons';
import { useTheme } from '@material-ui/styles';
import { LocalStorageKey, useLocalStorageState } from '../components/Hooks/useLocalStorageState'

const headerSizeWithMargin = '68px';

interface Props {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    '@global': {
      '*::-webkit-scrollbar': {
        display: 'block',
        width: '10px !important',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: `${theme.palette.type === 'dark' ? theme.palette.text.primary : theme.palette.primary.main} !important`,
        borderRadius: '5px !important',
        backgroundClip: 'content-box !important',
        border: '2px solid transparent !important',
      },
    },
    root: {
      position: 'absolute',
      top: '0px',
      bottom: '0px',
      left: '0px',
      right: '0px',
      display: 'flex',
      overflow: 'hidden',
      transform: 'translate(0,0)',
    },
    hide: {
      display: 'none',
    },
    drawer: {
      height: '100%',
      width: theme.overrides.MuiDrawer.root['width'],
      flexShrink: 0,
      // transition: theme.transitions.create('width', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
    },
    drawerPaper: {
      width: theme.overrides.MuiDrawer.root['width'],
      // transition: theme.transitions.create('width', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    drawerMinimized: {
      flexShrink: 0,
      width: theme.overrides.MuiDrawer.root['minimizedWidth'],
      // transition: theme.transitions.create('width', {
      //   easing: theme.transitions.easing.easeIn,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
    },
    listContainer: {
      height: '100vh',
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      alignSelf:'flex-start',
      margin: '0px 16px',
      marginBottom: 4,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
      marginBottom: 4,
    },
    routeMain: {
      width: '100%',
      height: `calc(100% - ${headerSizeWithMargin})`,
      overflow: 'auto',
      overflowX: 'hidden',
    },
    content: {
      flexGrow: 1,
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.easeInOut,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      marginLeft: `calc(0 - ${theme.overrides.MuiDrawer.root['width']}px)`,
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      whiteSpace: 'nowrap',
    },
    contentShift: {
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen,
      // }),
      marginLeft: 0,
      width: `calc(100vw - ${theme.overrides.MuiDrawer.root['width']}px)`,
      height: '100vh',
    },
  })
);


const MainLayout = (props: Props) => {
  const classes = styles(props);
  const dispatch = useDispatch();
  const theme: Theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(true);
  const [drawerMinimized, setDrawerMinimized] = useState<boolean>(false);

  const blueInitialized = useSelector((state: ReduxState) => state.blue.blueInitialized);
  const sagasLoaded = useSelector((state: ReduxState) => state.lifecycle.sagasReady);

  const [themeType, setThemeType] = useLocalStorageState<PaletteType>(LocalStorageKey.ThemeType, 'light');

  useEffect(() => {
    const checkEmbed = () => {
      if (!(window as any).embedded_svc || !(window as any).embedded_svc.hasSessionDataLoaded) {
        setTimeout(checkEmbed, 2000);
      } else {
        const helpButton = document.getElementsByClassName('uiButton');
        if (helpButton[0]) {
          (helpButton[0] as any).style.backgroundColor = theme.palette.primary.main;
        } else {
          setTimeout(checkEmbed, 500);
        }
      }
    };
    checkEmbed();
  }, []);

  const handleDrawerSizeToggle = () => {
    setDrawerMinimized(!drawerMinimized);
  }

  const toggleThemeType = () => {
    setThemeType(themeType === 'light' ? 'dark' : 'light');
  }

  return (
    <ModalProvider>
      <div className={classes.root}>
        <ModalInjector/>
        <CssBaseline />
        <TopBar
          drawerOpen={ drawerOpen }
          drawerMinimized = { drawerMinimized }/>
        <Drawer
          className={
            classNames(
              classes.drawer,
              {
                [classes.drawerMinimized]: !drawerMinimized,
              })}
          variant="persistent"
          anchor="left"
          open={drawerOpen}
          classes={{
            paper: drawerMinimized ? classes.drawerPaper : classes.drawerMinimized,
          }}
          >
          <div className={classes.listContainer}>
            <SideBar/>
            <div style={{
              display: 'flex',
              flexDirection: !drawerMinimized ? 'column' : blueInitialized ? 'row' : 'row-reverse',
              justifyContent: !drawerMinimized ? '' : 'space-between',
            }}>
              { !blueInitialized && themeType &&
                <Tooltip title={themeType === 'light' ? 'Dark Mode' : 'Light Mode'}>
                  <IconButton
                    edge="end"
                    aria-label="mode"
                    onClick={toggleThemeType}
                    className={classes.button}>
                    {themeType === 'light' ? <Brightness3 fontSize="small"/> : <SunnyIcon fontSize="small"/>}
                  </IconButton>
                </Tooltip>
              }
              <IconButton
                aria-label="Toggle Drawer"
                onClick={handleDrawerSizeToggle}
                className={classes.button}>
                <HamburgerIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </Drawer>
        <main className={classNames(classes.content, { [classes.contentShift]: drawerOpen })}>

          <div className={classes.drawerHeader} />
          <div className={classes.routeMain}>
            { sagasLoaded ?
            <Routes>
              {routes.map((route, key) => {
                return (
                <Route
                  key={key}
                  path={route.path}
                  element={<route.component/>}
                />
              )})}
              <Route element={<Dashboard/>} />
            </Routes> :
            <></>
            }
          </div>

        </main>
      </div>
    </ModalProvider>
  );
}

export default MainLayout;
