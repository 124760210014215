import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const ClearClipboardIcon = (props: any) => (
  <SvgIcon {...props}>
    <svg version="1.1" id="Layer_1" viewBox="0 0 512 512">
    <g>
      <g>
        <polygon points="387.661,396.15 414.632,369.179 392.468,347.014 365.497,373.985 337.108,345.598 314.944,367.762
          343.332,396.15 316.361,423.121 338.525,445.286 365.497,418.315 393.885,446.704 416.05,424.539 		"/>
      </g>
    </g>
    <g>
      <g>
        <rect x="102.64" y="262.437" width="137.927" height="31.347"/>
      </g>
    </g>
    <g>
      <g>
        <rect x="101.658" y="135.586" width="137.927" height="31.347"/>
      </g>
    </g>
    <g>
      <g>
        <rect x="101.658" y="199.325" width="250.776" height="31.347"/>
      </g>
    </g>
    <g>
      <g>
        <path d="M426.3,293.044V106.731L320.136,0H28.572v512h340.284v-0.051c63.482-1.789,114.573-53.983,114.573-117.889
          C483.428,351.259,460.508,313.713,426.3,293.044z M329.296,53.66l43.634,43.867h-43.634V53.66z M59.919,480.653V31.347H297.95
          v97.527h97.002v150.974c-9.421-2.431-19.294-3.726-29.464-3.726c-65.032,0-117.94,52.907-117.94,117.939
          c0,34.194,14.633,65.031,37.959,86.593H59.919z M365.489,480.653c-47.748,0-86.593-38.845-86.593-86.593
          c0-47.748,38.845-86.592,86.593-86.592s86.593,38.845,86.593,86.592C452.081,441.807,413.236,480.653,365.489,480.653z"/>
      </g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    <g>
    </g>
    </svg>
  </SvgIcon>
);

export default ClearClipboardIcon;
