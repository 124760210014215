import { useSelector } from 'react-redux';

import FloorPlanScaleModal from '../../../../Modals/FloorPlanScaleModal';
import { ModalConsumer } from '../../../../Modals/ModalContext';
import {
  HandlesFromBlue,
} from '../../../models';
import { ZoomBar, FloorPlanBar, UndoRedo } from '../../bars';
import { GlobalViewState, SaveBarState } from '../../../../../models/GlobalState';
import { ReduxState } from '../../../../../reducers';
import FloorPlanWallScaleModal from '../../../../Modals/FloorPlanWallScaleModal';
import LoadImageModal from '../../../../Modals/LoadImageModal';
import ViewOptions from '../../bars/ViewOptionsBar/ViewOptions'

type Props = {
  designerCallbacks?: HandlesFromBlue;
};

const FloorPlanUI = (props: Props) => {
  const currentFixturePlan = useSelector((state: ReduxState) => state.designer.floorPlan);
  const saveBarState = useSelector((state: ReduxState) => state.globalstate.saveBarState);

  const handleSetFloorPlanImage = imageURL => {
    props.designerCallbacks.onLoadFloorplanImg(imageURL);
  }

  const handleWallHeight = height => {
    if (height) {
      props.designerCallbacks.setWallHeight(height);
      props.designerCallbacks.setHideWalls(false);
    } else {
      props.designerCallbacks.setHideWalls(true);
    }
  }


  const {designerCallbacks} = props;

  return (
    <>
      <UndoRedo/>
      <ViewOptions/>
      <ZoomBar
        fullScreen={true}
        globalState={GlobalViewState.Fixtures}
      />
        <ModalConsumer>
          {({ showModal }) =>
            <FloorPlanBar
              handleSetFloorPlanImage={handleSetFloorPlanImage}
              onDeleteAllClick={props.designerCallbacks.deleteFloorplan}
              onSetFloorPlanImageClick={ () => showModal(
                LoadImageModal,
                { ...props,
                  imageLabel: 'Floor Plan Image',
                  handleSetImage: handleSetFloorPlanImage,
                  currentImage: currentFixturePlan.floorplanImageUrl
                })
              }
              onSetWallHeightClick={ () => showModal(
                FloorPlanWallScaleModal,
                { ...props,
                  wallHeight: currentFixturePlan.wallHeight,
                  hideWalls: currentFixturePlan.hideWalls,
                  handleWallHeight
                })
              }
            />
          }
        </ModalConsumer>
      </>
  );
}


export default FloorPlanUI;
