import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router';
import {
  Theme,
  createStyles }
from '@material-ui/core/styles';

// Components
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import {
  AddCircleOutline as AddIcon
} from '@material-ui/icons';
import { ClientTable } from '../../components/Tables';
import PlaceCharts from '../../components/PlaceCharts/PlaceCharts';

// Models
import { SelectClient, GetClientMetrics } from '../../reducers/client';

// Routes
import { clientRoutes } from '../../routes';

// Util
import findInSearchableFeilds from '../../sharing/utils/findInSearchableFeilds';
import { DateRange } from '../../sharing/utils/DateHelper';
import { ReduxState } from '../../reducers';
import Jumbotron from '../../components/Jumbotron';

interface Props {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      overflow: 'auto',
      padding: theme.spacing(3),
    },
    body: {
      overflow: 'auto',
      display: 'grid',
      gridGap: theme.spacing(3),
      gridTemplateColumns: '4fr 1fr',
      gridTemplateAreas: '"header1 header2" "content sidebar"',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto',
      },
    },
    header1: {
      gridArea: 'header1',
    },
    header2: {
      gridArea: 'header2',
    },
    content: {
      gridArea: 'content',
      borderRadius: '4px',
      overflow: 'hidden',
    },
    sidebar: {
      gridArea: 'sidebar',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    heading: {
      float: 'left',
    },
    selectionStats: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      margin: `${theme.spacing(3)}px 0px`,
      flexWrap: 'wrap',
    },
    tableButtons: {
      float: 'right',
      paddingTop: '5px',
    },
    buttonIcon: {
      width: '30px',
      height: '30px',
    },
    titleBar: {
      background: theme.palette.secondary.main,
      padding: '5px 15px 5px 15px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
)

const ClientList = (props: Props) => {

  const dispatch = useDispatch();
  const classes = styles(props);
  const navigate = useNavigate();

  const clients = useSelector((state: ReduxState) => state.client.clients);
  const selectedId = useSelector((state: ReduxState) => state.client.selectedId);
  const globalFilter = useSelector((state: ReduxState) => state.settings.globalFilter);
  const metrics = useSelector((state: ReduxState) => state.client.metrics);
  const selectedClientMetric = useSelector((state: ReduxState) => state.client.selectedClientMetric);

  const goToEdit = () => {
    if (selectedId === undefined || selectedId === null) {
      return;
    }
    navigate(clientRoutes.edit.path.replace(':id', selectedId.toString()));
  }

  const goToAdd = () => {
    (window as any).gtag('event', 'add_new_client');
    navigate(clientRoutes.new.path);
  }

  const onRowClick = (scene: any) => {
    dispatch(SelectClient(scene.dataItem.id));
  }

  useEffect(() => {
    const allTimeRange: DateRange = { startDate: null, endDate: null };
    dispatch(GetClientMetrics(allTimeRange));
  }, [])

  const filteredClients = clients.filter(client => findInSearchableFeilds(client, globalFilter));

  return (
    <div className={classes.root}>
      <Jumbotron
        title="Clients"
      >
        <Tooltip title="Add New Client">
          <IconButton aria-label="Add New Client"
            color="secondary"
            onClick={goToAdd}>
            <AddIcon fontSize="large"/>
          </IconButton>
        </Tooltip>
      </Jumbotron>
      <div className={classes.body}>
        <div className={classes.content}>
          <ClientTable
            clients={filteredClients}
            onRowClick={onRowClick}
            selectedId={selectedId}
            samePage={false}
          />
        </div>
        <div className={classes.sidebar}>
          <PlaceCharts metrics = {metrics} selectedMetric={selectedClientMetric} />
        </div>
      </div>
    </div>
  );
}

export default ClientList;
