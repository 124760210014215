import withModalContext from '../withModalContext';

import {
Dialog,
} from '@material-ui/core';
import NewFloorPlanForm from './NewFloorPlanForm';
import { AssetCatalog, Place } from '../../../api';
import { SimpleModalProps } from '../SimpleModal';

interface Props extends SimpleModalProps {
  catalog: AssetCatalog;
  place: Place;
}

const NewFloorPlanModal = (modalProps: Props) => {
  const { open, setOpen, place } = modalProps;

  return(
    <Dialog
      maxWidth='md'
      open={open}
      aria-labelledby="scene-place-modal"
      scroll="paper"
      >
      <NewFloorPlanForm
        setOpen={setOpen}
        place={place}
      />
    </Dialog>
  );
};

export default withModalContext(NewFloorPlanModal);
