import { FloorItem } from './floor_item';
import { Asset } from './asset';
import { Item } from './item';

export abstract class OnFloorItem extends FloorItem {
  constructor(
    asset: Asset) {
    super(asset);
    this.receiveShadow = true;
    this.castShadow = false;
  }

}
