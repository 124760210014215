import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
/* tslint:disable */
export const SendARIcon = (props: any) => (
  <SvgIcon {...props} viewBox="0 0 128 128">
    <path d="M124.996 119.626H3.27037C2.63507 119.626 2.03473 119.339 1.63606 118.845C1.23856 118.35 1.08469 117.702 1.21991 117.082L11.7135 68.8112C11.9233 67.8471 12.7778 67.1582 13.764 67.1582H114.502C115.491 67.1582 116.343 67.8471 116.554 68.8112L127.048 117.082C127.181 117.703 127.029 118.35 126.629 118.845C126.232 119.339 125.631 119.626 124.996 119.626ZM5.87454 115.429H122.392L112.812 71.3559H15.4566L5.87454 115.429Z"/>
    <path d="M99.6484 69.7087L103.752 68.8169L114.244 117.09L110.141 117.981L99.6484 69.7087Z"/>
    <path d="M64.1351 62.9618C63.8134 62.9618 63.4917 62.8872 63.1967 62.7403L33.8142 48.049C33.1031 47.6935 32.6543 46.9673 32.6543 46.1723C32.6543 45.3761 33.1031 44.6498 33.8142 44.2943L63.1967 29.603C63.7866 29.3081 64.4837 29.3081 65.0735 29.603L94.4561 44.2943C95.1671 44.6498 95.6159 45.3761 95.6159 46.1723C95.6159 46.9673 95.1671 47.6935 94.4561 48.049L65.0735 62.7403C64.7786 62.8872 64.4569 62.9618 64.1351 62.9618ZM39.4456 46.1723L64.1351 58.5158L88.8234 46.1723L64.1351 33.8275L39.4456 46.1723Z"/>
    <path d="M64.1351 33.5793C63.8134 33.5793 63.4917 33.5058 63.1967 33.3578L33.8142 18.6677C33.1031 18.3121 32.6543 17.5847 32.6543 16.7897C32.6543 15.9947 33.1031 15.2685 33.8142 14.913L63.1967 0.22168C63.7866 -0.0732421 64.4837 -0.0732421 65.0735 0.22168L94.4561 14.913C95.1671 15.2685 95.6159 15.9947 95.6159 16.7897C95.6159 17.5847 95.1671 18.3121 94.4561 18.6677L65.0735 33.3578C64.7786 33.5058 64.4569 33.5793 64.1351 33.5793ZM39.4456 16.7897L64.1351 29.1345L88.8234 16.7897L64.1351 4.445L39.4456 16.7897Z"/>
    <path d="M64.1346 62.9616C63.7511 62.9616 63.3675 62.8555 63.0307 62.648C62.4128 62.2656 62.0352 61.5895 62.0352 60.8621V31.4807C62.0352 30.6857 62.4851 29.9583 63.1962 29.6028L92.5776 14.9127C93.2269 14.5886 93.9998 14.6201 94.6187 15.0048C95.2377 15.3871 95.6154 16.062 95.6154 16.7894V46.172C95.6154 46.967 95.1655 47.6932 94.4544 48.0488L65.073 62.7401C64.7769 62.8869 64.4551 62.9616 64.1346 62.9616ZM66.2328 32.7782V57.4665L91.4177 44.8734V20.1851L66.2328 32.7782Z"/>
    <path d="M64.1351 62.9618C63.8146 62.9618 63.4928 62.8872 63.1967 62.7404L33.8142 48.0491C33.1031 47.6935 32.6543 46.9673 32.6543 46.1723V16.7897C32.6543 16.0623 33.0308 15.3874 33.6498 15.0051C34.2688 14.6239 35.0416 14.5901 35.6921 14.913L65.0735 29.6031C65.7846 29.9586 66.2334 30.686 66.2334 31.481V60.8624C66.2334 61.5898 65.8568 62.2659 65.239 62.6483C64.901 62.8558 64.5186 62.9618 64.1351 62.9618ZM36.852 44.8737L62.0357 57.4667V32.7784L36.852 20.1854V44.8737Z"/>
    <path d="M62.0352 2.09814H66.2328V31.4807H62.0352V2.09814Z"/>
    <path d="M124.996 128.021H3.27013C2.11143 128.021 1.17188 127.083 1.17188 125.922V117.528C1.17188 116.368 2.11143 115.428 3.27013 115.428H124.996C126.156 115.428 127.095 116.368 127.095 117.528V125.922C127.095 127.083 126.156 128.021 124.996 128.021ZM5.36955 123.824H122.897V119.626H5.36955V123.824Z"/>
    <path d="M66.2324 69.2573H70.4301V81.8492H66.2324V69.2573Z"/>
    <path d="M91.418 46.1719H95.6156V58.7637H91.418V46.1719Z"/>
    <path d="M83.0234 73.4551H87.2211V81.8493H83.0234V73.4551Z"/>
    <path d="M76.7266 58.7642H80.9242V69.2578H76.7266V58.7642Z"/>
    <path d="M91.418 75.5537H95.6156V83.9491H91.418V75.5537Z"/>
    <path d="M74.627 81.8496H78.8246V96.5409H74.627V81.8496Z"/>
    <path d="M55.7383 69.2573H59.936V96.5405H55.7383V69.2573Z"/>
    <path d="M32.6543 52.4683H36.852V58.7642H32.6543V52.4683Z"/>
    <path d="M41.0488 83.2495H45.2453V96.5408H41.0488V83.2495Z"/>
    <path d="M47.3438 58.7642H51.5403V69.2578H47.3438V58.7642Z"/>
    <path d="M32.6543 75.5537H36.852V83.9491H32.6543V75.5537Z"/>
    <path d="M49.4434 79.751H53.641V88.496H49.4434V79.751Z"/>
    <path d="M66.2324 86.0469H70.4301V90.2446H66.2324V86.0469Z"/>
  </SvgIcon>
);

export default SendARIcon;
