import { HTMLAttributes } from 'react';
import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import classnames from 'classnames';

// Components
import CatalogCardLabel from './CatalogCardLabel';
import {
  Card,
  CardContent,
  CardHeader,
  Zoom,
  makeStyles,
  useTheme,
} from '@material-ui/core';

// Models
import animations from '../../assets/themes/animations';
import { Catalog } from '../../models';

interface Props {
  catalog: Catalog;
  selected: boolean;
  timeout?: number;
  onClick: (id: string) => void;
}

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '280px',
      marginBottom: theme.spacing(2),
      border: 'solid 1px transparent',
    },
    selected: {
      borderColor: theme.palette.primary.main,
    },
    header: {
      padding: theme.spacing(),
    },
    content: {
      padding: theme.spacing(),
    },
    headerContent: {
      alignSelf: 'flex-start',
    },
    title: {
      color: theme.palette.text.primary,
      fontSize: 16, // FIXME Remove specific font adjustment
    },
    stats: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-around',
      borderTop: `solid ${theme.palette.grey[200]} 1px`,
      paddingTop: theme.spacing(),
    },
    green: {
      color: '#005500 !important',
    },
  })
);

const CatalogCard = (props: Props & HTMLAttributes<HTMLDivElement>) => {

  const classes = styles(props);
  const theme: Theme = useTheme();

  const formatDate = (date: Date): string => {
    if (!date) {
      return '';
    }
    return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
  }

  const { catalog, onClick, selected, timeout } = props;
  return (
    <Zoom
      in={true}
      timeout={timeout ? timeout : animations.zoomDuration}>
      <Card
        onClick={onClick}
        className={classnames(classes.root, selected ? classes.selected : '')}>
        <CardHeader
          avatar={
            <div
              title={catalog.name}
              style={{
                height: 60,
                width: 100,
                background: `url(${window.env['REACT_APP_DAM']}/${catalog.imageURL}), ${theme.palette.primary.main}`,
                backgroundPosition: 'center',
                backgroundSize: '50px 50px',
                backgroundRepeat: 'no-repeat',
                borderRadius: '4px',
              }}
            />
          }
          title={catalog.name}
          subheader={''}
          classes={{
            root: classes.header,
            content: classes.headerContent,
            title: classes.title,
          }}
        />
        <CardContent className={classes.content}>
          <div className={classes.stats}>
            <CatalogCardLabel
              label="Assets"
              value={catalog.assetCount.toString()} />
            <CatalogCardLabel
              label="Date Added"
              value={formatDate(catalog.created)} />
            <CatalogCardLabel
              label="Owned"
              value={catalog.owned ? 'Yes' : 'No'}
            />
            <CatalogCardLabel
              label="Active"
              value={catalog.active ? 'Yes' : 'No'} />
          </div>
        </CardContent>
      </Card>
    </Zoom>
  );
}

export default CatalogCard;
