import { Fragment, useState } from 'react';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Input,
  Grid,
} from '@material-ui/core';

import { ClientDetail, PickList } from '../../../api';
import AutoSuggest from '../../AutoSuggest';
import { SimpleModalProps } from '../SimpleModal';
import modalStyles from '../../Blue/models/modalStyles.css';
import { makeStyles } from '@material-ui/styles';

const localStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    title: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(2),
      fontSize: '26px',
      textAlign: 'center',
    },
    content: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: '100px',
    },
    field: {
      minWidth: '40%',
      width: '80%',
      margin: '10px 24px',
    },
    field2: {
      minWidth: '40%',
      width: '80%',
      margin: '10px 24px 10px 24px',
    },
    button: {
      padding: '4px 30px',
      borderRadius: theme.shape.borderRadius,
      width: '120px',
    },
    actions: {
      borderTop: `1px solid ${theme.palette.divider}`,
      margin: 0,
      padding: theme.spacing(),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      padding: theme.spacing(),
      textAlign: 'center',
    },
  })
);
interface Props extends SimpleModalProps {
  handleSetNewClient: Function;
  clientName?: string;
  picklists: PickList[];
}

const ClientForm = (props: Props) => {
  const localClasses = localStyles(props);
  const modalStyle = makeStyles<Theme>(modalStyles);
  const modalClasses = modalStyle(props);
  const classes = {
    ...modalClasses,
    ...localClasses,
  }


  const [client, setClient] = useState<ClientDetail>({
    name: props.clientName
  } as ClientDetail)

  const handleSave = event => {
    const newClient = { ...client };
    // I couldnt get the local props to work.  seemed undefined
    props.handleSetNewClient(newClient);
    props.setOpen(false);
  }

  const handleChange = event => {
    const { target: { value, name } } = event;
    setClient({
      ...client,
      [name]: value
    })
  }

  const handleStateChange = (name: string, value: string) => {
    setClient({
      ...client,
      [name]: value,
    })
  }

  const { picklists } = props;
  return(
    <Fragment>
      <DialogTitle className={classes.dialogTitle}>
        Add New Client
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container>
          <Grid item xs={6}>
            <TextField
              className={classes.field}
              autoFocus
              id="client-name"
              label="Client Name"
              name="name"
              value={client.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
          <FormControl className={classes.field}>
            <InputLabel htmlFor="type">Client Type</InputLabel>
              <Select
                  value={client.type || '' }
                  onChange={handleChange}
                  inputProps={{
                    name: 'type',
                    id: 'client-type',
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                >
                {
                  picklists.find(list => {
                    return list.name === 'ClientType';
                  }).picklistOptions
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((clienttype, index) =>
                    <MenuItem
                      key={`${clienttype.name}-${index}`}
                      value={clienttype.name}>{clienttype.name}</MenuItem>)
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.field}>
              <InputLabel htmlFor="phone-number">
                Phone Number
              </InputLabel>
              <Input
                name="phone"
                value={client.phone}
                onChange={handleChange}
                id="phone-number"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              className={classes.field}
              id="email"
              label="Email"
              name="email"
              value={client.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={classes.field}
              id="address-line-1"
              label="Street Address"
              name="address1"
              value={client.address1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.field}
              id="city"
              label="City"
              name="city"
              value={client.city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl className={classes.field2}>
              <AutoSuggest
                  value={client.stProv || ''}
                  onChange={(value: string) => {
                    handleStateChange('stProv', value);
                  }}/>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              className={classes.field}
              id="zip"
              label="Postal"
              name="postal"
              value={client.postal}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => props.setOpen(false)}>
            Cancel
        </Button>
        <Button
          disabled={!client.name || client.name.length < 3}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSave}>
            Save
          </Button>
      </DialogActions>
    </Fragment>
  );
}

export default ClientForm;
