import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from '../../../../reducers';
import { Vector3, Matrix4, Quaternion } from 'three';
import {
  Typography,
  Button,
  Tooltip,
  Input,
  IconButton,
  ListItem,
  List,
  Paper,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { ArrowRight, Edit, Delete, Cancel, Save as SaveIcon} from '@material-ui/icons';
import { HandlesFromBlue, ControlsType, PhotosphereSetup, CameraType } from '../../models';

import panelStyles from './panels.css';
import PhotosphereViewer from './PhotosphereViewer';
import { UpdatePhotosphereSetup } from '../../../../reducers/globalState';
import { SetControlsType, SetAutoRotate, SetPhotosphere, AutoRotatePhotosphereCamera, SetCameraType, Save, FitToView } from '../../../../reducers/blue';

import { ModalConsumer } from '../../../Modals/ModalContext';
import { Photosphere } from '../../models/Photosphere';

import { Utils } from '../../../../blue/core/utils';
import { AreYouSureDelete } from '../../../Modals/UniversalModal';
import LoadImageModal from '../../../Modals/LoadImageModal';
import { UniversalModalWrapper } from '../../../Modals/UniversalModalWrapper';
import { SetFloorPlan } from '../../../../reducers/designer';

const storageName = 'PhotospherePanel';

type Props = {
  handlesFromBlue: HandlesFromBlue;
  setFullScreen?: Function;
  edit: boolean;
};

const PhotospherePanel = (props: Props) => {
  const dispatch = useDispatch();
  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const controlsType = useSelector((state: ReduxState) => state.blue.controlsType);
  const autoRotate = useSelector((state: ReduxState) => state.blue.autoRotate);
  const currentFixturePlan = useSelector((state: ReduxState) => state.designer.floorPlan);
  const photoSphereSetup = useSelector((state: ReduxState) => state.globalstate.photoSphereSetup);

  const [fixturePhotospheres, setFixturePhotospheres] = useState<Photosphere[]>(currentFixturePlan?.photoSpheres ?? []);

  const [selectedPhotosphereID, setSelectedPhotosphereID] = useState<string>(undefined);
  const [moved, setMoved] = useState<boolean>(false);
  const [editName, setEditName] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>('');

  useEffect(() => {
    if (!selectedPhotosphereID && fixturePhotospheres.length > 0) {
      onPhotosphere(fixturePhotospheres[0]);
    }
  }, [fixturePhotospheres, selectedPhotosphereID])

  useEffect(() => {
    setEditName(false);
  }, [selectedPhotosphereID])

  const host= window.env['REACT_APP_DAM'];

  const handleCreatePhotosphere = (imageUrl: string, name?: string) => {
    const imagePath = imageUrl.slice(imageUrl.search('/Assets'));
    const newPhotosphere = {
      imagePath,
      name: name ?? '',
      id: Utils.guid(),
    } as Photosphere;
    handleAddPhotosphere(newPhotosphere);
  }

  const handleAddPhotosphere = (photosphere: Photosphere) => {
    const photoSpheres = [...fixturePhotospheres];
    photoSpheres.push(photosphere);
    dispatch(SetFloorPlan({
      photoSpheres,
    }));
    dispatch(Save());
  }

  const handleUpdatePhotosphere = (photoSphere: Photosphere) => {
    const photoSpheres = [...fixturePhotospheres];
    const newPhotospheres = photoSpheres.filter(returnableSphere => { return returnableSphere.id !== photoSphere.id; });
    newPhotospheres.push(photoSphere);
    dispatch(SetFloorPlan({
      photoSpheres: newPhotospheres,
    }));
    dispatch(Save());
  }


  const setFullScreen = () => {
    props.setFullScreen();
  }

  const handleInitPhotosphere = () => {
    dispatch(SetCameraType(CameraType.Orthographic));
    dispatch(SetControlsType(ControlsType.OrthographicControls));
    dispatch(FitToView());
  }

  const onMove = e => {
    setMoved(true);
  }

  const onDragPhotosphere = (e, photo) => {
    if (moved && controlsType === ControlsType.OrthographicControls) {
      const coordinates = props.handlesFromBlue.onDragCoordinates(e);
      if (coordinates) {
        coordinates.position.setY(5 * 12 * 2.54);
        const transformation = new Matrix4();
        transformation.setPosition(coordinates.position);
        const scaleMat = new Matrix4();
        const size = props.handlesFromBlue.getCameraFar();
        scaleMat.makeScale(-size, size, size);
        transformation.multiply(scaleMat);
        photo.transformation = transformation.toArray();
        handleUpdatePhotosphere(photo);
        setMoved(false);
        onPhotosphere(photo);
      }
    }
  }

  const onPhotosphere = (photo: Photosphere) => {
    if (!photo) return;
    if (!photo.transformation) {
      handleInitPhotosphere()
      return;
    }
    if (autoRotate) dispatch(SetAutoRotate(false));
    const transformation = new Matrix4().fromArray(photo.transformation);
    const position = new Vector3();
    const quaternion = new Quaternion();
    const scale = new Vector3();
    transformation.decompose(position, quaternion, scale);

    dispatch(SetPhotosphere(photo, props.edit))

    if (photo.direction) {
      const direction = new Vector3().fromArray(photo.direction);
      dispatch(SetCameraType(CameraType.FPVCamera));
      dispatch(SetControlsType(ControlsType.Photosphere, position, direction));
    } else {
      dispatch(SetCameraType(CameraType.FPVCamera));
      dispatch(SetControlsType(ControlsType.Photosphere, position));
    }
    setSelectedPhotosphereID(photo.id);
  }

  const findIndexById = (id: string) => (photosphere: Photosphere) => {
    return photosphere.id === id;
  }

  const saveCurrentPhotosphereMesh = () => {
    if (props.edit) {
      const sphere = props.handlesFromBlue.getPhotosphere();
      if (sphere.id) {
        handleUpdatePhotosphere(sphere);
        dispatch(UpdatePhotosphereSetup(PhotosphereSetup.Home));
      }
    }
  }

  const onDelete = (sphere: Photosphere) => {
    const photoSpheres = [...fixturePhotospheres];
    const newFixturePhotospheres = photoSpheres.filter(returnableSphere => {
      return returnableSphere.id !== sphere.id;
    });
    dispatch(UpdatePhotosphereSetup(PhotosphereSetup.Home))
    dispatch(SetFloorPlan({photoSpheres: newFixturePhotospheres}))
    dispatch(Save());
    handleInitPhotosphere();
  }

  const saveSelectedPhotosphere = () => {
    const photosphere = fixturePhotospheres.find((photosphere: Photosphere) => photosphere.id === selectedPhotosphereID);

    if (!photosphere || !currentFixturePlan) {
      localStorage.removeItem(storageName);
      return;
    }

    localStorage.setItem(storageName, JSON.stringify({
      currentFixturePlanId: currentFixturePlan.id,
      selectedPhotosphereId: photosphere.id,
    }));
  }

  const saveName = (sphere: Photosphere) => {
    const photoSpheres = fixturePhotospheres.map((photosphsere: Photosphere) => {
      if (photosphsere.id === sphere.id) {
        return {
          ...photosphsere,
          name: newName,
        }
      } else {
        return photosphsere;
      }
    });
    dispatch(SetFloorPlan({photoSpheres}))
    dispatch(Save());
  }

  useEffect(() => {
    props.handlesFromBlue.drawPhotosphereLocations(fixturePhotospheres);
    dispatch(AutoRotatePhotosphereCamera(false));
    return (() => {
      saveSelectedPhotosphere();
      props.handlesFromBlue.drawPhotosphereLocations([]);
    })
  }, [])

  useEffect(() => {
    const fixturePhotospheres = currentFixturePlan?.photoSpheres ?? [];
    setFixturePhotospheres(fixturePhotospheres);
  }, [
   currentFixturePlan
  ])

  useEffect(() => {
    props.handlesFromBlue.drawPhotosphereLocations(fixturePhotospheres);
  }, [
   fixturePhotospheres
  ])


  useEffect(() => {
    switch (photoSphereSetup) {
      case PhotosphereSetup.SaveSetup:
        saveCurrentPhotosphereMesh();
        break;
      case PhotosphereSetup.Cancel:
        setFixturePhotospheres(currentFixturePlan?.photoSpheres ?? []);
        dispatch(UpdatePhotosphereSetup(PhotosphereSetup.Home));
        const selectedPhotosphere = fixturePhotospheres.find((photoSphere: Photosphere) => photoSphere.id === selectedPhotosphereID);
        onPhotosphere(selectedPhotosphere);
        break;

      default:
        break;
    }
  }, [
   photoSphereSetup
  ])

  const { edit } = props;

  const photoSpherelistItems = fixturePhotospheres
    .sort((a, b) => a?.name > b?.name ? 1 : -1)
    .map((sphere: Photosphere, index) =>
      <ListItem
        disabled={selectedPhotosphereID !== sphere.id && photoSphereSetup !== PhotosphereSetup.Home && photoSphereSetup !== PhotosphereSetup.View}
        classes={{ selected: classes.selected }}
        key={sphere.id}
        selected={selectedPhotosphereID === sphere.id}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          className={classes.listItem}>
          {edit &&
            <div
              className={classes.photosphereImageContainer}
              draggable={edit}
              onDragEnd={(e: any) => onDragPhotosphere(e, sphere)}
              onTouchMove={(e: any) => onMove(e)}
              onMouseMove={(e: any) => onMove(e)}
              onTouchEnd={(e: any) => onDragPhotosphere(e, sphere)}>
              <div
                onClick={e => { if (photoSphereSetup === PhotosphereSetup.Home || photoSphereSetup === PhotosphereSetup.View) onPhotosphere(sphere); }}
                key={sphere.imagePath}
                className={classes.photosphereImageContainer}>
                <PhotosphereViewer
                  key={sphere.id}
                  photosphere={sphere}
                  updatePhotosphere={(e) => {handleUpdatePhotosphere(e)}}
                ></PhotosphereViewer>
              </div>

            </div>
          }
          {!edit &&
            <div
              onClick={e => { if (photoSphereSetup === PhotosphereSetup.Home || photoSphereSetup === PhotosphereSetup.View) onPhotosphere(sphere); }}
              key={sphere.imagePath}
              className={classes.photosphereImageContainer}>
              <img className={classes.image} src={sphere.thumbnailPath ? `${host}${sphere.thumbnailPath}` : `${host}${sphere.imagePath}`} alt={"Setup Photosphere"} />
              <Typography className={classes.photosphereName} align="center"> {sphere.name} </Typography>
            </div>
          }


          {edit && sphere.transformation &&
            <div className={classes.photosphereTitleContainer} >
              {sphere.id === selectedPhotosphereID ?
                <>
                  { editName ?
                  <>
                    <Tooltip title="Save Name">
                      <IconButton
                        key={`edit-${index}`}
                        onClick={() => {
                          setEditName(false);
                          saveName(sphere);
                          setNewName('');
                        }}
                        className={classes.iconButton}>
                        <SaveIcon fontSize="inherit"/>
                      </IconButton>
                    </Tooltip>
                    <Input
                      placeholder="Edit Name"
                      id="adornment-password"
                      defaultValue={sphere.name}
                      onChange={event => { setNewName(event.target.value); }}
                    />
                    <Tooltip title="Cancel">
                      <IconButton
                        key={`edit-${index}`}
                        onClick={() => {
                          setEditName(false);
                          setNewName('');
                        }}
                        className={classes.iconButton}>
                        <Cancel fontSize="inherit"/>
                      </IconButton>
                    </Tooltip>
                  </>
                  :
                  <>
                    <Tooltip title="Edit Name">
                      <IconButton
                        key={`edit-${index}`}
                        onClick={() => {
                          setEditName(true);
                        }}
                        className={classes.iconButton}>
                        <Edit fontSize="inherit"/>
                      </IconButton>
                    </Tooltip>
                    <Typography className={classes.photosphereName} align="center"> {sphere.name} </Typography>
                    <UniversalModalWrapper
                      onDelete= {() => onDelete(sphere)}
                      modalHeader='Are you sure?'
                    >
                      <Tooltip title="Delete Photosphere">
                        <IconButton
                          key={`delete-${index}`}
                          className={classes.deletePhotosphereButton}
                          >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      {AreYouSureDelete('Photosphere')}
                    </UniversalModalWrapper>
                  </>
                  }
                </>
              :
                <Typography className={classes.photosphereName} align="center"> {sphere.name} </Typography>
            }
            </div>
          }

          {!sphere.transformation && edit && controlsType !== ControlsType.OrthographicControls &&
            <Button
              onClick={e => { if (photoSphereSetup === PhotosphereSetup.Home || photoSphereSetup === PhotosphereSetup.View) onPhotosphere(sphere); }}>
              Setup Photosphere
            </Button>
          }

          {!sphere.transformation && edit && controlsType === ControlsType.OrthographicControls &&
            <div className={classes.photosphereHeight} >
              <Typography variant="body2"> Drag above image to Layout </Typography>
            </div>
          }
        </Paper>
      </ListItem>
    );

  return (
    <div className={classes.root}>
      <div className={classes.panelUpper}>
        <div className={classes.titleContainer}>
          {
            props.setFullScreen &&
            (
              <Tooltip title="Hide Panel">
                <Button className={classes.hidePanelButton}>
                  <ArrowRight onClick={setFullScreen} fontSize={'large'} />
                </Button>
              </Tooltip>
            )
          }
          <Typography className={classes.title}>
            Photospheres
          </Typography>
          <div className={classes.cancelButton} />
        </div>
        {fixturePhotospheres.length === 0 &&
          <Typography className={classes.photosphereName}>No Photospheres </Typography>
        }
      </div>
      <div className={classes.panelLower}>
        <List className={classes.listItemContainer}>
          {photoSpherelistItems}
        </List>
      </div>
      <div className={classes.groupContainer}>
        {edit &&
          <ModalConsumer>
            {({ showModal, props }) =>
              <Tooltip title="Set FloorPlan Image">
                <Button
                  className={classes.button}
                  onClick={() => showModal(
                    LoadImageModal,
                    { ...props,
                      imageLabel: 'Photosphere',
                      handleSetImage: handleCreatePhotosphere,
                      setName: true,
                    })}
                  variant="outlined"
                  classes={{
                    root: classes.button,
                  }}>
                  Add Photosphere
                </Button>
              </Tooltip>
            }
          </ModalConsumer>

        }
      </div>
    </div>
  );
}

export default PhotospherePanel;
