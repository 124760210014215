import { Blueprint3d } from './blueprint3d';
import { store } from '../index';
import { InitDesigner, DisposeDesigner, ToastMessage, SetSelectedItems } from '../reducers/blue';
import { HandlesFromBlue } from '../components/Blue/models';
import { Asset, Labels } from './items/asset';
import { Attendee, PlacezFixturePlan } from '../api';
import { Room } from './model/room';
import { Item } from './items/item';
import { Wall } from './model/wall';
import { Photosphere } from '../components/Blue/models/Photosphere';
import { Utils } from './core/utils';
import { ArrowDirection, HorizAlignModifiers, VertAlignModifiers } from './three/controller';
import { WallItem } from './items/wall_item';
import { WallFloorItem } from './items/wall_floor_item';
import { InWallFloorItem } from './items/in_wall_floor_item';
import { Theme } from '@material-ui/core';
import { CeilingItem } from './items/ceiling_item';
import { AssetGroup } from './items';
import { Vector3, Quaternion, Vector } from 'three';
import { getInventory } from './model/scene'

export class BlueJS {
  public dispose;
  public setTheme;

  constructor(
    containerElement: any,
    bluejsCallbacks: HandlesFromBlue,
  ) {
    const unsubscribeStore = store.subscribe(() => {
    // On redux dispatch
    });

    this.dispose = function () {
      unsubscribeStore();
      blueprint3d.dispose();
      store.dispatch(DisposeDesigner());
    };

    const viewerDiv =  containerElement.querySelector('#viewer');
    const floorplanDiv =  containerElement.querySelector('#floorplan');

    const opts = {
      floorplannerElement: floorplanDiv,
      threeElement: viewerDiv,
    };

    const blueprint3d = new Blueprint3d(opts);

    this.setTheme = (theme: Theme) => {
      blueprint3d.setTheme(theme);
    }

    store.dispatch(InitDesigner(blueprint3d)); // is this normal to pass in the object?

    bluejsCallbacks.onDragAndDrop = (e: any, asset: Asset, state: string) => {
      const intersects = blueprint3d.getMain().getController().dragAndDrop(e, asset);
      const assetCopy = JSON.parse(JSON.stringify(asset));
      if (intersects) {
        assetCopy.groupId = undefined;
        if (asset.gltf) {
          assetCopy.gltf = asset.gltf.clone();
        }
        assetCopy.fromScene = true;
        assetCopy.instanceId = Utils.guid();
        blueprint3d.getModel().scene.addAssetAsync(assetCopy, false)
          .then(() => {
            blueprint3d.getModel().scene.updateSceneStats();
            blueprint3d.getMain().getController().updateItemsList();
          });

      } else {
        store.dispatch(ToastMessage(
          'Could not position item. Try dragging to a different location.'
        ));
      }
    };

    bluejsCallbacks.onDragAndDropAssetGroup = (e: any, assetGroup: AssetGroup, state: string) => {
      if (state === 'dragging') {
        blueprint3d.getMain().getController().drawAttendeeGroupFootprints(e, assetGroup);
      } else if (state === 'stop') {
        blueprint3d.getMain().getController().pasteItems(assetGroup);
      }
    };

    bluejsCallbacks.onDragAttendee = (attendee: Attendee) => {
      blueprint3d.getMain().getController().dropAttendee(attendee);
    };

    bluejsCallbacks.onStreetView = (e: any) => {
      blueprint3d.getMain().streetView(e);
    };

    bluejsCallbacks.onDragCoordinates = (e: any): {normal: Vector3, position: Vector3} => {
      const controller = blueprint3d.getMain().getController();
      controller.setMouseFromEvent(e);
      return controller.dragAndDropCoordinates(72 * 2.54);
    };

    bluejsCallbacks.onLoadFloorplanImg = (src: any) => {
      blueprint3d.getFloorPlan().setImage(src);
    };

    bluejsCallbacks.getFloorPlan = (): PlacezFixturePlan => {
      return blueprint3d.getModel().exportFloorPlan();
    };

    bluejsCallbacks.onScreenshot = (download?: boolean) => {
      return blueprint3d.getMain().screenCapture(download);
    };

    bluejsCallbacks.onRotation = e => {
      blueprint3d.getMain().controls.autoRotate = e;
    };

    bluejsCallbacks.onExport = (callback: (blob: Blob) => void) => {
      blueprint3d.getModel().scene.exportEvent('gltf', callback);
    };

    bluejsCallbacks.setHideWalls = (hide: boolean): void => {
      blueprint3d.getFloorPlan().floorplan.setHideWalls(hide);
    };

    bluejsCallbacks.deleteFloorplan = (clearImages?: boolean) => {
      blueprint3d.getFloorPlan().deleteAndReset(clearImages);
    };

    bluejsCallbacks.deleteFloorplanImage = () => {
      blueprint3d.getFloorPlan().deleteFloorplanImage();
    };

    bluejsCallbacks.setWallHeight = (height: number) => {
      blueprint3d.getFloorPlan().floorplan.setWallHeight(height);
    };

    bluejsCallbacks.getWallHeight = () => {
      return blueprint3d.getFloorPlan().floorplan.getWallHeight();
    };

    bluejsCallbacks.removeItems = (items: Item[]) => {
      blueprint3d.getMain().getController().deleteSelectedItems(items, true);
    };

    bluejsCallbacks.addModeChangeHandle =  h => {
      blueprint3d.getFloorPlan().modeChangedCallbacks.add(h);
    };

    bluejsCallbacks.removeModeChangeHandle =  h => {
      blueprint3d.getFloorPlan().modeChangedCallbacks.remove(h);
    };

    bluejsCallbacks.getInventory = () => {
      return getInventory(blueprint3d.getModel().scene.getItems());
    };

    bluejsCallbacks.setAllWalls = material => {
      blueprint3d.getModel().floorplan.getWalls().forEach((wall: Wall) => {
        if (wall.frontEdge) {
          wall.frontEdge.setMaterial(material);
        }
        if (wall.backEdge) {
          wall.backEdge.setMaterial(material);
        }
      });
    };

    bluejsCallbacks.setAllFloors = material => {
      blueprint3d.getModel().floorplan.getRooms().forEach((room: Room) => {
        room.setMaterial(material);
      });
    };

    bluejsCallbacks.setRoom = (room: Room, material) => {
      room.getEdges().forEach(element => {
        element.setMaterial(material);
      });
    };

    bluejsCallbacks.copyCommonAsset = (asset: Asset) => {
      blueprint3d.getMain().getController().copyCommonAsset(asset);
    };

    bluejsCallbacks.setItemHeight = (selectedList: Item[], height: number) => {
      selectedList
      .filter((item: Item) => {
        return (item instanceof WallItem && !(item instanceof WallFloorItem) && !(item instanceof InWallFloorItem)) || item instanceof CeilingItem;
      })
      .forEach((item: Item, index: number) => {
        if (index !== 0) return;
        item.setHeight(height);
        item.updateItem();
      });
    };

    bluejsCallbacks.setLabelSize = (selectedList: Item[], size: number) => {
      selectedList
      .forEach((item: Item, index: number) => {
        item.asset.extensionProperties.fontSize = size;
        item.buildLabel();
        blueprint3d.getMain().needsUpdate();
      });
    };

    bluejsCallbacks.setItemLabel = (selectedList: Item[], label: keyof Labels, value: string) => {
      selectedList.forEach((item: Item) => {
        if (item.asset.labels) {
          item.asset.labels[label] = value;
        } else {
          item.asset.labels = { titleLabel: '' };
          item.asset.labels[label] = value;
        }
        item.updateItem();
      });
      store.dispatch(SetSelectedItems([...selectedList]));
    };

    bluejsCallbacks.setAssetProp = (selectedList: Item[], assetProperty, value) => {
      selectedList.forEach((item: Item) => {
        if (Object.keys(item.asset).includes(assetProperty)) {
          item.asset[assetProperty] = value;
        } else {
          item.asset.extensionProperties[assetProperty] = value;
          if (assetProperty === 'excludeFromChairCount') {
            blueprint3d.getModel().scene.updateSceneStats();
          }
        }
        item.updateItem();
        item.update();
      });
      store.dispatch(SetSelectedItems([...selectedList]));
    };

    bluejsCallbacks.setExtensionProp = (selectedList: Item[], extensionProperty, value) => {
      selectedList.forEach((item: Item) => {
        item.asset.extensionProperties[extensionProperty] = value;
        item.updateItem();
      });
      store.dispatch(SetSelectedItems([...selectedList]));
    };

    bluejsCallbacks.getCameraFar = (): number => {
      const fpSize = blueprint3d.getFloorPlan().floorplan.getSpecs().diagonal * 2;
      return fpSize;
    };

    bluejsCallbacks.getPhotosphere = (): Photosphere => {
      const photosphereMesh: THREE.Mesh = blueprint3d.getMain().getPhotosphereMesh();
      const direction: THREE.Vector3 = blueprint3d.getMain().getPhotosphereDirection();
      const photosphere: Photosphere = (photosphereMesh.userData as Photosphere);
      photosphere.transformation = photosphereMesh.matrix.toArray();
      photosphere.direction = direction.toArray();
      return photosphere;
    };

    bluejsCallbacks.drawPhotosphereLocations = (photos: Photosphere[]) => {
      blueprint3d.getMain().buildPhotosphereLocations(photos);
    };

    bluejsCallbacks.copyItems = () => {
      blueprint3d.getMain().getController().copySelected();
    };

    bluejsCallbacks.alignHorizontal = (modifier: HorizAlignModifiers) => {
      blueprint3d.getMain().getController().alignHorizontal(modifier);
    };

    bluejsCallbacks.alignVertical = (modifier: VertAlignModifiers) => {
      blueprint3d.getMain().getController().alignVertical(modifier);
    };

    bluejsCallbacks.updateItemsList = (itemIDs?: Item[]) => {
      blueprint3d.getMain().getController().updateItemsList(itemIDs);
    };

    bluejsCallbacks.fixItems = (selectedList: Item[], fixed: boolean) => {
      selectedList.forEach((item: Item) => {
        item.asset.extensionProperties.fixed = fixed;
      });
      store.dispatch(SetSelectedItems([...selectedList]));
    };

    bluejsCallbacks.rotateControlsTo = (degreeAngle: number) => {
      const newAngle = isNaN(degreeAngle) ? 0 : degreeAngle;
      blueprint3d.getMain().controls.setCameraRotation(Utils.convertToRadians(-newAngle % 360));
    };

    bluejsCallbacks.rotateItemTo = (selectedList: Item[], radianAngle: number) => {
      const newAngle = isNaN(radianAngle) ? 0 : radianAngle;
      const rotateToQuat = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), newAngle);
      selectedList.forEach((item: Item) => {
        item.rotateTo(rotateToQuat);
      });
      blueprint3d.getMain().needsUpdate();
    };

    bluejsCallbacks.rotateItemBy = (selectedList: Item[], radianAngle: number) => {
      const newAngle = isNaN(radianAngle) ? 0 : radianAngle;
      const rotateByQuat = new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), newAngle);
      const controller  = blueprint3d.getMain().getController();
      controller.rotateItemBy(rotateByQuat);
    };

    bluejsCallbacks.movePointerLockCamera = (direction: ArrowDirection) => {
      blueprint3d.getMain().firstPersonControls.onMove(direction);
    };

    bluejsCallbacks.rotatePointerLockCamera = (direction: ArrowDirection) => {
      blueprint3d.getMain().firstPersonControls.onRotate(direction);
    };

  }
}
