import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Theme,
  Typography,
  FormLabel,
  Button,
  Tooltip,
  Slider,
  Switch,
  TextField,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useTheme,
} from '@material-ui/core';

import {
  Settings,
  ClearOutlined,
  BorderHorizontal,
  BorderBottom,
  BorderTop,
  BorderRight,
  BorderVertical,
  BorderLeft,
  Lock,
  Favorite,
  LockOpen,
  ViewList,
  Edit,
  RotateLeft,
  RotateRight,
  Highlight,
} from '@material-ui/icons';

import { PlannerInput } from '../inputs/PlannerInput';

import {
  HandlesFromBlue,
} from '../../models';

import { Asset, AssetModifierKeys, Labels, SkuType } from '../../../../blue/items/asset';
import { Item, isFloorItem } from '../../../../blue/items/item';
import TableConfigModal from '../../../Modals/TableConfigModal';
import panelStyles from './panels.css';
import { ReduxState } from '../../../../reducers';
import { HorizAlignModifiers, VertAlignModifiers } from '../../../../blue/three/controller';
import { ValidUnits, getUserSettingValue } from '../../../../api/placez/models/UserSetting';
import { Utils } from '../../../../blue/core/utils';
import { SaveAssetGroupAction, SaveCustomAsset } from '../../../../reducers/asset';
import { WallItem } from '../../../../blue/items/wall_item';
import { WallFloorItem } from '../../../../blue/items/wall_floor_item';
import { InWallFloorItem } from '../../../../blue/items/in_wall_floor_item';
import Sku from '../../../../api/dam/models/Sku';
import { createSelector } from 'reselect';
import { CeilingItem } from '../../../../blue/items/ceiling_item';
import { InitBatchItem, NeedSaveAction, SetActiveController, SetSelectedItems } from '../../../../reducers/blue';
import TabPanel from '../../../Modals/TableConfigModal/TabPanel';
import { makeStyles } from '@material-ui/styles';
import { ChangeToolState } from '../../../../reducers/globalState';
import { GlobalViewState, LayoutViewState, ToolState } from '../../../../models/GlobalState';
import EditUplightMod from './EditUplightMod'
import { LocalStorageKey, useLocalStorageSelector } from '../../../Hooks/useLocalStorageState'
import NumberEditor from '../../../NumberEditor'
import { getInventory } from '../../../../blue/model/scene'
import EditSize from '../utility/EditSize'

interface Props {
  handlesFromBlue: HandlesFromBlue;
  onBack: Function;
}

enum TabOptions {
  itemInfo = 'itemInfo',
  selectedItems = 'selectedItems',
  editUplightMod = 'editUplightMod',
}

const EditItemPanel = (props: Props) => {
  const theme: Theme = useTheme();
  const styles = makeStyles<Theme>(panelStyles);
  const dispatch = useDispatch();

  const [itemSelected, setItemSelected] = useState<Item>(undefined);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<TabOptions>(TabOptions.itemInfo);
  const [groupLabel, setGroupLabel] = useState<string>('');
  const [rotation, setRotation] = useState<number>(0);
  const [socialDistanceLocked, setSocialDistanceLocked] = useState<boolean>(false);

  const selectedItems = useSelector((state: ReduxState) => state.blue.selectedItems);
  const units = useLocalStorageSelector<ValidUnits>(LocalStorageKey.Units);
  const userProfile = useSelector((state: ReduxState) => state.oidc.user.profile);
  const customSkus = useSelector((state: ReduxState) => state.asset.customSkus);
  const globalViewState: GlobalViewState = useSelector((state: ReduxState) => state.globalstate.globalViewState);
  const assetsBySku = useSelector((state: ReduxState) => state.asset.bySku);

  const [previousSelectedAmount, setPreviousSelectedAmount] = useState<number>(0);
  const [inventoryList, setInventoryList] = useState<{description: string; quantity: number}[]>([]);


  useEffect(() => {
    if (selectedItems.length > 1 && selectedItems.length !== previousSelectedAmount) {
      setTabIndex(TabOptions.selectedItems)
      const inventory = getInventory(selectedItems)
        .map((item) => {
          return {
            description: assetsBySku[item.assetId]?.name,
            quantity: item.count,
          }
        })
      setInventoryList(inventory);
    }
    setPreviousSelectedAmount(selectedItems.length)
  }, [selectedItems])


  let rotationSubscription = undefined;

  useEffect(() => {
    return () => {
      rotationSubscription?.unsubscribe();
    }
  }, [])

  const selectItem = () => {
    const list: Item[] = selectedItems;
    const obj = list[0];
    if (rotationSubscription) {
      rotationSubscription.unsubscribe();
    }

    if (obj) {
      rotationSubscription = obj.events.rotation
        .subscribe(
          (rotation: THREE.Euler) => {
            setRotation(Utils.convertToDegrees(rotation.y))
          }
        );

      setItemSelected(obj);
      setRotation(Utils.convertToDegrees(obj.getRotation()));
    }
  }


  const setSpacing = (e, value) => {
    if (itemSelected) {
      props.handlesFromBlue.setAssetProp(selectedItems, 'spacing', Utils.convertToCM(parseFloat(value)));
    }
    dispatch(NeedSaveAction(true))
  }

  const setCovidSpacing = (e?) => {
    const distance = Utils.convertUnitsFromTo(36, 'in', units);
    if (itemSelected) {
      props.handlesFromBlue.setAssetProp(selectedItems, 'spacing', Utils.convertToCM(distance));
    }
    dispatch(NeedSaveAction(true))
  }

  const rotate = (angle?: number) => e => {
    if (itemSelected) {
      props.handlesFromBlue.rotateItemBy(selectedItems, angle);
    }
    const returnedRotation = Utils.convertToDegrees(itemSelected.getRotation());
    setRotation(itemSelected ? returnedRotation : 0);
    dispatch(NeedSaveAction(true));
    (window as any).gtag('event', 'edit_item', 'rotate_icon');
  }

  const setRotationInput = e => {
    const input = parseInt(e.target.value, 10);
    const newAngle = isNaN(input) ? 0 : input;
    const rotation = -Utils.convertToRadians(newAngle);

    if (itemSelected) {
      props.handlesFromBlue.rotateItemTo(selectedItems, rotation);
    }
    const returnedRotation = Utils.convertToDegrees(itemSelected.getRotation());

    setRotation(itemSelected ? returnedRotation : 0);
    dispatch(NeedSaveAction(true));
    (window as any).gtag('event', 'edit_item', 'rotate_input');
  }

  const setLabelSize = (e, value) => {
    if (itemSelected) {
      props.handlesFromBlue.setExtensionProp(selectedItems, 'fontSize', value || 32);
    }
    dispatch(NeedSaveAction(true))
  }

  const setSpacingLocked = (e) => {
    if (itemSelected) {
      setCovidSpacing();
      props.handlesFromBlue.setExtensionProp(selectedItems, 'spacingLocked', e.target.checked);
    }
    dispatch(NeedSaveAction(true))
  }

  const setHeight = (height?: number) => {
    if (itemSelected) {
      props.handlesFromBlue.setItemHeight(selectedItems, height);
    }
    dispatch(NeedSaveAction(true))
  }

  const setLabel = (labelType: keyof Labels) => e => {
    if (itemSelected) {
      props.handlesFromBlue.setItemLabel(selectedItems, labelType, e.target.value);
    }
    dispatch(NeedSaveAction(true))
  }


  const setStringProp = (assetProperty: keyof Asset) => e => {
    if (itemSelected) {
      props.handlesFromBlue.setAssetProp(selectedItems, assetProperty, e.target.value.slice(0, 20));
    }
    dispatch(NeedSaveAction(true))
  }


  useEffect(() => {
    if (selectedItems?.length > 0) {
      setGroupLabel('');
      selectItem();
    } else {
      if (rotationSubscription) {
        rotationSubscription.unsubscribe();
      }
      setItemSelected(undefined);
    }
  }, [
    selectedItems,
  ])

  const deleteSelected = () => {
    props.handlesFromBlue.removeItems(selectedItems);
  }

  const alignVert = (modifier: VertAlignModifiers) => () => {
    (window as any).gtag('event', 'edit_item', 'align');
    props.handlesFromBlue.alignVertical(modifier);
    dispatch(NeedSaveAction(true))
    selectedItems.forEach((item: Item) => {
      item.updateItem();
    });
  }

  const alignHoriz = (modifier: HorizAlignModifiers) => () => {
    (window as any).gtag('event', 'edit_item', 'align');
    props.handlesFromBlue.alignHorizontal(modifier);
    dispatch(NeedSaveAction(true))
    selectedItems.forEach((item: Item) => {
      item.updateItem();
    });
  }

  const copySelected = () => {
    (window as any).gtag('event', 'edit_item', 'copy_button');
    props.handlesFromBlue.copyItems();
  }

  const onCustomize = (item: Item) => {
    item.update();
    setShowSettings(true);
  }

  const onConfigureCancel = () => {

    setShowSettings(false);
  }

  const cancelEdit = () => {
    selectedItems.forEach((item: Item) => {
      item.setUnselected();
    })
    dispatch(SetSelectedItems([]));
  }

  const onTableConfigured = (configuredItem: Asset) => {
    setShowSettings(false);
    props.handlesFromBlue.copyCommonAsset(configuredItem);
    dispatch(InitBatchItem(selectedItems[0].asset));
  }


  const handleDragAsset = (event: any, dragType: string) => {
    const asset = itemSelected.asset as Asset;
    props.handlesFromBlue.onDragAndDrop(event, asset, dragType);
    (window as any).gtag('event', 'edit_item', 'drag_copy');
  }

  const handleItemSelect = (event: any, instanceId: string) => {
    // sort item to top of selected list
  }

  const setBoolProp = assetProperty => e => {
    if (itemSelected) {
      props.handlesFromBlue.setAssetProp(selectedItems, assetProperty, e.target.checked);
    }
    dispatch(NeedSaveAction(true))
  }

  const onFavoriteAsset = () => {

    selectedItems[0].asset.name = selectedItems[0].asset.labels?.titleLabel ? selectedItems[0].asset.labels.titleLabel : selectedItems[0].asset.name;

    // TODO put save as in Utils
    if (selectedItems[0].asset.modifiers !== undefined && selectedItems[0].asset.extensionProperties.progenitorId === undefined) {
      const progenitorId = selectedItems[0].asset.id;
      selectedItems[0].asset.id = Utils.guid(); // is wrong should not edit directly
      selectedItems[0].asset.extensionProperties = {
        ...selectedItems[0].asset.extensionProperties,
        progenitorId,
      };
      dispatch(SaveCustomAsset({
        ...selectedItems[0].asset,
      }));
    } else {
      dispatch(SaveCustomAsset(selectedItems[0].asset));
    }
  }

  const onFixAsset = (fixed: boolean) => {
    props.handlesFromBlue.fixItems(selectedItems, fixed);
    dispatch(NeedSaveAction(true))
  }

  const setItemSize = (width, depth, height) => {
    selectedItems.forEach((item: Item) => {
      if (item.asset.id === itemSelected.asset.id) {
        item.resize(
          height,
          width,
          depth
        );
      }
    });
    dispatch(NeedSaveAction(true))
  }

  const classes = styles(props);

  return (
    <div className={classes.root}
      style={{ display: 'flex'}}>
      {itemSelected &&
        <div className={classes.panel}>
          <div className={classes.mainHeadingContainer}>
            <div className={classes.cancelButton} />
            <Typography className={classes.title}>
              {'Edit Item '}
            </Typography>
            <Tooltip title="Cancel Item Configuration">
              <ClearOutlined color="primary" onClick={cancelEdit} className={classes.cancelButton} />
            </Tooltip>
          </div>
          {(selectedItems.length > 1 || (selectedItems[0]?.asset?.modifiers?.uplightMod)) &&
            <Tabs
              className={classes.tabs}
              value={tabIndex}
              onChange={(e, v) => setTabIndex(v)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="primary"
              aria-label="Table Configuration"
            >
              <Tab
                className={classes.tabIcon}
                color="secondary"
                value={TabOptions.itemInfo}
                icon={<Edit />} />
              {selectedItems.length > 1 &&
              <Tab
                className={classes.tabIcon}
                value={TabOptions.selectedItems}
                icon={<ViewList />} />
              }
              {(selectedItems[0]?.asset?.modifiers?.uplightMod) &&
                <Tab
                  className={classes.tabIcon}
                  value={TabOptions.editUplightMod}
                  icon={<Highlight />} />
              }
            </Tabs>
          }
          <TabPanel
            value={tabIndex}
            index={TabOptions.itemInfo}
            className={classes.tabPanel}
          >
            <div
              className={classes.itemImage}
              draggable={true}
              onDragEnd={(e: any) => handleDragAsset(e, 'stop')}
              onTouchEnd={(e: any) => handleDragAsset(e, 'stop')}
              style={{
                backgroundImage: `url(${window.env['REACT_APP_DAM']}/${itemSelected.asset.previewPath}), radial-gradient(#f5f5f5, #909090)`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                style={{
                  flex: 1,
                  flexDirection: 'column',
                  margin: '10px',
                }}>
                {SkuType[itemSelected.asset.skuType] === SkuType.TBL &&
                  <Settings
                    className={classes.itemSettings}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => onCustomize(itemSelected)}
                  />
                }
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: '10px',
                }}
              >
                <Tooltip title="Save as Custom">
                  <Favorite
                    className={classes.itemSettings}
                    style={{
                      color: customSkus.find((sku: Sku) => sku.sku === (itemSelected.asset ? itemSelected.asset.id : 'notFound')) ? theme.palette.primary.main : '',
                    }}
                    onClick={onFavoriteAsset}
                  />
                </Tooltip>
                {(itemSelected.asset.extensionProperties?.fixed) &&
                  <Tooltip title="Locked">
                    <Lock
                      className={classes.itemSettings}
                      onClick={() => { onFixAsset(false); }}
                      style={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  </Tooltip>
                }
                {(!itemSelected.asset.extensionProperties || !itemSelected.asset.extensionProperties.fixed) &&
                  <Tooltip title="Unlocked">
                    <LockOpen
                      className={classes.itemSettings}
                      onClick={() => { onFixAsset(true); }}
                    />
                  </Tooltip>
                }
              </div>
            </div>

            <div className={classes.panelUpper}>
              <div className={classes.headingContainer}>
                <Typography className={classes.heading} align="center">
                  Details
                </Typography>
              </div>
              {isFloorItem(itemSelected.asset.classType) &&
              <div className={classes.fieldContainer}>
                <Tooltip title="Rotate Clockwise">
                  <IconButton
                    onClick={rotate(-Math.PI / 8)}>
                    <RotateRight />
                  </IconButton>
                </Tooltip>
                <form
                  className={classes.rotationInput}
                  noValidate
                  autoComplete="off">
                  <TextField
                    onKeyPress={e => { if (e.key === 'Enter') { e.stopPropagation(); e.preventDefault(); } }}
                    onChange={setRotationInput}
                    helperText="Item Rotation"
                    inputProps={{
                      style: { textAlign: 'center' },
                      maxLength: 3,
                    }}
                    FormHelperTextProps={{
                      style: {
                        textAlign: 'center',
                      },
                    }}
                    value={rotation === 0 ? 0 : 360 - rotation} />
                </form>
                <Tooltip title="Rotate Counterclockwise">
                  <IconButton
                    onClick={rotate(Math.PI / 8)}>
                    <RotateLeft />
                  </IconButton>
                </Tooltip>
              </div>
              }
              <div className={classes.fieldColumns}>
                <FormLabel className={classes.fieldHeading}>
                  Label
                  <Tooltip title="Hide / Show">
                    <Switch
                      size="small"
                      checked={itemSelected.asset.showLabel}
                      onChange={setBoolProp('showLabel')}
                      value={'test'}
                    />
                  </Tooltip>
                </FormLabel>
                <PlannerInput
                  className={classes.labelInput}
                  name="label"
                  type="text"
                  onChange={setLabel('titleLabel')}
                  value={itemSelected.asset.labels.titleLabel}
                  onKeyDown={e => e.stopPropagation()}
                  multiline
                />
              </div>
              <div className={classes.fieldColumns}>
                <FormLabel className={classes.fieldHeading}>
                  Info
                </FormLabel>
                <PlannerInput
                  className={classes.labelInput}
                  name="info"
                  type="text"
                  multiline
                  onChange={setLabel('numberLabel')}
                  value={itemSelected.asset.labels.numberLabel}
                  onKeyDown={e => e.stopPropagation()}
                />
              </div>
              <div className={classes.fieldColumns}>
                <FormLabel className={classes.fieldHeading}>
                  SKU
                </FormLabel>
                <PlannerInput
                  className={classes.labelInput}
                  name="sku"
                  type="text"
                  onChange={setStringProp('vendorSku')}
                  value={itemSelected.asset.vendorSku}
                  onKeyDown={e => e.stopPropagation()}
                />
              </div>
              {selectedItems.every((item: Item) => { return item instanceof WallItem && !(item instanceof WallFloorItem) && !(item instanceof InWallFloorItem) || item instanceof CeilingItem; }) &&
                <div
                  className={classes.modGroup}>
                  <div className={classes.headingContainer}>
                    <Typography className={classes.heading} align="center">
                      Edit Position
                    </Typography>
                  </div>
                  <div className={classes.fieldContainer}>
                    <FormLabel className={classes.fieldHeading
                    }>
                      Height ({units})
                    </FormLabel>
                    <NumberEditor
                      value={itemSelected.position.y}
                      onChange={value => { setHeight(value); }}
                      step={1}
                      round={1}
                      dark
                      allowZero
                    />
                  </div>
                </div>
              }
              {itemSelected.asset.resizable &&
                <EditSize
                  itemSelected={itemSelected}
                  onChange={setItemSize}
                  />
              }
              <div className={classes.fieldColumns}>
                <FormLabel className={classes.fieldHeading}>
                  Label Size
                </FormLabel>
                <div className={classes.sliderDiv}>
                  <Slider
                    className={classes.spacingSlider}
                    value={itemSelected.asset.extensionProperties?.fontSize ? itemSelected.asset.extensionProperties.fontSize : 32}
                    step={1}
                    min={0}
                    max={80}
                    valueLabelDisplay="auto"
                    onChange={setLabelSize}
                    color="secondary"
                  />
                </div>
              </div>
              {(
                SkuType[itemSelected.asset.skuType] === SkuType.CHR ||
                SkuType[itemSelected.asset.skuType] === SkuType.TBL
              ) &&
                <div className={classes.fieldContainer}>
                  <FormLabel className={classes.fieldHeading}>
                    Exclude from Count
                    <Switch
                      size="small"
                      checked={itemSelected.asset.extensionProperties.excludeFromChairCount}
                      onChange={setBoolProp('excludeFromChairCount')}
                      value={'test'}
                    />
                  </FormLabel>
                </div>
              }
            {/* <div className={classes.fieldContainer}>
                <Button
                  onClick={setCovidSpacing}
                  className={classes.button}
                  variant="outlined"
                  classes={{
                    root: classes.button,
                  }}>
                  Set Covid Spacing
                </Button>
              </div> */}
              <div className={classes.fieldColumns}>
                <FormLabel className={classes.fieldHeading}>
                  Spacing ({units})
                  <Tooltip title="Social Distance">
                    <Switch
                      size="small"
                      checked={itemSelected.asset?.extensionProperties?.spacingLocked ?? false}
                      onChange={setSpacingLocked}
                      value={'test'}
                    />
                    {/* <IconButton
                  aria-label="Set Social Distance"
                  onClick={setCovidSpacing}>
                  <SocialDistanceIcon/>
                </IconButton> */}
                  </Tooltip>
                </FormLabel>
                {!itemSelected.asset?.extensionProperties?.spacingLocked &&
                  <div className={classes.sliderDiv}>
                    <Slider
                      value={Utils.unitsOutNumber(itemSelected.asset.spacing ?? 0, undefined, [ValidUnits.ft, ValidUnits.ftIn].includes(units) ? 1 : 0)}
                      step={1}
                      min={0}
                      max={Utils.convertUnitsFromTo(2, 'm', Utils.getUnit())}
                      valueLabelDisplay="auto"
                      onChange={setSpacing}
                      color="secondary"
                      marks={[{ value: Utils.convertUnitsFromTo(36, 'in', units) }]}
                    />
                  </div>
                }
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  onClick={copySelected}
                  className={classes.button}
                  variant="outlined">
                  Copy
                </Button>
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  onClick={deleteSelected}
                  className={classes.button}
                  variant="outlined"
                  classes={{
                    root: classes.deleteButton,
                  }}>
                  Delete
                </Button>
              </div>
              {selectedItems.length > 1 &&
                <>
                  <div className={classes.headingContainer}>
                    <Typography className={classes.heading} align="center">
                      Align
                    </Typography>
                  </div>
                  <div className={classes.fieldContainer}>
                    <Tooltip title="Align Top">
                      <Button
                        onClick={alignHoriz(HorizAlignModifiers.top)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderTop />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Align Center">
                      <Button
                        onClick={alignHoriz(HorizAlignModifiers.center)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderHorizontal />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Align Bottom">
                      <Button
                        onClick={alignHoriz(HorizAlignModifiers.bottom)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderBottom />
                      </Button>
                    </Tooltip>
                  </div>
                  <div className={classes.fieldContainer}>
                    <Tooltip title="Align Left">
                      <Button
                        onClick={alignVert(VertAlignModifiers.left)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderLeft />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Align Center">
                      <Button
                        onClick={alignVert(VertAlignModifiers.center)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderVertical />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Align Right">
                      <Button
                        onClick={alignVert(VertAlignModifiers.right)}
                        className={classes.button}
                        variant="outlined"
                        classes={{
                          root: classes.deleteButton,
                        }}>
                        <BorderRight />
                      </Button>
                    </Tooltip>
                  </div>
                </>
              }
            </div>
            {selectedItems.length <= 1 && globalViewState !== GlobalViewState.Fixtures &&
              <div className={classes.panelLower}>
                  <div className={classes.buttonDiv}>
                    <Button
                      onClick={e => {
                        dispatch(InitBatchItem(selectedItems[0].asset, () =>
                          dispatch(ChangeToolState(ToolState.NewBatch))
                        ));
                      }}
                      className={classes.button}
                      variant="outlined"
                      style={{
                        flex: 1,
                      }}
                      classes={{
                        root: classes.button,
                      }}>
                      Pattern
                    </Button>
                  </div>
              </div>
            }
          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={TabOptions.selectedItems}
            className={classes.tabPanel}
          >
            <TextField
              style={{
                width: '100%',
                padding: '5px',
              }}
              label="Group Label"
              value={groupLabel}
              onChange={(e) => {setGroupLabel(e.target.value)}}
            />

            <div className={classes.buttonDiv}>
              <Button
                color="primary"
                className={classes.button}
                variant="contained"
                disabled={groupLabel === ''}
                onClick={() => dispatch(SaveAssetGroupAction(selectedItems, groupLabel))}>
                Save Group
              </Button>
            </div>
            {/* <List component="nav" aria-label="main mailbox folders">
              {selectedItems.map((item: Item) =>
                <ListItem
                  button
                  selected={selectedItemIndex === selectedItems.findIndex((slItem: Item) => slItem.asset.instanceId === item.asset.instanceId)}
                  onClick={event => handleItemSelect(event, item.asset.instanceId)}
                  key={item.asset.instanceId}
                >
                  <ListItemText primary={item.asset.labels.titleLabel} />
                </ListItem>
              )}
            </List> */}
            <div style={{display: 'grid', gridTemplateColumns: '1fr 30px', width: '260px', margin: 'none', padding: '4px'}}>
              {inventoryList.map((data, index) => (
                <Fragment key={index}>
                  <Typography variant="h6">{data.description}</Typography>
                  <Typography variant="body1" style={{textAlign: 'right'}}>{data.quantity}</Typography>
                </Fragment>
              ))}
            </div>

          </TabPanel>
          <TabPanel
            value={tabIndex}
            index={TabOptions.editUplightMod}
            className={classes.tabPanel}
          >
            <EditUplightMod
              modifier={AssetModifierKeys.uplightMod}
              params={selectedItems[0]?.asset?.modifiers}
              onModifierChange={(key, value) => props.handlesFromBlue.setAssetProp(selectedItems, 'modifiers', {...itemSelected.asset.modifiers, [key]: value})}
            />
          </TabPanel>

          <TableConfigModal
            asset={itemSelected.asset}
            tableConfigModalOpen={showSettings}
            onTableConfigured={onTableConfigured}
            onConfigureCancel={onConfigureCancel}/>
        </div>
      }
    </div>
  );
}

export default EditItemPanel;
