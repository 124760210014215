import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ReduxState } from '../../reducers';
import { useParams } from 'react-router';
import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { GetScene } from '../../reducers/scenes';
import { GetSceneLayouts } from '../../reducers/layouts';

// Components
import SceneForm from '../../components/Scenes/SceneForm';
import { Scene } from '../../api';

type Props = {};

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
  })
);

const EditScene = (props: Props) => {
  const dispatch = useDispatch();
  const classes = styles(props);
  const [scene, setScene] = useState<Scene>(null);

  const layouts = useSelector((state: ReduxState) => state.layouts.scene.unsorted);
  const currentScene = useSelector((state: ReduxState) => state.scenes.currentScene);

  const { id } = useParams();

  useEffect(() => {
    if (currentScene) {
      setScene(currentScene);
      dispatch(GetSceneLayouts(currentScene.id));
    }
  }, [currentScene])

  useEffect(() => {
    dispatch(GetScene(parseInt(id)));
  }, [id])

  return (scene &&
    <div className={classes.root}>
      {scene ? <SceneForm scene={scene} layouts={layouts} /> : <div />}
    </div>
  );
}

export default EditScene;
