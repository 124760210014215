import {
  Theme, createStyles,
} from '@material-ui/core';

const sideBarStyles = (theme: Theme) => createStyles({
  root:{
    margin: '4px 0px',
  },
  link:{
    textDecoration: 'none',
    backgroundColor: `${theme.palette.background.paper} !important`,
    color: theme.palette.text.primary,
  },
  listItem:{
    // backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'red',
    height: '64px',
    '.MuiTouchRipple-child': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  listItemText:{
    color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
  },
  activeLink: {
    display: 'flex',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    '& div': {
      borderLeft: '0px',
      background: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.white} !important`,
    },
    '& svg': {
      color: `${theme.palette.common.white} !important`,
    },
  },
  icon: {
    margin: 0,
    overflow: 'visible',
    marginRight: '7px',
    color: theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.common.white,
  },
  label: {
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
  },
});

export default sideBarStyles;
