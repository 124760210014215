import { useState, useEffect } from 'react';

import { Asset } from '../../../blue/items/asset';
import { FormLabel, makeStyles, MenuItem, Select, Switch, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import panelStyles from './panels/panels.css';
import { PlannerInput } from './inputs/PlannerInput';
import { BillingRate } from '../../../blue/core/utils';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { userIsInRole } from '../../../sharing/utils/userHelpers';


interface Props {
  asset: Asset;
  updateAsset?(asset: Asset): void;
}

const ItemInfoPanel = (props: Props) => {
  const styles = makeStyles<Theme>(panelStyles);
  const classes = styles(props);

  const [customAsset, setCustomAsset] = useState(undefined);

  useEffect(() => {
    setCustomAsset(props.asset);
  }, [])

  useEffect(() => {
    if (customAsset) props.updateAsset(customAsset);
  }, [customAsset])

  const user = useSelector((state: ReduxState) => state.oidc.user);

  const setProp = (prop: string) => e => {
    setCustomAsset({
      ...customAsset,
      [prop] : e.target.value,
    });
  }

  const onSetRate = (event: React.ChangeEvent<{ value: BillingRate}>) => {
    setCustomAsset({
      ...customAsset,
      priceRateInHours : event.target.value,
    });
  }

  const setLabel = (prop: string) => e => {
    setCustomAsset({
      ...customAsset,
      labels: {
        ...customAsset.labels,
        [prop] : e.target.value,
      },
    });
  }

  const handleToggle = (prop: string) => e => {
    setCustomAsset({
      ...customAsset,
      [prop]: e.target.checked,
    })
  }

  if (!customAsset) return <></>;
  return (
    <div
      className={classes.panel}
      >
      <div className={classes.lightThemePanel}>
        <div className={classes.headingContainerLight}>
          <Typography className={classes.lightHeading} align="center">
            Details
          </Typography>
        </div>
        <div className={classes.fieldContainer}>
          <FormLabel className={classes.fieldHeadingLight}>
            Name
          </FormLabel>
          <PlannerInput
            className={classes.labelInputLight}
            name="label"
            type="text"
            onChange={setProp('name')}
            value={customAsset.name}
          />
        </div>
        <div className={classes.fieldContainer}>
          <FormLabel className={classes.fieldHeadingLight}>
            Label
          </FormLabel>
          <PlannerInput
            className={classes.labelInputLight}
            name="label"
            type="text"
            onChange={setLabel('titleLabel')}
            value={customAsset.labels.titleLabel ?? ''}
          />
        </div>
        <div className={classes.fieldContainer}>
          <FormLabel className={classes.fieldHeadingLight}>
            Info
          </FormLabel>
          <PlannerInput
            className={classes.labelInputLight}
            name="info"
            type="text"
            onChange={setLabel('numberLabel')}
            value={customAsset.labels.numberLabel ?? ''}
          />
        </div>
        <div className={classes.fieldContainer}>
          <FormLabel className={classes.fieldHeadingLight}>
            SKU
          </FormLabel>
          <PlannerInput
            className={classes.labelInputLight}
            name="sku"
            type="text"
            onChange={setProp('vendorSku')}
            value={customAsset.vendorSku ?? ''}
          />
        </div>
        { userIsInRole(user, 'admin') &&
          <div className={classes.fieldContainer}>
            <FormLabel className={classes.fieldHeadingLight}>
              Price
            </FormLabel>
            <CurrencyTextField
              className={classes.labelInputLight}
              variant="outlined"
              defaultValue={customAsset?.price}
              outputFormat="number"
              currencySymbol="$"
              onChange={setProp('price')}
              />
          </div>
        }
        { userIsInRole(user, 'admin') &&
          <div className={classes.fieldContainer}>
            <FormLabel className={classes.fieldHeadingLight}>
              Rate Per
            </FormLabel>
            <Select
              style={{flex: 1}}
              id="placeSelect"
              value={customAsset.priceRateInHours}
              onChange={onSetRate}>
              {Object.values(BillingRate)
                .filter((v) => isNaN(Number(v)))
                .map((rate) =>
                  <MenuItem key={rate} value={BillingRate[rate]}>{rate}</MenuItem>
                )}
            </Select>
          </div>
        }
        <div className={classes.fieldContainer}>
          <FormLabel className={classes.fieldHeadingLight}>
            Show Label
          </FormLabel>
          <Switch
            size="small"
            checked={customAsset.showLabel}
            onChange={handleToggle('showLabel')}
            value={'test'}
          />
        </div>
      </div>
    </div>
  );
}

export default ItemInfoPanel;
