
import { useDispatch, useSelector } from 'react-redux';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import {
  HandlesFromBlue,
  ControlsType,
} from '../../../models';
import { SetSelectedItems } from '../../../../../reducers/blue';
import { ChangeToolState } from '../../../../../reducers/globalState';
import {
  CatalogType
} from '../../../../../models';
import { ZoomBar, UndoRedo } from '../../bars';
import { AddItemPanel, EditItemPanel, EditSurfacePanel, LabelPanel } from '../../panels';
import PhotospherePanel from '../../panels/PhotospherePanel';
import TogglePhotosphereBar from '../../bars/TogglePhotosphereBar';
import {  GlobalViewState, PlannerTool, ToolState } from '../../../../../models/GlobalState';
import { ReduxState } from '../../../../../reducers';
import ViewOptions from '../../bars/ViewOptionsBar/ViewOptions'
import ShapePanel from '../../panels/ShapePanel'

interface Props {
  handlesFromBlue: HandlesFromBlue;
}

const FixturePlanUI = (props:Props) => {
  const blueControlsType = useSelector((state: ReduxState) => state.blue.controlsType);
  const plannerTool = useSelector((state: ReduxState) => state.globalstate.plannerTool);
  const toolState = useSelector((state: ReduxState) => state.globalstate.toolState);

  const dispatch = useDispatch();

  const resetToolToDefault = () => {
    dispatch(SetSelectedItems([]));
    dispatch(ChangeToolState(ToolState.AddItem));
  }

  const renderPanels = () => {
    switch (plannerTool) {
      case PlannerTool.Designer:
        switch (toolState) {
          case ToolState.EditItem:
            return (
              <EditItemPanel
                handlesFromBlue={props.handlesFromBlue}
                onBack={() => dispatch(resetToolToDefault)} />
            );
          case ToolState.AddItem:
            return (
              <AddItemPanel
                panelTitle={'Fixture'}
                catalogType={CatalogType.GeneralPurpose}
                addAssetControls={props.handlesFromBlue}
              />
            );
          case ToolState.EditSurface:
            return (
              <EditSurfacePanel
                handlesFromBlue={props.handlesFromBlue}
              />
            )
          default:
            return null;
        }
      case PlannerTool.Photosphere:
        return (
          <PhotospherePanel
            handlesFromBlue={props.handlesFromBlue}
            edit={true}
          />
        );
      case PlannerTool.Label:
        return (
          <LabelPanel/>
        );
      case PlannerTool.Shape:
        return (
          <ShapePanel
            handlesFromBlue={props.handlesFromBlue}
            onClosePanel={() => {}}
            onBack={() => { }}
          />
        );
      default:
        return null;
    }
  }

  const classes = styles(props);

  return (
    <>
      {plannerTool !== PlannerTool.Photosphere &&
        <UndoRedo/>
      }
      <div>
        {(plannerTool === PlannerTool.Photosphere) && blueControlsType === ControlsType.Photosphere &&
          <div>
            <TogglePhotosphereBar />
            <ViewOptions/>
          </div>
        }
        { blueControlsType !== ControlsType.Photosphere && plannerTool !== PlannerTool.Photosphere &&
          <div>
            <div className={classes.bottomButtons}>
              <ZoomBar globalState={GlobalViewState.Fixtures} />
            </div>
            <ViewOptions/>
          </div>
        }
      </div>
      {renderPanels()}
    </>
  );
}

const headerHeight = '29px';
const itempanelWidth = '258px';
const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    headerContainer: {
      width: '100%',
      height: headerHeight,
    },
    designerContainer: {
      display: 'flex',
      width: '100%',
      height: `calc(100% - ${headerHeight})`,
    },
    designerViewContainer: {
      width: `calc(100% - ${itempanelWidth})`,
      height: '100%',
    },
    designerViewContainerFullscreen: {
      width: '100%',
    },
    itempanels: {
      background: '#1F2126',
      width: itempanelWidth,
      display: 'flex',
      flexDirection: 'column',
      minHeight: 0,
    },
    itempanelsHidden: {
      display: 'hidden',
    },
    bottomButtons: {
      float: 'right',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  })
);

export default FixturePlanUI;
