import createReducer from './helpers/createReducer';
import { normalizeArray } from '../sharing/utils/normalizeArray';
import PlacezLayoutPlan from '../api/placez/models/PlacezLayoutPlan';

// Action Types
const GET_SCENE_LAYOUTS = 'GET_SCENE_LAYOUTS';
const GET_SCENE_LAYOUTS_SUCCESS = 'GET_SCENE_LAYOUTS_SUCCESS';
const GET_SCENE_LAYOUTS_FAILURE = 'GET_SCENE_LAYOUTS_FAILURE';


const UPDATE_LAYOUT = 'UPDATE_LAYOUT';
const UPDATE_LAYOUT_SUCCESS = 'UPDATE_LAYOUT_SUCCESS';
const UPDATE_LAYOUT_FAILURE = 'UPDATE_LAYOUT_FAILURE';

const UPDATE_LAYOUTS = 'UPDATE_LAYOUTS';
const UPDATE_LAYOUTS_SUCCESS = 'UPDATE_LAYOUTS_SUCCESS';

const CREATE_LAYOUT = 'CREATE_LAYOUT';
const CREATE_LAYOUT_SUCCESS = 'CREATE_LAYOUT_SUCCESS';
const CREATE_LAYOUT_FAILURE = 'CREATE_LAYOUT_FAILURE';

const DELETE_LAYOUT = 'DELETE_LAYOUT';
const DELETE_LAYOUT_SUCCESS = 'DELETE_LAYOUT_SUCCESS';
const DELETE_LAYOUT_FAILURE = 'DELETE_LAYOUT_FAILURE';

export const types = {
  GET_SCENE_LAYOUTS,
  GET_SCENE_LAYOUTS_SUCCESS,
  GET_SCENE_LAYOUTS_FAILURE,
  UPDATE_LAYOUT,
  UPDATE_LAYOUT_SUCCESS,
  UPDATE_LAYOUT_FAILURE,
  UPDATE_LAYOUTS,
  UPDATE_LAYOUTS_SUCCESS,
  CREATE_LAYOUT,
  CREATE_LAYOUT_SUCCESS,
  CREATE_LAYOUT_FAILURE,
  DELETE_LAYOUT,
  DELETE_LAYOUT_SUCCESS,
  DELETE_LAYOUT_FAILURE,
};

type Types =
  typeof GET_SCENE_LAYOUTS | typeof GET_SCENE_LAYOUTS_SUCCESS | typeof GET_SCENE_LAYOUTS_FAILURE |
  typeof UPDATE_LAYOUT | typeof UPDATE_LAYOUT_SUCCESS | typeof UPDATE_LAYOUT_FAILURE |
  typeof CREATE_LAYOUT | typeof CREATE_LAYOUT_SUCCESS | typeof CREATE_LAYOUT_FAILURE |
  typeof DELETE_LAYOUT | typeof DELETE_LAYOUT_SUCCESS | typeof DELETE_LAYOUT_FAILURE |
  typeof UPDATE_LAYOUTS | typeof UPDATE_LAYOUTS_SUCCESS;

// State
export type State = {
  newLayout: PlacezLayoutPlan,
  scene: {
    id: number,
    unsorted: PlacezLayoutPlan[],
    byId: { [layoutId: number] : PlacezLayoutPlan },
  };
};

const initialState: State = {
  newLayout: null,
  scene: {
    id: -1,
    unsorted: [],
    byId: null,
  },
};

// Action Creators
export const GetSceneLayouts = (sceneId: number) => ({
  type: GET_SCENE_LAYOUTS as typeof GET_SCENE_LAYOUTS,
  sceneId,
});

export const GetSceneLayoutsSuccess = (layouts: PlacezLayoutPlan[]) => ({
  type: GET_SCENE_LAYOUTS_SUCCESS as typeof GET_SCENE_LAYOUTS_SUCCESS,
  layouts,
});

export const GetSceneLayoutsFailure = (error: any) => ({
  type: GET_SCENE_LAYOUTS_FAILURE as typeof GET_SCENE_LAYOUTS_FAILURE,
  error,
});

export const UpdateLayout = (layout: PlacezLayoutPlan) => ({
  type: UPDATE_LAYOUT as typeof UPDATE_LAYOUT,
  layout,
});

export const UpdateLayoutSuccess = (layout: PlacezLayoutPlan) => ({
  type: UPDATE_LAYOUT_SUCCESS as typeof UPDATE_LAYOUT_SUCCESS,
  layout,
});

export const UpdateLayoutFailure = (error: any) => ({
  type: UPDATE_LAYOUT_FAILURE as typeof UPDATE_LAYOUT_FAILURE,
  error,
});

export const UpdateLayouts = (layouts: PlacezLayoutPlan[]) => ({
  type: UPDATE_LAYOUTS as typeof UPDATE_LAYOUTS,
  layouts,
});

export const UpdateLayoutsSuccess = (layouts: PlacezLayoutPlan[]) => ({
  type: UPDATE_LAYOUTS_SUCCESS as typeof UPDATE_LAYOUTS_SUCCESS,
  layouts,
});

export const CreateLayout = (layout: PlacezLayoutPlan) => ({
  type: CREATE_LAYOUT as typeof CREATE_LAYOUT,
  layout,
});

export const CreateLayoutSuccess = (layout: PlacezLayoutPlan) => ({
  type: CREATE_LAYOUT_SUCCESS as typeof CREATE_LAYOUT_SUCCESS,
  layout,
});

export const CreateLayoutFailure = (error: any) => ({
  type: CREATE_LAYOUT_FAILURE as typeof CREATE_LAYOUT_FAILURE,
  error,
});

export const DeleteLayout = (layoutId: string) => ({
  type: DELETE_LAYOUT as typeof DELETE_LAYOUT,
  layoutId,
});

export const DeleteLayoutSuccess = (layoutId: string) => ({
  type: DELETE_LAYOUT_SUCCESS as typeof DELETE_LAYOUT_SUCCESS,
  layoutId,
});

export const DeleteLayoutFailure = (error: any) => ({
  type: DELETE_LAYOUT_FAILURE as typeof DELETE_LAYOUT_FAILURE,
  error,
});

export type GetSceneLayoutsAction = ReturnType<typeof GetSceneLayouts>;
export type GetSceneLayoutsSuccessAction = ReturnType<typeof GetSceneLayoutsSuccess>;
export type GetSceneLayoutsFailureAction = ReturnType<typeof GetSceneLayoutsFailure>;
export type UpdateLayoutAction = ReturnType<typeof UpdateLayout>;
export type UpdateLayoutSuccessAction = ReturnType<typeof UpdateLayoutSuccess>;
export type UpdateLayoutFailureAction = ReturnType<typeof UpdateLayoutFailure>;
export type UpdateLayoutsAction = ReturnType<typeof UpdateLayouts>;
export type UpdateLayoutsSuccessAction = ReturnType<typeof UpdateLayoutsSuccess>;
export type CreateLayoutAction = ReturnType<typeof CreateLayout>;
export type CreateLayoutSuccessAction = ReturnType<typeof CreateLayoutSuccess>;
export type CreateLayoutFailureAction = ReturnType<typeof CreateLayoutFailure>;
export type DeleteLayoutAction = ReturnType<typeof DeleteLayout>;
export type DeleteLayoutSuccessAction = ReturnType<typeof DeleteLayoutSuccess>;
export type DeleteLayoutFailureAction = ReturnType<typeof DeleteLayoutFailure>;

export type Action =
  GetSceneLayoutsAction | GetSceneLayoutsSuccessAction | GetSceneLayoutsFailureAction |
  UpdateLayoutAction | UpdateLayoutSuccessAction | UpdateLayoutFailureAction |
  CreateLayoutAction | CreateLayoutSuccessAction | CreateLayoutFailureAction |
  DeleteLayoutAction | DeleteLayoutSuccessAction | DeleteLayoutFailureAction |
  UpdateLayoutsAction | UpdateLayoutsSuccessAction;

// Reducer
export default createReducer<State, Types, Action>(
  initialState,
  {
    [GET_SCENE_LAYOUTS]: (state: State, action: GetSceneLayoutsAction): State => ({
      ...state,
      scene: {
        ...state.scene,
        unsorted: [],
        id: action.sceneId,
      },
    }),
    [GET_SCENE_LAYOUTS_SUCCESS]: (state: State, action: GetSceneLayoutsSuccessAction): State => ({
      ...state,
      scene: {
        ...state.scene,
        unsorted: action.layouts,
        byId: normalizeArray(action.layouts, 'id'),
      },
    }),
    [GET_SCENE_LAYOUTS_FAILURE]: (state: State, action: GetSceneLayoutsFailureAction):
      State => (state),
    [UPDATE_LAYOUT]: (state: State, action: UpdateLayoutAction): State => (state),
    [UPDATE_LAYOUT_SUCCESS]: (state: State, action: UpdateLayoutSuccessAction):
      State => ({
        ...state,
        scene: {
          ...state.scene,
          unsorted: state.scene.unsorted
            .map(layout => layout.id === action.layout.id ?
            { ...layout, ...action.layout } : layout),
          byId: {
            ...state.scene.byId,
            [action.layout.id]: action.layout,
          },
        },
      }),
    [UPDATE_LAYOUTS]: (state: State, action: UpdateLayoutsAction): State => (state),
    [UPDATE_LAYOUTS_SUCCESS]: (state: State, action: UpdateLayoutsSuccessAction):
      State => ({
        ...state,
        scene: {
          ...state.scene,
          unsorted: state.scene.unsorted
            .map(layout => action.layouts.find((newLayout: PlacezLayoutPlan) => newLayout.id === layout.id) ?? layout ),
          byId: {
            ...state.scene.byId,
            ...action.layouts.reduce((acc, layout: PlacezLayoutPlan) => {acc[layout.id] = layout; return acc;}, {}),
          },
        },
      }),
    [UPDATE_LAYOUT_FAILURE]: (state: State, action: UpdateLayoutFailureAction):
      State => (state),
    [CREATE_LAYOUT]: (state: State, action: CreateLayoutAction): State => (state),
    [CREATE_LAYOUT_SUCCESS]: (state: State, action: CreateLayoutSuccessAction):
      State => ({
        ...state,
        scene: {
          ...state.scene,
          unsorted: state.scene.unsorted.concat([action.layout])
            .map(layout => layout.id === action.layout.id ?
            { ...layout, ...action.layout } : layout),
          byId: {
            ...state.scene.byId,
            [action.layout.id]: action.layout,
          },
        },
        newLayout: action.layout,
      }),
    [CREATE_LAYOUT_FAILURE]: (state: State, action: CreateLayoutFailureAction):
      State => (state),
    [DELETE_LAYOUT]: (state: State, action: DeleteLayoutAction): State => (state),
    [DELETE_LAYOUT_SUCCESS]: (state: State, action: DeleteLayoutSuccessAction):
      State => {
      const newById = state.scene.byId;
      delete newById[action.layoutId];
      return {
        ...state,
        scene: {
          ...state.scene,
          unsorted: state.scene.unsorted
          .filter(layout => layout.id !== action.layoutId),
          byId: {
            ...newById,
          },
        },
      };
    },
    [DELETE_LAYOUT_FAILURE]: (state: State, action: DeleteLayoutFailureAction):
        State => (state),
  }
);

export const getLayouts = (state: { layouts: State }) => {
  return state.layouts.scene.byId;
};
