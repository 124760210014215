import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../reducers/index';
import { CreateAttendee, UpdateAttendee, UpdateAttendees, DeleteAttendee } from '../../../reducers/attendee';
import { Attendee } from '../../../api';
import { WithModalContext } from '../withModalContext';
import XLSX from 'xlsx';

import {
  Theme,
  createStyles
} from '@material-ui/core/styles';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  DialogContentText,
  TextField,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import '@material-ui/core/';
import FileSelector from '../../Utils/FileSelector';
import { Utils } from '../../../blue/core/utils';
import { Input as InputIcon, Delete, Edit, Add, DeleteSweep, CloudDownload } from '@material-ui/icons';
import { AttendeeModalState } from '../../../models/AttendeeModalState';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { orderBy } from '@progress/kendo-data-query';
import { AttendeeMetadata } from '../../../api/placez/models/Attendee';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import { PDFIcon } from '../../../assets/icons';

const styles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
  title: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    fontSize: '40px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  content: {
    padding: 0,
    height: '450px',


    '& .k-grid': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    '& .k-grid-container': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    '& .k-grid-content': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    '& .k-grid-header': {
      paddingRight: '0px !important',
      color: theme.palette.text.primary,
    },
    '& .k-grid-content .k-virtual-content': {
      paddingRight: '0px !important',
    },
    '& .k-grid .k-grid-header th.k-header > .k-link': {
      textOverflow: 'initial',
      cursor: 'pointer',
    },
    '& .k-master-row:hover': {
      backgroundColor: 'rgba(92, 35, 111, 0.09)',
    },
    '& .k-detail-row .k-grid tbody tr:hover': {
      backgroundColor: 'rgba(92, 35, 111, 0.09) !important',
    },
    '& .k-grid td': {
      paddingTop: 4,
      paddingBottom: 4,
    },
  },
  field: {
    minWidth: '40%',
    margin: '10px 24px',
  },
  button: {
    padding: '4px 10px',
    borderRadius: theme.shape.borderRadius,
    width: '140px',
    margin: '20px',
  },
  actionButton: {
    padding: '4px 30px',
    borderRadius: theme.shape.borderRadius,
    width: '120px',
  },
  actions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    padding: theme.spacing(),
    textAlign: 'center',
  },
  formTwoColGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  paper: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    align: 'center',
  },
  attendeeCRUDbutton: {
    color: theme.palette.primary.main,
  },
  hidden: {
    display: 'none',
  },
  heading: {
    margin: 0,
    display: 'flex',
    justifyContent: 'center',
    paddingRight: 0,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  iconButton: {
    height: '20px',
    width: '20px',
    padding: 2,
    color: '#afa0b3',
    '&:hover': {
      color: 'purple',
    },
  },
  deleteIconButton: {
    height: '20px',
    width: '20px',
    padding: 2,
    '&:hover': {
      color: 'red',
    },
  },
  tableCell: {
    border: 'none',
  },
  })
);

interface Props extends WithModalContext{ }

const AttendeeForm = (props: Props) => {
  const [attendeeModalState, setAttendeeModalState] = useState(AttendeeModalState.ImportAttendee);
  const [attendeesChanged, setAttendeesChanged] = useState(false);
  const [attendeeSelectedRow, setAttendeeSelectedRow] = useState(0);
  const [sort, setSort] = useState([]);
  const [modifiedAttendee, setModifiedAttendee] = useState(undefined);

  const dispatch = useDispatch();
  const attendees = useSelector((state: ReduxState) => state.attendee.attendees);

  const handleFieldChange = (prop: string) => (event: any) => {
    setModifiedAttendee({
      ...modifiedAttendee,
      [prop]: event.target.value
    });
  }

  const handleSaveAttendeesToLayout = () => {
    props.modalContext.hideModal();
  }

  const handleFileSubmit = (files: FileList) => {
    readFile(files);
  }

  const readFile = async (files: FileList) => {
    const reader = new FileReader();
    reader.readAsBinaryString(files[0]);

    reader.onload = () => {
      const wb: XLSX.WorkBook = XLSX.read(reader.result, {
        type: 'binary',
      });
      const json = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
      applyAttendees(json as Attendee[]);
    };
  }

  const applyAttendees = (loadedAttendees: Attendee[]) => {
    const newAttendees = loadedAttendees.map(elem => {
      const ret: Attendee = {};
      Object.keys(elem)
        .forEach(k => {
          ret[Utils.camelize(k)] = elem[k];
        });
      return ret;
    });

    const importedAttendee = newAttendees.filter(attendee => attendee.firstName);
    const allAttendees = [...attendees, ...importedAttendee];

    setAttendeesChanged(true);
    setAttendeeModalState(AttendeeModalState.ListView);
    dispatch(UpdateAttendees(allAttendees));
  }

  useEffect(() => {
    if (attendees.length) {
      setAttendeeModalState(AttendeeModalState.ListView);
    }
  }, [])

  const onEditAttendee = (event, rowId) => {
    event.preventDefault();
    const attendee = attendees.find(attendee => {
      return attendee.id === rowId;
    });

    setModifiedAttendee({ ...attendee });
    setAttendeeModalState(AttendeeModalState.EditAttendee);
    setAttendeeSelectedRow(rowId);
    setAttendeesChanged(true);
  }

  const onAddAttendee = event => {
    event.preventDefault();

    setModifiedAttendee({
      firstName: '',
      lastName: '',
      meal: '',
      group: '',
      allergies: '',
      email: '',
      phone: '',
      rsvp: '',
    })
    setAttendeeModalState(AttendeeModalState.AddAttendee);
    setAttendeesChanged(true);
  }

  const onDeleteAttendee = (event, rowId) => {
    event.preventDefault();
    const deletedAttendee = attendees.find(attendeeElement => attendeeElement.id === rowId);

    if (deletedAttendee) {
      setAttendeesChanged(true);
      dispatch(DeleteAttendee(deletedAttendee));
    }
  }

  const switchToImport = e => {
    setAttendeeModalState(AttendeeModalState.ImportAttendee);
  }

  const clearAttendees = e => {
    const emptyAttendees = [] as Attendee[];
    dispatch(UpdateAttendees(emptyAttendees));
  }

  const exportAttendees = e => {
    const fileName = 'attendees.xlsx';
    const filterOut = [
      'id',
      'layoutId',
      'deleted',
      'createdUtcDateTime',
      'lastModifiedBy',
      'createdBy',
      'lastModifiedUtcDateTime',
    ]
    const cleanAttendees = JSON.parse(JSON.stringify(attendees));
    cleanAttendees.forEach(attendee => {
      filterOut.forEach(key => {
        delete attendee[key];
      });
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(cleanAttendees);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'test');
		XLSX.writeFile(wb, fileName);
  }

  const updateAttendee = (attendee: Attendee) => {
    const updatedAttendee = {
      ...attendee,
      ...modifiedAttendee,
    }

    const newAttendee = !attendee.id;
    if (newAttendee) {
      dispatch(CreateAttendee(updatedAttendee));
    } else {
      dispatch(UpdateAttendee(updatedAttendee));
    }

    setAttendeeModalState(AttendeeModalState.ListView);
  }

  const handleSave = e => {
    e.preventDefault();

    switch (attendeeModalState) {
      case AttendeeModalState.EditAttendee:
        handleEditAttendee();
        break;
      case AttendeeModalState.ListView:
        handleSaveAttendeesToLayout();
        break;
      case AttendeeModalState.AddAttendee:
        handleAddAttendee();
        break;
      case AttendeeModalState.ImportAttendee:
        handleAddAttendee();
        break;
      default:
    }
  }

  const handleAddAttendee = () => {
    const attendee = {} as Attendee;
    updateAttendee(attendee);
  }

  const handleEditAttendee = () => {
    const attendee: Attendee = attendees.find(attendee => attendee.id === attendeeSelectedRow);
    updateAttendee(attendee);
  }

  const editAttendeeCell = props => <EditAttendeeCell {...props} classes={props.classes} propOnEdit={onEditAttendee} />;
  const deleteAttendeeCell = props => <DeleteAttendeeCell {...props} classes={props.classes} propOnDelete={onDeleteAttendee} />;

  const { hideModal } = props.modalContext;
  const classes = styles(props);

  let gridPDFExport: GridPDFExport | null;
  const exportPDF = () => {
    if (gridPDFExport !== null) {
      gridPDFExport.save();
    }
  };

  const attendeeGrid = (
    <Grid
      sortable
      sort={sort}
      onSortChange={e => setSort(e.sort)}
      selectedField="selected"
      data={orderBy(
        attendees,
        sort)
      }>
      {AttendeeMetadata
        .filter(metadata => metadata !== 'tableInfo' && metadata !== 'chairNumber')
        .map(metadata => {
          return (
            <GridColumn
              field={metadata}
              title={Utils.camelToUpperCase(metadata)}
              key={metadata}
            />
          );
        })
      }
      <GridColumn cell={editAttendeeCell} width="50px" sortable={false} />
      <GridColumn cell={deleteAttendeeCell} width="75px" sortable={false} />
    </Grid>
  );

  const pdfGrid = (
    <Grid
      sortable
      sort={sort}
      onSortChange={e => setSort(e.sort)}
      selectedField="selected"
      data={orderBy(
        attendees,
        sort)
      }>
      {AttendeeMetadata
        .filter(metadata => metadata !== 'tableInfo' && metadata !== 'chairNumber')
        .map(metadata => {
          return (
            <GridColumn
              field={metadata}
              title={Utils.camelToUpperCase(metadata)}
              key={metadata}
            />
          );
        })
      }
    </Grid>
  );

  return (
    <>
      {attendeeModalState === AttendeeModalState.ImportAttendee &&
        <>
          <DialogTitle
            id="place-modal-dialog"
            className={classes.title}>
            Import Attendee List
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.container}>
              <Button href={`${window.env['REACT_APP_DAM']}/Assets/e63e74f2-7199-42f1-bee8-259e5d7d61d4.csv`}>
                <CloudDownload className={classes.attendeeCRUDbutton} />
                &nbsp;Template
              </Button>
              <div className={classes.field}>
                <FileSelector
                  customID="AttendeeCSV"
                handleFileSubmit={handleFileSubmit}
                  accept=".csv, .xls, .xlsx" />
              </div>
              <Button onClick={e => onAddAttendee(e)}>
                <Add className={classes.attendeeCRUDbutton} />
                &nbsp;Add New
              </Button>
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button className={classes.button} variant="contained" onClick={hideModal}>Cancel</Button>
            <Button
              className={classes.actionButton}
              variant="contained"
              disabled={!attendeesChanged}
              color="primary"
              onClick={e => handleSave(e)}>
              Ok
            </Button>
          </DialogActions>
        </>
      }
      {attendeeModalState === AttendeeModalState.AddAttendee &&
        <>
          <DialogTitle
            id="place-modal-dialog"
            className={classes.title}>
            Add Attendee
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div className={classes.formTwoColGrid}>
              {AttendeeMetadata
                .filter(metadata => metadata !== 'tableInfo' && metadata !== 'chairNumber')
                .map(metadata => {
                  return (
                    <TextField
                      className={classes.field}
                      id={metadata}
                      label={Utils.camelToUpperCase(metadata)}
                      value={modifiedAttendee[metadata] || ''}
                      onChange={handleFieldChange(metadata)}
                      key={metadata}
                    />
                  );
                })}
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
            onClick={() => setAttendeeModalState(AttendeeModalState.ListView) }
            >
              Cancel
            </Button>
            <Button
              className={classes.button}
              variant="contained"
              disabled={
                !attendeesChanged
              || (AttendeeModalState.AddAttendee && !modifiedAttendee.firstName)
              }
              color="primary"
            onClick={e => handleSave(e)}>
              Ok
            </Button>
          </DialogActions>
        </>
      }
      {attendeeModalState === AttendeeModalState.ListView &&
        <>
          <DialogTitle
            id="place-modal-dialog"
            className={classes.title}>
            Guest Report
          </DialogTitle>
          <DialogActions className={classes.heading}>
          <Button className={classes.button} onClick={e => onAddAttendee(e)}>
              <Add className={classes.attendeeCRUDbutton} />
              &nbsp;Add New
            </Button>
          <Button className={classes.button} onClick={e => switchToImport(e)}>
              <InputIcon className={classes.attendeeCRUDbutton} />
              &nbsp;Import
            </Button>
          <Button className={classes.button} onClick={e => clearAttendees(e)}>
              <DeleteSweep className={classes.attendeeCRUDbutton} />
              &nbsp;Clear
            </Button>
          <Button className={classes.button} onClick={e => exportAttendees(e)}>
              <CloudDownload className={classes.attendeeCRUDbutton} />
              &nbsp;Excel
            </Button>
          <Button className={classes.button} onClick={exportPDF}>
              <PDFIcon className={classes.attendeeCRUDbutton} />
              &nbsp;PDF
            </Button>
          </DialogActions>
          <DialogContent dividers={false} className={classes.content}>
            <DialogContentText>
              {attendeeGrid}
              <GridPDFExport
                ref={(pdfExport) => (gridPDFExport = pdfExport)}>
                {pdfGrid}
              </GridPDFExport>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            onClick={e => handleSave(e)}>
              Ok
            </Button>
          </DialogActions>
        </>
      }
      {attendeeModalState === AttendeeModalState.EditAttendee &&
        <>
          <DialogTitle
            id="place-modal-dialog"
            className={classes.title}>
            Edit Attendee
          </DialogTitle>
          <DialogContent dividers={true} className={classes.content}>

            <div className={classes.formTwoColGrid}>
              {AttendeeMetadata
                .filter(metadata => metadata !== 'tableInfo' && metadata !== 'chairNumber')
                .map(metadata => {
                  return (
                    <TextField
                      onKeyPress={e => { if (e.key === 'Delete' || e.key === "Backspace") { e.stopPropagation(); e.preventDefault(); } }}
                      className={classes.field}
                      id={metadata}
                      label={Utils.camelToUpperCase(metadata)}
                      value={modifiedAttendee[metadata] || ''}
                      onChange={handleFieldChange(metadata)}
                      key={metadata}
                    />
                  );
                })}
            </div>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              className={classes.actionButton}
              variant="contained"
            onClick={() => setAttendeeModalState(AttendeeModalState.ListView)}>
              Cancel
            </Button>
            <Button
              className={classes.actionButton}
              variant="contained"
              disabled={
                !attendeesChanged
              || (AttendeeModalState.EditAttendee && !modifiedAttendee.firstName)
              }
              color="primary"
              onClick={e => handleSave(e)}>
              Ok
            </Button>
          </DialogActions>
        </>
      }
    </>
  );
}

interface DeleteAttendeeCellProps extends WithModalContext {
  propOnDelete: Function;
  dataItem: any;
}

const DeleteAttendeeCell = (props: DeleteAttendeeCellProps) => {
  const classes = styles(props);
  const { id } = props.dataItem;
  return (
    <td>
      <Tooltip title="Delete Attendee">
        <IconButton
          className={classes.deleteIconButton}
          aria-label="delete-attendee"
          onClick={e => props.propOnDelete(e, id)}>
          <Delete fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </td>
  );
}

interface EditAttendeeCellProps extends WithModalContext {
  propOnEdit: Function;
  dataItem: any;
}

const EditAttendeeCell = (props: EditAttendeeCellProps) => {
  const classes = styles(props);
  const { id } = props.dataItem;
  return (
    <td>
      <Tooltip title="Edit Attendee">
        <IconButton
          className={classes.iconButton}
          aria-label="edit-attendee"
          onClick={e => props.propOnEdit(e, id)}>
          <Edit fontSize="medium"/>
        </IconButton>
      </Tooltip>
    </td>
  );
}

export default AttendeeForm
